import React, { useState, useCallback } from 'react';
import styled, { css } from 'styled-components';
import SelectButton from '../atoms/SelectButton';
import SelectList from '../atoms/SelectList';
import { SelectOption, OptionText } from '../atoms/SelectOption';
import SearchInput from '../atoms/SearchInput';
import { CheckMarkSVG } from '../../svg';

const Container = styled.div<{
  disabled?: boolean;
  width?: string;
  height?: string;
}>`
  position: relative;
  outline: none;
  width: ${(props) => (props.width ? props.width : '135px')};
  height: ${(props) => (props.height ? props.height : '100%')};
  ${({ disabled }) =>
    disabled
      ? css`
          opacity: 0.5;
        `
      : null}
  & > button {
    width: ${(props) => (props.width ? props.width : '135px')};
    height: ${(props) => (props.height ? props.height : '100%')};
    border: solid 1px hsl(0, 0%, 92%);
  }
`;

const RadioBox = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 2px solid #62768c;
  margin-right: 10px;
  flex-shrink: 0;
`;

const CheckMarkIcon = styled(CheckMarkSVG)`
  width: 14px;
  height: 14px;
  margin-right: 10px;
  flex-shrink: 0;
`;

interface IListItem {
  title: string;
  value: string;
}

interface ISelectProps {
  title: string | number;
  list: (string | IListItem | number)[];
  selected: string | number;
  onSelect: (item: string | string[] | number | number[]) => void;
  showFilter?: boolean;
  clearable?: boolean;
  icon?: string;
  disabled?: boolean;
  width?: string;
  height?: string;
  paddingRight?: string;
  boxWidth?: string;
  iconSize?: number;
  DropdownIconSize?: number;
  hideArrow?: boolean;
  bgColor?: string;
}

const Select: React.FC<ISelectProps> = ({
  title,
  showFilter,
  list,
  iconSize = 17,
  selected,
  icon,
  clearable,
  onSelect,
  disabled,
  width,
  height,
  paddingRight,
  boxWidth = '268px',
  DropdownIconSize = 9,
  hideArrow = false,
  bgColor
}) => {
  const [expanded, setExpanded] = useState(false);
  const toggle = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);
  const select = useCallback(
    (item: string | number) => {
      if (clearable && selected === item) {
        onSelect('');
      } else {
        onSelect(item);
      }
      setExpanded(false);
    },
    [onSelect, clearable, selected]
  );
  const onBlur = useCallback((e: React.FocusEvent<HTMLDivElement>) => {
    const currentTarget = e.currentTarget;
    setTimeout(() => {
      if (!currentTarget.contains(document.activeElement)) {
        setExpanded(false);
      }
    }, 0);
  }, []);

  return (
    <Container
      tabIndex={0}
      onBlur={onBlur}
      disabled={disabled}
      width={width}
      height={height}
    >
      <SelectButton
        expanded={expanded}
        paddingRight={paddingRight}
        onClick={disabled ? () => { } : toggle}
        title={title.toString()}
        icon={icon}
        iconSize={iconSize}
        DropdownIconSize={DropdownIconSize}
        hideArrow={hideArrow}
        bgColor={bgColor}
        disabled={disabled}
      />
      <SelectList expanded={expanded} boxWidth={boxWidth}>
        {showFilter ? <SearchInput type='text' iconSize={iconSize} /> : ''}
        {list.map((item: string | IListItem | number) => {
          const value =
            typeof item === 'string' || typeof item === 'number'
              ? item
              : item.value;
          const title =
            typeof item === 'string' || typeof item === 'number'
              ? item
              : item.title;
          return (
            <SelectOption
              selected={selected === value}
              onClick={() => {
                select(value);
              }}
              key={title}
            >
              {selected === value ? <CheckMarkIcon /> : <RadioBox />}
              <OptionText title={title.toString()}>{title}</OptionText>
            </SelectOption>
          );
        })}
      </SelectList>
    </Container>
  );
};

export default Select;
