import React from 'react';
import styled, { css } from 'styled-components';
import { resetButtonStyles, RowCss } from '../tools/common';
import { SelectType } from '../tools/common/index';
import { Icon as SelectIcon } from 'scorer-ui-kit';
import { MetaNetwork } from '../../svg'

const Button = styled.button<{
  expanded: boolean;
  bgColor?: string;
  selectType: SelectType;
  isLoading: boolean;
  disabled: boolean;
  width: string;
  paddingRight?: string;
}>`
  ${resetButtonStyles}
  ${RowCss}
  font-family: ${({ theme }) => theme.fontFamily.ui};
  height: 100%;
  display: flex;
  align-items: center;
  color: hsl(208, 8%, 39%);
  border: 1px solid rgb(217, 218, 217);
  box-sizing: border-box;
  width: ${(props) => (props.width ? props.width : 'auto')};
  justify-content: space-between;
  padding-left: 6px;
  padding-right: ${(props) => props.paddingRight ? props.paddingRight : '10px'};
  white-space: nowrap;
  outline: none;
  color: hsl(0, 0%, 50%);
  font-size: 16px;
  background-color: ${({ bgColor }) => (bgColor ? bgColor : 'hsl(0, 0%, 100%)')};
  border-radius: '0px 3px 3px 0px';
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  ${(props) =>
    props.expanded &&
    css`
      background-color: hsl(204, 50%, 68%);
      color: hsl(0, 0%, 100%);
      svg {
        fill: hsl(0, 0%, 100%);
        stroke: hsl(0, 0%, 100%);
      }
    `}
  ${(props) =>
    props.selectType === 'primary' &&
    css`
      font-family: ${({ theme }) => theme.fontFamily.data};
      height: 35px;
      line-height: 0.94;
      letter-spacing: normal;
      color: hsl(207, 5%, 57%);
      background-color: hsl(0, 0%, 100%);
      border-radius: 3px;
      border: solid 1px hsl(120, 1%, 85%);
      svg {
        fill: hsla(205, 60%, 57%, 0.65);
        stroke: hsla(205, 60%, 57%, 0.65);
      }
    `}
  ${(props) =>
    props.selectType === 'primary' &&
    props.disabled &&
    css`
      color: hsla(0, 0%, 46%, 0.5);
      font-style: italic;
    `}

  ${(props) =>
    props.isLoading === true &&
    css`
      border-top-right-radius: none;
      border-bottom-right-radius: none;
      border-right: none;
    `};
`;

const ButtonText = styled.span<{ expanded: boolean }>`
  margin-left: 5px;
  margin-right: 13px;
  color: ${({ expanded }) => !expanded ? '#717989' : 'white'};
  font-size: 14px;
  font-family: monorale;
`;

const IconWrapper = styled.div`
  padding: 4px;
`;

const MetaNetworkWrapper = styled.div<{ expanded: boolean }>`
  > svg{
    > g{
      > rect{
        stroke: ${({ expanded }) => !expanded ? '#80838d' : 'white'};
      }
      > path{
        stroke: ${({ expanded }) => !expanded ? '#b9bbc6' : 'white'}; 
      }
    }
  }
`;

const MetaNetworkIcon = styled(MetaNetwork)`
  height: 12px;
  width: 12px;
`;

interface ISelectButtonProps
  extends React.InputHTMLAttributes<HTMLButtonElement> {
  title: string;
  expanded: boolean;
  bgColor?: string;
  icon?: string;
  iconSize?: number;
  hideArrow?: boolean;
  selectType?: SelectType;
  width?: string;
  isLoading?: boolean;
  disabled?: boolean;
  paddingRight?: string;
  DropdownIconSize?: number;
}

const SelectButton: React.FC<ISelectButtonProps> = ({
  title,
  expanded,
  bgColor,
  onClick,
  hideArrow,
  selectType = 'toolbar',
  width = 'auto',
  isLoading = false,
  disabled = false,
  icon,
  iconSize = 17,
  paddingRight,
  DropdownIconSize,
}) => {
  const getArrow = (
    expanded = false,
    hideArrow = false,
    DropdownIconSize = 9
  ) => {
    if (!hideArrow) {
      return expanded ? (
        <SelectIcon size={9} color='inverse' icon='Up' />
      ) : (
        <SelectIcon size={DropdownIconSize} color='dimmed' icon='Down' />
      );
    } else {
      return null;
    }
  };

  return (
    <Button
      type='button'
      expanded={expanded}
      bgColor={bgColor}
      onClick={onClick}
      selectType={selectType}
      width={width}
      isLoading={isLoading}
      disabled={disabled}
      paddingRight={paddingRight}
    >
      {icon && (
        <IconWrapper>
          {icon === 'meta-network' ? (
            <MetaNetworkWrapper
              expanded={expanded}
            >
              <MetaNetworkIcon />
            </MetaNetworkWrapper>
          ) : (
            <SelectIcon
              size={iconSize}
              color={expanded ? 'inverse' : 'dimmed'}
              icon={icon}
            />
          )}
        </IconWrapper>
      )}

      <ButtonText expanded={expanded}>{title}</ButtonText>
      {getArrow(expanded, hideArrow, DropdownIconSize)}
    </Button>
  );
};

export default SelectButton;
