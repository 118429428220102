import React, { useContext, useState, useCallback, useEffect, ReactElement, useRef } from 'react';
import styled from 'styled-components';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { Button, Content, Icon, PageHeader, MultilineContent, useCopyToClipboard, useClickOutside, Spinner, Icon as SelectIcon, ButtonWithIcon, useModal } from 'scorer-ui-kit';
import { useNotification } from '../components/Notifications/useNotification';
import { ITableColumnConfig } from 'scorer-ui-kit/dist/Tables';
import { useTranslation } from 'react-i18next';
import SortBy, { ISortBy, ISortItem } from '../components/molecules/SortBy';
import Select from '../components/molecules/Select';
import SearchInput from '../components/atoms/SearchInput';
import { useWindowSize } from '../hooks/useWindowSize';
import { FeatureEnabledContext, ContextProps, EditContextProps, EditRoutingContext } from '../App';
import AccordionTable from '../components/accordionTable/AccordionTable';
import { IAccordionRowData, IAccordionTableData } from '../components/accordionTable/AccordionTable';
import GenericDropdown from '../components/molecules/GenericDropdown';
import InputFilter from '../components/molecules/InputFilter';
import Breadcrums from '../components/molecules/Breadcrums';
import { DeviceLinksData, getDeviceStatus, getWebgateDeviceLinks, getWebgateLinkInfo, getWebgateStatus, isWebgateEnabled, Links, toggleWebgateDevice, WebgateLinkInfo } from '../lib/api_config';
import { MetaNetwork } from '../svg';
import InfoTooltip from '../components/atoms/InfoTooltip';
import useCheckDevice from '../hooks/useCheckDevice';
import { convertValue, getUnit } from '../Utils/ConversionUtils';
import { formatEpochToDateTime, formatTimestamp, getSystemTimezoneAbbreviation, timeAgo } from '../Utils/DateUtils';
import { handleException } from '../hooks/handleException';
import { HistoryParams } from './WebGateway';
import { handleDismiss } from '../components/Notifications/Notification';
import ConfirmModal from '../components/molecules/ConfirmModal';
import ConfirmEnableModal from '../components/molecules/ConfirmEnableModal';
import i18n from '../i18n';

const Wrapper = styled.div<{ enableContent?: boolean }>`
  width: 100%;
  opacity: ${({ enableContent }) => (enableContent ? '1' : '0.6')};
  pointer-events: ${({ enableContent }) => (enableContent ? 'auto' : 'none')};
`;

const ContentWrapper = styled(Content)<{ isMenuOpen?: boolean }>`
  width: 100%;
  padding: 0px;
  min-width: ${({ isMenuOpen }) => isMenuOpen ? '1024px' : '944px'};
  max-width: ${({ isMenuOpen }) => isMenuOpen ? 'calc(100vw - 280px)' : 'calc(100vw - 80px)'};
  div {
    max-width: 100%;
  }
`;

const DrawerSection = styled.div<{ isMenuOpen?: boolean }>`
  padding: 48px 120px 0px;

  // Media queries for screen 1024px - 1279px.
  @media (max-width: ${({ isMenuOpen }) => (isMenuOpen ? '1479px' : '1279px')}) {
    padding: 48px 45px 0px 67px;
  }

  // Media queries for screen 1280px - 1535px.
  @media (min-width: ${({ isMenuOpen }) => (isMenuOpen ? '1480px' : '1280px')}) and (max-width: ${({ isMenuOpen }) => (isMenuOpen ? '1735px' : '1535px')}) {
    padding: 48px 99px 0px;
  }

  // Media queries for screen greater than 1535px.
  @media (min-width: ${({ isMenuOpen }) => (isMenuOpen ? '1736px' : '1536px')}) {
    padding: 48px 123px 0px;
  }
`;

const Drawer = styled.div`
  height: 24px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  color: #898e96;
`;

const IconWrapper = styled.div<{
  marginTop?: string;
  height?: string;
  width?: string;
  position?: 'absolute' | 'relative';
  left?: string;
  top?: string;
  iconMargin?: string;
}>`
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '4px')};
  width: ${({ width }) => (width ? width : '16px')};
  height: ${({ height }) => (height ? height : '16px')};
  display: flex;
  justify-content: center;
  padding: 4px;
  align-items: center;
  flex-grow: 0;
  position: ${({ position }) =>
    position === 'absolute' ? position : 'relative'};
  left: ${({ position, left }) => (position === 'absolute' ? left : 'auto')};
  top: ${({ position, top }) => (position === 'absolute' ? top : 'auto')};
  > div {
    margin: ${({ iconMargin }) => (iconMargin ? iconMargin : '0px')};
  }
`;

const ColumnText = styled.p<{
  padding?: string;
  fontSize?: string;
  color?: string;
  fontWeight?: string;
  letter?: string;
  line?: string;
  margin?: string;
  family?: string;
}>`
  height: auto;
  padding: ${({ padding }) => (padding ? padding : '0px')};
  font-family: ${({ family }) => (family ? family : 'Lato')};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '14px')};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ color }) => (color ? color : '#898e96')};
  margin: ${({ margin }) => (margin ? margin : '0px')};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : '500')};
  letter-spacing: ${({ letter }) => letter};
  line-height: ${({ line }) => line};
`;

const HeaderSection = styled.div<{ isMenuOpen?: boolean }>`
  min-width: 929px;
  padding: 24px 120px 46px;

  // Media queries for screen 1024px - 1279px.
  @media (max-width: ${({ isMenuOpen }) => (isMenuOpen ? '1479px' : '1279px')}) {
    padding: 26px 49px 32px 64px;
    margin-top: 38px;
    margin-bottom: 15px;
  }

  // Media queries for screen 1280px - 1535px.
  @media (min-width: ${({ isMenuOpen }) => (isMenuOpen ? '1480px' : '1280px')}) and (max-width: ${({ isMenuOpen }) => (isMenuOpen ? '1735px' : '1535px')}) {
    padding: 24px 81px 47px 96px;
  }

  // Media queries for screen greater than 1535px.
  @media (min-width: ${({ isMenuOpen }) => (isMenuOpen ? '1736px' : '1536px')}) {
    padding: 24px 120px 47px 120px;
  }
`;

const InnerHeader = styled.div`
  width: 100%;
  height: auto;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 16px;
`;

const LinkRoute = styled(Link)`
  width: auto;
  height: 32px;
  font-size: 14px;
  font-weight: 500;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none !important;
`;

const DeviceLeftContainer = styled.div<{ isMenuOpen?: boolean }>`
  width: auto;
  height: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 26px;
  padding: 0 32px 4px 0;

  // Media queries for screen 1024px - 1279px.
  @media (max-width: ${({ isMenuOpen }) => (isMenuOpen ? '1479px' : '1279px')}) {
    gap: 25px;
  }
`;

const DeviceInfo = styled.div<{ isMenuOpen?: boolean }>`
  width: auto;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 60px;
  padding: 0px 0 4px;
  color: #8b9196;
  position: relative;

  > div:first-child {
    max-width: 50%; 

    > div > div:first-child {
      > div:nth-child(2) {
        > h1 {
          line-height: 30px; 
        }
        > div:first-child {
          overflow-wrap: anywhere;
        }
      }

      // Media queries for screen above 1280px.
      @media (min-width: ${({ isMenuOpen }) => (isMenuOpen ? '1480px' : '1280px')}) {
        > div:nth-child(2) {
          gap: 1px;
        }
      }

      // Media queries for screen 1024px - 1279px.
      @media (max-width: ${({ isMenuOpen }) => (isMenuOpen ? '1479px' : '1279px')}) {
        gap: 10px;
        flex-direction: column;
        margin-top: -37px;
        > div:first-child {
          justify-content: left;
          margin-left: 4px;
        }
        > div:nth-child(2) {
          gap: 0px;
        }
      }
    }
  }  
`;

const DeviceStatusMeta = styled.div<{ isMenuOpen?: boolean }>`
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;

  // Media queries for screen 1024px - 1279px.
  @media (max-width: ${({ isMenuOpen }) => (isMenuOpen ? '1479px' : '1279px')}) {
    padding: 4px 0 1px 0px;
  }

  // Media queries for screen size above 1280px.
  @media (min-width: ${({ isMenuOpen }) => (isMenuOpen ? '1480px' : '1280px')}) {
    padding: 3px 0 1px 0px;
  }
`;

const DeviceStatusSection = styled.div`
  width: auto;
  min-width: 602px;
  height: 35px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 7px;
  padding: 0 0 7px;
`;

const DeviceStatus = styled.div<{ height?: string; margin?: string }>`
  width: auto;
  height: ${({ height }) => (height ? height : '28px;')};
  margin: ${({ margin }) => (margin ? margin : '0px;')};
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius: 3px;
  border: solid 1px #e6e8ec;
  > button {
    pointer-events: none;
    > p {
      line-height: 28px;
    }
  }
  > button:last-child {
    padding-right: 15px;
  }
`;

const StatusColor = styled.div<{
  width?: string;
  height?: string;
  color?: string;
}>`
  width: ${({ width }) => (width ? width : '4px')};
  height: ${({ height }) => (height ? height : '12px')};
  flex-grow: 0;
  border-radius: 3px;
  background-color: ${({ color }) => (color ? color : '#5bb98c')};
`;

const IconButton = styled(Button) <{
  border?: string;
  background?: string;
  radius?: string;
  height?: string;
  gap?: string;
  padding?: string;
  top?: string;
}>`
  display:flex;
  height: ${({ height }) => (height ? height : '28px')};
  align-items: center;
  justify-content:center';
  font-size : 14px;
  padding: ${({ padding }) => (padding ? padding : '0 8px')};
  gap: ${({ gap }) => (gap ? gap : '8px')};
  border-right: solid 1px #e6e8ec;
  border: ${({ border }) => (border ? border : '')};
  border-radius: ${({ radius }) => (radius ? radius : '3px')};
  background: ${({ background }) => (background ? background : '')};
`;

const DeviceMetas = styled.div`
  height: 100%;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  padding: 0;
  max-width: 50% !important;
`;

const DeviceTagCategory = styled.div`
  height: auto;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
  padding: 0;
  margin-left: 0px;
  flex-wrap: wrap;
  
  button {
    pointer-events: none;
    > p {
      letter-spacing: -0.1px;
      overflow-wrap: anywhere;
      line-height: 18px;
      text-align: left;
    }
  }
`;

const LineDivider = styled.div`
  width: 100% !important;
  height: 4px;
  padding: 8px;
  border-top: 1px solid #e6e8ec;
`;

const DeviceRightContainer = styled.div<{ isMenuOpen?: boolean }>`
  a {
    height: 28px;
    margin-top: 3px;
    > div > button {
      height: 28px;
      border: solid 1px #80b5ff;
      background-color: #e8e8ec;
      > div {
        > div:first-child {
          line-height: 28px;
        }
        > div:nth-child(2) {
          width: 28px;
        }
      }
    }
  }

  // Media queries for screen 1024px - 1279px.
  @media (max-width: ${({ isMenuOpen }) => (isMenuOpen ? '1479px' : '1279px')}) {
    a {
      margin-top: -5px;
    }
  }
`;

const DataContainer = styled.div<{ isMenuOpen?: boolean }>`
  height: auto;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 44px;
  min-width: 929px;

  padding: 34px 120px 64px;

  // Media queries for screen 1024px - 1279px.
  @media (max-width: ${({ isMenuOpen }) => (isMenuOpen ? '1479px' : '1279px')}) {
    padding: 33px 32px 64px 64px;
    gap: 48px;
  }

  // Media queries for screen 1280px - 1535px.
  @media (min-width: ${({ isMenuOpen }) => (isMenuOpen ? '1480px' : '1280px')}) and (max-width: ${({ isMenuOpen }) => (isMenuOpen ? '1735px' : '1535px')}) {
    padding: 37px 81px 64px 96px;
  }

  // Media queries for screen greater than 1535px.
  @media (min-width: ${({ isMenuOpen }) => (isMenuOpen ? '1736px' : '1536px')}) {
    padding: 35px 104px 64px 120px;
  }
`;

const ActionFilterResultContainer = styled.div<{ isMenuOpen?: boolean }>`
  height: 128px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 20px;
  padding: 0;

  > div:first-child {
    // Media queries for screen 1024px - 1279px.
    @media (max-width: ${({ isMenuOpen }) => (isMenuOpen ? '1479px' : '1279px')}) {
      margin-top: 4px;
    }
  }
`;

const ActionContainer = styled.div`
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  padding: 0;
  padding-top: 2px;
`;

const SearchFieldContainer = styled.div`
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 9px;
  padding: 0;
`;

const LeftContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const InputWrapper = styled.div`
  border-radius: 3px;
  svg {
    position: absolute;
    left: 10px;
    top: 10px;
    fill: hsl(0, 0%, 0%, 0.32);
    stroke: hsl(0, 0%, 0%, 0.32);
    z-index: 1;
  }
  input::placeholder {
    color: rgba(118, 118, 118, 0.65);
    font-size: 11.5px;
    letter-spacing: 0.15px;
    position: absolute;
    top: 8px;
    left: 31px;
    font-family: monorale;
  }
`;

const SelectType = styled.div`
  > div > div {
    max-width: max-content;
  }
  button {
    box-shadow: 0 2px 1px 0 rgba(0, 102, 255, 0.04);
    border: solid 1px rgba(0, 29, 95, 0.16);
    border-radius: 3px;
    span {
      font-size: 12px;
      line-height: 12px;
      font-weight: 500;
    }
    > div:last-child {
      svg {
        width: 6px;
        height: 6px;
      }
    }
  }
`;

const FilterInput = styled(SearchInput)<{ lang?: string;}>`
  & input {
    box-sizing: border-box;
    width: 220px;
    height: 32px;
    background-color: #f0f0f3;
    font-size: 12px;
    border-radius: 3px;
    box-shadow: 0 2px 1px 0 rgba(0, 102, 255, 0.04);
    border: solid 1px rgba(0, 29, 95, 0.16);
    flex-shrink: 0;
    ::placeholder {
      color: #80838d;
      position: absolute;
      top: ${({ lang }) => (lang === 'ja' ? '6px' : '8px')}
    }
  }
`;

const AreaTitle = styled.div`
  width: auto;
  height: 24px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  padding: 6px 0;
`;

const BoldText = styled.p`
  width: auto;
  height: 14px;
  flex-grow: 0;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  margin: 0;
  padding: 0px;
  color: #8b9196;
`;

const ButtonListContainer = styled.div`
  height: 32px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
  padding: 0;
`;

const ButtonList = styled.div`
  height: 32px;
  flex-grow: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  gap: 4px;
  padding: 0;
`;

const ResultInfoContainer = styled.div`
  height: 40px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0;
`;

const ResultCountLabel = styled.label`
  font-family: ${({ theme }) => theme.fontFamily.data};
  position: relative;
  display: flex;
  flex-flow: row wrap;
  max-width: 80%;
  font-size: 12px;
  color: #898f95;
  line-height: 1.67;
  font-weight: 500;
`;

const FilterContainer = styled.div`
  padding-left: 10px;
  display: flex;
`;

const FilterListIcon = styled.div`
  margin-top: 2px;
`;

const ClearLinkAll = styled.label`
  width: auto;
  margin: 0px 0 0 10px;
  opacity: 0.6;
  font-family: ${({ theme }) => theme.fontFamily.data};
  font-size: 12px;
  font-weight: 500;
  color: #676e74;
  text-decoration: underline;
  cursor: pointer;
  padding-left: 5px;
`;

const ClearItem = styled(FilterListIcon)`
  cursor: pointer;
`;

const FilterText = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-left: 11px;
  margin-right: 5px;
  font-size: 12px;
  font-weight: 500;
  color: hsl(210, 5%, 56%);
`;

const SortByWrapper = styled.div`
  height: 40px;
  > div {
    padding: 4px 0px;
    > button {
      border: none;
      padding: 0px;
      > div:first-child {
        margin: 0px;
        > div {
          width: 32px;
          > svg {
            width: 12px;
            height: 12px;
          }
        }
      }
      > span {
        font-size: 12px;
        font-weight: 500;
        line-height: 12px;
        margin: 0px;
        font-family: monorale;
      }
      > div:last-child {
        width: 30px;
        > svg {
          margin-bottom: 3px;
          width: 8px;
          height: 6px;
        }
      }
    }
  }
`;

const TableContainer = styled.div<{ isMenuOpen?: boolean }>`
  > div > div {
    > div:first-child {
      div:nth-child(8) {
        display: none;
      }
    }
    > div:nth-child(2),
    > div:nth-child(3) {
      > div:nth-child(2) {
        div {
          div:nth-child(8) {
            display: none;
          }
        }
      }
    }
  }

  // Media queries for screen 1024px - 1279px.
  @media (max-width: ${({ isMenuOpen }) => (isMenuOpen ? '1479px' : '1279px')}) {
    > div > div {
      > div:first-child {
        > div:nth-child(3),
        div:nth-child(6),
        div:nth-child(7) {
          display: none;
        }
        > div:nth-child(8) {
          display: flex;
        }
      }
      > div:nth-child(2),
      div:nth-child(3) {
        > div:nth-child(2) {
          > div {
            > div:nth-child(3),
            div:nth-child(6),
            div:nth-child(7) {
              display: none;
            }
            > div:nth-child(8) {
              display: flex;
            }
          }
        }
      }
    }
  }
`;

const DeviceContainer = styled.div<{
  direction?: string;
  padding?: string;
  justify?: string;
  gap?: string;
  lineHeight?: string;
}>`
  height: auto;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: ${({ direction }) => (direction ? direction : 'row')};
  justify-content: ${({ direction, justify }) =>
    direction === 'column' ? 'center' : justify ? justify : 'flex-start'};
  align-items: ${({ direction }) =>
    direction === 'column' ? 'flex-start' : 'center'};
  gap: ${({ gap }) => (gap ? gap : '4px')};
  padding: ${({ padding }) => padding};
  line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : '20px')};
  > div {
    > div:last-child {
      margin-left: 0px;
    }
  }
  > div:last-child {
    margin-left: 0px;
  }
`;

const NameContainer = styled.div`
  display: flex;
  gap: 6px;
`;

const TableLinkRow = styled.div<{ width?: string }>`
  position: relative;
  width: ${props => props.width || '141px'};
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const TogglingService = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  right: 0px;
  min-width: max-content;
  gap: 8px;
  i {
    font-size: 12px;
  }
`;

const TableColumnLink = styled.div<{
  expanded?: boolean;
  zIndex?: string;
  border?: string;
  background?: string;
  borderRadius?: string;
  width?: string;
  gap?: string;
  paddingLeft?: string;
  position?: string;
  right?: string;
  justifyContent?: string;
}>`
  width: ${({ width }) => (width ? width : 'auto')};
  padding-left: ${({ paddingLeft }) => paddingLeft};
  position: ${({ position }) => position};
  right: ${({ right }) => right};
  gap: ${({ gap }) => (gap ? gap : '0px')};
  height: 24px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: ${({ justifyContent }) => (justifyContent ? justifyContent : 'flex-start')};
  align-items: center;
  border: ${({ border }) => (border ? border : 'none')};
  background-color: ${({ background }) => background ? background : 'inherit'};
  padding: 0px;
  border-radius: ${({ expanded, borderRadius }) => expanded ? borderRadius : '3px'};
  z-index: ${({ zIndex }) => (zIndex ? zIndex : 0)};
  cursor: pointer;
`;

const ButtonText = styled.span`
  color: 'hsl(0, 0%, 100%)';
  font-size: 12px;
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamily.data};
  min-width: max-content;
`;

const EnableWrapper = styled.div<{
  marginTop?: string;
  width?: string;
  borderLeft?: string;
  padding?: string;
}>`
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '4px')};
  margin-bottom: 0px;
  width: ${({ width }) => (width ? width : '16px')};
  padding: ${({ padding }) => (padding ? padding : '0px')};
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  padding: 4px;
  font-size: 16px !important;
  border-left: ${({ borderLeft }) => (borderLeft ? borderLeft : 'none')};
  box-shadow: none !important;
  > div:first-child {
    display: flex;
    align-items: center;
  }
`;

const EnableModalWrapper = styled.div`
  height: min-content;
  max-height: 80vh;
  overflow: auto;
`;

const CopyIcon = styled.button<{ tooltipText: string }>`
  cursor: pointer;
  opacity: 0;
  padding: 0px 4px;
  width: 18px;
  height: 20px;
  background: transparent;
  border: none;
  outline: none;
  position: relative;
  -webkit-transition: 0.35s;
  transition: 0.35s;
  z-index: 1;
  &:hover {
    opacity: 1 !important;
  }

  > div {
    display: flex;
    align-items: center;
  }

  /* Tooltip style */
  &:hover::after {
    content: '${props => props.tooltipText}';
    position: absolute;
    top: -25px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    white-space: nowrap;
  }
`;

const CellContainer = styled.div`
  flex: 1;
  display: flex !important;
  align-items: center;

  &:hover ${CopyIcon} {
    opacity: 0.5;
  }
`;

const ShowMore = styled.p<{
  font?: string;
  color?: string;
  wrap?: string;
}>`
  color: ${({ color }) => (color ? color : '#80b5ff')};
  cursor: pointer;
  font-size: 13px;
  margin: 0px;
  font-weight: 600;

  &:hover {
    text-decoration: underline; // Add underline on hover
  }
`;

const LightText = styled.p<{
  font?: string;
  color?: string;
  wrap?: string;
}>`
  color: ${({ color }) => (color ? color : '#898e96')};
  font-size: ${({ font }) => (font ? font : '12px')};
  margin: 0;
  overflow-wrap: ${({ wrap }) => wrap};
`;

const LinkText = styled.a<{
  font?: string;
  color?: string;
  wrap?: string;
}>`
  color: ${({ color }) => (color ? color : '#898e96')};
  font-size: ${({ font }) => (font ? font : '12px')};
  margin: 0;
  overflow-wrap: ${({ wrap }) => wrap};
`;

const UsageText = styled.p<{ font?: string }>`
  color: #b7bcc4;
  font-size: ${({ font }) => (font ? font : '12px')};
  margin: 0;
`;
const DarkText = styled.p<{
  font?: string;
  wrap?: string;
  maxWidth?: string;
}>`
  color: #60646b;
  font-size: ${({ font }) => (font ? font : '12px')};
  margin: 0;
  overflow-wrap: ${({ wrap }) => wrap};
  max-width: ${({ maxWidth }) => maxWidth};
`;

// All interfaces

// Type for the expected parameters
interface IParams {
  deviceID: string;
}

// Interface for table data after restructuring the data from the API
interface IStatusObject {
  id: string;
  message: string;
}
interface IExpiryObject {
  time: number;
  action: string;
}
interface IUploadObject {
  uploadLimit: number;
  uploadLimitUnit: string;
}
export interface ITypeData {
  id?: number;
  name: string;
  linkID: string;
  links: string[];
  linkName: 'standard' | 'other';
  status: IStatusObject;
  connection: string;
  port: number;
  noIndex: boolean;
  expiry: IExpiryObject;
  accordionGrp: string;
  lastAccessedDate: string;
  lastAccessedTime: string;
  ingressData: number;
  egressData: number;
  uploadLimit: IUploadObject;
}

// Interface for dropdowns of the device links: they are open or close
interface IExtendedMap {
  [linkID: string]: boolean;
}
type ILinkType = 'standard' | 'other';
interface IExtendLinkMap {
  [key: string]: ILinkType extends infer LT
  ? LT extends ILinkType
  ? IExtendedMap
  : never
  : never;
}

// Interface for Accordion section closing and opening
type IVisibleCategory = {
  [key: string]: boolean;
};

// Interface for Loading Ids while toggling the webgate links
export interface ILoadingIds {
  [id: string]: {
    displayText: string | null;
    text: string | null;
  };
}

const DeviceLinks: React.FC = () => {
  // variable from the params
  const { deviceID } = useParams<IParams>();

  // Check validation for webgate enabled or not
  const { isFeatureEnabled, setIsFeatureEnabled, setSwitchState }: ContextProps = useContext(FeatureEnabledContext);

  // Function for setting the state which is used to manage the routing of edit links page
  const { setIsEditFromHome }: EditContextProps = useContext(EditRoutingContext);

  // Variable to keep the track of side Menu
  const isMenuOpen = localStorage.getItem(`${window.location.hostname}_isMenuOpen`) === 'true';

  // To measure the viewport width
  const [width] = useWindowSize();

  // Variable defined to use the history hook provided by the router library
  const history = useHistory();

  // To use the text transalation files (English - Japanese)
  const { t } = useTranslation(['DeviceLinks', 'Common']);

  // Function used from the useCopyToClipboard component to copy the text to clipboard
  const { copyToClipboard } = useCopyToClipboard();

  // Function used from the useModal component to open the modal
  const { setModalOpen, createModal } = useModal();

  // Function used from the useNotification component to send notifications
  const { sendNotification } = useNotification();

  // State for setting the device status
  const [deviceStatus, setDeviceStatus] = useState<boolean>(false);

  // Api Row Data
  const [apiRowData, setApiRowData] = useState<ITypeData[]>([]);

  const updateApiRowData = useCallback(() => {
    if (!isFeatureEnabled) {
      setApiRowData((prevData) =>
        prevData.map((item) => ({
          ...item,
          status: item.linkID ? {
            id: t('DeviceLinks:deviceStatusSection.text5'),
            message: '',
          }
            : item.status,
          expiry: {
            time: 0,
            action: ''
          }
        }))
      );
    }
  }, [isFeatureEnabled, t]);
  
  useEffect(() => {
    updateApiRowData();
  }, [updateApiRowData]);
  
  // Section: Filter Section

  // Name Filter
  // State for storing the input value in the name filter
  const [searchText, setSearchText] = useState('');

  // Link Type Filter
  // State for storing the selected link type option
  const [selectedLinkType, setSelectedLinkType] = useState<string>('');

  // Link Status Filter
  // State for storing the selected link status option
  const [selectedLinkStatus, setSelectedLinkStatus] = useState<string>('');

  // Port Search Filter
  // State for storing the searched port value
  const [portText, setPortText] = useState('');

  // State for storing the count after filter application
  const [resultCount, setResultCount] = useState(0);

  // Sort Filter
  // State for storing the sort by filter selected option
  const [sortBy, setSortBy] = useState<ISortBy>({
    sort: 'name',
    ascending: true,
    title: t('DeviceLinks:sortBy.option1'),
  });

  // Section: Table Section

  // Table row data which is passed directly in the component
  const [rowData, setRowData] = useState<IAccordionTableData>([]);

  // State for storing the checked device links
  const [initialRowData, setInitialRowData] = useState<IAccordionTableData>([]);

  // Reference for checking that toggle API is called from actions or not
  const isToggleFromDone = useRef(false);

  // State for storing the checked action button from button list
  const [checkedButtons, setCheckedButtons] = useState<string>('');

  // State for storing the action lable for each device link
  const [loadingIds, setLoadingIds] = useState<ILoadingIds>({});

  // Table Generate States

  // State for dropdowns of the device links: they are open or close
  // Stores linkID as the key and boolean as the value
  const [extendedLink, setExtendedLink] = useState<IExtendLinkMap>({});

  // State for storing the Accordion section closing and opening state
  const [visibleCategory, setVisibleCategory] = useState<IVisibleCategory>({});

  useEffect(() => {
    // Update visibleCategory based on translations
    setVisibleCategory({
      [t('DeviceLinks:tableText.emptyStandardText.accordionText')]: true,
      [t('DeviceLinks:tableText.emptyOtherText.accordionText')]: true,
    });
    
    // Update apiRowData based on translations
    setApiRowData((prev)=>{
      return prev.map((item) => ({
        ...item,
        accordionGrp:
        ['Standard Links', '標準リンク'].includes(item.accordionGrp)
          ? t('DeviceLinks:tableText.emptyStandardText.accordionText')
          : ['Other Links', 'その他のリンク'].includes(item.accordionGrp)
            ? t('DeviceLinks:tableText.emptyOtherText.accordionText')
            : item.accordionGrp,
        status: {
          ...item.status,
          id : ['Enabled', 'アクティブ'].includes(item.status.id)
            ? t('DeviceLinks:deviceStatusSection.text4')
            : ['Disabled', '停止中'].includes(item.status.id)
              ? t('DeviceLinks:deviceStatusSection.text5')
              : ['Error', 'エラー'].includes(item.status.id)
                ? t('DeviceLinks:deviceStatusSection.text6')
                : item.status.id
        },
        name : ['Standard Links', '標準リンク'].includes(item.accordionGrp) && ['Edge UI', 'デバイス管理UI'].includes(item.name) 
          ? t('DeviceLinks:tableText.edgeUI') 
          : ['Standard Links', '標準リンク'].includes(item.accordionGrp) && ['Solution UI', 'ソリューションUI'].includes(item.name)
            ? t('DeviceLinks:tableText.solutionUI') 
            : item.name,
        lastAccessedTime: item.lastAccessedTime
          .replace('min ago', t('DeviceLinks:tableText.timeAgoUnits.min'))
          .replace('分前', (match: string) =>
            parseInt(item.lastAccessedTime) === 1
              ? t('DeviceLinks:tableText.timeAgoUnits.min')
              : match
          )
          .replace('mins ago', t('DeviceLinks:tableText.timeAgoUnits.mins'))
          .replace('分前', t('DeviceLinks:tableText.timeAgoUnits.mins'))
          .replace('hour ago', t('DeviceLinks:tableText.timeAgoUnits.hour'))
          .replace('時間前', (match: string) =>
            parseInt(item.lastAccessedTime) === 1
              ? t('DeviceLinks:tableText.timeAgoUnits.hour')
              : match
          )
          .replace('hours ago', t('DeviceLinks:tableText.timeAgoUnits.hours'))
          .replace('時間前', t('DeviceLinks:tableText.timeAgoUnits.hours'))
          .replace('day ago', t('DeviceLinks:tableText.timeAgoUnits.day'))
          .replace('日前', (match: string) =>
            parseInt(item.lastAccessedTime) === 1
              ? t('DeviceLinks:tableText.timeAgoUnits.day')
              : match
          )
          .replace('days ago', t('DeviceLinks:tableText.timeAgoUnits.days'))
          .replace('日前', t('DeviceLinks:tableText.timeAgoUnits.days'))
          .replace('month ago', t('DeviceLinks:tableText.timeAgoUnits.month'))
          .replace('ヶ月前', (match: string) =>
            parseInt(item.lastAccessedTime) === 1
              ? t('DeviceLinks:tableText.timeAgoUnits.month')
              : match
          )
          .replace('months ago', t('DeviceLinks:tableText.timeAgoUnits.months'))
          .replace('ヶ月前', t('DeviceLinks:tableText.timeAgoUnits.months'))
          .replace('year ago', t('DeviceLinks:tableText.timeAgoUnits.year'))
          .replace('年前', (match: string) =>
            parseInt(item.lastAccessedTime) === 1
              ? t('DeviceLinks:tableText.timeAgoUnits.year')
              : match
          )
          .replace('years ago', t('DeviceLinks:tableText.timeAgoUnits.years'))
          .replace('年前', t('DeviceLinks:tableText.timeAgoUnits.years'))
      }));
    });
    
    // Update SelectedLinkType based on translations
    setSelectedLinkType((prev) => {
      return ['Standard Links', '標準リンク'].includes(prev)
        ? t('DeviceLinks:tableText.emptyStandardText.accordionText')
        : ['Other Links', 'その他のリンク'].includes(prev)
          ? t('DeviceLinks:tableText.emptyOtherText.accordionText')
          : prev;
    });

    // Update SelectedLinkStatus based on translations
    setSelectedLinkStatus((prev) => {
      return ['Enabled', 'アクティブ'].includes(prev)
        ? t('DeviceLinks:deviceStatusSection.text4')
        : ['Disabled', '停止中'].includes(prev)
          ? t('DeviceLinks:deviceStatusSection.text5')
          : ['Error', 'エラー'].includes(prev)
            ? t('DeviceLinks:deviceStatusSection.text6')
            : prev;
    });

    // Update Checked Action Buttons based on translations
    setCheckedButtons((prev) => {
      return ['Disable Link(s)', '停止する'].includes(prev)
        ? t('DeviceLinks:ActionFilterResultContainer.button1')
        : ['Enable Link(s)', 'アクティブにする'].includes(prev)
          ? t('DeviceLinks:ActionFilterResultContainer.button2')
          : ['Delete Link(s)', '削除する'].includes(prev)
            ? t('DeviceLinks:ActionFilterResultContainer.button4')
            : prev;
    });

    setLoadingIds((prev) => {
      // Explicitly type the updatedState as the same type as `prev`
      const updatedState: Record<string, typeof prev[keyof typeof prev]> = {};
    
      // Iterate over each key in the previous state
      Object.keys(prev).forEach((key) => {
        let newKey: string = key;
    
        // Check if the key matches one of the conditions
        if (['Edge UI', 'デバイス管理UI'].includes(key)) {
          newKey = t('DeviceLinks:tableText.edgeUI');
        } else if (['Solution UI', 'ソリューションUI'].includes(key)) {
          newKey = t('DeviceLinks:tableText.solutionUI');
        }
    
        // Add the key-value pair to the updated state
        updatedState[newKey] = {
          ...prev[key],
          // Optional: update `displayText` or other properties if needed
          displayText:
            ['Enabling', 'アクティブにしています'].includes(prev[key].displayText || '')
              ? t('DeviceLinks:tableText.dropdown.action1')
              : ['Disabling', '停止しています'].includes(prev[key].displayText || '')
                ? t('DeviceLinks:tableText.dropdown.action2')
                : ['Deleting', '削除しています'].includes(prev[key].displayText || '')
                  ? t('DeviceLinks:tableText.dropdown.action3')
                  : prev[key].displayText,
        };
      });
    
      return updatedState; // Return the updated state with modified keys
    });
    
    
  }, [t]);

  // Track expanded custom domain links section for individual device links
  const [expandedRows, setExpandedRows] = useState<Record<string, boolean>>({});

  // Track copied link for a device link
  const [copiedLink, setCopiedLink] = useState<string | null>(null);

  // State for giving reference to each device link dropdown
  const dropdownRef = useRef<{
    [key: string]: React.RefObject<HTMLDivElement>;
  }>({});

  // State for unique device link dropdown reference
  const [refId, setRefId] = useState<string>('');

  // UseEffect for initialising the device links dropdown references
  useEffect(() => {
    return () => {
      dropdownRef.current = {};
    };
  }, []);

  // State for setting api loading
  const [loading, setLoading] = useState<boolean>(false);

  // State for checking whether the other links are present in the API data or not
  const [otherLinksinAPI, setOtherLinksinAPI] = useState<boolean>(true);

  // State for checking whether the standard links are present in the API data or not
  const [standardLinksinAPI, setStandardLinksinAPI] = useState<boolean>(true);

  // Page Data

  // Fetched device Name and the categories or tags from the API hook
  const { deviceName, tagsCategories } = useCheckDevice(deviceID);

  // Header Section
  const breadCrumbBack = '/';
  const breadCrumsData = [
    {
      href: '/',
      text: t('Common:breadCrums.home'),
    },
    {
      text: t('Common:breadCrums.deviceLinks'),
    },
  ];

  // Variable for storing the links status
  const linksStatus = apiRowData.reduce(
    (acc, link) => {
      const status = link.status.id.toLowerCase();
      if (
        status === t('DeviceLinks:deviceStatusSection.text4').toLowerCase() ||
        status === t('DeviceLinks:deviceStatusSection.text5').toLowerCase() ||
        status === t('DeviceLinks:deviceStatusSection.text6').toLowerCase()
      ) {
        acc[status] = (acc[status] || 0) + 1;
      }
      return acc;
    },
    {
      [t('DeviceLinks:deviceStatusSection.text4').toLowerCase()]: 0,
      [t('DeviceLinks:deviceStatusSection.text5').toLowerCase()]: 0,
      [t('DeviceLinks:deviceStatusSection.text6').toLowerCase()]: 0,
    }
  );
  
  // Section: Filter Section

  // Name Filter

  // Function for changing the filter input value state
  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
  
    if (!inputValue.trim()) {
      setSearchText(''); // Set to empty string if the input is empty or spaces only
    } else {
      setSearchText(inputValue); // Preserve the input as is if valid
    }
  };

  // Function for clearing the filter inputs
  const removeSearch = () => {
    setSearchText('');
  };

  // Link Type Filter

  // Variable for storing the valid values for the link type filter dropdown
  const linkTypeList = Array.from(
    new Set(apiRowData.map(item => item.accordionGrp))
  );

  // Function for updating the selected value of link type
  const onChangeLinkType = useCallback(
    (filter: string | number | string[] | number[]) => {
      if (selectedLinkType === filter) {
        setSelectedLinkType('');
      } else {
        setSelectedLinkType(filter.toString());
      }
    },
    [selectedLinkType]
  );

  // Function for clearing the link type filter inputs
  const removeLinkType = () => {
    setSelectedLinkType('');
  };

  // Link Status Filter

  // Variable for storing the valid values for the link status filter dropdown
  const linkStatusList = Array.from(
    new Set(
      apiRowData.map(item => item.status.id).filter(statusId => statusId !== '') // Exclude empty status.id
    )
  );

  // Function for updating the selected value of link status
  const onChangeLinkStatus = useCallback(
    (filter: string | string[] | number | number[]) => {
      if (selectedLinkStatus === filter) {
        setSelectedLinkStatus('');
      } else {
        setSelectedLinkStatus(filter.toString());
      }
    },
    [selectedLinkStatus]
  );

  // Function for clearing the link status filter inputs
  const removeLinkStatus = () => {
    setSelectedLinkStatus('');
  };

  // Port Search Filter

  // Function for updating the searched port value
  const handlePortInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    // Validate: Allow only numbers and prevent less than 0
    if (/^\d*$/.test(inputValue) && Number(inputValue) >= 0) {
      setPortText(inputValue);
    }
  };

  // Function for clearing the searched port value
  const handlePortClearInput = () => {
    setPortText('');
  };

  //Clear all filters & filter label section
  const onHandleClearAllLink = useCallback(() => {
    setSearchText('');
    setSelectedLinkType('');
    setSelectedLinkStatus('');
    setPortText('');
    setSortBy({
      sort: 'name',
      ascending: true,
      title: t('DeviceLinks:sortBy.option1'),
    });
  }, [t]);

  // Sort Filter

  // Variable for storing the available options for the sort by filter
  const [sortByList, setSortByList] = useState<ISortItem[]>([]);

  // Function to set sorting options for filter
  const generateSortByList = useCallback(() => {

    const sortByList: ISortItem[] = [
      {
        sort: 'name',
        title: t('DeviceLinks:sortBy.option1'),
      },
      {
        sort: 'lastAccessed',
        title: t('DeviceLinks:sortBy.option2'),
      },
      {
        sort: 'ingress',
        title: t('DeviceLinks:sortBy.option3'),
      },
      {
        sort: 'egress',
        title: t('DeviceLinks:sortBy.option4'),
      },
    ];

    setSortByList(sortByList);
  }, [t]);
  
  // Function call for sorting filter
  useEffect(() => {
    generateSortByList();
  }, [generateSortByList, t]);

  // Function for updating the selected value of sort by filter
  const sort = useCallback((sortBy: ISortBy) => {
    setSortBy(sortBy);
  }, []);

  // Update the title in the state when language changes
  useEffect(() => {
    const currentOption = sortByList.find(item => item.sort === sortBy.sort);
    if (currentOption) {
      setSortBy(prev => ({
        ...prev,
        title: currentOption.title,
      }));
    }
  }, [sortBy.sort, sortByList]);

  // Fetching & setting params section
  const params = useLocation().search;
  const [historyParams] = useState<string>(params);
  const setParams = useCallback(() => {
    const params =
      '?' +
      `${searchText === '' ? '' : '&searchText=' + searchText}` +
      `${selectedLinkType === '' ? '' : '&selectedLinkType=' + selectedLinkType
      }` +
      `${selectedLinkStatus === ''
        ? ''
        : '&selectedLinkStatus=' + selectedLinkStatus
      }` +
      `${portText === '' ? '' : '&portText=' + portText}` +
      `${sortBy.ascending && sortBy.sort === 'name'
        ? ''
        : '&sortBy=' + JSON.stringify(sortBy)
      }`;
    if (params === '?') {
      return '';
    }
    return params;
  }, [searchText, selectedLinkType, selectedLinkStatus, portText, sortBy]);
  const fetchHistoryParams = useCallback(() => {
    const string = new URLSearchParams(historyParams);
    let newParams: HistoryParams = {};
    newParams.searchText = string.get('searchText') || undefined;
    newParams.selectedLinkType = string.get('selectedLinkType') || undefined;
    newParams.selectedLinkStatus =
      string.get('selectedLinkStatus') || undefined;
    newParams.portText = string.get('portText') || undefined;
    newParams.sortBy = string.get('sortBy') || undefined;

    if (newParams.searchText) {
      setSearchText(newParams.searchText);
    }
    if (newParams.selectedLinkType) {
      setSelectedLinkType(newParams.selectedLinkType);
    }
    if (newParams.selectedLinkStatus) {
      setSelectedLinkStatus(newParams.selectedLinkStatus);
    }
    if (newParams.portText) {
      setPortText(newParams.portText);
    }
    if (newParams.sortBy) {
      setSortBy(JSON.parse(newParams.sortBy));
    }
  }, [historyParams]);
  useEffect(() => {
    fetchHistoryParams();
  }, [fetchHistoryParams]);
  useEffect(() => {
    history.push(`/links/${deviceID}` + setParams());
  }, [history, setParams, deviceID]);

  // Table Section

  // Column config for the table
  let tableColumns: ITableColumnConfig[] = [
    {
      header: t('DeviceLinks:tableText.headerTitles.title1'),
      sortable: true,
      cellStyle: 'firstColumn',
      width: 262,
      groupTitle: t('DeviceLinks:tableText.headerGroups.group1'),
      sortActive: true,
    },

    {
      header: t('DeviceLinks:tableText.headerTitles.title2'),
      sortable: false,
      cellStyle: 'normalImportance',
      groupTitle: t('DeviceLinks:tableText.headerGroups.group1'),
      minWidth: width >= (isMenuOpen ? 1736 : 1536) ? 189 : 109.3,
    },
    {
      header: t('DeviceLinks:tableText.headerTitles.title3'),
      sortable: false,
      cellStyle: 'normalImportance',
      groupTitle: t('DeviceLinks:tableText.headerGroups.group1'),
      width: width >= (isMenuOpen ? 1480 : 1280) ? 96 : undefined,
      minWidth: width >= (isMenuOpen ? 1480 : 1280) ? 96 : undefined,
    },
    {
      header: t('DeviceLinks:tableText.headerTitles.title4'),
      sortable: true,
      cellStyle: 'normalImportance',
      groupTitle: t('DeviceLinks:tableText.headerGroups.group2'),
      sortActive: true,
      minWidth: width >= (isMenuOpen ? 1736 : 1536) ? 247 : width >= (isMenuOpen ? 1480 : 1280) ? 167.3 : 165,
    },
    {
      header: t('DeviceLinks:tableText.headerTitles.title5'),
      sortable: true,
      cellStyle: 'normalImportance',
      groupTitle: t('DeviceLinks:tableText.headerGroups.group3'),
      sortActive: true,
      width: i18n.language === 'ja' ? 178 : 149,
    },
    {
      header: t('DeviceLinks:tableText.headerTitles.title6'),
      sortable: true,
      cellStyle: 'normalImportance',
      groupTitle: t('DeviceLinks:tableText.headerGroups.group3'),
      sortActive: true,
      width: width >= (isMenuOpen ? 1736 : 1536) ? 170 : 121.3,
    },
    {
      header: t('DeviceLinks:tableText.headerTitles.title7'),
      sortable: true,
      cellStyle: 'normalImportance',
      minWidth: 206,
      groupTitle: t('DeviceLinks:tableText.headerGroups.group4'),
      sortActive: true,
    },
    {
      header: t('DeviceLinks:tableText.headerTitles.title8'),
      sortable: false,
      alignment: 'left',
      cellStyle: 'normalImportance',
      minWidth: 69,
    },
  ];

  // Function for toggle disable the device link
  const handleDisable = useCallback(
    async (linkID: string, linkName: string, linkType: string) => {
      try {
        setLoadingIds(prev => {
          const key = linkID !== '' ? linkID : linkName;
          if (!prev[key]) {
            return {
              ...prev,
              [key]: {
                displayText: t('DeviceLinks:tableText.dropdown.action2'),
                text: 'Disabling',
              },
            };
          }
          return prev;
        });
        setCheckedButtons('');

        // Webgate feature switch state setting function
        if (!isToggleFromDone.current) setSwitchState('locked');

        // Check feature enabled before executing API call
        if (!isToggleFromDone.current) {
          // Check for webgate feature
          const enabled = await isWebgateEnabled();
          // if API returns a response then set the featue enabled state
          if (enabled !== null) setIsFeatureEnabled(enabled);
          // if the feature is disaled then exit from the loop
          if (enabled === false || !enabled) {
            // empty the loading state
            setLoadingIds({});
            // Webgate feature switch state setting function
            setSwitchState('default');
            handleDismiss(); // Clear all notification bar before sending a new one
            sendNotification({
              type: 'warning',
              message: t('Common:notificationMessage.webgateFeatureDisabled'),
            });
            return;
          }
        }
        isToggleFromDone.current = false;

        const getToggleStatus = await toggleWebgateDevice(
          deviceID,
          linkID,
          linkType.toLowerCase() === 'standard' && linkName === 'デバイス管理UI' ? 'Edge UI' : linkType.toLowerCase() === 'standard' && linkName === 'ソリューションUI' ? 'Solution UI' : linkName,
          linkType.toLowerCase() === 'standard' ? true : false,
          false,
          false
        );

        if (getToggleStatus.enabled === null) {
          throw new Error();
        }

        if (!getToggleStatus.enabled) {
          handleDismiss(); // Clear all notification bar before sending a new one
          sendNotification({
            type: 'success',
            message: t(
              'DeviceLinks:notificationMessage.disableSuccess'
            ).replace('{linkName}', ['Edge UI', 'デバイス管理UI'].includes(linkName) ? t('DeviceLinks:tableText.edgeUI') : ['Solution UI', 'ソリューションUI'].includes(linkName) ? t('DeviceLinks:tableText.solutionUI') : linkName),
          });
          setApiRowData(prev => {
            const selectedLink = prev.find(link => {
              if (linkType === 'standard' && ['Edge UI', 'デバイス管理UI'].includes(linkName)) return link.port === 20001;
              else if (linkType === 'standard' && ['Solution UI', 'ソリューションUI'].includes(linkName)) return link.port === 80; 
              else return link?.linkID === linkID;
            });

            if (selectedLink) {
              selectedLink.status.id = t('DeviceLinks:deviceStatusSection.text5');
              selectedLink.expiry.action = '';
              selectedLink.expiry.time = 0;
            }

            return [...prev];
          });
        }
      } catch (error) {
        handleDismiss(); // Clear all notification bar before sending a new one
        sendNotification({
          type: 'error',
          message: t('DeviceLinks:notificationMessage.disableError').replace(
            '{linkName}',
            ['Edge UI', 'デバイス管理UI'].includes(linkName) ? t('DeviceLinks:tableText.edgeUI') : ['Solution UI', 'ソリューションUI'].includes(linkName) ? t('DeviceLinks:tableText.solutionUI') : linkName
          ),
        });
      } finally {
        setLoadingIds(prev => {
          const updated = { ...prev };
          delete updated[linkID !== '' ? linkID : linkName];
          return updated;
        });

        // Webgate feature switch state setting function
        // Use the callback function to ensure the latest `loadingIds` state is considered
        setLoadingIds(currentLoadingIds => {
          if (Object.keys(currentLoadingIds).length === 0) {
            setSwitchState('default');
          }
          return currentLoadingIds;
        });
      }
    },
    [
      deviceID,
      sendNotification,
      t,
      isToggleFromDone,
      setIsFeatureEnabled,
      setSwitchState,
    ]
  );

  // Function for toggle delete the device link
  const handleDelete = useCallback(
    async (linkID: string, linkName: string, linkType: string) => {
      try {
        setLoadingIds(prev => {
          const key = linkID !== '' ? linkID : linkName;
          if (!prev[key]) {
            return {
              ...prev,
              [key]: {
                displayText: t('DeviceLinks:tableText.dropdown.action3'),
                text: 'Deleting',
              },
            };
          }
          return prev;
        });
        setCheckedButtons('');

        // Webgate feature switch state setting function
        if (!isToggleFromDone.current) setSwitchState('locked');

        // Check feature enabled before executing API call
        if (!isToggleFromDone.current) {
          // Check for webgate feature
          const enabled = await isWebgateEnabled();
          // if API returns a response then set the featue enabled state
          if (enabled !== null) setIsFeatureEnabled(enabled);
          // if the feature is disaled then exit from the loop
          if (enabled === false || !enabled) {
            // empty the loading state
            setLoadingIds({});
            // Webgate feature switch state setting function
            setSwitchState('default');
            handleDismiss(); // Clear all notification bar before sending a new one
            sendNotification({
              type: 'warning',
              message: t('Common:notificationMessage.webgateFeatureDisabled'),
            });
            return;
          }
        }
        isToggleFromDone.current = false;

        const getToggleStatus = await toggleWebgateDevice(
          deviceID,
          linkID,
          linkType.toLowerCase() === 'standard' && linkName === 'デバイス管理UI' ? 'Edge UI' : linkType.toLowerCase() === 'standard' && linkName === 'ソリューションUI' ? 'Solution UI' : linkName,
          linkType.toLowerCase() === 'standard' ? true : false,
          false,
          true
        );
        if (getToggleStatus.flush) {
          handleDismiss(); // Clear all notification bar before sending a new one
          sendNotification({
            type: 'success',
            message: t('DeviceLinks:notificationMessage.deleteSuccess').replace(
              '{linkName}',
              ['Edge UI', 'デバイス管理UI'].includes(linkName) ? t('DeviceLinks:tableText.edgeUI') : ['Solution UI', 'ソリューションUI'].includes(linkName) ? t('DeviceLinks:tableText.solutionUI') : linkName
            ),
          });
          if (linkType.toLowerCase() === 'standard') {
            setApiRowData(prev => {
              const selectedLink = prev.find(link => {
                if (linkType === 'standard' && ['Edge UI', 'デバイス管理UI'].includes(linkName)) return link.port === 20001;
                else if (linkType === 'standard' && ['Solution UI', 'ソリューションUI'].includes(linkName)) return link.port === 80; 
                else return link?.linkID === linkID;
              });

              if (selectedLink) {
                selectedLink.connection = 'HTTPS';
                selectedLink.egressData = 0.0;
                selectedLink.expiry.time = 0;
                selectedLink.expiry.action = '';
                selectedLink.ingressData = 0.0;
                selectedLink.lastAccessedDate = '';
                selectedLink.lastAccessedTime = '';
                selectedLink.linkID = '';
                selectedLink.links = [];
                selectedLink.noIndex = true;
                selectedLink.port = ['Edge UI', 'デバイス管理UI'].includes(selectedLink.name) ? 20001 : 80;
                selectedLink.status.id = '';
                selectedLink.status.message = '';
                selectedLink.uploadLimit.uploadLimit = 100;
                selectedLink.uploadLimit.uploadLimitUnit = 'MiB';
              }

              return [...prev];
            });
          } else if (linkType.toLowerCase() === 'other') {
            setApiRowData(prev => {
              const selectedLink = prev.find(link => {
                if (linkType === 'standard' && ['Edge UI', 'デバイス管理UI'].includes(linkName)) return link.port === 20001;
                else if (linkType === 'standard' && ['Solution UI', 'ソリューションUI'].includes(linkName)) return link.port === 80; 
                else return link?.linkID === linkID;
              });

              const newArr = prev.filter(item => {
                return selectedLink?.linkID !== item.linkID;
              });

              if (!newArr?.some(item => item.linkName === 'other')) {
                setOtherLinksinAPI(false);
              }
              return [...newArr];
            });
          }
        }
      } catch (error) {
        handleDismiss(); // Clear all notification bar before sending a new one
        sendNotification({
          type: 'error',
          message: t('DeviceLinks:notificationMessage.deleteError').replace(
            '{linkName}',
            ['Edge UI', 'デバイス管理UI'].includes(linkName) ? t('DeviceLinks:tableText.edgeUI') : ['Solution UI', 'ソリューションUI'].includes(linkName) ? t('DeviceLinks:tableText.solutionUI') : linkName
          ),
        });
      } finally {
        setLoadingIds(prev => {
          const updated = { ...prev };
          delete updated[linkID !== '' ? linkID : linkName];
          return updated;
        });

        // Webgate feature switch state setting function
        // Use the callback function to ensure the latest `loadingIds` state is considered
        setLoadingIds(currentLoadingIds => {
          if (Object.keys(currentLoadingIds).length === 0) {
            setSwitchState('default');
          }
          return currentLoadingIds;
        });
      }
    },
    [
      t,
      deviceID,
      sendNotification,
      isToggleFromDone,
      setIsFeatureEnabled,
      setSwitchState,
    ]
  );

  // Function for toggle enable the device link
  const handleEnable = useCallback(
    async (linkID: string, linkName: string, linkType: string) => {
      try {
        setLoadingIds(prev => {
          const key = linkID !== '' ? linkID : linkName;
          if (!prev[key]) {
            return {
              ...prev,
              [key]: {
                displayText: t('DeviceLinks:tableText.dropdown.action1'),
                text: 'Enabling',
              },
            };
          }
          return prev;
        });
        setCheckedButtons('');

        // Webgate feature switch state setting function
        if (!isToggleFromDone.current) setSwitchState('locked');

        // Check feature enabled before executing API call
        if (!isToggleFromDone.current) {
          // Check for webgate feature
          const enabled = await isWebgateEnabled();
          // if API returns a response then set the featue enabled state
          if (enabled !== null) setIsFeatureEnabled(enabled);
          // if the feature is disaled then exit from the loop
          if (enabled === false || !enabled) {
            // empty the loading state
            setLoadingIds({});
            // Webgate feature switch state setting function
            setSwitchState('default');
            handleDismiss(); // Clear all notification bar before sending a new one
            sendNotification({
              type: 'warning',
              message: t('Common:notificationMessage.webgateFeatureDisabled'),
            });
            return;
          }
        }
        isToggleFromDone.current = false;

        const getToggleStatus = await toggleWebgateDevice(
          deviceID,
          linkID,
          linkType.toLowerCase() === 'standard' && linkName === 'デバイス管理UI' ? 'Edge UI' : linkType.toLowerCase() === 'standard' && linkName === 'ソリューションUI' ? 'Solution UI' : linkName,
          linkType.toLowerCase() === 'standard' ? true : false,
          true,
          false
        );

        // Check if enabling the link was successful
        if (!getToggleStatus.enabled || !getToggleStatus) {
          throw new Error();
        }

        const getLinkToggleStatus = await getWebgateStatus(deviceID, [
          {
            isStandardLink: linkType.toLowerCase() === 'standard' ? true : false,
            linkID: linkID,
            linkName: linkType.toLowerCase() === 'standard' && linkName === 'デバイス管理UI' ? 'Edge UI' : linkType.toLowerCase() === 'standard' && linkName === 'ソリューションUI' ? 'Solution UI' : linkName,
          },
        ]);

        if (!getLinkToggleStatus) throw new Error();

        const linkUrl = getLinkToggleStatus?.Links.find(link => {
          if (linkType === 'standard') {
            return linkName === 'デバイス管理UI'
              ? 'Edge UI'
              : linkName === 'ソリューションUI'
                ? 'Solution UI'
                : linkName;
          }
          else return link?.linkID === linkID;
        })?.url;
        if (linkUrl) {
          setApiRowData(prev => {
            const selectedLink = prev.find(link => {
              if (linkType === 'standard' && ['Edge UI', 'デバイス管理UI'].includes(linkName)) return link.port === 20001;
              else if (linkType === 'standard' && ['Solution UI', 'ソリューションUI'].includes(linkName)) return link.port === 80; 
              else return link?.linkID === linkID;
            });

            if (
              selectedLink &&
              !selectedLink.links?.find(
                existingLink => existingLink === linkUrl
              )
            ) {
              selectedLink.links.push(linkUrl);
              selectedLink.linkID =
                getLinkToggleStatus?.Links.find(link => {
                  if (linkType === 'standard')
                    return link.linkName === linkName;
                  else return link?.linkID === linkID;
                })?.linkID || '';
            }
            if (selectedLink) {
              selectedLink.status.id = t('DeviceLinks:deviceStatusSection.text4');
              selectedLink.status.message = '';

              if (linkType === 'standard') {
                selectedLink.port =
                  getLinkToggleStatus?.Links.find(link => {
                    if (linkType === 'standard')
                      return link.linkName === linkName;
                    else return link?.linkID === linkID;
                  })?.linkName === 'Edge UI'
                    ? 20001
                    : linkName === 'デバイス管理UI'
                      ? 20001
                      : 80;
                selectedLink.connection = 'HTTPS';
              }
            }
            return [...prev];
          });
          handleDismiss(); // Clear all notification bar before sending a new one
          sendNotification({
            type: 'success',
            message: t('DeviceLinks:notificationMessage.enableSuccess').replace(
              '{linkName}',
              ['Edge UI', 'デバイス管理UI'].includes(linkName) ? t('DeviceLinks:tableText.edgeUI') : ['Solution UI', 'ソリューションUI'].includes(linkName) ? t('DeviceLinks:tableText.solutionUI') : linkName
            ),
          });
        }
      } catch {
        handleDismiss(); // Clear all notification bar before sending a new one
        sendNotification({
          type: 'error',
          message: t('DeviceLinks:notificationMessage.enableError').replace(
            '{linkName}',
            ['Edge UI', 'デバイス管理UI'].includes(linkName) ? t('DeviceLinks:tableText.edgeUI') : ['Solution UI', 'ソリューションUI'].includes(linkName) ? t('DeviceLinks:tableText.solutionUI') : linkName
          ),
        });
      } finally {
        setLoadingIds(prev => {
          const updated = { ...prev };
          delete updated[linkID !== '' ? linkID : ['Edge UI', 'デバイス管理UI'].includes(linkName) ? t('DeviceLinks:tableText.edgeUI') : ['Solution UI', 'ソリューションUI'].includes(linkName) ? t('DeviceLinks:tableText.solutionUI') : linkName];
          return updated;
        });

        // Webgate feature switch state setting function
        // Use the callback function to ensure the latest `loadingIds` state is considered
        setLoadingIds(currentLoadingIds => {
          if (Object.keys(currentLoadingIds).length === 0) {
            setSwitchState('default');
          }
          return currentLoadingIds;
        });
      }
    },
    [
      t,
      deviceID,
      setSwitchState,
      sendNotification,
      isToggleFromDone,
      setIsFeatureEnabled
    ]
  );

  // Function to take the confirmation from the user by showing modal while enabling
  const toggleEnable = useCallback(async (
    linkID: string,
    linkName: string,
    linkType: string
  ) => {
    const deviceData = apiRowData.find(id => {
      if (id.linkID) {
        return id.linkID === linkID;
      } else {
        return id.name === linkName;
      }
    });

    if (!deviceData) return;
    if (!linkType) return;


    const confirmModal: ReactElement = (
      <EnableModalWrapper>
        <ConfirmEnableModal
          deviceID={deviceID}
          devicelinkData={deviceData}
          handleCancel={() => {
            setCheckedButtons('');
            setModalOpen(false);
          }}
          setModalOpen={setModalOpen}
          setLoadingIds={setLoadingIds}
          setApiRowData={setApiRowData}
          sendNotification={sendNotification}
          isFeatureEnabled={isFeatureEnabled}
          setIsFeatureEnabled={setIsFeatureEnabled}
          setSwitchState={setSwitchState}
        />
      </EnableModalWrapper>
    );
    createModal({
      isCloseEnable: false,
      customComponent: confirmModal,
      width: 'fit-content',
    });

    // Update rowData and deselect the selected rows using the functional updater
    setRowData(prevRowData =>
      prevRowData.map(item =>
        item._checked !== undefined ? { ...item, _checked: false } : item
      )
    );

    // Update initialRowData and deselect the selected rows using the functional updater
    setInitialRowData(prevInitialRowData =>
      prevInitialRowData.map(item =>
        item._checked !== undefined ? { ...item, _checked: false } : item
      )
    );
    
  }, [
    deviceID, 
    apiRowData, 
    createModal, 
    setModalOpen,
    setSwitchState,
    sendNotification,
    isFeatureEnabled,
    setIsFeatureEnabled
  ]);

  // Function to take the confirmation from the user by showing modal while deleting
  const toggleDelete =  useCallback(async (linkID: string, linkName: string, linkType: string) => {
    const confirmModal: ReactElement = (
      <ConfirmModal
        title={t('DeviceLinks:confirmationPopup.deleteTitle')}
        content={t('DeviceLinks:confirmationPopup.deleteContent')}
        confirmationMessage={t('DeviceLinks:confirmationPopup.deviceName').replace('{deviceName}', deviceName)}
        confirmationMessage2={t('DeviceLinks:confirmationPopup.linkName').replace('{linkName}', linkName)}
        cancelButtonText={t('DeviceLinks:confirmationPopup.cancel')}
        confirmButtonText={t('DeviceLinks:confirmationPopup.confirmDelete')}
        onCancel={() => {
          setModalOpen(false);
          setCheckedButtons('');
        }}
        onConfirm={() => {
          handleDelete(linkID, linkName, linkType);
          setModalOpen(false);

          // Update rowData and deselect the selected rows using the functional updater
          setRowData(prevRowData =>
            prevRowData.map(item =>
              item._checked !== undefined ? { ...item, _checked: false } : item
            )
          );

          // Update initialRowData and deselect the selected rows using the functional updater
          setInitialRowData(prevInitialRowData =>
            prevInitialRowData.map(item =>
              item._checked !== undefined ? { ...item, _checked: false } : item
            )
          );
        }}
      />
    );
    createModal({ isCloseEnable: false, customComponent: confirmModal });
  }, [
    t,
    deviceName, 
    createModal, 
    handleDelete, 
    setModalOpen
  ]);

  // Function to take the confirmation from the user by showing modal while disabling
  const toggleDisable = useCallback(async (
    linkID: string,
    linkName: string,
    linkType: string
  ) => {

    const confirmModal: ReactElement = (
      <ConfirmModal
        title={t('DeviceLinks:confirmationPopup.disableTitle')}
        content={t('DeviceLinks:confirmationPopup.disableContent')}
        confirmationMessage={t('DeviceLinks:confirmationPopup.deviceName').replace('{deviceName}', deviceName)}
        confirmationMessage2={t('DeviceLinks:confirmationPopup.linkName').replace('{linkName}', linkName)}
        cancelButtonText={t('DeviceLinks:confirmationPopup.cancel')}
        confirmButtonText={t('DeviceLinks:confirmationPopup.confirmDisable')}
        onCancel={() => {
          setModalOpen(false);
          setCheckedButtons('');
        }}
        onConfirm={() => {
          handleDisable(linkID, linkName, linkType);
          setModalOpen(false);

          // Update rowData and deselect the selected rows using the functional updater
          setRowData(prevRowData =>
            prevRowData.map(item =>
              item._checked !== undefined ? { ...item, _checked: false } : item
            )
          );

          // Update initialRowData and deselect the selected rows using the functional updater
          setInitialRowData(prevInitialRowData =>
            prevInitialRowData.map(item =>
              item._checked !== undefined ? { ...item, _checked: false } : item
            )
          );
        }}
      />
    );
    createModal({ isCloseEnable: false, customComponent: confirmModal });
  }, [
    t,
    deviceName, 
    createModal, 
    setModalOpen,
    handleDisable
  ]);

  // Function to redirect the user to the edit links page
  const toggleEdit = (linkID: string) => {
    setIsEditFromHome(false);
    history.push(`/links/${deviceID}/edit-link/${linkID}`);
  };

  // Function for selecting the individual device link & deselect the selected action button
  const handleRowSelect = useCallback(
    (checked: boolean, id?: string | number) => {
      setRowData(prevRowData => {
        const newRows = [...prevRowData];
        const targetRowIndex = newRows.findIndex(row => row.id === id);
        if (targetRowIndex !== -1) {
          newRows[targetRowIndex]._checked = checked;
        }
        return newRows;
      });
      setInitialRowData(rowData);
      setCheckedButtons('');
    },
    [rowData]
  );

  // Function for checking if any device link is selected
  const hasIsChecked = useCallback((findCheck: IAccordionTableData) => {
    return findCheck.filter(obj => obj._checked === true);
  }, []);

  // Function for selecting all the device links at once & deselect the selected action button
  const handleSelectAllRows = useCallback(
    (selectAll: boolean) => {
      const newRows = rowData.map(row => ({
        ...row,
        _checked: selectAll,
      }));
      setRowData(newRows);
      setInitialRowData(newRows);
      setCheckedButtons('');
    },
    [rowData]
  );

  // Function for selecting the action button from button list and updating the checkedButtons state
  const handleButtonClick = useCallback((btnName: string) => {
    setCheckedButtons(btnName);
  }, []);

  // Function for performing actions on the selected device links using the switch cases
  const handleDone = () => {
    const updatedCheckedRowData = rowData.filter(
      item => item._checked === true
    );
    if (!checkedButtons.length) return;

    const selectedDeviceData = updatedCheckedRowData.map(item =>
      apiRowData.find((apiItem: ITypeData) => item.id === apiItem.id)
    );

    switch (checkedButtons) {
    case t('DeviceLinks:ActionFilterResultContainer.button1'): {
      selectedDeviceData.forEach(device => {
        if (device?.linkID || device?.name) {
          setLoadingIds(prev => ({
            ...prev,
            [device?.linkID !== '' ? device?.linkID : device?.name]: {
              displayText: t('DeviceLinks:tableText.dropdown.action2'),
              text: 'Disabling',
            },
          }));
        }
      });
      // Webgate feature switch state setting function
      setSwitchState('locked');
      const DisableResponse = async () => {
        for (const device of selectedDeviceData) {
          // Check for webgate feature
          const enabled = await isWebgateEnabled();
          // if API returns a response then set the featue enabled state
          if (enabled !== null) setIsFeatureEnabled(enabled);
          // if the feature is disaled then exit from the loop
          if (enabled === false || !enabled) {
            // empty the loading state
            setLoadingIds({});
            // Webgate feature switch state setting function
            setSwitchState('default');
            handleDismiss(); // Clear all notification bar before sending a new one
            sendNotification({
              type: 'warning',
              message: t('Common:notificationMessage.webgateFeatureDisabled'),
            });
            return;
          }
          isToggleFromDone.current = true;

          await handleDisable(
            device?.linkID || '',
            device?.name || '',
            device?.linkName || ''
          );
        }
      };
      DisableResponse();
      break;
    }
    case t('DeviceLinks:ActionFilterResultContainer.button2'): {
      selectedDeviceData.forEach(device => {
        if (device?.linkID || device?.name) {
          setLoadingIds(prev => ({
            ...prev,
            [device?.linkID !== '' ? device?.linkID : device?.name]: {
              displayText: t('DeviceLinks:tableText.dropdown.action1'),
              text: 'Enabling',
            },
          }));
        }
      });
      // Webgate feature switch state setting function
      setSwitchState('locked');

      const EnableResponse = async () => {
        for (const device of selectedDeviceData) {
          // Check for webgate feature
          const enabled = await isWebgateEnabled();
          // if API returns a response then set the featue enabled state
          if (enabled !== null) setIsFeatureEnabled(enabled);
          // if the feature is disaled then exit from the loop
          if (enabled === false || !enabled) {
            // empty the loading state
            setLoadingIds({});
            // Webgate feature switch state setting function
            setSwitchState('default');
            handleDismiss(); // Clear all notification bar before sending a new one
            sendNotification({
              type: 'warning',
              message: t('Common:notificationMessage.webgateFeatureDisabled'),
            });
            return;
          }
          isToggleFromDone.current = true;

          await handleEnable(
            device?.linkID || '',
            device?.name || '',
            device?.linkName || ''
          );
        }
      };
      EnableResponse();
      break;
    }
    case t('DeviceLinks:ActionFilterResultContainer.button3'): {
      history.push(
        `/links/${deviceID}/edit-link/${selectedDeviceData[0]?.linkID}`
      );
      break;
    }
    case t('DeviceLinks:ActionFilterResultContainer.button4'): {
      selectedDeviceData.forEach(device => {
        if (device?.linkID || device?.name) {
          setLoadingIds(prev => ({
            ...prev,
            [device?.linkID !== '' ? device?.linkID : device?.name]: {
              displayText: t('DeviceLinks:tableText.dropdown.action3'),
              text: 'Deleting',
            },
          }));
        }
      });
      // Webgate feature switch state setting function
      setSwitchState('locked');

      const deleteResponse = async () => {
        for (const device of selectedDeviceData) {
          // Check for webgate feature
          const enabled = await isWebgateEnabled();
          // if API returns a response then set the featue enabled state
          if (enabled !== null) setIsFeatureEnabled(enabled);
          // if the feature is disaled then exit from the loop
          if (enabled === false || !enabled) {
            // empty the loading state
            setLoadingIds({});
            // Webgate feature switch state setting function
            setSwitchState('default');
            handleDismiss(); // Clear all notification bar before sending a new one
            sendNotification({
              type: 'warning',
              message: t('Common:notificationMessage.webgateFeatureDisabled'),
            });
            return;
          }
          isToggleFromDone.current = true;

          await handleDelete(
            device?.linkID || '',
            device?.name || '',
            device?.linkName || ''
          );
        }
      };
      deleteResponse();
      break;
    }
    default:
      break;
    }
    setCheckedButtons('');
    const updatedRowData = rowData.map(item => {
      if (item._checked !== undefined) {
        return { ...item, _checked: false };
      }
      return item;
    });

    setRowData(updatedRowData);
    setInitialRowData(updatedRowData);
  };

  // Function for taking confirmation before performing action from the button list
  const doneModal = () => {
    // modal for done click to open before final request send to backened
    
    const updatedCheckedRowData = rowData.filter(
      item => item._checked === true
    );
    const LinkNames = updatedCheckedRowData.map((item)=>{
      return item.columns[0].customComponent?.props.contentArray[0].props.children[0].props.children[0].props.children;
    });

    const confirmModal: ReactElement = (
      <ConfirmModal
        title={checkedButtons.split(' ')[0] === t('DeviceLinks:tableText.delete') ? t('DeviceLinks:confirmationPopup.deleteTitle') : checkedButtons.split(' ')[0] === '停止する' ? t('DeviceLinks:confirmationPopup.disableTitle') : t('DeviceLinks:confirmationPopup.commonTitle').replace('{buttonName}', checkedButtons.split(' ')[0])}
        content={checkedButtons.split(' ')[0] === t('DeviceLinks:tableText.dropdown.option1') ? t('DeviceLinks:confirmationPopup.enableContent') : checkedButtons.split(' ')[0] === t('DeviceLinks:tableText.dropdown.option2') ? t('DeviceLinks:confirmationPopup.disableContent') : t('DeviceLinks:confirmationPopup.deleteContent')}
        confirmationMessage={t('DeviceLinks:confirmationPopup.deviceName').replace('{deviceName}', deviceName)}
        confirmationMessage2={LinkNames.join('\n')}
        fromDeviceLinksDone
        cancelButtonText={t('DeviceLinks:confirmationPopup.cancel')}
        confirmButtonText={checkedButtons.split(' ')[0] === t('DeviceLinks:tableText.dropdown.option1') ? t('DeviceLinks:confirmationPopup.confirmEnable') : checkedButtons.split(' ')[0] === t('DeviceLinks:tableText.dropdown.option2') ? t('DeviceLinks:confirmationPopup.confirmDisable') : t('DeviceLinks:confirmationPopup.confirmDelete')}
        onCancel={() => setModalOpen(false)}
        onConfirm={() => {
          handleDone();
          setModalOpen(false);
        }}
      />
    );
    createModal({ isCloseEnable: false, customComponent: confirmModal });
  };

  // Function for setting the status color based on the status value
  const getStatusColor = useCallback((status: string) => {
    switch (status) {
    case t('DeviceLinks:deviceStatusSection.text4').toLowerCase():
      return '#30a46c';
    case t('DeviceLinks:deviceStatusSection.text5').toLowerCase():
      return '#cdced6';
    case t('DeviceLinks:deviceStatusSection.text6').toLowerCase():
      return '#d16565';
    default:
      return 'none';
    }
  }, [t]);

  // Filter, Sort & Column sorting functions
  const filterLinks = useCallback(
    links => {
      const filter: ITypeData[] = [];
      links.forEach((link: ITypeData) => {
        const { name, accordionGrp, status, port } = link;
        if (
          (name.toLowerCase().includes(searchText.toLowerCase()) ||
            searchText === '') &&
          (status.id.toLowerCase().includes(selectedLinkStatus.toLowerCase()) ||
            selectedLinkStatus === '') &&
          (accordionGrp.includes(selectedLinkType) ||
            selectedLinkType === '') &&
          ((port.toString().includes(portText) && status.id.toLowerCase() !== '') || portText === '')
        ) {
          filter.push(link);
        }
      });
      return filter;
    },
    [searchText, selectedLinkStatus, selectedLinkType, portText]
  );

  // Sort function that uses the new sortBy state
  const sortLinks = useCallback((data: ITypeData[], sortBy: ISortBy) => {
    const standardLinks = data
      .filter(item => item.accordionGrp === t('DeviceLinks:tableText.emptyStandardText.accordionText'))
      .sort((a, b) => a.name.localeCompare(b.name));
    const otherLinks = data
      .filter(item => item.accordionGrp === t('DeviceLinks:tableText.emptyOtherText.accordionText'))
      .sort((a, b) => a.name.localeCompare(b.name));
    const sortFunction = (a: ITypeData, b: ITypeData) => {
      if (sortBy.sort === 'lastAccessed') {
        const parseDate = (dateString: string) => {
          try {
            const [date, time] = dateString.split(' - ');
            const formattedDate = `${date.replace(
              /(\d{4})\/(\d{2})\/(\d{2})/,
              '$1-$2-$3'
            )}T${time}`;
            const parsedDate = new Date(formattedDate);
            return isNaN(parsedDate.getTime()) ? new Date(0) : parsedDate;
          } catch (error) {
            console.error(`Date parsing error: ${dateString}`, error);
            return new Date(0);
          }
        };

        const dateA = parseDate(a.lastAccessedDate);
        const dateB = parseDate(b.lastAccessedDate);

        return sortBy.ascending
          ? dateA.getTime() - dateB.getTime()
          : dateB.getTime() - dateA.getTime();
      } else if (sortBy.sort === 'ingress') {
        return sortBy.ascending
          ? a.ingressData - b.ingressData
          : b.ingressData - a.ingressData;
      } else if (sortBy.sort === 'egress') {
        return sortBy.ascending
          ? a.egressData - b.egressData
          : b.egressData - a.egressData;
      } else if (sortBy.sort === 'name') {
        return sortBy.ascending
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name);
      }

      return 0;
    };

    const sortedStandardLinks = standardLinks.sort(sortFunction);
    const sortedOtherLinks = otherLinks.sort(sortFunction);

    return [...sortedStandardLinks, ...sortedOtherLinks];
  }, [t]);

  // Table Generate function

  // Function for generating the first column row data
  const generateNameAndLinks = useCallback(
    (
      name: string,
      links: string[],
      expiry: IExpiryObject,
      status: string,
      noIndex: boolean,
      uploadLimit: IUploadObject
    ): ReactElement[] => {
      const handleCopy = (link: string) => {
        copyToClipboard(link);
        setCopiedLink(link); // Set the copied link
        setTimeout(() => setCopiedLink(null), 3000);
      };

      const uniqueKey = links[0]; // Use links[0] as the unique key
      const isExpanded = expandedRows[uniqueKey] || false;
      const displayedLinks = isExpanded ? links : links.slice(0, 5); // Show first 3 links if collapsed
      const hiddenLinksCount = links.length - displayedLinks.length;

      const toggleExpandRow = () => {
        setExpandedRows(prev => ({
          ...prev,
          [uniqueKey]: !prev[uniqueKey],
        }));
      };

      return [
        <DeviceContainer key={uniqueKey} direction='column' gap='2px'>
          <NameContainer>
            <DarkText font='13px' wrap='anywhere' maxWidth='226px'>
              {name}
            </DarkText>
            {(expiry.time !== 0 || !noIndex || (!(uploadLimit.uploadLimit === 100 && uploadLimit.uploadLimitUnit === 'MiB') && uploadLimit.uploadLimit !== 0)) && (
              <InfoTooltip
                infoText={expiry.time !== 0 ? `${t('DeviceLinks:tableText.tooltipExpiry')} ${formatEpochToDateTime(expiry.time)} ${getSystemTimezoneAbbreviation()}` : ''}
                infoText2={expiry.time !== 0 ? `${t('DeviceLinks:tableText.tooltipExpiryAction').replace('{action}', expiry.action === 'Disable' ? t('DeviceLinks:tableText.stop') : t('DeviceLinks:tableText.delete'))}` : ''}
                infoText3={!noIndex ? t('DeviceLinks:tableText.tooltipNoIndex') : ''}
                infoText4={!(uploadLimit.uploadLimit === 100 && uploadLimit.uploadLimitUnit === 'MiB') && uploadLimit.uploadLimit !== 0 ? `${t('DeviceLinks:tableText.tooltipUploadLimit')} ${uploadLimit.uploadLimit} ${uploadLimit.uploadLimitUnit}` : ''}
                infoColor='#12a594'
                tooltipPosition={width < (isMenuOpen ? 1480 : 1280) ? 'right' : 'center'}
                minWidth='max-content'
              />
            )}
          </NameContainer>
          {displayedLinks.length > 0 &&
            displayedLinks.map((link: string, index) => (
              <CellContainer key={index}>
                {status.toLowerCase() === t('DeviceLinks:deviceStatusSection.text4').toLowerCase() ||
                  status.toLowerCase() === t('DeviceLinks:deviceStatusSection.text6').toLowerCase() ? (
                    <>
                      <LinkText
                        href={`https://${link}`}
                        target='_blank'
                        font='13px !important'
                        color='#80b5ff !important'
                        wrap='anywhere'
                      >
                        {link}
                      </LinkText>
                      <CopyIcon
                        tooltipText={
                          copiedLink === link
                            ? t('DeviceLinks:tableText.copied')
                            : t('DeviceLinks:tableText.copy')
                        }
                        onClick={() => handleCopy(link)}
                      >
                        <Icon icon='Copy' size={12} />
                      </CopyIcon>
                    </>
                  ) : (
                    <LightText
                      font='13px !important'
                      color='#cdced6'
                      wrap='anywhere'
                    >
                      {link}
                    </LightText>
                  )}
              </CellContainer>
            ))}
          {links.length > 5 && (
            <ShowMore
              onClick={toggleExpandRow}
              color={
                status.toLowerCase() === t('DeviceLinks:deviceStatusSection.text4').toLowerCase() ||
                  status.toLowerCase() === t('DeviceLinks:deviceStatusSection.text6').toLowerCase()
                  ? '#80b5ff'
                  : '#cdced6'
              }
            >
              {isExpanded
                ? t('DeviceLinks:tableText.showLess')
                : t('DeviceLinks:tableText.showMore').replace(
                  '{count}',
                  hiddenLinksCount.toString()
                )}
            </ShowMore>
          )}
        </DeviceContainer>,
      ];
    },
    [copyToClipboard, t, copiedLink, expandedRows, width, isMenuOpen]
  );

  // Function for generating the second column row data
  const generateLinksStatus = useCallback(
    (status: IStatusObject): ReactElement[] => {
      if (!status?.id) {
        return [<DeviceContainer key='empty'>-</DeviceContainer>];
      }

      const statusIdLowerCase = status.id.toLowerCase();
      const color = getStatusColor(statusIdLowerCase);

      return [
        <DeviceContainer key={status.id} gap='8px'>
          <StatusColor width='12px' color={color} />
          <LightText>
            {statusIdLowerCase.charAt(0).toUpperCase() +
              statusIdLowerCase.slice(1)}
          </LightText>
          {(statusIdLowerCase === t('DeviceLinks:deviceStatusSection.text6').toLowerCase() || statusIdLowerCase === t('DeviceLinks:deviceStatusSection.text4').toLowerCase()) &&
            status.message && 
            (
              <InfoTooltip
                infoIcon={statusIdLowerCase === t('DeviceLinks:deviceStatusSection.text6').toLowerCase() ? 'Warning' : 'Information'}
                infoText={status.message}
                infoColor='rgb(166, 166, 166)'
                textBackground={color}
                tooltipPosition='center'
                minWidth='max-content'
              />
            )}
        </DeviceContainer>
      ];
    }, [getStatusColor, t]);

  // Function for generating the third column row data
  const generateLinksDetails = useCallback(
    (
      connection: string,
      port: number,
      width: number,
      status: IStatusObject
    ): ReactElement[] => {
      if (!status?.id) {
        const emptyContainer = (
          <DeviceContainer direction='column' lineHeight='15px'>
            -
          </DeviceContainer>
        );
        return [emptyContainer];
      }

      const statusIdLowerCase = status.id.toLowerCase();
      const color = getStatusColor(statusIdLowerCase);

      const commonElements = (
        <DeviceContainer direction='column' lineHeight='15px'>
          <LightText>{connection}</LightText>
          <DarkText>{port}</DarkText>
        </DeviceContainer>
      );
      if (width > (isMenuOpen ? 1479 : 1279)) {
        return [commonElements];
      } else {
        return [
          <DeviceContainer key={port} gap='14px'>
            <InfoTooltip
              icon='square'
              infoIcon={
                statusIdLowerCase === t('DeviceLinks:deviceStatusSection.text6').toLowerCase() ? 'Warning' : 'Information'
              }
              infoText={status.message ? status.message : status.id}
              infoColor={color}
              iconMargin='0px'
              textGap='8px'
              textBackground={color}
              tooltipPosition='right'
              minWidth='max-content'
            />
            {commonElements}
          </DeviceContainer>,
        ];
      }
    },
    [getStatusColor, t, isMenuOpen]
  );

  // Function for generating the fourth column row data
  const generateLastAccess = useCallback(
    (date: string, time: string, port: number): ReactElement[] => {
      if (!date || !time) {
        return [
          <DeviceContainer key={port} direction='column' lineHeight='15px'>
            -
          </DeviceContainer>
        ];
      } else {
        return [
          <DeviceContainer key={port} direction='column' lineHeight='15px'>
            <LightText>{time}</LightText>
            <DarkText>{date}</DarkText>
          </DeviceContainer>
        ];
      }
    },
    []
  );

  // Function for generating the fifth column row data
  const generateIngress = useCallback(
    (used: number, id: number, status: string): ReactElement[] => {
      if (!status) {
        return [
          <DeviceContainer padding='0px' gap='4px' key={id}>
            -
          </DeviceContainer>,
        ];
      } else {
        return [
          <DeviceContainer padding='0px' gap='4px' key={id}>
            <LightText font='14px'>{convertValue(used)}</LightText>
            <UsageText font='10px'>{getUnit(used)}</UsageText>
          </DeviceContainer>,
        ];
      }
    },
    []
  );

  // Function for generating the sixth column row data
  const generateEgress = useCallback(
    (used: number, id: number, status: string): ReactElement[] => {
      if (!status) {
        return [
          <DeviceContainer padding='0px' gap='4px' key={id}>
            -
          </DeviceContainer>,
        ];
      } else {
        return [
          <DeviceContainer padding='0px' gap='4px' key={id}>
            <LightText font='14px'>{convertValue(used)}</LightText>
            <UsageText font='10px'> {getUnit(used)}</UsageText>
          </DeviceContainer>,
        ];
      }
    },
    []
  );

  // Function for generating the seventh column row data
  const generateIngressEgress = useCallback(
    (
      ingressUsed: number,
      egressUsed: number,
      id: number,
      status: string
    ): ReactElement[] => {
      if (!status) {
        return [
          <DeviceContainer padding='0px' gap='4px' key={id}>
            -
          </DeviceContainer>,
        ];
      } else {
        return [
          <DeviceContainer key={id} padding='0px' gap='4px'>
            {generateIngress(ingressUsed, id, status)} /{' '}
            {generateEgress(egressUsed, id, status)}
          </DeviceContainer>,
        ];
      }
    },
    [generateIngress, generateEgress]
  );

  // Functon for closing dropdown on clicking outside of it.
  const toggleUnexpanded = () => {
    setExtendedLink(prevExtendedLink => {
      // Check if the state is empty
      if (Object.keys(prevExtendedLink).length === 0) {
        return prevExtendedLink;
      }

      // Check if there's at least one true value in the extendedLink object
      const hasTrueValue = Object.values(prevExtendedLink).some(section =>
        Object.values(section).includes(true)
      );

      // If no true value exists, return the previous state without updating
      if (!hasTrueValue) {
        return prevExtendedLink;
      }

      // Proceed with setting true values to false
      const updatedExtendedLink = Object.fromEntries(
        Object.entries(prevExtendedLink).map(([key, section]) => [
          key,
          Object.fromEntries(
            Object.entries(section).map(([url, value]) => [
              url,
              value === true ? false : value,
            ])
          ),
        ])
      );

      return updatedExtendedLink;
    });
  };
  useClickOutside(dropdownRef.current[refId], () => toggleUnexpanded());

  // Function for generating the eigth column row data
  const generateActions = useCallback(
    (
      id: number,
      linkName: 'standard' | 'other',
      linksInfo: ITypeData
    ): ReactElement[] => {

      const handleEdit = () => {
        setIsEditFromHome(false);
        history.push(`/links/${deviceID}/edit-link/${linksInfo.linkID}`);
      };

      const toggleExpanded = (
        toggleId: string,
        linkType: string,
        expanded: boolean
      ) => {
        setRefId(id.toString());
        setExtendedLink(prevExtendedLink => ({
          ...prevExtendedLink,
          [linkType]: {
            ...prevExtendedLink[linkType],
            [toggleId]: !expanded,
          },
        }));
      };

      const toggleViewUsage = () => {
        history.push(`/links/${deviceID}/data-usage/${linksInfo.linkID}`);
      };

      const dropdownItems = {
        standard: [
          {
            displayName: t('DeviceLinks:tableText.dropdown.option1'),
            name: 'Enable',
            icon: 'Add',
            action: toggleEnable,
          },
          {
            displayName: t('DeviceLinks:tableText.dropdown.option2'),
            name: 'Disable',
            icon: 'Subtract',
            action: toggleDisable,
          },
          {
            displayName: t('DeviceLinks:tableText.dropdown.title'),
            name: 'View Usage',
            icon: 'Information',
            action: toggleViewUsage,
          },
          {
            displayName: t('DeviceLinks:tableText.dropdown.option3'),
            name: 'Delete',
            icon: 'ClearField',
            action: toggleDelete,
          },
          {
            displayName: t('DeviceLinks:tableText.dropdown.option4'),
            name: 'Edit',
            icon: 'Edit',
            action: handleEdit,
          },
        ],
        other: [
          {
            displayName: t('DeviceLinks:tableText.dropdown.option1'),
            name: 'Enable',
            icon: 'Add',
            action: toggleEnable,
          },
          {
            displayName: t('DeviceLinks:tableText.dropdown.option2'),
            name: 'Disable',
            icon: 'Subtract',
            action: toggleDisable,
          },
          {
            displayName: t('DeviceLinks:tableText.dropdown.title'),
            name: 'View Usage',
            icon: 'Information',
            action: toggleViewUsage,
          },
          {
            displayName: t('DeviceLinks:tableText.dropdown.option3'),
            name: 'Delete',
            icon: 'ClearField',
            action: toggleDelete,
          },
          {
            displayName: t('DeviceLinks:tableText.dropdown.option4'),
            name: 'Edit',
            icon: 'Edit',
            action: handleEdit,
          },
        ],
      };
      dropdownRef.current[id] =
        dropdownRef.current[id] || React.createRef<HTMLDivElement>();

      let items = dropdownItems[linkName] || [];
      items = items.filter(action => {
        if (
          linksInfo.status.id.toLowerCase() === t('DeviceLinks:deviceStatusSection.text4').toLowerCase() ||
          linksInfo.status.id.toLowerCase() === t('DeviceLinks:deviceStatusSection.text6').toLowerCase()
        )
          return action.name === 'Disable' || action.name === 'Edit';
        else if (linksInfo.status.id.toLowerCase() === t('DeviceLinks:deviceStatusSection.text5').toLowerCase())
          return action.name !== 'Disable' && action.name !== 'Edit';
        else return action.name === 'Enable';
      });

      const linkId = linksInfo.linkID ? linksInfo.linkID : linksInfo.name;
      const loadingState = loadingIds[linkId];
      return [
        <TableLinkRow key={id}>
          {loadingState ? (
            <TogglingService>
              <Spinner size='medium' styling='primary' />
              <i>{loadingState?.displayText}</i>
            </TogglingService>
          ) : linksInfo.status.id.toLowerCase() === '' &&
            linkName === 'standard' &&
            !linksInfo.links.length ? 
            (
              <TableColumnLink
                position='absolute'
                right='0px'
                justifyContent='end'
                onClick={() => {
                  if (!Object.keys(loadingIds).length)
                    toggleEnable(
                      linksInfo.linkID ? linksInfo.linkID : '',
                      linksInfo.name,
                      linksInfo.linkName
                    );
                }}
                zIndex='1'
                gap='8px'
              >
                <EnableWrapper
                  marginTop='0px'
                  width='23px'
                  padding='3px 0px 3px 3px'
                >
                  <SelectIcon icon='Add' size={16} color='dimmed' />
                </EnableWrapper>
                <ButtonText>
                  {t('DeviceLinks:tableText.dropdown.option1')}
                </ButtonText>
              </TableColumnLink>
            ) : (
              <GenericDropdown
                id={id.toString()}
                ref={dropdownRef.current[id]}
                name={linksInfo.name}
                linkId={linksInfo.linkID}
                title={t('DeviceLinks:tableText.dropdown.title')}
                linkType={linkName}
                connection={linksInfo.connection}
                status={linksInfo.status.id.toLowerCase()}
                expanded={extendedLink[linkName]?.[id] || false}
                link={`/links/${deviceID}/data-usage/${linksInfo.linkID}`}
                titleIcon='Information'
                dropdownItems={items}
                progressCheck={Object.keys(loadingIds).length}
                toggleExpanded={toggleExpanded}
              />
            )}
        </TableLinkRow>,
      ];
    },
    [
      t,
      extendedLink, 
      loadingIds, 
      deviceID, 
      history, 
      toggleEnable,
      toggleDelete,
      toggleDisable,
      setIsEditFromHome
    ]
  );

  // Button list for the action buttons
  const buttonLists = [
    {
      btnName: t('DeviceLinks:ActionFilterResultContainer.button1'),
      btnAction: toggleDisable,
    },
    {
      btnName: t('DeviceLinks:ActionFilterResultContainer.button2'),
      btnAction: toggleEnable,
    },
    {
      btnName: t('DeviceLinks:ActionFilterResultContainer.button3'),
      btnAction: toggleEdit,
    },
    {
      btnName: t('DeviceLinks:ActionFilterResultContainer.button4'),
      btnAction: toggleDelete,
    },
  ];

  // Functions for generating the row data using the above eight functions
  const generateRowData = useCallback((): IAccordionTableData => {
    const updateCheckedData = (id: number) => {
      let sortedDevicesnew;
      if (initialRowData.length) {
        sortedDevicesnew = initialRowData.find(
          initialItem => initialItem.id === id
        );

        if (sortedDevicesnew && sortedDevicesnew._checked) {
          return sortedDevicesnew._checked;
        } else {
          return false;
        }
      }
      //update the array
      else {
        return false;
      }
    };

    const emptyRow: IAccordionTableData = [];

    let filterData = filterLinks(apiRowData);
    let sortedLinks = sortLinks(filterData, sortBy);
    // let sortedColumns = sortColumns(sortedLinks);

    const data = sortedLinks.map(linksInfo => {
      const {
        id,
        name,
        links,
        status,
        linkName,
        connection,
        port,
        expiry,
        accordionGrp,
        lastAccessedDate,
        lastAccessedTime,
        ingressData,
        egressData,
        noIndex,
        uploadLimit,
      } = linksInfo;
      const row: IAccordionRowData = {
        id: id,
        _checked: id !== undefined ? updateCheckedData(id) : false,
        header: {},
        accGrp: accordionGrp,
        columns: [
          {
            customComponent: (
              <MultilineContent
                contentArray={generateNameAndLinks(
                  name,
                  links,
                  expiry,
                  status.id,
                  noIndex,
                  uploadLimit
                )}
              />
            ),
          },
          {
            customComponent: (
              <MultilineContent contentArray={generateLinksStatus(status)} />
            ),
          },
          {
            customComponent: (
              <MultilineContent
                contentArray={generateLinksDetails(
                  connection,
                  port,
                  width,
                  status
                )}
              />
            ),
          },
          {
            customComponent: (
              <MultilineContent
                contentArray={generateLastAccess(
                  lastAccessedDate,
                  lastAccessedTime,
                  port
                )}
              />
            ),
          },
          {
            customComponent: (
              <MultilineContent
                contentArray={generateIngress(
                  ingressData,
                  Number(id),
                  status.id
                )}
              />
            ),
          },
          {
            customComponent: (
              <MultilineContent
                contentArray={generateEgress(egressData, Number(id), status.id)}
              />
            ),
          },
          {
            customComponent: (
              <MultilineContent
                contentArray={generateIngressEgress(
                  ingressData,
                  egressData,
                  Number(id),
                  status.id
                )}
              />
            ),
          },
          {
            customComponent: (
              <MultilineContent
                contentArray={generateActions(Number(id), linkName, linksInfo)}
              />
            ),
          },
        ],
      };

      return row;
    });

    setResultCount(data.length);
    if (data.length === 0) {
      return emptyRow;
    }
    return data;
  }, [
    width,
    sortBy,
    filterLinks,
    apiRowData,
    sortLinks,
    generateNameAndLinks,
    generateLinksStatus,
    generateLinksDetails,
    generateLastAccess,
    generateIngress,
    generateEgress,
    generateIngressEgress,
    generateActions,
    initialRowData,
  ]);

  // UseEffect for generating the row data on page load
  useEffect(() => {
    setRowData(generateRowData());
  }, [
    width,
    extendedLink,
    apiRowData,
    searchText,
    selectedLinkType,
    selectedLinkStatus,
    portText,
    sortBy,
    generateRowData,
  ]);

  // Function for checking if any filter is applied or not
  const filterLinksStatus = useCallback(() => {
    if (searchText || selectedLinkType || selectedLinkStatus || portText)
      return false;
    else return true;
  }, [searchText, selectedLinkType, selectedLinkStatus, portText]);

  // All API calls

  // API call for fetching the device status
  const fetchDeviceStatus = useCallback(async () => {
    try {
      const deviceStatus = await getDeviceStatus(deviceID);
      if (deviceStatus) {
        if (deviceStatus.status.alive)
          setDeviceStatus(deviceStatus.status.alive);
      } else throw new Error();
    } catch (error) {
      console.error('Error in fetchDeviceStatus:', error);
    }
  }, [deviceID]);

  useEffect(() => {
    fetchDeviceStatus();
  }, [deviceID, fetchDeviceStatus]);

  // Function to map links data to ITypeData structure
  const mapLinksToData = useCallback((
    links: DeviceLinksData[],
    linkType: 'standard' | 'other',
    accordionGroup: string
  ): ITypeData[] => {
    return links.map(link => ({
      name: linkType.toLowerCase() === 'standard' && ['Edge UI', 'デバイス管理UI'].includes(link.name) ? t('DeviceLinks:tableText.edgeUI') : linkType.toLowerCase() === 'standard' && ['Solution UI', 'ソリューションUI'].includes(link.name) ? t('DeviceLinks:tableText.solutionUI') : link.name,
      linkID: link.linkID,
      links: [
        ...(link.url ? [link.url] : []),
        ...(link.customDomains?.sort() || []),
      ],
      linkName: linkType,
      status: link.status,
      connection: link.protocol,
      port: link.port,
      noIndex: link.noIndex,
      expiry: link.expiry,
      accordionGrp: accordionGroup,
      lastAccessedDate: formatTimestamp(link.lastAccessed),
      lastAccessedTime: timeAgo(link.lastAccessed, t),
      ingressData: link.dataUsage.ingress,
      egressData: link.dataUsage.egress,
      uploadLimit: link.uploadLimit,
    }));
  }, [t]);

  // Function to fetch additional link data
  const fetchDeviceLinkInfo = useCallback(async (
    deviceID: string,
    rowIndexingData: ITypeData[]
  ): Promise<ITypeData[]> => {
    const results = await Promise.allSettled(
      rowIndexingData.map(async item => {
        try {
          const fetchWebgateLinkInfo: WebgateLinkInfo = await getWebgateLinkInfo(deviceID, item.linkID);
          if (
            fetchWebgateLinkInfo &&
            fetchWebgateLinkInfo.status_code === 200 &&
            fetchWebgateLinkInfo.payload
          ) {
            return {
              ...item,
              status: {
                
                ...fetchWebgateLinkInfo.payload.status, // Spread the existing properties of item.status
                id:
                  fetchWebgateLinkInfo.payload.status.id === 'Enabled'
                    ? t('DeviceLinks:deviceStatusSection.text4')
                    : fetchWebgateLinkInfo.payload.status.id === 'Disabled'
                      ? t('DeviceLinks:deviceStatusSection.text5')
                      : fetchWebgateLinkInfo.payload.status.id === 'Error'
                        ? t('DeviceLinks:deviceStatusSection.text6')
                        : fetchWebgateLinkInfo.payload.status.id,
              },
              ingressData: fetchWebgateLinkInfo.payload.dataUsage?.ingress,
              egressData: fetchWebgateLinkInfo.payload.dataUsage?.egress,
              uploadLimit: fetchWebgateLinkInfo.payload.uploadLimit,
              lastAccessedDate: formatTimestamp(fetchWebgateLinkInfo.payload.lastAccessed),
              lastAccessedTime: timeAgo(fetchWebgateLinkInfo.payload.lastAccessed, t),
            };
          }
          return item;
        } catch (error) {
          console.error(
            `Error fetching data for linkID: ${item.linkID}`,
            error
          );
          return item;
        }
      })
    );

    // Extract only the fulfilled results
    return results
      .filter(
        (result): result is PromiseFulfilledResult<ITypeData> =>
          result.status === 'fulfilled'
      )
      .map(result => result.value);
  }, [t]);

  // Function to update link counts
  const updateLinksCount = (
    links: DeviceLinksData[] | undefined,
    setLinksInAPI: (value: boolean) => void
  ): void => {
    setLinksInAPI(!!(links && links.length > 0));
  };

  // Function to create extend link map
  const createExtendLinkMap = (links: DeviceLinksData[]): IExtendedMap => {
    return links.reduce((acc, link) => {
      // Ensure link.id is a valid string, or use a fallback like an empty string or a default value
      const linkId = link.id ?? 'id'; // Replace 'id' with a more appropriate default if needed
      return { ...acc, [linkId]: false };
    }, {});
  };

  const fetchDeviceLinks = useCallback(async () => {
    setLoading(true);
    setSwitchState('locked');

    try {
      const fetchDeviceLinks: Links = await getWebgateDeviceLinks(deviceID);

      if (fetchDeviceLinks && fetchDeviceLinks.status_code === 200) {
        const standardLinks = mapLinksToData(
          fetchDeviceLinks?.standardLinks || [],
          'standard',
          t('DeviceLinks:tableText.emptyStandardText.accordionText')
        );
        const otherLinks = mapLinksToData(
          fetchDeviceLinks?.otherLinks || [],
          'other',
          t('DeviceLinks:tableText.emptyOtherText.accordionText')
        );
        const deviceLinkNames = [...standardLinks, ...otherLinks];
        // Update the link counts for the UI
        updateLinksCount(
          fetchDeviceLinks?.standardLinks,
          setStandardLinksinAPI
        );
        updateLinksCount(fetchDeviceLinks?.otherLinks, setOtherLinksinAPI);

        // Create and set extend link maps
        const extendLinkMap: IExtendLinkMap = {
          standard: createExtendLinkMap(fetchDeviceLinks?.standardLinks || []),
          other: createExtendLinkMap(fetchDeviceLinks?.otherLinks || []),
        };

        setExtendedLink(extendLinkMap);

        // Fetch additional data for links
        const deviceLinkInformation = await fetchDeviceLinkInfo(
          deviceID,
          deviceLinkNames
        );

        // Set the API row data with ids
        const rowData = deviceLinkInformation.map((item, index) => ({
          id: index,
          ...item,
        }));
        setApiRowData(rowData);
      } else if (fetchDeviceLinks && fetchDeviceLinks.status_code === 404) {
        handleDismiss(); // Clear all notification bar before sending a new one
        sendNotification({
          type: 'warning',
          message: fetchDeviceLinks.status_msg || '',
        });
        history.push('/');
      } else {
        handleDismiss(); // Clear all notification bar before sending a new one
        sendNotification({
          type: 'warning',
          message: t('Common:notificationMessage.null_response'),
        });
        history.push('/');
      }
    } catch (error) {
      handleDismiss(); // Clear all notification bar before sending a new one
      sendNotification({
        type: 'error',
        message: t(`Common:exceptionNotificationMessages.${handleException(error).message}`),
      });
    } finally {
      setLoading(false);
      setSwitchState('default');
    }
  }, [
    t,
    history,  
    deviceID, 
    mapLinksToData,
    setSwitchState, 
    sendNotification,
    fetchDeviceLinkInfo 
  ]);

  useEffect(() => {
    fetchDeviceLinks();
    // eslint-disable-next-line
  }, []);

  return (
    <ContentWrapper isMenuOpen={isMenuOpen}>
      <Wrapper enableContent={isFeatureEnabled}>
        <DrawerSection isMenuOpen={isMenuOpen}>
          <Drawer>
            <Breadcrums
              backLink
              showBreadcrumbs
              breadCrumbBack={breadCrumbBack}
              breadcrumbs={breadCrumsData}
            />
          </Drawer>
        </DrawerSection>
        <HeaderSection isMenuOpen={isMenuOpen}>
          <InnerHeader>
            <DeviceLeftContainer isMenuOpen={isMenuOpen}>
              <DeviceInfo isMenuOpen={isMenuOpen}>
                <PageHeader
                  icon='Link'
                  iconColor='primary'
                  areaTitleBottom
                  areaTitle={deviceName}
                  title={t('DeviceLinks:pageTitle')}
                />
                {tagsCategories.length !== 0 && (
                  <DeviceMetas>
                    <ColumnText
                      fontWeight='600'
                      padding='0px'
                      fontSize='12px'
                      family='monorale'
                      line='1.6'
                    >
                      {t('Common:dummyDeviceMetas.heading')}
                    </ColumnText>
                    <DeviceTagCategory>
                      {tagsCategories.map((data, index) => {
                        return (
                          <IconButton
                            key={index}
                            background='transparent'
                            design='secondary'
                            size='small'
                            border='solid 1px #e6e8ec'
                            height='max-content'
                            gap='2px'
                            padding='0px 0px 0px 1px'
                          >
                            <IconWrapper marginTop='0px'>
                              <Icon
                                size={8}
                                icon={data.icon}
                                color='dimmed'
                                weight='regular'
                              />
                            </IconWrapper>
                            <ColumnText
                              fontSize='12px'
                              padding='0px 6px 0px 0px'
                            >
                              {data.text}
                            </ColumnText>
                          </IconButton>
                        );
                      })}
                    </DeviceTagCategory>
                  </DeviceMetas>
                )}
              </DeviceInfo>
              <DeviceStatusMeta isMenuOpen={isMenuOpen}>
                <DeviceStatusSection>
                  <DeviceStatus height='30px' margin='2px 0px 0px'>
                    <IconButton
                      radius='0px'
                      background='transparent'
                      design='secondary'
                      size='small'
                      padding='0px 6px'
                    >
                      <Icon
                        size={12}
                        icon='DevicesScorerEdge'
                        color='dimmed'
                        weight='regular'
                      />
                      <ColumnText fontWeight='bold'>
                        {t('DeviceLinks:deviceStatusSection.text1')}
                      </ColumnText>
                    </IconButton>
                    <IconButton
                      background='transparent'
                      design='secondary'
                      size='small'
                    >
                      <StatusColor height='14px' color={deviceStatus ? '#5bb98c' : '#d16565'} />
                      <ColumnText>
                        {deviceStatus ? t('DeviceLinks:deviceStatusSection.text2') : t('DeviceLinks:deviceStatusSection.text2a')}
                      </ColumnText>
                    </IconButton>
                  </DeviceStatus>
                  <IconWrapper
                    height='30px'
                    width='20px'
                    iconMargin='0px 0px 0px 0px'
                    marginTop='2px'
                  >
                    <Icon color='dimmed' size={12} icon='Right' />
                  </IconWrapper>
                  <DeviceStatus margin='0px 0px 0px 0px'>
                    <IconButton
                      radius='0px'
                      background='transparent'
                      design='secondary'
                      size='small'
                      padding='0px 6px'
                    >
                      <Icon
                        size={12}
                        icon='Link'
                        color='dimmed'
                        weight='regular'
                      />
                      <ColumnText fontWeight='bold'>
                        {t('DeviceLinks:deviceStatusSection.text3')}
                      </ColumnText>
                    </IconButton>
                    <IconButton
                      background='transparent'
                      design='secondary'
                      size='small'
                    >
                      <StatusColor color='#30a46c' width='12px' />
                      <ColumnText>
                        {linksStatus[t('DeviceLinks:deviceStatusSection.text4').toLowerCase()]}{' '}
                        {t('DeviceLinks:deviceStatusSection.text4')}
                      </ColumnText>
                    </IconButton>

                    <IconButton
                      background='transparent'
                      design='secondary'
                      size='small'
                    >
                      <StatusColor color='#8b8d98' width='12px' />
                      <ColumnText>
                        {linksStatus[t('DeviceLinks:deviceStatusSection.text5').toLowerCase()]}{' '}
                        {t('DeviceLinks:deviceStatusSection.text5')}
                      </ColumnText>
                    </IconButton>
                    <IconButton
                      background='transparent'
                      design='secondary'
                      size='small'
                    >
                      <StatusColor color='#d16565' width='12px' />
                      <ColumnText>
                        {linksStatus[t('DeviceLinks:deviceStatusSection.text6').toLowerCase()]}{' '}
                        {t('DeviceLinks:deviceStatusSection.text6')}
                      </ColumnText>
                    </IconButton>
                  </DeviceStatus>
                </DeviceStatusSection>
              </DeviceStatusMeta>
            </DeviceLeftContainer>
            <DeviceRightContainer isMenuOpen={isMenuOpen}>
              <LinkRoute to={`/links/${deviceID}/create-link`}>
                <ButtonWithIcon
                  icon='Add'
                  design='secondary'
                  size='normal'
                  position='left'
                  color='dimmed'
                >
                  {t('DeviceLinks:createLinkButton')}
                </ButtonWithIcon>
              </LinkRoute>
            </DeviceRightContainer>
          </InnerHeader>
        </HeaderSection>
        <LineDivider />
        <DataContainer isMenuOpen={isMenuOpen}>
          <ActionFilterResultContainer isMenuOpen={isMenuOpen}>
            {hasIsChecked(rowData).length ? (
              <ActionContainer>
                <AreaTitle>
                  <BoldText>
                    {t('DeviceLinks:ActionFilterResultContainer.heading')}
                  </BoldText>
                </AreaTitle>
                <ButtonListContainer>
                  <ButtonList>
                    {/* Render button list according to the selected device links and their status */}
                    {!Object.keys(loadingIds).length ? (
                      <>
                        {(() => {
                          const checkedRow = hasIsChecked(rowData);
                          let hasVisibleButton = false; // Flag to check if any button is shown

                          // Map over buttonLists to conditionally render buttons
                          const renderedButtons = buttonLists.map(btn => {
                            if (checkedRow.length === 1 && checkedRow[0]) {
                              const getCheckedRow = apiRowData.find(
                                (item: ITypeData) => item.id === checkedRow[0].id);
                              if (getCheckedRow) {
                                const status =
                                  getCheckedRow?.status.id.toLowerCase();

                                // Conditional rendering based on status and linkName
                                if (
                                  status === t('DeviceLinks:deviceStatusSection.text4').toLowerCase() ||
                                  status === t('DeviceLinks:deviceStatusSection.text6').toLowerCase()
                                ) {
                                  if (
                                    btn.btnName ===
                                    t('DeviceLinks:ActionFilterResultContainer.button2')
                                    ||
                                    btn.btnName === t('DeviceLinks:ActionFilterResultContainer.button4'))
                                    return null;
                                  if (btn.btnName === t('DeviceLinks:ActionFilterResultContainer.button4'))
                                    return null;
                                } else if (status === t('DeviceLinks:deviceStatusSection.text5').toLowerCase()) {
                                  if (btn.btnName === t('DeviceLinks:ActionFilterResultContainer.button1'))
                                    return null;
                                  if (btn.btnName === t('DeviceLinks:ActionFilterResultContainer.button3'))
                                    return null;
                                } else {
                                  if (
                                    [
                                      t('DeviceLinks:ActionFilterResultContainer.button1'),
                                      t('DeviceLinks:ActionFilterResultContainer.button3'),
                                      t('DeviceLinks:ActionFilterResultContainer.button4'),
                                    ].includes(btn.btnName)
                                  ) {
                                    return null;
                                  }
                                }
                              }
                            } else if (checkedRow.length > 1) {
                              const allRowsHaveSameState = checkedRow.every(
                                row => {
                                  const getCheckedRow = apiRowData.find((item: ITypeData) => item.id === row.id);
                                  if (getCheckedRow) {
                                    const status = getCheckedRow?.status.id.toLowerCase();

                                    if (btn.btnName === t('DeviceLinks:ActionFilterResultContainer.button3'))
                                      return null;

                                    // Check if any of the rows should not render the button
                                    if (
                                      status === t('DeviceLinks:deviceStatusSection.text4').toLowerCase() ||
                                      status === t('DeviceLinks:deviceStatusSection.text6').toLowerCase()
                                    ) {
                                      if (
                                        btn.btnName === t('DeviceLinks:ActionFilterResultContainer.button2')
                                        ||
                                        btn.btnName === t('DeviceLinks:ActionFilterResultContainer.button4')
                                      )
                                        return false;
                                    } else if (status === t('DeviceLinks:deviceStatusSection.text5').toLowerCase()) {
                                      if (btn.btnName === t('DeviceLinks:ActionFilterResultContainer.button1'))
                                        return false;
                                    } else {
                                      if (
                                        [
                                          t('DeviceLinks:ActionFilterResultContainer.button1'),
                                          t('DeviceLinks:ActionFilterResultContainer.button3'),
                                          t('DeviceLinks:ActionFilterResultContainer.button4'),
                                        ].includes(btn.btnName)
                                      ) {
                                        return false;
                                      }
                                    }
                                  }
                                  return true;
                                }
                              );

                              if (!allRowsHaveSameState) return null;
                            }

                            hasVisibleButton = true; // A button is being rendered
                            // Render the button if conditions pass
                            return (
                              <ButtonWithIcon
                                position='left'
                                icon='Success'
                                design={
                                  checkedButtons.includes(btn.btnName)
                                    ? 'primary'
                                    : 'secondary'
                                }
                                size='normal'
                                key={btn.btnName}
                                onClick={() => {
                                  if (
                                    checkedRow.length === 1 && checkedRow[0]
                                  ) {
                                    const getCheckedRow = apiRowData.find((item: ITypeData) => item.id === checkedRow[0].id);
                                    setCheckedButtons(btn.btnName);
                                    btn.btnAction(
                                      getCheckedRow?.linkID || '',
                                      getCheckedRow?.name || '',
                                      getCheckedRow?.linkName || ''
                                    );
                                  } else handleButtonClick(btn.btnName);
                                }}
                              >
                                {btn.btnName}
                              </ButtonWithIcon>
                            );
                          });

                          // If no buttons are rendered, show a 'No actions are available' message
                          return hasVisibleButton ? (
                            renderedButtons
                          ) : (
                            <ColumnText margin='0px' fontSize='12px'>
                              {t('DeviceLinks:ActionFilterResultContainer.noActions')}
                            </ColumnText>
                          );
                        })()}
                      </>
                    ) : (
                      <ColumnText margin='0px' fontSize='12px'>
                        {t('DeviceLinks:ActionFilterResultContainer.actionsInProcess')}
                      </ColumnText>
                    )}
                  </ButtonList>
                  {hasIsChecked(rowData).length !== 1 && (
                    <ButtonWithIcon
                      icon='Success'
                      position='left'
                      size='normal'
                      onClick={() => {
                        if (
                          checkedButtons ===
                        t('DeviceLinks:ActionFilterResultContainer.button2') &&
                        rowData.filter(item => item._checked === true)
                          .length === 1
                        ) {
                          const checkedRow = hasIsChecked(rowData);
                          const getCheckedRow = apiRowData.find(
                            (item: ITypeData) => item.id === checkedRow[0].id
                          );
                          if (getCheckedRow)
                            toggleEnable(
                            getCheckedRow?.linkID,
                            getCheckedRow?.name,
                            getCheckedRow?.linkName
                            );
                        } else {
                          doneModal();
                        }
                      }}
                      disabled={
                        checkedButtons.length === 0 ||
                      Object.keys(loadingIds).length !== 0
                      }
                    >
                      {t('DeviceLinks:ActionFilterResultContainer.button5')}
                    </ButtonWithIcon>
                  )}
                </ButtonListContainer>
              </ActionContainer>
            ) : (
              <SearchFieldContainer>
                <ColumnText
                  fontWeight='600'
                  padding='5px 0px'
                  fontSize='12px'
                  family='monorale'
                  line='1.6'
                >
                  {t('DeviceLinks:SearchFieldContainer.heading')}
                </ColumnText>
                <LeftContainer>
                  <InputWrapper>
                    <FilterInput
                      iconSize={12}
                      paddingTop='0px'
                      type='text'
                      placeholder={t('DeviceLinks:SearchFieldContainer.filter1')}
                      value={searchText}
                      onChange={onChangeInput}
                      lang={i18n.language}
                    />
                  </InputWrapper>
                  {linkTypeList.length !== 0 && (
                    <SelectType>
                      <Select
                        iconSize={12}
                        DropdownIconSize={6}
                        icon='meta-network'
                        title={t('DeviceLinks:SearchFieldContainer.filter2')}
                        width='auto'
                        onSelect={onChangeLinkType}
                        selected={selectedLinkType}
                        list={linkTypeList}
                        disabled={linkTypeList.length === 0}
                      />
                    </SelectType>
                  )}
                  {linkStatusList.length !== 0 && (
                    <SelectType>
                      <Select
                        iconSize={12}
                        DropdownIconSize={6}
                        icon='Information'
                        title={t('DeviceLinks:SearchFieldContainer.filter3')}
                        width='auto'
                        onSelect={onChangeLinkStatus}
                        selected={selectedLinkStatus}
                        list={linkStatusList}
                        disabled={linkStatusList.length === 0}
                      />
                    </SelectType>
                  )}
                  {apiRowData.length !== 0 && linkStatusList.length !== 0 && (
                    <InputFilter
                      iconSize={12}
                      icon='Add'
                      paddingTop='0px'
                      placeholder={t('DeviceLinks:SearchFieldContainer.placeHolder4')}
                      value={portText}
                      onChange={handlePortInputChange}
                      onCrossClick={handlePortClearInput}
                      canHide
                      showFieldText={t('DeviceLinks:SearchFieldContainer.filter4')}
                    />
                  )}
                </LeftContainer>
              </SearchFieldContainer>
            )}
            <ResultInfoContainer>
              <ResultCountLabel>
                {t('DeviceLinks:resultCountLabel.heading')} ({resultCount}):
                {searchText !== '' && (
                  <FilterContainer>
                    <FilterListIcon>
                      <Icon icon='Search' color='dimmed' size={14} />
                    </FilterListIcon>
                    <FilterText>{searchText}</FilterText>
                    <ClearItem onClick={removeSearch}>
                      <Icon icon='CloseCompact' color='dimmed' size={14} />
                    </ClearItem>
                  </FilterContainer>
                )}
                {selectedLinkType !== '' && (
                  <FilterContainer>
                    <FilterListIcon>
                      <MetaNetwork />
                    </FilterListIcon>
                    <FilterText>{selectedLinkType}</FilterText>
                    <ClearItem onClick={removeLinkType}>
                      <Icon icon='CloseCompact' color='dimmed' size={14} />
                    </ClearItem>
                  </FilterContainer>
                )}
                {selectedLinkStatus !== '' && (
                  <FilterContainer>
                    <FilterListIcon>
                      <Icon icon='Information' color='dimmed' size={14} />
                    </FilterListIcon>
                    <FilterText>{selectedLinkStatus}</FilterText>
                    <ClearItem onClick={removeLinkStatus}>
                      <Icon icon='CloseCompact' color='dimmed' size={14} />
                    </ClearItem>
                  </FilterContainer>
                )}
                {portText !== '' && (
                  <FilterContainer>
                    <FilterListIcon>
                      <Icon icon='Add' color='dimmed' size={14} />
                    </FilterListIcon>
                    <FilterText>{portText}</FilterText>
                    <ClearItem onClick={handlePortClearInput}>
                      <Icon icon='CloseCompact' color='dimmed' size={14} />
                    </ClearItem>
                  </FilterContainer>
                )}
                {(searchText !== '' ||
                  selectedLinkType !== '' ||
                  selectedLinkStatus !== '' ||
                  portText !== '') && 
                  (
                    <ClearLinkAll onClick={onHandleClearAllLink}>
                      {t('DeviceLinks:resultCountLabel.clearAll')}
                    </ClearLinkAll>
                  )}
              </ResultCountLabel>
              <SortByWrapper>
                <SortBy
                  title={`${t('DeviceLinks:sortBy.heading')} ${sortBy.title}`}
                  selected={sortBy}
                  onSelect={sort}
                  list={sortByList}
                  bgColor='transparent'
                />
              </SortByWrapper>
            </ResultInfoContainer>
          </ActionFilterResultContainer>
          <TableContainer isMenuOpen={isMenuOpen}>
            <AccordionTable
              columnConfig={tableColumns}
              rows={rowData}
              isLoading={loading}
              emptyTableText={t('DeviceLinks:tableText.headerTitles.noDeviceLinkFound')}
              selectable
              selectCallback={handleRowSelect}
              toggleAllCallback={handleSelectAllRows}
              hasHeaderGroups
              sortBy={sortBy}
              defaultAscending={sortBy.ascending}
              loadingText={t('Common:loadingData')}
              notFoundText={t('DeviceLinks:tableText.notFoundText')}
              visibleCategory={visibleCategory}
              setVisibleCategory={setVisibleCategory}
              filterLinksStatus={filterLinksStatus}
              emptyContainerButtonLink={`/links/${deviceID}/create-link`}
              standardLinksinAPI={standardLinksinAPI}
              otherLinksinAPI={otherLinksinAPI}
            />
          </TableContainer>
        </DataContainer>
      </Wrapper>
    </ContentWrapper>
  );
};

export default DeviceLinks;
