import React, { HTMLAttributes, useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { Icon, SelectField } from 'scorer-ui-kit';
import { Button } from 'scorer-ui-kit';
import { useTranslation } from 'react-i18next';

const PaginationContainer = styled.div`
  height: 40px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 40px;
  padding: 0;
  margin-right: 3px;
  margin-bottom: 30px;
`;

const SelectFieldContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  > div > div > div {
    right: 14px !important; 
  }
`;

const PageSelectField = styled(SelectField)`
  width: 80px;
  height: 40px;
  border-radius: 3px;
  border: 1px solid #d9dad9;
  background-color: #ffffff;
  color: #808080;
  padding-left: 11px;
  font-size: 14px;
  line-height: 26px !important;
`;

const PageSizeLabel = styled.label`
  height: 16px;
  flex-grow: 0;
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #8b9196;
`;

const PageLabel = styled.label<{ active: boolean }>`
  font-family: ${({ theme }) => theme.fontFamily.ui};
  pointer-events: ${({ active }) => (active ? 'auto' : 'none')};
  height: 16px;
  flex-grow: 0;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #8b9196;
`;

const PageSizeContainer = styled.div`
  min-width: 189px;
  height: 40px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  padding: 0;
`;

const IconWrapper = styled.div<{
  marginTop?: string;
  marginLeft?: string;
  zIndex?: number;
  paddingRight?: string;
}>`
  margin-left: ${({ marginLeft }) => (marginLeft ? marginLeft : '55px')};
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '3px')};
  z-index: ${({ zIndex }) => zIndex};
  padding-right: ${({ paddingRight }) => paddingRight};
`;

const PageButton = styled(Button)`
  height: 20px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 3px 8px;
  font-size: 12px;
  border: none;
`;

const PageSwitchContainer = styled.div`
  height: 40px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  padding: 0;
`;

const SelectPage = styled.select`
  width: 100%;
  height: 40px;
  border-radius: 3px;
  border: solid 1px #d9dad9;
  background-color: #ffffff;
  color: #808080;
  outline: none;
  appearance: none;
  position: absolute;
  padding-left: 14px;
  background: transparent;
  font-size: 14px;
  cursor: pointer;
`;

const ToggleButtons = styled.div`
  width: 32px !important;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: solid 1px #d9dad9;
  background-color: #ffffff;
  border-radius: 3px;
  cursor: pointer;
  > div:first-child{
    display: flex;
    align-items: center; 
  }
`;

const ToggleButtonsContainer = styled.div<{ marginLeft?: string }>`
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  display: flex;
  gap: 8px;
`;


interface IOwnProps {
  totalPages: number;
  pageSizeOptions?: number[];
  defaultPage?: number;
  defaultPageSize: number;
  onPageSizeChange: (size: number) => void;
  onPageChange: (page: number) => void;
  pageSizeText?: string;
  prevPageText?: string;
  nextPageText?: string;
  firstPageText?: string;
  lastPageText?: string;
}

type Props = IOwnProps & HTMLAttributes<HTMLDivElement>;

const Pagination: React.FC<Props> = (props) => {
  const {
    pageSizeOptions = [10, 20, 30, 50, 100],
    defaultPageSize = 10,
    defaultPage = 1,
    pageSizeText = 'Items Per Page',
    prevPageText,
    nextPageText,
    firstPageText,
    lastPageText,
    totalPages,
    onPageSizeChange,
    onPageChange,
  } = props;
  const [pageSize, setPageSize] = useState<number>(defaultPageSize);
  const [activePage, setActivePage] = useState(defaultPage);
  const { t } = useTranslation(['Common']);

  useEffect(() => {
    setPageSize(defaultPageSize);
  }, [defaultPageSize]);

  useEffect(() => {
    setActivePage(defaultPage);
  }, [defaultPage]);

  const handlePageSizeChange = useCallback(
    (value: string) => {
      const parsedValue = parseInt(value, 10);
      if (!isNaN(parsedValue)) {
        onPageSizeChange(parsedValue);
      }
    },
    [onPageSizeChange]
  );


  const handlePageChange = (value: number) => {
    if (value > 0 && value <= totalPages) {
      setActivePage(value);
      onPageChange(value);
    }
  };

  const handleNextPage = () => {
    if (activePage < totalPages) {
      handlePageChange(activePage + 1);
    }
  };

  const handlePrevPage = () => {
    if (activePage > 1) {
      handlePageChange(activePage - 1);
    }
  };

  return totalPages ? (
    <PaginationContainer>
      {pageSizeOptions && (
        <PageSizeContainer>
          {pageSizeText && <PageSizeLabel>{pageSizeText}</PageSizeLabel>}
          <SelectFieldContainer>
            <PageSelectField
              value={pageSize}
              defaultValue={pageSize}
              changeCallback={handlePageSizeChange}
            >
              {pageSizeOptions.map((size: number, index: number) => (
                <option key={index} value={size}>
                  {size}
                </option>
              ))}
            </PageSelectField>
          </SelectFieldContainer>
        </PageSizeContainer>
      )}
      <PageSwitchContainer>
        {firstPageText && (
          <PageLabel
            onClick={() => handlePageChange(1)}
            active={activePage !== 1}
          >
            {firstPageText}
          </PageLabel>
        )}
        {prevPageText && (
          <PageLabel
            onClick={() => handlePageChange(activePage - 1)}
            active={activePage !== 1}
          >
            {prevPageText}:
          </PageLabel>
        )}
        <SelectFieldContainer>
          <IconWrapper marginTop='0px' marginLeft='75px' paddingRight='8px'>
            <PageButton size='small'>{t('Common:paginationText')}</PageButton>
          </IconWrapper>
          <SelectPage
            value={activePage}
            onChange={(event) => handlePageChange(parseInt(event.target.value))}
          >
            {Array.from(Array(totalPages), (e, i) => (
              <option key={i + 1} value={i + 1}>
                {i + 1} / {totalPages}
              </option>
            ))}
          </SelectPage>
        </SelectFieldContainer>
        {nextPageText && (
          <PageLabel
            onClick={() => handlePageChange(activePage + 1)}
            active={activePage !== totalPages}
          >
            {nextPageText}
          </PageLabel>
        )}
        {lastPageText && (
          <PageLabel
            onClick={() => handlePageChange(totalPages)}
            active={activePage !== totalPages}
          >
            {lastPageText}
          </PageLabel>
        )}
        <ToggleButtonsContainer>
          <ToggleButtons onClick={handlePrevPage}>
            <Icon icon='Left' weight='regular' color='dimmed' size={8} />
          </ToggleButtons>
          <ToggleButtons onClick={handleNextPage}>
            <Icon icon='Right' weight='regular' color='dimmed' size={8} />
          </ToggleButtons>
        </ToggleButtonsContainer>
      </PageSwitchContainer>
    </PaginationContainer>
  ) : null;
};

export default Pagination;