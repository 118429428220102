import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import AccordionTableRow from './AccordionTableRow';
import AccordionTableHeader from './AccordionTableHeader';
import { ButtonWithIcon, Icon, Spinner } from 'scorer-ui-kit';
import { ITableColumnConfig, IRowHeader, ICellData } from 'scorer-ui-kit/dist/Tables';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ISortBy } from '../molecules/SortBy';

export interface IAccordionRowData {
  _checked?: boolean;
  id?: number | string;
  accGrp?: string;
  header?: IRowHeader;
  columns: ICellData[];
}

export type IAccordionTableData = IAccordionRowData[];

type IVisibleCategory = {
  [key: string]: boolean;
};

interface ITableProps {
  columnConfig: ITableColumnConfig[];
  rows: IAccordionTableData;
  selectable?: boolean;
  hasStatus?: boolean;
  hasThumbnail?: boolean;
  hasTypeIcon?: boolean;
  defaultAscending?: boolean;
  isLoading?: boolean;
  loadingText?: string;
  emptyTableTitle?: string;
  emptyTableText?: string;
  hasHeaderGroups?: boolean;
  selectCallback?: (checked: boolean, id?: string | number) => void;
  toggleAllCallback?: (checked: boolean) => void;
  // sortCallback?: (ascending: boolean, columnId: string) => void;
  sortCallback?: (columnId: string) => void;
  closeText?: string;
  columnIdCallback?: (columnId: string) => void
  sortBy?: ISortBy;
  notFoundText: string;
  visibleCategory?: IVisibleCategory;
  setVisibleCategory?: (value: IVisibleCategory | ((prev: IVisibleCategory) => IVisibleCategory)) => void;
  filterLinksStatus?: () => boolean;
  emptyContainerButtonLink: string;
  standardLinksinAPI?: boolean;
  otherLinksinAPI?: boolean;
}

const Container = styled.div``;

const TableContainer = styled.div`
  width: 100%;
  position: relative;
`;

const TableSection = styled.div`
  padding-top: 8px;
  padding-bottom: 10px;
`;

const SectionHeader = styled.div<{ marginTop?: string; }>`
  display: flex;
  width:100%;
  align-items: center;
  cursor: pointer;
  height: 40px;
  padding: 9px 17px 9px 15px;
  margin: 17px 0px 5px 0px;
  background: #eef0f3;
  color: #7f838b;
  font-size: 12px;
  font-weight: 600;
  border-radius: 3px;
  border: solid 1px #e8e8ec;
  background-color: rgba(0, 0, 85, 0.02);
  margin-top: ${({ marginTop }) => marginTop};
`;

const EmptyTableBox = styled.div`
  position: absolute;
  left: 0;
  z-index: 99;
  padding: 20px;
  width: 100%;
  min-height: 100px;
  text-align: center;
  h3 {
    font-weight: 500;
    color: var(--grey-11);
  }
  color: var(--grey-10);
`;

const SectionRows = styled.div<{ isVisibleOthers?: boolean }>`
  margin-bottom: 2px;
`;

const AccordionText = styled.div`
  display:flex;
  align-items:center;
  justify:content;
  gap:12px;
  min-height: 0px !important; 
  padding-top: 0px !important;
  margin-top: 0px !important;
  > div:first-child {
    display: flex;
    align-item: center;
  }
`;

const LoadingText = styled.div`
  color: var(--grey-a11);
`;
const LoadingBox = styled.div`
  background: rgb(255, 255, 255);
  height: calc(100% - 50px);
  opacity: 85%;
  width: 100%;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;

  ${LoadingText} {
    margin-top: 10px;
  }
`;

const ToggleButton = styled.button`
  margin-left: auto;
  margin-right: -4px;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  color: var(--grey-11);
`;

const EmptyContainerHeading = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #717989 !important;
  padding-top: 0px !important;
  margin-top: 0px !important;
  height: 18.5px;
`;
const EmptyContainerText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #80838d !important;
  padding-top: 0px !important;
  margin-top: 0px !important;
  min-height: 0px !important;
  
  height: 25px;
  align-self: stretch;
  flex-grow: 0;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.79;
  letter-spacing: normal;
`;
const EmptyContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-top: 16px;
  padding: 32px 16px;
  text-align: center;
  gap: 9px;
  padding: 32px 16px;
  border-radius: 4px;
  border: solid 1px #e8e8ec;
`;

const DeviceRightContainer = styled.div`
  min-height: 0px;
  margin-top: 8px;
  padding-top:7px;
  a {
    height: 28px;
    margin-top: 3px;
    border-radius: 3px;
    > button {
      border-radius: 3px;
      box-shadow: 0 2px 4px 2px rgba(0, 0, 51, 0.06);
      border: solid 1px rgba(217, 217, 224, 0.88);
      background-image: linear-gradient(108deg, #e0e1e6 -55%, rgba(217, 217, 224, 0.88) 100%);
      color: #5a6671;

      width: 100%;
      height: 100%;
      font-weight: 500;
      padding-left: 0px;
      gap: 8px;
      div {
        width: 30px;
        margin-top: 0px !important;
        align-self: stretch;
        flex-grow: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 3px 4px;
        border-right: solid 1px rgba(0, 110, 255, 0.08);
      }
        svg {
          g {
            stroke: #5a6671;
          }
        }
      }
    }
  }
  // Media queries for screen less then 1280px.
  @media (max-width: 1280px) {
    a {
      margin-top: -3px;
    }
  }
`;
const LinkRoute = styled(Link)`
  width: auto;
  height: 32px;
  font-size: 14px;
  font-weight: 500;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none !important;
`;

const groupByAccGrp = (rows: IAccordionTableData, notFoundText: string) => {
  return rows.reduce<Record<string, IAccordionRowData[]>>((acc, row) => {
    const groupName = row.accGrp || notFoundText || 'Links';
    if (!acc[groupName]) {
      acc[groupName] = [];
    }
    acc[groupName].push(row);
    return acc;
  }, {});
};

const AccordionTable: React.FC<ITableProps> = ({
  columnConfig,
  selectable,
  rows = [],
  closeText,
  hasStatus = false,
  hasThumbnail = false,
  hasTypeIcon = false,
  defaultAscending = true,
  isLoading = false,
  loadingText = 'Loading Data...',
  emptyTableTitle = '',
  emptyTableText = '',
  hasHeaderGroups = false,
  sortCallback = () => { },
  selectCallback = () => { },
  toggleAllCallback = () => { },
  columnIdCallback = () => { },
  sortBy,
  notFoundText,
  visibleCategory = {},
  setVisibleCategory = () => { },
  filterLinksStatus = () => true,
  emptyContainerButtonLink,
  standardLinksinAPI = true,
  otherLinksinAPI = true

}) => {
  const { t } = useTranslation(['DeviceLinks', 'Common']);
  const [allChecked, setAllChecked] = useState(false);
  const [isVisibleStandard, setIsVisibleStandard] = useState<boolean>(true);
  const [isVisibleOthers, setIsVisibleOthers] = useState<boolean>(true);
  const [visibleSections, setVisibleSections] = useState<
    { name: string; rows: IAccordionRowData[]; isVisible: boolean }[]
  >([]);

  const isEmptyTable = rows.length === 0 && !isLoading;

  const handleSort = (columnId: string) => {
    columnIdCallback(columnId);
  };

  useEffect(() => {
    const groupedRows = groupByAccGrp(rows, notFoundText);
    const sections = Object.keys(groupedRows).map((name) => ({
      name,
      rows: groupedRows[name],
      isVisible: visibleCategory[name],
    }));
    setVisibleSections(sections);
  }, [rows, notFoundText, t, visibleCategory]);

  const toggleVisibility = (index: number) => {
    setVisibleSections((prevSections) =>
      prevSections.map((section, i) =>
        i === index ? { ...section, isVisible: !section.isVisible } : section
      )
    );

    visibleSections.forEach((section, i) => {
      if (i === index) {
        setVisibleCategory((prev) => ({
          ...prev,
          [section.name]: !prev[section.name],
        }));
      }
    });
  };


  useEffect(() => {
    let areAllChecked = false;
    if (rows.every((row) => row._checked) && rows.length > 0 && !isEmptyTable) {
      areAllChecked = true;
    }
    setAllChecked(areAllChecked);
  }, [isEmptyTable, rows]);



  return (
    <Container>
      <TableContainer>
        <AccordionTableHeader
          {...{
            selectable,
            hasStatus,
            hasThumbnail,
            hasTypeIcon,
            defaultAscending,
            allChecked,
            isEmptyTable,
            isLoading,
            hasHeaderGroups,
            columnConfig,
            toggleAllCallback,
            sortCallback: handleSort,
            sortBy
          }}
        />
        {isLoading ? (
          <LoadingBox>
            <Spinner size='large' styling='primary' />
            <LoadingText>{loadingText}</LoadingText>
          </LoadingBox>
        ) :
          isEmptyTable ? (
            <EmptyTableBox>
              <h3>{emptyTableTitle}</h3>
              <p>{emptyTableText}</p>
            </EmptyTableBox>
          ) : visibleSections.map((section, index) => (
            <React.Fragment key={section.name}>
              {!visibleSections.some((item) => item.name === t('DeviceLinks:tableText.emptyStandardText.accordionText')) && !standardLinksinAPI && filterLinksStatus() && (
                <TableSection>
                  <SectionHeader
                    onClick={() => setIsVisibleStandard(!isVisibleStandard)}
                  >
                    <AccordionText>
                      <Icon icon='MetaTags' color='dimmed' size={14} />
                      <span>{t('DeviceLinks:tableText.emptyStandardText.accordionText')} (0)</span>
                    </AccordionText>
                    <ToggleButton>
                      {isVisibleStandard ? (
                        <Icon icon='Down' color='dimmed' size={8} />
                      ) : (
                        <Icon icon='Up' color='dimmed' size={8} />
                      )}
                    </ToggleButton>
                  </SectionHeader>
                  {isVisibleStandard && (
                    <EmptyContainer>
                      <EmptyContainerHeading>
                        {t('DeviceLinks:tableText.emptyStandardText.heading')}
                      </EmptyContainerHeading>
                      <EmptyContainerText>
                        {t('DeviceLinks:tableText.emptyStandardText.text')}
                      </EmptyContainerText>
                    </EmptyContainer>
                  )}
                </TableSection>
              )}
              <TableSection key={index}>
                <SectionHeader onClick={() => toggleVisibility(index)}>
                  <AccordionText>
                    <Icon icon='MetaTags' color='dimmed' size={14} />
                    <span>
                      {section.name} {section.name !== t('DeviceLinks:tableText.emptyStandardText.accordionText') ? `(${section.rows.length})` : undefined}
                    </span>
                  </AccordionText>
                  <ToggleButton>
                    {section.isVisible ? (
                      <Icon icon='Down' color='dimmed' size={8} />
                    ) : (
                      <Icon icon='Up' color='dimmed' size={8} />
                    )}
                  </ToggleButton>
                </SectionHeader>
                {section.isVisible && (
                  <SectionRows>
                    {section.rows.map((row, rowIndex) => (
                      <AccordionTableRow
                        key={rowIndex}
                        rowData={row}
                        isLastRow={rowIndex === section.rows.length - 1}
                        selectable={selectable}
                        selectCallback={selectCallback}
                        columnConfig={columnConfig}
                        hasStatus={hasStatus}
                        hasThumbnail={hasThumbnail}
                        hasTypeIcon={hasTypeIcon}
                        closeText={closeText}
                      />
                    ))}
                  </SectionRows>
                )}
                {!visibleSections.some((item) => item.name === t('DeviceLinks:tableText.emptyOtherText.heading')) && !otherLinksinAPI && filterLinksStatus() && (
                  <>
                    <SectionHeader
                      marginTop='37px'
                      onClick={() => setIsVisibleOthers(!isVisibleOthers)}
                    >
                      <AccordionText>
                        <Icon icon='MetaTags' color='dimmed' size={14} />
                        <span>{t('DeviceLinks:tableText.emptyOtherText.accordionText')} (0)</span>
                      </AccordionText>
                      <ToggleButton>
                        {isVisibleOthers ? (
                          <Icon icon='Down' color='dimmed' size={8} />
                        ) : (
                          <Icon icon='Up' color='dimmed' size={8} />
                        )}
                      </ToggleButton>
                    </SectionHeader>
                    {isVisibleOthers && (
                      <EmptyContainer>
                        <EmptyContainerHeading>{t('DeviceLinks:tableText.emptyOtherText.heading')}</EmptyContainerHeading>
                        <EmptyContainerText>
                          {t('DeviceLinks:tableText.emptyOtherText.text')}
                        </EmptyContainerText>

                        <DeviceRightContainer>
                          <LinkRoute to={emptyContainerButtonLink}>
                            <ButtonWithIcon
                              icon='Add'
                              design='secondary'
                              size='normal'
                              position='left'
                              color='dimmed'>
                              {t('DeviceLinks:createLinkButton')}
                            </ButtonWithIcon>
                          </LinkRoute>
                        </DeviceRightContainer>
                      </EmptyContainer>
                    )}
                  </>
                )}
              </TableSection>
            </React.Fragment>
          ))
        }
      </TableContainer>
    </Container>
  );
};

export default AccordionTable;
