import React, { useState, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { Icon } from 'scorer-ui-kit';
import { useCopyToClipboard } from 'scorer-ui-kit';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


interface IBreadcrumb {
  text: string;
  href?: string;
}

export interface IUtilityHeader {
  $iconInGutter?: boolean;
  backLink?: boolean;
  showBreadcrumbs?: boolean;
  breadcrumbs?: IBreadcrumb[];
  showShareLink?: boolean;
  shareLink?: string;
  breadCrumbBack?: string;
}

const Container = styled.div`
  max-width: var(--max-content-width);
  margin-top: var(--utility-header-padding-top);
  padding: 0 var(--content-layout-padding-right) 0 var(--content-layout-padding-left);
  height: 48px;
  width: 100%;
  display: flex;
`;

const LeftArea = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
  flex: 1 0 0;
`;
const BackLinkIcon = styled.div`
  display: flex;
  width: 16px;
  height: 16px;
  justify-content: center;
  align-items: center;
  flex: 1;  
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
const BackLink = styled.div<{ $iconInGutter: boolean, $showDivider: boolean }>`
  position: relative;
  display: flex;
  padding: 0;
  align-items: center;
  text-wrap: nowrap;
  gap: 8px;
  color: #80838d;
  text-align: center;
  font-family: var(--font-ui);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  border: none;
  background: none;
  text-decoration: none;
  transition: color 0.25s ease;
  cursor: pointer;
  line-height: 14px;
  margin-left: ${props => props.$iconInGutter ? '-25px' : '0'};

  ${BackLinkIcon}{
    svg * {
      transition: stroke 0.25s ease;
    }
  }

  ${({ $showDivider }) => $showDivider && css`
    &::after {
      content: '';
      display: inline-block;
      height: 12px;
      width: 1px;
      padding-left: 8px;
      border-right: 1px solid #cdced6;
    }
  `}
`;

const ExtraActionIcon = styled.div`
  display: flex;
  width: 16px;
  height: 16px;
  justify-content: center;
  align-items: center;
`;

const ExtraAction = styled.button`
  position: relative;
  display: flex;
  padding: 0;
  align-items: center;
  gap: 8px;
  color: var(--grey-10);
  text-align: center;
  font-family: var(--font-ui);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  border: none;
  background: none;
  text-decoration: none;
  transition: color 0.25s ease;
  cursor: pointer;

  ${ExtraActionIcon}{
    svg * {
      transition: stroke 0.25s ease;
    }
  }
  
  &:hover {
    color: var(--grey-12);
    ${ExtraActionIcon}{
      svg * {
        stroke: var(--grey-12);
      }
    }
  }

`;

const Breadcrumbs = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 8px;
`;

const Breadcrumb = styled.div`
  flex: 1;
  display: flex;
  display: inline-flex;
  align-items: center;
  gap: 8px;
`;

const BreadcrumbIcon = styled.div`
  flex: 0;
  display: flex;
  align-items: center;

  > div {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const BreadcrumbLink = styled(Link)<{ hasHref?: boolean }>`
  flex: 1;
  color: #80838d;
  line-height: 14px;
  text-decoration: none;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  transition: color 0.25s ease;
  width: max-content !important;
  cursor: ${({ hasHref }) => (hasHref ? 'pointer' : 'text')};
  &:hover {
    color: var(--grey-12);
  }
`;

const NoLink = styled.p<{ hasHref?: boolean }>`
  flex: 1;
  margin: 0px;
  color: #80838d;
  line-height: 14px;
  text-decoration: none;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  transition: color 0.25s ease;
  width: max-content !important;
  cursor: ${({ hasHref }) => (hasHref ? 'pointer' : 'text')};
  &:hover {
    color: var(--grey-12);
  }
`;

const RightArea = styled.div`
  flex: 1;
  align-items: center;
  display: flex;
  justify-content: right;
`;




const Breadcrums: React.FC<IUtilityHeader> = ({ showBreadcrumbs = true, breadcrumbs = [], backLink, $iconInGutter = true, showShareLink = false, shareLink, breadCrumbBack = '' }) => {

  const [copyActionText, setCopyActionText] = useState<string>('Share');
  const { copyToClipboard } = useCopyToClipboard();

  const hasBreadcrumbs = showBreadcrumbs && breadcrumbs.length > 0;
  const { t } = useTranslation(['Common']);

  const clickHandlerShareLink = useCallback(() => {
    copyToClipboard(shareLink ? shareLink : window.location.href);
    setCopyActionText('Copied');
    setTimeout(() => setCopyActionText('Share'), 2000);
  }, [shareLink, copyToClipboard]);

  const history = useHistory();
  const handleBackClick = () => {
    if (breadCrumbBack !== '') {
      history.push(breadCrumbBack);
    } else {
      history.goBack();
    }
  };

  return (
    <Container>
      <LeftArea>
        {backLink ?
          <BackLink $showDivider={hasBreadcrumbs} {...{ $iconInGutter }} onClick={handleBackClick}>
            <BackLinkIcon>
              <Icon icon='Back' color='dimmed' size={16} />
            </BackLinkIcon>
            {t('Common:breadCrums.back')}
          </BackLink>
          : null}
        {hasBreadcrumbs ?
          <Breadcrumbs>
            {breadcrumbs.map((breadcrumb, index) => {
              const { text, href } = breadcrumb;
              const isLast = index === breadcrumbs.length - 1;

              return (
                <React.Fragment key={index}>
                  <Breadcrumb>
                  {href ? (
                    <BreadcrumbLink to={href} hasHref={!!href}>{text}</BreadcrumbLink>
                  ) : (
                    <NoLink>{text}</NoLink>
                  )}
                    {!isLast ? <BreadcrumbIcon><Icon icon='Right' color='dimmed' size={4} /></BreadcrumbIcon> : null}
                  </Breadcrumb>
                </React.Fragment>
              );
            })}
          </Breadcrumbs>
          : null}
      </LeftArea>
      <RightArea>
        {showShareLink ?
          <ExtraAction onClick={clickHandlerShareLink}>
            <ExtraActionIcon>
              <Icon icon='Link' size={16} color='grey-10' />
            </ExtraActionIcon>
            {copyActionText}
          </ExtraAction>
          : null}
      </RightArea>
    </Container>
  );
};

export default Breadcrums;