/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const devicesQuery = /* GraphQL */ `
  query Devices {
    devices {
      name
      lid
      tags
      type
      group
    }
  }
`;

export const deviceStatusQuery = /* GraphQL */ `
  query DeviceStatus($deviceID: String!) {
    deviceStatus(deviceID: $deviceID) {
      lastSnapshot {
        signedUrl
        time
      }
      status {
        alive
        diskUsageAlertLevel
        vpnIp
      }
    }
  }
`;

export const getWebgateStatusQuery = /* GraphQL */ `
  query GetWebgateStatus($deviceID: ID!, $links: [LinkInput!]!) {
    getWebgateStatus(deviceID: $deviceID, links: $links) {
      Links {
        linkID
        linkName
        status
        url
        enabled
        lastUpdated
      }
      deviceIP
      otherLinksCount        
    }
  }
`;

export const isWebgateEnabledQuery = /* GraphQL */ `
  query IsWebgateEnabled {
    isWebgateEnabled
  }
`;




// Device Links API's
export const getWebgateDeviceLinksQuery = /* GraphQL */ `
  query GetWebgateDeviceLinks($deviceID: String!) {
    getWebgateDeviceLinks(deviceID: $deviceID) {
      status_code
      status_msg
      standardLinks {
        name
        linkID
        url
        status{
          id
          message
        }
        port
        protocol
        noIndex
        expiry {
          time
          action
        }
        lastAccessed
        customDomains
        dataUsage {
          ingress
          egress
        }
        uploadLimit {
          uploadLimit
          uploadLimitUnit
        }
      }
      otherLinks {
        name
        linkID
        url
        status{
          id
          message
        }
        port
        protocol
        noIndex
        expiry {
          time
          action
        }
        lastAccessed
        customDomains
        dataUsage {
          ingress
          egress
        }
        uploadLimit {
          uploadLimit
          uploadLimitUnit
        }
      }
    }
  }
`;



// Webgate Link Info API
export const getWebgateLinkInfoQuery = /* GraphQL */ `
  query GetWebgateLinkInfo($deviceID: String!, $linkID: String!) {
    getWebgateLinkInfo(deviceID: $deviceID, linkID: $linkID) {
      status_code
      status_msg
      linkID
      payload {
        name
        linkID
        url
        status {
          id
          message
        }
        port
        protocol
        noIndex
        expiry {
          time
          action
        }
        lastAccessed
        customDomains
        dataUsage {
          ingress
          egress
        }
        uploadLimit {
          uploadLimit
          uploadLimitUnit
        }
      }
    }
  }
`;



// Connection Usage API's

//1st API: Get Current Month and Previous Month data usage
export const getUsageStatsQuery = /* GraphQL */ `
  query GetUsageStats($linkID: String!, $deviceID: String!) {
    getUsageStats(linkID: $linkID, deviceID: $deviceID) {
      currentMonthUsage {
        totalUsage
        ingressUsage
        egressUsage
      }
      lastMonthUsage {
        totalUsage
        ingressUsage
        egressUsage
      }
    }
  }
`;

//2nd API: Get top 5 clients for an particular date
export const getTopUsageClientsQuery = /* GraphQL */ `
  query GetTopUsageClientsStats($linkID: String!, $deviceID: String!, $usageDate: Int!) {
    getTopUsageClientsStats(linkID: $linkID, deviceID: $deviceID, usageDate: $usageDate) {
    ingress {
      clientIP
      ingressDataUsage
    }  
    egress {
      clientIP
      egressDataUsage
    }  
    }
  }
`;

//3rd API: Get Weekly(7 days) data usage
export const getWeeklyUsageStatsQuery = /* GraphQL */ `
  query GetWeeklyUsageStats($linkID: String!, $deviceID: String!, $usageStartDate: Int!, $usageEndDate: Int! ) {
    getWeeklyUsageStats(linkID: $linkID, deviceID: $deviceID, usageStartDate: $usageStartDate, usageEndDate: $usageEndDate) {
      weeklyUsageData {
        date
        ingressUsage
        egressUsage
      }
    }
  }
`;

// Settings Page API's
export const getLinksAllocatedStatusQuery = /* GraphQL */ `
  query GetLinksAllocatedStatus {
    getLinksAllocatedStatus{
      linksAllocated
      networkAddress
    } 
  }
`;