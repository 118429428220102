import React, { useState, useCallback, useEffect, ReactElement, useContext, useRef } from 'react';
import styled from 'styled-components';
import { Content, PageHeader, TypeTable, INotificationProps, resetButtonStyles, Button, useModal, MultilineContent, Icon, Spinner, useClickOutside, ButtonWithIcon } from 'scorer-ui-kit';
import { useNotification } from '../components/Notifications/useNotification';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { ITypeTableData, ITableColumnConfig, IRowData } from 'scorer-ui-kit/dist/Tables';
import { useDevices } from '../hooks/useDevices';
import { useTranslation } from 'react-i18next';
import GhostLogo from '../svg/ghost-logo.svg';
import { LoginScreen } from '../svg';
import { useFeatureEnable } from '../hooks/useFeatureEnable';
import Pagination from '../components/Pagination';
import DropDown from '../components/molecules/Dropdown';
import SortBy, { ISortBy, ISortItem } from '../components/molecules/SortBy';
import SelectMulti from '../components/molecules/SelectMulti';
import Select from '../components/molecules/Select';
import SearchInput from '../components/atoms/SearchInput';
import { Device, DeviceStatus, enableWebgateVPN, getDeviceStatus, getWebgateStatus, isWebgateEnabled, toggleWebgateDevice} from '../lib/api_config';
import { GetWebgateStatusQuery } from '../API';
import { FeatureEnabledContext, ContextProps, GatewayModalContext, EditRoutingContext, EditContextProps } from '../App';
import { handleDismiss } from '../components/Notifications/Notification';
import { useWindowSize } from '../hooks/useWindowSize';
import ConfirmModal from '../components/molecules/ConfirmModal';
import i18n from '../i18n';

const MainContainer = styled(Content)`
  padding: 0px;
  max-width: calc(100vw - 80px);
  div {
    max-width: 100%;
  }
`;

const Wrapper = styled.div<{ enableContent?: boolean }>`
  width: 100%;
  opacity: ${({ enableContent }) => (enableContent ? '1' : '0.6')};
  pointer-events: ${({ enableContent }) => (enableContent ? 'auto' : 'none')};
`;

const LayoutHeader = styled.div`
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 0 0 4px;
`;

const HeaderWrapper = styled.div`
  width: auto;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 8px;
  padding: 47px 0 4px;
`;

const SectionContainer = styled.div<{
  height?: string;
  width?: string;
  minWidth?: string;
  gap?: string;
  padding?: string;
  grow?: number;
  direction?: string;
  justify?: string;
  align?: string;
}>`
  width: ${({ width }) => (width ? width : 'auto')};
  min-width: ${({ minWidth }) => (minWidth ? minWidth : 'auto')};
  height: ${({ height }) => (height ? height : 'auto')};
  align-self: stretch;
  flex-grow: ${({ grow }) => (grow ? grow : 0)};
  display: flex;
  flex-direction: ${({ direction }) => (direction ? direction : 'column')};
  justify-content: ${({ justify }) => (justify ? justify : 'flex-start')};
  align-items: ${({ align }) => (align ? align : 'stretch')};
  gap: ${({ gap }) => (gap ? gap : '16px')};
  padding: ${({ padding }) => (padding ? padding : '32px 96px 64px 96px')};
`;

const InnerHeader = styled.div`
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0;
`;

const MainHeader = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 24px;
  padding: 0 32px 6px 0;
  position: relative;
  h1 {
    height: 30.5px;
    margin-left: -1px;
    margin-bottom: 0px;
    letter-spacing: -0.3px;
    line-height: 30.5px;
  }
  p {
    letter-spacing: -1px !important;
  }
`;

const SpinnerContainer = styled.div`
  position: absolute;
  left: -45px;
  top: 3px;
`;

const TextContainer = styled.div<{
  height?: string;
  width?: string;
  gap?: string;
  padding?: string;
  grow?: number;
  direction?: string;
  justify?: string;
  align?: string;
  letter?: string;
  maxWidth?: string;
}>`
  width: ${({ width }) => (width ? width : 'auto')};
  max-width: ${({ maxWidth }) => maxWidth};
  height: ${({ height }) => (height ? height : 'auto')};
  align-self: stretch;
  flex-grow: ${({ grow }) => (grow ? grow : 0)};
  display: flex;
  flex-direction: ${({ direction }) => (direction ? direction : 'column')};
  justify-content: ${({ justify }) => (justify ? justify : 'flex-start')};
  align-items: ${({ align }) => (align ? align : 'stretch')};
  gap: ${({ gap }) => (gap ? gap : '0px')};
  padding: ${({ padding }) => (padding ? padding : '0px')};
  letter-spacing: ${({ letter }) => letter};
`;

const PageHeaderText = styled.div`
  min-width: 648px;
  width: 648px;
  flex-grow: 1;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.43;
  letter-spacing: 0.04px;
  text-align: left;
  color: #898e96;
  margin-left: -1px;
  font-family: ${({ theme }) => theme.fontFamily.ui};
  height: 60.5px;
`;

const ColumnText = styled.div<{
  lineHeight?: number;
  padding?: string;
  fontSize?: string;
  color?: string;
  fontWeight?: string;
  height?: string;
  width?: string;
  family?: string;
  letter?: string;
  justifyContent?: string;
  gap?: string;
  flexWrap?: string;
}>`
  min-height: ${({ height }) => (height ? height : '14px')};
  height: ${({ height }) => height};
  width: ${({ width }) => (width ? width : 'auto')};
  padding: ${({ padding }) => (padding ? padding : '0px')};
  font-family: Lato;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '12px')};
  display: flex;
  align-items: center;
  gap: ${({ gap }) => gap};
  flex-wrap: ${({ flexWrap }) => flexWrap};
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : 'center'};
  color: ${({ color }) => (color ? color : '#8b9196')};
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: ${({ theme, family }) =>
    family ? family : theme.fontFamily.ui};
  line-height: ${({ lineHeight }) => lineHeight};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : '500')};
  letter-spacing: ${({ letter }) => letter};
`;

const ActionContainer = styled.div`
  height: 68px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  padding: 0;
  > div:last-child {
    > button {
      margin-right: 2px;
    }
  }
`;

const AreaTitle = styled.div`
  width: auto;
  height: 24px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  padding: 5px 0;
`;

const BoldText = styled.p`
  width: auto;
  height: 14px;
  flex-grow: 0;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  margin: 0;
  padding: 0px;
  color: #8b9196;
`;

const ButtonListContainer = styled.div`
  height: 32px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
  padding: 0;
`;

const ButtonList = styled.div`
  height: 32px;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  padding: 0;
`;

const NoAction = styled.p<{
  fontSize?: string;
  margin?: string;
}>`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '14px')};
  margin: ${({ margin }) => (margin ? margin : '0px')};
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 170px !important;
  svg {
    width: 12px;
    height: 12px;
    top: 10px;
    g {
      stroke: rgba(118, 118, 118, 0.65);
    }
  }
  input::placeholder {
    color: rgba(118, 118, 118, 0.65);
    font-size: 11.5px;
    letter-spacing: 0.15px;
    padding-left: 1px;
    position: absolute;
    top: 8px;
    font-family: monorale;
  }
`;

const FilterInput = styled(SearchInput)<{ lang?: string;}>`
  input {
    width: 250px;
    min-height: 32px;
    border-radius: 3px;
    padding: 0 16px 4px 30px;
    box-shadow: 0 2px 8px 0 rgba(0, 16, 64, 0.06);
    border: solid 1px #cbcfd5;
    background-color: #fcfcfd;
    font-size: 12px;
    letter-spacing: 0px;
    ::placeholder {
      position: absolute;
      top: ${({ lang }) => (lang === 'ja' ? '6px' : '8px')}
    }
  }
`;

const SelectWrapper = styled.div`
  height: 32px;
  max-width: 123px;
  box-shadow: 0 2px 8px 0 rgba(0, 16, 64, 0.06);
  background-color: transparent;
  border: solid 1px #cbcfd5;
  border-radius: 3px;
  div {
    max-width: 121px;

    button {
      padding: 0;
      max-width: 121px;
      border: none;
      padding-right: 0px;
      gap: 0px;
      display: flex;
      align-items: center;
      justify-content: center;

      div:first-child {
        width: 32px;
        margin: 0px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        div:first-child svg {
          width: 12px;
          height: 12px;
        }
      }
      span {
        min-width: 62px;
        font-weight: 500;
        margin-left: 0px;
        font-size: 12px;
        margin: 0px;
        padding: 0px;
        letter-spacing: 0.2px;
        font-family: monorale;
        line-height: 12px;
      }
      div:last-child {
        width: 30px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          width: 8px;
          height: 6px;
        }
      }
    }
  }
`;

const SelectMultiWrapper = styled.div`
  height: 32px;
  max-width: 87px;
  box-shadow: 0 2px 8px 0 rgba(0, 16, 64, 0.06);
  background-color: transparent;
  border: solid 1px #cbcfd5;
  border-radius: 3px;
  div {
    max-width: none !important;

    button {
      padding: 0;
      max-width: 87px;
      border: none;
      padding-right: 0px;
      gap: 0px;
      display: flex;
      align-items: center;
      justify-content: center;

      div:first-child {
        width: 32px;
        margin: 0px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        div:first-child svg {
          width: 12px;
          height: 12px;
        }
      }
      span {
        min-width: 29px;
        font-weight: 500;
        margin-left: 0px;
        font-size: 12px;
        margin: 0px;
        padding: 0px;
        font-family: monorale;
        letter-spacing: -0.2px;
        line-height: 12px;
      }
      div:last-child {
        width: 30px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          width: 8px;
          height: 6px;
        }
      }
    }
  }
`;

const SepratorContainer = styled.div`
  border-left: 2px solid rgb(240, 240, 240);
  box-sizing: border-box;
  height: auto;
  margin-right: 10px;
`;

const FilterContainer = styled.div`
  padding-left: 10px;
  display: flex;
`;

const FilterListIcon = styled.div`
  margin-top: 2px;
  > div {
    display: flex;
    align-items: center;
  }
`;

const ClearItem = styled(FilterListIcon)`
  cursor: pointer;
`;

const FilterText = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-left: 11px;
  margin-right: 5px;
  font-size: 14px;
  font-weight: 500;
  color: hsl(210, 5%, 56%);
`;

const TogglingService = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  i {
    font-size: 12px;
  }
`;

const TableLinkRow = styled.div`
  position: relative;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
`;

// custom domain links
const LinkLayout = styled.div`
  max-width: 185px;
`;

const LinkContainer = styled(Link)<{
  paddingBotton?: string;
}>`
  width: auto;
  height: 24px;
  font-size: 12px;
  font-weight: 500;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none !important;
`;

const LinkText = styled.div<{
  padding?: string;
  minWidth?: string;
  marginRight?: string;
}>`
  font-family: Monorale;
  line-height: normal;
  height: 24px;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-right: ${({ marginRight }) => marginRight};
  font-weight: 600;
  padding: ${({ padding }) => padding};
  border: solid 1px transparent;
  color: #8b8d98;

  :hover {
    transition: 0.3s;
    color: #75aaf3;
    border: solid 1px #9bc3fa;
    border-radius: 3px;
  }
`;

/// --- Custom Modal Template --- ///

const LogoBackground = styled.img`
  position: absolute;
  top: -542px;
  left: -570px;
  max-width: 1076px;
  height: auto;
  object-fit: contain;
  mix-blend-mode: overlay;
`;

const Logo = styled(LoginScreen)`
  max-width: 350px;
  object-fit: contain;
  position: absolute;
  top: 50%;
  left: 49.8%;
  transform: translate(-50%, -50%);
`;

const StyledButton = styled.button`
  ${resetButtonStyles};
  color: hsl(0, 0%, 65%);
  font-size: 14px;
  font-weight: 500;
  line-height: 1.79;
  color: hsl(0, 0%, 65%);
  display: block;
  margin: 10px 0 0 0;
`;

const ButtonGroup = styled.div`
  text-align: center;
  margin-top: 10px;
  > button {
    margin: 12px auto 0px auto;
  }
`;

const CardCover = styled.div`
  height: 210px;
  border-radius: 5px 5px 0 0;
  position: relative;
  z-index: 99;
  background-image: linear-gradient(
    114deg,
    hsl(250, 60%, 62%),
    hsl(0, 46%, 54%)
  );
  overflow: hidden;
`;

const CardTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
  font-family: ${({ theme }) => theme.fontFamily.ui};
  color: hsl(210, 6%, 47%);
  margin-bottom: 10px;
`;

const SubCardContainer = styled.div`
  margin: 0 0 34px 0;
`;

const SubCardContent = styled.p`
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 14px;
  font-weight: 500;
  color: #7c7e7f;
  margin: 13px 0 25px 0;
`;

const CardContent = styled.div`
  padding: 28px 38px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.79;
  color: hsl(200, 1%, 49%);
`;

const CardModal = styled.div`
  display: flex;
  flex-direction: column;
`;

const ClearLinkAll = styled.label`
  width: auto;
  margin: 2px 0 0 10px;
  opacity: 0.6;
  font-family: ${({ theme }) => theme.fontFamily.data};
  font-size: 12px;
  font-weight: 500;
  color: #676e74;
  text-decoration: underline;
  cursor: pointer;
  padding-left: 5px;
`;

const SortByWrapper = styled.div`
  height: 40px;
  margin-right: 6px;
  max-width: auto;
  > div:first-child {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  div {
    max-width: auto;
    button.kLvMzd {
      background-color: transparent !important;
    }
    button {
      height: 32px;
      max-width: auto;
      border: none;
      padding-right: 0px;
      gap: 0px;
      display: flex;
      align-items: center;
      justify-content: center;

      div:first-child {
        width: 32px;
        margin: 0px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        > div:first-child {
          width: 32px;
          svg {
            width: 12px;
            height: 12px;
          }
        }
      }
      span {
        height: 20px;
        font-weight: 500;
        min-width: max-content;
        margin-left: 0px;
        font-size: 12px;
        line-height: 12px;
        margin: 0px;
        padding: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: monorale;
      }
      div:last-child {
        width: 30px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          width: 8px;
          height: 6px;
        }
      }
    }
    div:last-child > div:last-child > button {
      span {
        margin: 0px 15px 0px 5px;
        min-width: auto;
      }
      svg {
        width: 14px;
        height: 14px;
      }
    }
    button + div {
      position: absolute;
      top: 90%;
      max-width: none;
    }
  }
`;

const DeviceContainer = styled.div`
  height: auto;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  padding: 9px 16px 10px 0;
`;

const TableText = styled.p<{
  marginLeft?: string;
  fontSize?: string;
  color?: string;
}>`
  line-height: 14px;
  overflow-wrap: anywhere;
  margin-left: ${({ marginLeft }) => (marginLeft ? marginLeft : '8px')};
  font-family: Lato;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '14px')};
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ color }) => (color ? color : '#606466')};
  margin-top: 0px;
  margin-bottom: 0px;
`;

const LinkNameContainer = styled(Link)<{
  fontSize?: string;
  color?: string;
}>`
  line-height: 16px;
  overflow-wrap: anywhere;
  font-family: Lato;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '14px')};
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ color }) => (color ? color : '#606466')};
  margin-top: 0px;
  margin-bottom: 0px;
  text-decoration: none !important;
  :hover {
    color: #9bc3fa;
  }
`;

const CategoryTagContainer = styled.div`
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0;
`;

const TableRow = styled.div<{
  justify?: string;
}>`
  display: flex;
  gap: 4px;
  justify-content: ${({ justify }) => (justify ? justify : 'flex-end')};
  align-items: center;
  flex-wrap: wrap;
`;

const TableTagCategoryColumn = styled.div`
  width: auto;
  max-width: 450px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1px;
  padding: 1px 6px 1px 1px;
  border-radius: 3px;
  border: solid 1px #eceef0;
  line-height: 20px;
`;

const IconWrapper = styled.div<{
  marginTop?: string;
  borderLeft?: string;
  color?: string;
}>`
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '4px')};
  display: flex;
  justify-conent: center;
  align-items: center;
  flex-grow: 0;
  padding: 4px;
  border-left: ${({ borderLeft }) => (borderLeft ? borderLeft : 'none')};
  > div {
    display: flex;
    align-items: center;
    svg {
      g {
        stroke: ${({ color }) => (color ? color : '#717989')};
      }
    }
  }
`;

// Table component
const TableWrapper = styled.div`
  width: 100%;
  position: relative;
`;

const InnerTableWrapper = styled.div`
  > div > div {
    > div:first-child {
      > div:first-child {
        position: absolute;
        top: 38%;
        left: -33px;
      }
      > div:nth-child(5),
      div:nth-child(6),
      div:nth-child(7),
      div:nth-child(8) {
        > div:first-child {
          > div:first-child {
            margin-top: -9px;
            > div:first-child {
              padding-left: 0px;
              font-weight: 500;
            }
          }
          > div:nth-child(2) {
            margin-top: 11px;
            padding: 0px;
          }
        }
      }
    }
    > div.sc-keuYuY {
      position: absolute;
      > div:nth-child(2) {
        width: auto;
      }
    }
    > div.sc-jwWbkn {
      position: absolute;
    }
    > div {
      height: 72px;
      position: relative;
      > div:first-child {
        position: absolute;
        top: 35%;
        left: -33px;
      }
      > div:nth-child(2) {
        width: 7px;
      }
      > div:nth-child(3) {
        width: 63px;
        > div:first-child {
          margin: 0px;
        }
      }
      > div:nth-child(4) {
        width: 40px;
        > div:first-child {
          display: flex;
          align-items: center;
          > svg {
            margin: 4px;
          }
        }
      }
      > div:nth-child(5),
      div:nth-child(6),
      div:nth-child(7),
      div:nth-child(8) {
        > div:first-child {
          > div:nth-child(2) {
            margin-top: 2px;
            padding: 0px;
          }
        }
      }
      > div:nth-child(8) {
        > div:first-child {
          > div:nth-child(2) {
            margin-right: 23px;
          }
        }
      }
    }
  }
`;

const ContentContainer = styled(SectionContainer)``;
const TableFilterConatiner = styled(SectionContainer)``;
const FilterSet = styled(SectionContainer)``;
const FilterSection = styled(SectionContainer)``;
const FilterList = styled(SectionContainer)``;
const FilterConditions = styled(SectionContainer)``;

// All Interfaces

// Device status interface
interface IDeviceStatusMap {
  [deviceID: string]: DeviceStatus;
}

//  Interface to track link dropdown is extended or not
interface IExtendedMap {
  [deviceID: string]: boolean;
}

// Type for connection link type
type ILinkType = 'Edge UI' | 'Solution UI';

// Interface to track link dropdown for both Edge and Solution UI links
interface IExtendLinkMap {
  [key: string]: ILinkType extends infer LT
    ? LT extends ILinkType
      ? IExtendedMap
      : never
    : never;
}

// Type to check webgate device status
type IWebgateDeviceStatus = GetWebgateStatusQuery['getWebgateStatus'];

// Interface to validate webagte device status based on the device Id
interface IDeviceInfoMap {
  [deviceID: string]: IWebgateDeviceStatus;
}

// Interface for history params
export interface HistoryParams {
  selectedCategory?: string;
  selectedTag?: string;
  sortBy?: string;
  pageSize?: string;
  currentPage?: string;
  searchText?: string;
  totalPages?: string;
  selectedLinkType?: string;
  selectedLinkStatus?: string;
  portText?: string;
}

// Interface to Action Button List
interface ButtonType {
  btnName: string;
  btnType: 'Edge UI' | 'Solution UI';
  btnAction: (
    toggleId: string,
    linkType: 'Edge UI' | 'Solution UI',
    deviceName: string
  ) => void;
}

// Interface for Loading IDs
interface ILoadingIds {
  [key: string]: {
    [id: string]: {
      text: string | null;
    };
  };
}

const WebGateway: React.FC<{ username: string }> = ({ username }) => {
  // for switching the language between japanese and english.
  const { t } = useTranslation(['Webgateway', 'Common']);

  // To measure the viewport width
  const [width] = useWindowSize();

  // Apply loading state if ToggleWebagteVPN action performs
  const [isFeatureEnabledLoading, setIsFeatureEnabledLoading] = useState(false);

  // Action to enable webgate VPN
  const {actions: { putFeatureEnabled }} = useFeatureEnable();

  // Context to apply current webagte VPN state and set toggled webgate VPN
  const { isFeatureEnabled, setIsFeatureEnabled, setSwitchState }: ContextProps = useContext(FeatureEnabledContext);

  // Function for setting the state which is used to manage the routing of edit links page
  const { setIsEditFromHome }: EditContextProps = useContext(EditRoutingContext);

  // Hook to create and manage Modal
  const { setModalOpen, createModal } = useModal();

  // Context to close Modal
  const { modalClose } = useContext(GatewayModalContext);

  // State to manage Modal
  const [modalCreated, setModalCreated] = useState(false);

  // State manage for action Done button state
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);

  // Hook to get Devices list and corresponding action
  const {
    state: { devices, loading },
    actions: { fetchDevices },
  } = useDevices();

  // Hook to collect the browsing history
  const history = useHistory();

  // Hook to applied collect params from the URL
  const params = useLocation().search;

  // State to manage all available Device Tags
  const [tags, setTags] = useState<string[]>([]);

  // State to manage Sorting filter options
  const [sortByList, setSortByList] = useState<ISortItem[]>([]);

  // State to manage all available Device categories
  const [categories, setCategories] = useState<string[]>([]);

  // State to manage selected category filter
  const [selectedCategory, setSelectedCategory] = useState<string>('');

  // State to manage selected Tags filter
  const [selectedTag, setSelectedTag] = useState<string[]>([]);

  // State to manage Table Row data
  const [rowData, setRowData] = useState<ITypeTableData>([]);

  // Reference for checking that toggle API is called from actions or not
  const isToggleFromDone = useRef(false);

  // State to manage Search text filter
  const [searchText, setSearchText] = useState('');

  // State to manage Device result count
  const [resultCount, setResultCount] = useState(0);

  // State to extract params
  const [historyParams] = useState<string>(params);

  // State to manage Sorting filter
  const [sortBy, setSortBy] = useState<ISortBy>({
    sort: 'name',
    ascending: true,
    title: t('Webgateway:sorting.name'),
  });

  // State to manage Devices status
  const [statusUpdate, setStatusUpdate] = useState<IDeviceStatusMap>({});

  // State to manage webgate links dropdown
  const [extendedLink, setExtendedLink] = useState<IExtendLinkMap>({});

  // State to manage the links info fetching status
  const [loadingVPNStatus, setLoadingVPNStatus] = useState<boolean>(false);

  // State to manage Webgate links status
  const [vpnStatusUpdate, setVpnStatusUpdate] = useState<IDeviceInfoMap>({});

  const MakeLinksDisabled = useCallback(() => {
    if (!isFeatureEnabled) {
      setVpnStatusUpdate((prev) => {
        const newPrev = { ...prev };
  
        Object.keys(newPrev).forEach((deviceId) => {
          const currentState = newPrev[deviceId];
          if (currentState && currentState.Links) {
            newPrev[deviceId] = {
              ...currentState,
              Links: currentState.Links.map((link) => ({
                ...link,
                enabled: false, // Update enabled to false
              })),
            };
          }
        });
  
        return newPrev;
      });
    }
  }, [isFeatureEnabled]);
  
  useEffect(() => {
    MakeLinksDisabled();
  }, [MakeLinksDisabled]);
  

  // State to manage total available pages for the Devices
  const [totalPages, setTotalPages] = useState<number>(0);

  // State to manage current active Device page
  const [currentPage, setCurrentPage] = useState(1);

  // State to manage Webgate links status
  const [initialRowData, setInitialRowData] = useState<ITypeTableData>([]);

  // State to manage checked Devices
  const [checkedButtons, setCheckedButtons] = useState<string>('');

  // State to manage number of Device entries per page
  const [pageSize, setPageSize] = useState(10);

  // State to manage loading action
  const [loadingIds, setLoadingIds] = useState<{
    [key: string]: {
      [id: string]: {
        text: string | null;
      };
    };
  }>({});

  useEffect(() => {
    // Update Checked Action Buttons based on translations
    setCheckedButtons((prev) => {
      return ['Edge UI - Enable', 'デバイス管理UI - アクティブにする'].includes(prev)
        ? t('Webgateway:filters.enableEdge')
        : ['Edge UI - Disable', 'デバイス管理UI - 停止する'].includes(prev)
          ? t('Webgateway:filters.disableEdge')
          : ['Solution UI - Enable', 'ソリューションUI - アクティブにする'].includes(prev)
            ? t('Webgateway:filters.enableSolution')
            : ['Solution UI - Disable', 'ソリューションUI - 停止する'].includes(prev)
              ? t('Webgateway:filters.disableSolution')
              : prev;
    });
    
    // Update LoadingIds based on translations
    setLoadingIds((prev) => {
      // Clone the previous state
      const updatedState = { ...prev };
    
      // Iterate over the main keys ("Edge UI", "Solution UI")
      Object.keys(updatedState).forEach((section) => {
        const sectionKeys = updatedState[section];
    
        if (sectionKeys && Object.keys(sectionKeys).length > 0) {
          // Iterate over each key inside the section
          Object.keys(sectionKeys).forEach((key) => {
            const textValue = sectionKeys[key]?.text || '';
    
            // Update `text` using ternary conditions
            updatedState[section][key].text = 
              ['Disabling', '停止しています'].includes(textValue)
                ? t('Webgateway:tableHeaders.disabling')
                : ['Enabling', 'アクティブにしています'].includes(textValue)
                  ? t('Webgateway:tableHeaders.enabling')
                  : textValue; // Retain the original value if no match
          });
        }
      });
    
      return updatedState; // Return the updated state
    });
    
  }, [t]);
  

  // State to manage referense Id for dropdown
  const [refId, setRefId] = useState<{ title: string; id: string }>({
    title: '',
    id: '',
  });

  // Devices entries option per page
  const sizeOptions = [10, 20, 50, 100];

  // Extract the value if Modal has been popped up or not till now
  const showModalPopupFirstTime = localStorage.getItem(
    `${username}_show_modal_popup_first_time`
  );

  // Function to validate webgate VPN status
  const checkIsWebgateEnabled = useCallback(async () => {
    const enabled = await isWebgateEnabled(); // API call to check webgate is enabled or not
    if (enabled !== null) setIsFeatureEnabled(enabled); // Set API response to the context
    setIsFeatureEnabledLoading(true); // Set loading to true
  }, [setIsFeatureEnabled]);

  // Function call to check webgate VPN
  useEffect(() => {
    checkIsWebgateEnabled();
  }, [checkIsWebgateEnabled]);

  // Function to check any action is in pending or not
  const hasNestedKeys = useCallback((loadingState: ILoadingIds) => {
    const result = Object.keys(loadingState).some(
      (key) => Object.keys(loadingState[key]).length > 0
    );
    return result;
  }, []);

  // Function to set sorting options for filter
  const generateSortByList = useCallback(() => {
    // Check for tags and group presence
    const hasCategory = devices.some(
      (device) => device.group && device.group !== ''
    );
    const hasTags = devices.some(
      (device) => device.tags && device.tags.length > 0
    );

    const sortByList: ISortItem[] = [
      {
        sort: 'name',
        title: t('Webgateway:sorting.name'),
      },
    ];

    // Conditionally push 'category' if it exists
    if (hasCategory) {
      sortByList.push({
        sort: 'category',
        title: t('Webgateway:sorting.category'),
      });
    }

    // Conditionally push 'tags' if it exists
    if (hasTags) {
      sortByList.push({
        sort: 'tags',
        title: t('Webgateway:sorting.tags'),
      });
    }

    setSortByList(sortByList);
  }, [t, devices]);

  // Function call for sorting filter
  useEffect(() => {
    generateSortByList();
  }, [generateSortByList, t, devices]);

  // Hook to invoke notification
  const { sendNotification } = useNotification();

  // Notification type for successfully enabled webgate VPN
  const notiInfoSuccessEnabled = useCallback(()=>{
    const content: INotificationProps = {
      type: 'success',
      message: t('Webgateway:notificationMessage.successMessage'),
    };
    return content;
  }, [t]);

  // Function to check Checked Device
  const hasIsChecked = (findCheck: ITypeTableData) => {
    return findCheck.filter((obj) => obj._checked === true);
  };

  // Function to enable webagte VPN
  const enableWebgate = useCallback(async () => {
    setSwitchState('loading');
    await enableWebgateVPN(); // API call for enabling webgate VPN
    setSwitchState('default');
    putFeatureEnabled(true, username); // Setting enabled webgate VPN notification in local storage
    setIsFeatureEnabled(true); // Setting enabled webgate VPN true to the context
    handleDismiss(); // Clear all notification bar before sending a new one
    sendNotification(notiInfoSuccessEnabled()); // sending successfully enabled webgate VPN notification
  }, [
    sendNotification,
    notiInfoSuccessEnabled,
    setIsFeatureEnabled,
    putFeatureEnabled,
    username,
    setSwitchState,
  ]);

  const notiInfoDisabled = useCallback(()=>{
    // Notification type for successfully disable webgate VPN
    const conent: INotificationProps = {
      type: 'info',
      message: t('Webgateway:notificationMessage.disableMessage'),
      actionTextButton: t('Webgateway:notificationMessage.enableMessageNow'),
      onTextButtonClick: () => enableWebgate(), // Action to enable webgate VPN
      isPinned: false,
    };
    return conent;
  }, [t, enableWebgate]);

  // Create new Modal for enabling webgate and shown only for first time
  const custom = useCallback(() => (
    <CardModal>
      <CardCover>
        <Logo />
        <LogoBackground src={GhostLogo} />
      </CardCover>
      <CardContent>
        <CardTitle>{t('Webgateway:modalPopup.modalTitle')}</CardTitle>
        <SubCardContainer>
          <SubCardContent>
            {t('Webgateway:modalPopup.modalSubTitle1')}
          </SubCardContent>
          <SubCardContent>
            {t('Webgateway:modalPopup.modalSubTitle2')}
          </SubCardContent>
        </SubCardContainer>
        <ButtonGroup>
          <Button
            onClick={() => {
              setModalOpen(false); // Action for closing Modal
              enableWebgate(); // Action for enabling Webgate
              localStorage.setItem(
                `${username}_show_modal_popup_first_time`,
                'false'
              ); // Set value of Modal shown in local storage
            }}
          >
            {t('Webgateway:modalPopup.enableNow')}
          </Button>
          <StyledButton
            onClick={() => {
              sendNotification(notiInfoDisabled()); // Show notification for disabled webgate VPN
              setModalOpen(false); // Action for closing Modal
              localStorage.setItem(
                `${username}_show_modal_popup_first_time`,
                'false'
              ); // Set value of Modal shown in local storage
            }}
          >
            {t('Webgateway:modalPopup.enableLater')}
          </StyledButton>
        </ButtonGroup>
      </CardContent>
    </CardModal>
  ), [t, enableWebgate, notiInfoDisabled, sendNotification, setModalOpen, username]);

  // welcome Modal to show on the UI
  const welcomeModal = useCallback(() => {
    setIsFeatureEnabledLoading(false);
    createModal({
      isCloseEnable: false,
      width: '480px',
      padding: false,
      customComponent: custom(),
    });
  }, [createModal, custom]);

  // Call welcome Modal for first time to enable webgate VPN
  useEffect(() => {
    if (isFeatureEnabledLoading) {
      if (showModalPopupFirstTime === null && isFeatureEnabled) {
        return;
      } else if (showModalPopupFirstTime === null && !isFeatureEnabled) {
        if (modalClose) {
          return;
        } else {
          welcomeModal();
          setModalCreated(true);
        }
      } else {
        return;
      }
    }
  }, [
    welcomeModal,
    modalCreated,
    isFeatureEnabled,
    modalClose,
    showModalPopupFirstTime,
    isFeatureEnabledLoading,
  ]);

  // Function to set applied filter to the URL
  const setParams = useCallback(() => {
    const params =
      '?' +
      `${searchText === '' ? '' : '&searchText=' + searchText}` +
      `${
        selectedCategory === '' ? '' : '&selectedCategory=' + selectedCategory
      }` +
      `${
        selectedTag.length === 0
          ? ''
          : '&selectedTag=' + JSON.stringify(selectedTag)
      }` +
      `${
        sortBy.ascending && sortBy.sort === 'name'
          ? ''
          : '&sortBy=' + JSON.stringify(sortBy)
      }` +
      `${pageSize === 10 ? '' : '&pageSize=' + pageSize}` +
      `${currentPage === 1 ? '' : '&currentPage=' + currentPage}`;
    if (params === '?') {
      return '';
    }
    return params;
  }, [
    selectedCategory,
    selectedTag,
    sortBy,
    pageSize,
    currentPage,
    searchText,
  ]);

  // Fetching applied params from the URL and setting in the State
  const fetchHistoryParams = useCallback(() => {
    const string = new URLSearchParams(historyParams);
    let newParams: HistoryParams = {};
    newParams.selectedCategory = string.get('selectedCategory') || undefined;
    newParams.selectedTag = string.get('selectedTag') || undefined;
    newParams.sortBy = string.get('sortBy') || undefined;
    newParams.pageSize = string.get('pageSize') || undefined;
    newParams.currentPage = string.get('currentPage') || undefined;
    newParams.searchText = string.get('searchText') || undefined;

    if (newParams.selectedCategory) {
      setSelectedCategory(newParams.selectedCategory); // Setting Categories in the state
    }
    if (newParams.selectedTag) {
      setSelectedTag(JSON.parse(newParams.selectedTag)); // Setting Tags in the state
    }
    if (newParams.sortBy) {
      setSortBy(JSON.parse(newParams.sortBy)); // Setting sorting filter in the state
    }
    if (newParams.pageSize) {
      setPageSize(parseInt(newParams.pageSize)); // Setting number of entries per page in the state
    }
    if (newParams.currentPage) {
      setCurrentPage(parseInt(newParams.currentPage)); // Setting current in the state
    }
    if (newParams.searchText) {
      setSearchText(newParams.searchText); // Setting Searched text in the state
    }
    if (newParams.totalPages) {
      setTotalPages(parseInt(newParams.totalPages)); // Setting Total pagess in the state
    }
  }, [historyParams]);

  // Function call to collect params from URL
  useEffect(() => {
    fetchHistoryParams();
  }, [fetchHistoryParams]);

  // Pushing params into URL
  useEffect(() => {
    history.push('/' + setParams());
  }, [history, setParams]);

  // Devices Table column headers
  const tableColumns: ITableColumnConfig[] = [
    {
      header: t('Webgateway:tableHeaders.deviceName'),
      sortable: false,
      cellStyle: 'firstColumn',
      minWidth: 275,
      alignment: 'left',
    },
    {
      header: t('Webgateway:tableHeaders.EdgeDeviceUI'),
      sortable: false,
      cellStyle: 'normalImportance',
      groupTitle: t('Webgateway:tableHeaders.groupTitle'),
      alignment: 'left',
      minWidth: 199,
    },
    {
      header: t('Webgateway:tableHeaders.SolutionUI'),
      sortable: false,
      cellStyle: 'normalImportance',
      groupTitle: t('Webgateway:tableHeaders.groupTitle'),
      alignment: 'left',
      minWidth: 211,
    },
    {
      header: t('Webgateway:tableHeaders.OtherLinks'),
      sortable: false,
      cellStyle: 'normalImportance',
      alignment: 'right',
      groupTitle: t('Webgateway:tableHeaders.groupTitle'),
      minWidth: 211,
    },
  ];

  // collecting Categories from all Devices
  const getCategoryList = useCallback(() => {
    let categoryList: string[] = [];
    devices.forEach(({ group }) => {
      if (group !== '') {
        categoryList.push(group);
      }
    });
    categoryList = categoryList.filter(
      (n: string, i: number) => categoryList.indexOf(n) === i
    );
    return categoryList;
  }, [devices]);

  // collecting Tags from all Devices
  const getTagList = useCallback(() => {
    let tagList: string[] = [];
    devices.forEach(({ tags }) => {
      tags.forEach((tag: string) => {
        tagList.push(tag);
      });
    });
    tagList = tagList.filter(
      (n: string, i: number) => tagList.indexOf(n) === i
    );
    return tagList;
  }, [devices]);

  // API call to fetching the Devices list
  useEffect(() => {
    fetchDevices();
  }, [fetchDevices]);

  // Sort Devices based on selected sorting filter
  const sortDevices = useCallback(
    (devices: Device[]) => {
      if (sortBy.sort === 'name') {
        devices.sort((a: Device, b: Device) => {
          if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
          if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
          return 0;
        }); // Sort based on Device Name
      }
      if (sortBy.sort === 'category') {
        devices.sort((a: Device, b: Device) => {
          if (a.group.toLowerCase() > b.group.toLowerCase()) return 1;
          if (a.group.toLowerCase() < b.group.toLowerCase()) return -1;
          return 0;
        }); // Sort based on Device Categories
      }
      if (sortBy.sort === 'tags') {
        devices.sort((a: Device, b: Device) => {
          if (
            a.tags.sort().join().toLowerCase() >
            b.tags.sort().join().toLowerCase()
          )
            return 1;
          if (
            a.tags.sort().join().toLowerCase() <
            b.tags.sort().join().toLowerCase()
          )
            return -1;
          return 0;
        }); // Sort based on Device Tags
      }
      if (!sortBy.ascending) {
        devices.reverse(); // if Descending is selected from sorting filter
      }
      return devices;
    },
    [sortBy]
  );

  // Apply filter to the Devices list based on selected filter type
  const filterDevice = useCallback(
    (devices) => {
      const filter: Device[] = [];
      devices.forEach((device: Device) => {
        const { name, group, tags } = device;
        if (
          (group.includes(selectedCategory) || selectedCategory === '') &&
          (!selectedTag.length ||
            selectedTag.every((tag) => tags.includes(tag))) &&
          (name.toLowerCase().includes(searchText.toLowerCase()) ||
            searchText === '')
        ) {
          filter.push(device);
        }
      });
      const totalPage = Math.ceil(filter.length / pageSize);
      setTotalPages(totalPage);
      return filter;
    },
    [searchText, selectedCategory, selectedTag, pageSize]
  );

  // Function to check image is associated to the Device
  const checkImage = useCallback((url, lid) => {
    if (!url) return;
    const img = new Image();
    img.src = url;
    return new Promise((resolve, reject) => {
      img.onload = () =>
        resolve({
          isImage: true,
          lid: lid,
        });
      img.onerror = reject;
    });
  }, []);

  // Function call check image
  const loadImages = useCallback(
    async (statusMap: IDeviceStatusMap) => {
      type CheckImageResponse = { lid: string } | null;

      const deviceStatusPromises = Object.keys(statusMap).map(
        async (lid: string) => {
          try {
            const res: CheckImageResponse = (await checkImage(
              statusMap[lid].lastSnapshot.signedUrl,
              lid
            )) as CheckImageResponse; // Explicitly cast to CheckImageResponse
            return { res };
          } catch (e) {
            return { res: null }; // Handle errors by returning null
          }
        }
      );

      for await (const { res } of deviceStatusPromises) {
        if (res?.lid) {
          statusMap[res.lid].isImage = true; // Update the `isImage` flag for the device
        }
      }
      setStatusUpdate({ ...statusMap }); // Update the status map state
    },
    [checkImage]
  );

  // Function to fetching the Webgate status fo the Device
  const fetchDeviceStatus = useCallback(async () => {
    const LinksExtendedMap: IExtendedMap = {}; // Variable to Store Dropdown state of links
    const showProgressBar: IExtendedMap = {}; // Variable to Store Progress bar state of links if toggles
    const statusMap: IDeviceStatusMap = {}; // Variable to Store Devices status
    const vpnStatusUpdate: IDeviceInfoMap = {}; // Variable to Store Webgate links status

    const deviceStatusPromises = devices.map(async ({ lid }) => {
      // Webgate feature switch state setting function
      setSwitchState('locked');

      // Setting links info loading status
      setLoadingVPNStatus(true);

      const [status, vpnStatus] = await Promise.all([
        getDeviceStatus(lid).catch(() => {
          return null;
        }), // API call to fetch Device status and storing in status
        getWebgateStatus(lid, []).catch(() => {
          return null;
        }), // API call to fetch Webgate links status and storing in vpnStatus
      ]);

      // Webgate feature switch state setting function
      setSwitchState('default');

      return {
        deviceID: lid,
        devices: devices,
        status: status,
        vpnStatus: vpnStatus,
      };
    });

    for await (const { deviceID, status, vpnStatus } of deviceStatusPromises) {
      if (status) {
        statusMap[deviceID] = status; // Storing Device status to statusMap varibale respecting to the Device Id
      }
      if (vpnStatus) {
        vpnStatusUpdate[deviceID] = vpnStatus; // Storing Webgate links status to vpnStatusUpdate varibale respecting to the Device Id
      }
      LinksExtendedMap[deviceID] = false; // Setting initial dropdown state False to LinksExtendedMap varibale respecting to the Device Id
      showProgressBar[deviceID] = false; // Setting initial progress bar state False to showProgressBar varibale respecting to the Device Id
    }

    // Setting links info loading status
    setLoadingVPNStatus(false);

    // Storing Dropdown links values individually for both links
    const extendLinkMap: IExtendLinkMap = {
      Edge_UI: LinksExtendedMap,
      Solution_UI: LinksExtendedMap,
    };
    loadImages(statusMap); // Function call to check image for Device
    setStatusUpdate({ ...statusMap }); // Updating State for device links status
    setVpnStatusUpdate({ ...vpnStatusUpdate }); // Updating State for webgate links status
    setExtendedLink({ ...extendLinkMap }); // Updating State for extending dropdown for both links
  }, [devices, loadImages, setSwitchState]);

  // Function call to fetch Devices status and Webgate status
  useEffect(() => {
    fetchDeviceStatus();
  }, [fetchDeviceStatus]);

  // Generating Name, Tags, Categories table column data
  const generateNameTagCategoryColumn = useCallback(
    (
      name: string,
      group: string,
      tags: string[],
      lid: string
    ): ReactElement[] => {
      return [
        <DeviceContainer key=''>
          <LinkNameContainer to={`/links/${lid}`}>{name}</LinkNameContainer>
          {(group || tags.length !== 0) && (
            <CategoryTagContainer key=''>
              <TableRow justify='flex-start'>
                {group && (
                  <TableTagCategoryColumn>
                    <IconWrapper marginTop='0px' color='#717989'>
                      <Icon
                        icon='MetaCategories'
                        color='mono'
                        size={8}
                        weight='light'
                      />
                    </IconWrapper>
                    <TableText
                      title={t('Webgateway:filters.category')}
                      fontSize='12px'
                      marginLeft='0px'
                      color='#717989'
                    >
                      {group}
                    </TableText>
                  </TableTagCategoryColumn>
                )}
                {tags &&
                  tags.map((tag) => {
                    return (
                      <TableTagCategoryColumn key={tag}>
                        <IconWrapper marginTop='0px' color='#687076'>
                          <Icon
                            icon='MetaTags'
                            color='red'
                            size={8}
                            weight='light'
                          />
                        </IconWrapper>
                        <TableText
                          title={t('Webgateway:filters.tags')}
                          fontSize='12px'
                          marginLeft='0px'
                          color='#717989'
                        >
                          {tag}
                        </TableText>
                      </TableTagCategoryColumn>
                    );
                  })}
              </TableRow>
            </CategoryTagContainer>
          )}
        </DeviceContainer>,
      ];
    },
    [t]
  );

  // Function call to enabling webgate link
  const handleEnable = useCallback(
    async (
      toggleId: string,
      linkType: 'Edge UI' | 'Solution UI',
      deviceName: string
    ) => {
      try {
        setLoadingIds((prev) => {
          if (prev[linkType] && prev[linkType][toggleId]) {
            return prev;
          }
          return {
            ...prev,
            [linkType]: {
              ...(prev[linkType] || {}),
              [toggleId]: { text: t('Webgateway:tableHeaders.enabling') },
            },
          };
        });
        const linkId = vpnStatusUpdate[toggleId]?.Links?.find(
          (link) => link.linkName === linkType
        )?.linkID;
        
        // Webgate feature switch state setting function
        if (!isToggleFromDone.current) setSwitchState('locked');
        
        // Check feature enabled before executing API call
        if (!isToggleFromDone.current) {
          // Check for webgate feature
          const enabled = await isWebgateEnabled();
          // if API returns a response then set the featue enabled state
          if (enabled !== null) setIsFeatureEnabled(enabled);
          // if the feature is disaled then exit from the loop
          if (enabled === false || !enabled) {
            // empty the loading state
            setLoadingIds({});
            // Webgate feature switch state setting function
            setSwitchState('default');
            handleDismiss(); // Clear all notification bar before sending a new one
            sendNotification({
              type: 'warning',
              message: t('Common:notificationMessage.webgateFeatureDisabled'),
            });
            return;
          }
        }
        isToggleFromDone.current = false;
        
        // API call for toggling webagte link and passing toggle action type
        const getToggleStatus = await toggleWebgateDevice(
          toggleId,
          linkId ? linkId : '',
          linkType,
          true,
          true,
          false
        );

        // Check if enabling the link was successful
        if (!getToggleStatus.enabled) {
          throw new Error();
        }

        // Notification for successfully enabled webgate link
        const UILinkEnabled: INotificationProps = {
          type: 'success',
          message: t('Webgateway:notificationMessage.serviceEnabled')
            .replace('{linkType}', linkType === 'Edge UI' ? t('Webgateway:tableHeaders.EdgeDeviceUI') : t('Webgateway:tableHeaders.SolutionUI'))
            .replace('{deviceName}', deviceName),
        };

        // API call for fetching webagte links
        const getVpnStatus = await getWebgateStatus(toggleId, [
          {
            isStandardLink: true,
            linkID: linkId ? linkId : '',
            linkName: linkType,
          },
        ]);

        const newVpnStatus = getVpnStatus?.Links.find(
          (link) => link.linkName === linkType
        );
        const linkUrl = newVpnStatus?.url;
        if (linkUrl) {
          handleDismiss(); // Clear all notification bar before sending a new one
          sendNotification(UILinkEnabled); // Sending success notification if webgate link is allocated
          setLoadingIds((prev) => {
            const updated = { ...prev };
            delete updated[linkType][toggleId];
            return updated;
          });

          // Updating State for webgate links status
          setVpnStatusUpdate((prevState) => {
            const newState = { ...prevState };

            if (
              newState[toggleId]?.Links?.find(
                (item) => item.linkName === linkType
              ) &&
              newVpnStatus
            ) {
              const currentState = newState[toggleId];
              if (currentState) {
                newState[toggleId] = {
                  ...currentState,
                  __typename: 'WebgateStatus',
                  Links: currentState.Links.map((link) =>
                    link.linkName === linkType
                      ? { ...newVpnStatus }
                      : { ...link }
                  ),
                };
              }
            }
            return newState;
          });
        }
      } catch (error) {
        handleDismiss(); // Clear all notification bar before sending a new one
        // Notification for encountering error while enabling webgate link
        sendNotification({
          type: 'error',
          message: t('Webgateway:notificationMessage.serviceEnableError')
            .replace('{linkType}', linkType === 'Edge UI' ? t('Webgateway:tableHeaders.EdgeDeviceUI') : t('Webgateway:tableHeaders.SolutionUI'))
            .replace('{deviceName}', deviceName),
        });
        setLoadingIds((prev) => {
          const updated = { ...prev };
          delete updated[linkType][toggleId];
          return updated;
        });
        console.error('Toggle Error', (error as Error).message);
      } finally {
        setExtendedLink((prevState) => ({
          ...prevState,
          [linkType]: { ...prevState[linkType], [toggleId]: false },
        }));

        // Webgate feature switch state setting function
        setLoadingIds((currentLoadingIds) => {
          if (
            !(
              Object.keys(currentLoadingIds).length &&
              hasNestedKeys(currentLoadingIds)
            )
              ? true
              : false
          ) {
            setSwitchState('default');
          }

          return currentLoadingIds;
        });
      }
    },
    [
      t,
      hasNestedKeys,
      setSwitchState,
      sendNotification,
      vpnStatusUpdate,
      setIsFeatureEnabled,
    ]
  );

  // Function call to disabling webgate link
  const handleDisable = useCallback(async (
    toggleId: string,
    linkType: 'Edge UI' | 'Solution UI',
    deviceName: string
  ) => {
    try {
      setLoadingIds((prev) => {
        if (prev[linkType] && prev[linkType][toggleId]) {
          return prev;
        }
        return {
          ...prev,
          [linkType]: {
            ...(prev[linkType] || {}),
            [toggleId]: { text: t('Webgateway:tableHeaders.disabling') },
          },
        };
      });
      const linkId = vpnStatusUpdate[toggleId]?.Links?.find(
        (link) => link.linkName === linkType
      )?.linkID;

      // Webgate feature switch state setting function
      if (!isToggleFromDone.current) setSwitchState('locked');

      // Check feature enabled before executing API call
      if (!isToggleFromDone.current) {
        // Check for webgate feature
        const enabled = await isWebgateEnabled();
        // if API returns a response then set the featue enabled state
        if (enabled !== null) setIsFeatureEnabled(enabled);
        // if the feature is disaled then exit from the loop
        if (enabled === false || !enabled) {
          // empty the loading state
          setLoadingIds({});
          // Webgate feature switch state setting function
          setSwitchState('default');
          handleDismiss(); // Clear all notification bar before sending a new one
          sendNotification({
            type: 'warning',
            message: t('Common:notificationMessage.webgateFeatureDisabled'),
          });
          return;
        }
      }
      isToggleFromDone.current = false;

      // API call for toggling webagte link and passing toggle action type
      const vpnUpdate = await toggleWebgateDevice(
        toggleId,
        linkId ? linkId : '',
        linkType,
        true,
        false,
        false
      );

      // throw error if getting null while disabling
      if (vpnUpdate.enabled === null) {
        throw new Error();
      }

      // Updating State for webgate links status
      if (!vpnUpdate.enabled) {
        setExtendedLink({
          ...extendedLink,
          [linkType]: { ...extendedLink[linkType], [toggleId]: false },
        });
        setVpnStatusUpdate((prev) => {
          const newPrev = { ...prev };
          if (
            newPrev[toggleId]?.Links?.find((item) => item.linkName === linkType)
          ) {
            const currentState = newPrev[toggleId];
            if (currentState) {
              newPrev[toggleId] = {
                ...currentState,
                Links: currentState.Links.map((link) =>
                  link.linkName === linkType
                    ? {
                      ...link,
                      status: '',
                      enabled: false,
                    }
                    : link
                ),
              };
            }
          }
          return newPrev;
        });

        // Notification for successfully disabled webgate link
        const UILinkDisabled: INotificationProps = {
          type: 'success',
          message: t('Webgateway:notificationMessage.serviceDisabled')
            .replace('{linkType}', linkType === 'Edge UI' ? t('Webgateway:tableHeaders.EdgeDeviceUI') : t('Webgateway:tableHeaders.SolutionUI'))
            .replace('{deviceName}', deviceName),
        };
        setLoadingIds((prev) => {
          const updated = { ...prev };
          delete updated[linkType][toggleId];
          return updated;
        });

        handleDismiss(); // Clear all notification bar before sending a new one
        // Sending notification for successfully disabled webgate link
        sendNotification(UILinkDisabled);
      }
    } catch (error) {
      handleDismiss(); // Clear all notification bar before sending a new one
      // Notification for encountering error while disabling webgate link
      sendNotification({
        type: 'error',
        message: t('Webgateway:notificationMessage.serviceDisableError')
          .replace('{linkType}', linkType === 'Edge UI' ? t('Webgateway:tableHeaders.EdgeDeviceUI') : t('Webgateway:tableHeaders.SolutionUI'))
          .replace('{deviceName}', deviceName),
      });
      setLoadingIds((prev) => {
        const updated = { ...prev };
        delete updated[linkType][toggleId];
        return updated;
      });
      console.error('Toggle Error', (error as Error).message);
    } finally {
      setExtendedLink({
        ...extendedLink,
        [linkType]: { ...extendedLink[linkType], [toggleId]: false },
      });

      // Webgate feature switch state setting function
      setLoadingIds((currentLoadingIds) => {
        if (
          !(
            Object.keys(currentLoadingIds).length &&
          hasNestedKeys(currentLoadingIds)
          )
            ? true
            : false
        ) {
          setSwitchState('default');
        }

        return currentLoadingIds;
      });
    }
  }, [
    t, 
    extendedLink, 
    hasNestedKeys, 
    setSwitchState, 
    vpnStatusUpdate,
    sendNotification, 
    setIsFeatureEnabled 
  ]);


  //Close dropdown on clicking outside of it.
  const dropdownRef = useRef<{
    [title: string]: { [id: string]: React.RefObject<HTMLDivElement> };
  }>({});

  const toggleUnexpanded = () => {
    setExtendedLink((prevExtendedLink) => {
      if (Object.keys(prevExtendedLink).length === 0) {
        return prevExtendedLink;
      }

      // Check if there's at least one true value in the extendedLink object
      const hasTrueValue = Object.values(prevExtendedLink).some((section) =>
        Object.values(section).includes(true)
      );

      // If no true value exists, return the previous state without updating
      if (!hasTrueValue) {
        return prevExtendedLink;
      }

      // Proceed with setting true values to false
      const updatedExtendedLink = Object.fromEntries(
        Object.entries(prevExtendedLink).map(([key, value]) => [
          key,
          Object.fromEntries(
            Object.entries(value).map(([linkId, linkValue]) => [
              linkId,
              linkValue === true ? false : linkValue,
            ])
          ),
        ])
      );

      return updatedExtendedLink;
    });
  };
  useClickOutside(dropdownRef.current[refId.title]?.[refId.id], () =>
    toggleUnexpanded()
  );

  // Function to take the confirmation from the user by showing modal while enabling
  const toggleEnable = useCallback(async (
    toggleId: string,
    linkType: 'Edge UI' | 'Solution UI',
    deviceName: string
  ) => {  
    const confirmModal: ReactElement = (
      <ConfirmModal
        title={t('Webgateway:confirmationPopup.enableTitle')}
        content={t('Webgateway:confirmationPopup.enableContent').replace('{linkType}', linkType === 'Edge UI' ? t('Webgateway:tableHeaders.EdgeDeviceUI') : t('Webgateway:tableHeaders.SolutionUI'))}
        confirmationMessage={t('Webgateway:confirmationPopup.deviceNames').replace('{deviceName}', deviceName)}
        cancelButtonText={t('Webgateway:confirmationPopup.cancel')}
        confirmButtonText={t('Webgateway:confirmationPopup.confirmEnable')}
        onCancel={() => {
          setCheckedButtons('');
          setModalOpen(false);
        }}
        onConfirm={() => {
          handleEnable(toggleId, linkType, deviceName);
          setModalOpen(false);

          const updatedRowData = rowData.map((item) => {
            if (item._checked !== undefined) {
              return { ...item, _checked: false };
            }
            return item;
          });

          setRowData(updatedRowData);
          setInitialRowData(updatedRowData);
        }}
      />
    );
    createModal({ isCloseEnable: false, customComponent: confirmModal });
    // eslint-disable-next-line
  }, [
    t,
    createModal, 
    handleEnable, 
    setModalOpen 
  ]);
  
  // Function to take the confirmation from the user by showing modal while disabling
  const toggleDisable = useCallback(async (
    toggleId: string,
    linkType: 'Edge UI' | 'Solution UI',
    deviceName: string
  ) => {
    const confirmModal: ReactElement = (
      <ConfirmModal
        title={t('Webgateway:confirmationPopup.disableTitle')}
        content={t('Webgateway:confirmationPopup.disableContent').replace('{linkType}', linkType === 'Edge UI' ? t('Webgateway:tableHeaders.EdgeDeviceUI') : t('Webgateway:tableHeaders.SolutionUI'))}
        confirmationMessage={t('Webgateway:confirmationPopup.deviceNames').replace('{deviceName}', deviceName)}
        cancelButtonText={t('Webgateway:confirmationPopup.cancel')}
        confirmButtonText={t('Webgateway:confirmationPopup.confirmDisable')}
        onCancel={() => {
          setCheckedButtons('');
          setModalOpen(false);
        }}
        onConfirm={() => {
          handleDisable(toggleId, linkType, deviceName);
          setModalOpen(false);

          const updatedRowData = rowData.map((item) => {
            if (item._checked !== undefined) {
              return { ...item, _checked: false };
            }
            return item;
          });

          setRowData(updatedRowData);
          setInitialRowData(updatedRowData);
        }}
      />
    );
    createModal({ isCloseEnable: false, customComponent: confirmModal });
    // eslint-disable-next-line
  }, [
    t,
    createModal, 
    handleDisable, 
    setModalOpen 
  ]);

  // Function to redirect the user to the edit links page
  const toggleEdit = useCallback((deviceId: string, linkType: 'Edge UI' | 'Solution UI') => {    
    const linkId = vpnStatusUpdate[deviceId]?.Links?.find(
      (link) => link.linkName === linkType
    )?.linkID;
    setIsEditFromHome(true);
    history.push(`/links/${deviceId}/edit-link/${linkId}`);
  }, [history, vpnStatusUpdate, setIsEditFromHome]);

  // Available action buttons options
  const buttonLists: ButtonType[] = [
    {
      btnName: t('Webgateway:filters.enableEdge'),
      btnType: 'Edge UI',
      btnAction: toggleEnable,
    },
    {
      btnName: t('Webgateway:filters.disableEdge'),
      btnType: 'Edge UI',
      btnAction: toggleDisable,
    },
    {
      btnName: t('Webgateway:filters.editEdge'),
      btnType: 'Edge UI',
      btnAction: toggleEdit,
    },
    {
      btnName: t('Webgateway:filters.enableSolution'),
      btnType: 'Solution UI',
      btnAction: toggleEnable,
    },
    {
      btnName: t('Webgateway:filters.disableSolution'),
      btnType: 'Solution UI',
      btnAction: toggleDisable,
    },
    {
      btnName: t('Webgateway:filters.editSolution'),
      btnType: 'Solution UI',
      btnAction: toggleEdit,
    },
  ];

  // Generating Edge and Sloution links table column data
  const generateLinks = useCallback(
    (
      id: string,
      deviceName,
      name: string,
      title: 'Edge UI' | 'Solution UI',
      UILink: string,
      enabled: boolean,
      status: string,
      vpnStatusUpdate
    ): ReactElement[] => {
      UILink = UILink === '-' ? '' : 'https://' + UILink;

      // Initialize ref for this link under the given title
      if (!dropdownRef.current[title]) {
        dropdownRef.current[title] = {};
      }
      if (!dropdownRef.current[title][id]) {
        dropdownRef.current[title][id] = React.createRef<HTMLDivElement>();
      }

      const toggleExpanded = (
        toggleId: string,
        linkType: string,
        expanded: boolean
      ) => {
        setRefId({ title: linkType, id: toggleId }); // Set the current refId
        setExtendedLink({
          ...extendedLink,
          [linkType]: { ...extendedLink[linkType], [toggleId]: !expanded },
        });
      };

      let content;

      if (!vpnStatusUpdate) {
        content = <div>-</div>;
      } else if (loadingIds[title]?.[id]) {
        content = (
          <TogglingService>
            <Spinner size='medium' styling='primary' />
            <i>{loadingIds[title][id].text}</i>
          </TogglingService>
        );
      } else {
        content = (
          <TableLinkRow key={id} ref={dropdownRef.current[title][id]}>
            <DropDown
              id={id}
              dropdownRef={dropdownRef.current[title][id]}
              name={deviceName}
              title={name}
              linkType={title}
              expanded={extendedLink?.[title]?.[id]}
              link={UILink}
              titleIcon='Link'
              enabled={enabled}
              status={status}
              toggleEnable={toggleEnable}
              toggleDisable={toggleDisable}
              toggleEdit={toggleEdit}
              toggleExpanded={toggleExpanded}
              progressCheck={Object.keys(loadingIds).length && hasNestedKeys(loadingIds) ? true : false}
            />
          </TableLinkRow>
        );
      }

      return [<LinkLayout key=''>{content}</LinkLayout>];
    },
    [
      extendedLink,
      loadingIds,
      hasNestedKeys,
      toggleEnable,
      toggleDisable,
      toggleEdit
    ]
  );

  // Generating Other links count table column data
  const generateOtherLinksColumn = useCallback(
    (id: string, links: number): ReactElement[] => {
      return [
        <LinkLayout key={id}>
          <TableRow>
            {links === 0 ? (
              <LinkContainer to={`/links/${id}`}>
                <LinkText padding='0px 8px' marginRight='36px'>
                  {t('Webgateway:tableHeaders.none')}
                </LinkText>
              </LinkContainer>
            ) : (
              <LinkContainer to={`/links/${id}`}>
                <LinkText padding='0px 0px 0px 8px' marginRight='18px'>
                  + {links}{' '}
                  {links === 1
                    ? t('Webgateway:tableHeaders.link')
                    : t('Webgateway:tableHeaders.links')}
                  <IconWrapper marginTop='0px' color='#9bc3fa'>
                    <Icon size={16} icon='Forward' weight='regular' />
                  </IconWrapper>
                </LinkText>
              </LinkContainer>
            )}
          </TableRow>
        </LinkLayout>,
      ];
    },
    [t]
  );

  // Function to generate Table rows data
  const generateRowData = useCallback((): ITypeTableData => {
    // update the _checked value of the rowdata when reloaded
    const updateCheckedData = (lid: string) => {
      let sortedDevicesnew;
      if (initialRowData.length) {
        sortedDevicesnew = initialRowData.find(
          (initialItem) => initialItem.id === lid
        );

        if (sortedDevicesnew && sortedDevicesnew._checked) {
          return sortedDevicesnew._checked;
        } else {
          return false;
        }
      }
      //update the array
      else {
        return false;
      }
    };

    const emptyRow: ITypeTableData = [
      {
        columns: [],
      },
    ];
    let filterData = filterDevice(devices);
    let sortedDevices = sortDevices(filterData);
    const start = pageSize * (currentPage - 1);
    let end = sortedDevices.length;
    if (sortedDevices.length > pageSize * currentPage) {
      end = pageSize * currentPage;
    }
    const data: ITypeTableData = sortedDevices
      .slice(start, end)
      .map((device: Device) => {
        const { lid, name, group, tags, type } = device;
        let imageUrl = '';
        let vpnAccessLink: string | null = '-';
        let vpnEnabled: boolean | null = false;
        let vpnStatus: string | null = '-';
        let solutionEnabled: boolean | null = false;
        let solutionStatus: string | null = '-';
        let solutionLink: string | null = '-';
        let statusLine = 'danger';
        let imageUrlpopup = '';
        if (statusUpdate[lid]) {
          const {
            lastSnapshot: { signedUrl = null },
            status: { alive, diskUsageAlertLevel },
          } = statusUpdate[lid];
          if (signedUrl) imageUrl = signedUrl;

          if (diskUsageAlertLevel === 'danger') {
            statusLine = alive === false ? 'neutral' : 'danger';
          } else if (diskUsageAlertLevel === 'caution') {
            statusLine = alive === false ? 'neutral' : 'caution ';
          } else if (alive === true) {
            statusLine = 'good';
          } else if (alive === false) {
            statusLine = 'danger';
          } else {
            statusLine = 'danger';
          }
        }
        if (vpnStatusUpdate[lid]) {
          const edge = vpnStatusUpdate[lid]?.Links.find(
            (link) => link.linkName === 'Edge UI'
          ) || {
              url: null,
              enabled: null,
              status: null,
            };
          const solution = vpnStatusUpdate[lid]?.Links.find(
            (link) => link.linkName === 'Solution UI'
          ) || {
              url: null,
              enabled: null,
              status: null,
            };
          vpnAccessLink = edge.url || '-';
          vpnEnabled = edge.enabled || false;
          vpnStatus = edge.status || '-';
          solutionLink = solution.url || '-';
          solutionEnabled = solution.enabled || false;
          solutionStatus = solution.status || '-';
        }

        imageUrlpopup = statusUpdate[lid]?.isImage ? imageUrl : '';

        const row: IRowData = {
          id: lid,
          _checked: updateCheckedData(lid), //rowdata checked value updated
          header: {
            status: statusLine,
            image: imageUrl,
            mediaType: 'img',
            mediaUrl: imageUrlpopup,
            icon:
              type === 'folder'
                ? 'DevicesFolder'
                : type === 'stream'
                  ? 'DevicesLiveEndpoint'
                  : 'DevicesScorerEdge',
          },
          columns: [
            {
              customComponent: (
                <MultilineContent
                  // Function call to generate Name, category and Tags to Table data
                  contentArray={generateNameTagCategoryColumn(
                    name,
                    group,
                    tags,
                    lid
                  )}
                />
              ),
            },
            {
              customComponent: (
                <MultilineContent
                  // Function call to generate edge UI link to Table data
                  contentArray={generateLinks(
                    lid,
                    name,
                    t('Webgateway:tableHeaders.openEdge'),
                    'Edge UI',
                    vpnAccessLink,
                    vpnEnabled,
                    vpnStatus,
                    vpnStatusUpdate[lid]
                  )}
                />
              ),
            },
            {
              customComponent: (
                <MultilineContent
                  // Function call to generate solution UI link to Table data
                  contentArray={generateLinks(
                    lid,
                    name,
                    t('Webgateway:tableHeaders.openSolution'),
                    'Solution UI',
                    solutionLink,
                    solutionEnabled,
                    solutionStatus,
                    vpnStatusUpdate[lid]
                  )}
                />
              ),
            },
            {
              customComponent: (
                <MultilineContent
                  // Function call to generate other links to Table data
                  contentArray={generateOtherLinksColumn(
                    lid,
                    vpnStatusUpdate[lid]?.otherLinksCount || 0
                  )}
                />
              ),
            },
          ],
        };
        return row;
      });
    setResultCount(data.length);
    if (data.length === 0) {
      return emptyRow;
    }
    return data;
  }, [
    t,
    devices,
    pageSize,
    currentPage,
    filterDevice,
    statusUpdate,
    sortDevices,
    generateNameTagCategoryColumn,
    generateLinks,
    generateOtherLinksColumn,
    vpnStatusUpdate,
    initialRowData,
  ]);

  useEffect(() => {
    setTags(getTagList); // Update Tags to state
    setCategories(getCategoryList); // Update Categories to state
  }, [devices, getTagList, getCategoryList]);

  // Function call to generate Table data
  useEffect(() => {
    setRowData(generateRowData());
  }, [generateRowData, devices, statusUpdate]);

  useEffect(() => {
    setTags(getTagList); // Update Tags to state
    setCategories(getCategoryList); // Update Categories to state
    setRowData(generateRowData()); // Function call to generate Table data
  }, [devices, generateRowData, getTagList, getCategoryList, statusUpdate]);

  // On change for search filter
  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
  
    // Update state based on trimmed value for consistent handling
    if (!inputValue.trim()) {
      setSearchText(''); // Set empty string if input is just spaces
    } else {
      setSearchText(inputValue); // Preserve input as-is for valid cases
    }
    
    setCurrentPage(1);
  };

  // On change for category filter
  const onChangeCategory = useCallback(
    (filter: string | string[] | number | number[]) => {
      if (selectedCategory === filter) {
        setSelectedCategory('');
      } else {
        setSelectedCategory(filter.toString());
      }
      setCurrentPage(1);
    },
    [selectedCategory]
  );

  // On change for Tag filter
  const onChangeTag = useCallback((filter: string[]) => {
    setSelectedTag([...filter]);
    setCurrentPage(1);
  }, []);

  // On change for page size filter
  const onSizePerPageChange = useCallback((size: number) => {
    setPageSize(size);
    setCurrentPage(1);
  }, []);

  // On change for page change filter
  const onPageChange = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  // On change for clearing applied filter
  const onHandleClearAllLink = useCallback(() => {
    setSearchText('');
    setSelectedCategory('');
    setSelectedTag([]);
    setSortBy({
      sort: 'name',
      ascending: true,
      title: t('Webgateway:sorting.name'),
    });
  }, [t]);

  // Remove selected Tag from filter
  const removeTag = useCallback(
    (tag: string) => {
      setSelectedTag(selectedTag.filter((t) => t !== tag));
    },
    [selectedTag]
  );

  // Remove selected Category from filter
  const removeCategory = useCallback(() => {
    setSelectedCategory('');
  }, []);

  // Remove selected Search input from filter
  const removeSearch = useCallback(() => {
    setSearchText('');
  }, []);

  // Fuction to update sorting filter
  const sort = useCallback((sortBy: ISortBy) => {
    setSortBy(sortBy);
  }, []);

  // Update the title in the state when language changes
  useEffect(() => {
    const currentOption = sortByList.find((item) => item.sort === sortBy.sort);
    if (currentOption) {
      setSortBy((prev) => ({
        ...prev,
        title: currentOption.title,
      }));
    }
  }, [sortBy.sort, sortByList]);

  // Function to select Device rows one by one
  const handleRowSelect = useCallback(
    (checked: boolean, id?: string | number) => {
      const newRows = [...rowData];
      const targetRowIndex = newRows.findIndex((row) => row.id === id);
      const checkedId = newRows[targetRowIndex].id
        ? newRows[targetRowIndex].id
        : '';
      if (vpnStatusUpdate[checkedId ? checkedId : '']) {
        // mark check only to the devices which are alive
        newRows[targetRowIndex]._checked = checked;
      }
      setRowData(newRows);
      setInitialRowData(newRows); //maintain intial rowdata state to capture _checked value changes
      setCheckedButtons(''); //empty the selected button when clicked on row for valid button selection
    },
    [rowData, vpnStatusUpdate]
  );

  // Function to select all Device rows
  const handleSelectAllRows = useCallback(
    (selectAll: boolean) => {
      const newRows = rowData?.map((row) => {
        if (vpnStatusUpdate[row.id ? row.id : '']) {
          return {
            ...row,
            _checked: selectAll,
          };
        } else {
          return { ...row };
        }
      });
      setRowData(newRows);
      setInitialRowData(newRows); //maintain intial rowdata state to capture _checked value changes
      setCheckedButtons(''); //empty the selected button when clicked on row for valid button selection
    },
    [rowData, vpnStatusUpdate]
  );

  useEffect(() => {
    if (checkedButtons.length) setButtonDisabled(false);
    //make done button disable if no button is selcted from buttonlist
    else setButtonDisabled(true);
  }, [checkedButtons]);

  // Function to select action to perform selected devices
  const handleButtonClick = (btnName: string) => {
    setCheckedButtons(btnName);
  };

  // Confirmation Modal before Done action
  const doneModal = () => {
    const updatedCheckedRowData = rowData.filter(
      (item) => item._checked === true
    );
    const DeviceNames = updatedCheckedRowData.map((item)=>{
      return item.columns[0].customComponent?.props.contentArray[0].props.children[0].props.children;
    });
    //modal for done click to open before final request send to backened
    const confirmModal: ReactElement = (
      <ConfirmModal
        title={checkedButtons?.split(' ').pop() === '停止する' ? t('DeviceLinks:confirmationPopup.disableTitle') : t('Webgateway:confirmationPopup.commonTitle').replace('{buttonName}', checkedButtons?.split(' ').pop() || '')}
        content={checkedButtons?.split(' ').pop() === t('Webgateway:tableHeaders.enable') ? t('Webgateway:confirmationPopup.enableContent').replace('{linkType}', checkedButtons?.split(' - ')[0] || '') : t('Webgateway:confirmationPopup.disableContent').replace('{linkType}', checkedButtons?.split(' - ')[0] || '')}
        confirmationMessage={DeviceNames.join('\n')}                
        cancelButtonText={t('Webgateway:confirmationPopup.cancel')}
        fromHomePageDone
        onCancel={() => setModalOpen(false)}
        confirmButtonText={checkedButtons?.split(' ').pop() === t('Webgateway:tableHeaders.enable') ? t('Webgateway:confirmationPopup.confirmEnable') : t('Webgateway:confirmationPopup.confirmDisable')}
        onConfirm={() => {
          handleDone();
          setModalOpen(false);
        }}
      />
    );
    createModal({ isCloseEnable: false, customComponent: confirmModal });
  };

  //Final Done action for toggle action call
  const handleDone = () => {
    const updatedCheckedRowData = rowData.filter(
      (item) => item._checked === true
    );
    if (!checkedButtons.length) {
      return;
    }

    switch (checkedButtons) {
    case t('Webgateway:filters.enableEdge'):{
      const notEnableEdgeData = updatedCheckedRowData.filter((value) => {
        const vpnStatus =
          vpnStatusUpdate[value?.id !== undefined ? value.id : ''];
        const newVpn = vpnStatus?.Links.find(
          (link) => link.linkName === 'Edge UI'
        );
        return vpnStatus && !newVpn?.enabled;
      });
      notEnableEdgeData.forEach((data) => {
        setLoadingIds((prev) => ({
          ...prev,
          'Edge UI': {
            ...(prev['Edge UI'] || {}),
            [data?.id as string]: {
              text: t('Webgateway:tableHeaders.enabling'),
            },
          },
        }));
      });
      // Webgate feature switch state setting function
      setSwitchState('locked');
      // Perform enable webgate link action
      const EnableResponse = async () => {
        for (const data of notEnableEdgeData) {
          // Check for webgate feature
          const enabled = await isWebgateEnabled();
          // if API returns a response then set the featue enabled state
          if (enabled !== null) setIsFeatureEnabled(enabled);
          // if the feature is disaled then exit from the loop
          if (enabled === false || !enabled) {
            // empty the loading state
            setLoadingIds({});
            // Webgate feature switch state setting function
            setSwitchState('default');
            handleDismiss(); // Clear all notification bar before sending a new one
            sendNotification({
              type: 'warning',
              message: t('Common:notificationMessage.webgateFeatureDisabled'),
            });
            return;
          }
          isToggleFromDone.current = true;
          await handleEnable(
            typeof data.id === 'string' ? data.id : '',
            'Edge UI',
            typeof data.id === 'string' ? data.id : ''
          );
        }
      };
      EnableResponse();
      break;
    }
    case t('Webgateway:filters.disableEdge'): {
      const enableEdgeData = updatedCheckedRowData.filter((value) => {
        const vpnStatus =
          vpnStatusUpdate[value?.id !== undefined ? value.id : ''];
        const newVpn = vpnStatus?.Links.find(
          (link) => link.linkName === 'Edge UI'
        );
        return vpnStatus && newVpn?.enabled;
      });
      if (!enableEdgeData.length) {
        return;
      }
      enableEdgeData.forEach((data) => {
        setLoadingIds((prev) => ({
          ...prev,
          'Edge UI': {
            ...(prev['Edge UI'] || {}),
            [data?.id as string]: {
              text: t('Webgateway:tableHeaders.disabling'),
            },
          },
        }));
      });
      // Webgate feature switch state setting function
      setSwitchState('locked');
      const DisableResponse = async () => {
        for (const data of enableEdgeData) {
          // Check for webgate feature
          const enabled = await isWebgateEnabled();
          // if API returns a response then set the featue enabled state
          if (enabled !== null) setIsFeatureEnabled(enabled);
          // if the feature is disaled then exit from the loop
          if (enabled === false || !enabled) {
            // empty the loading state
            setLoadingIds({});
            // Webgate feature switch state setting function
            setSwitchState('default');
            handleDismiss(); // Clear all notification bar before sending a new one
            sendNotification({
              type: 'warning',
              message: t('Common:notificationMessage.webgateFeatureDisabled'),
            });
            return;
          }
          isToggleFromDone.current = true;
          await handleDisable(
            typeof data.id === 'string' ? data.id : '',
            'Edge UI',
            typeof data.id === 'string' ? data.id : ''
          );
        }
      };
      DisableResponse();
      break;
    }
    case t('Webgateway:filters.enableSolution'):{
      const notEnableSolutionData = updatedCheckedRowData.filter((value) => {
        const vpnStatus =
          vpnStatusUpdate[value?.id !== undefined ? value.id : ''];
        const newVpn = vpnStatus?.Links.find(
          (link) => link.linkName === 'Solution UI'
        );
        return vpnStatus && !newVpn?.enabled;
      });
      if (!notEnableSolutionData.length) {
        return;
      }
      notEnableSolutionData.forEach((data) => {
        setLoadingIds((prev) => ({
          ...prev,
          'Solution UI': {
            ...(prev['Solution UI'] || {}),
            [data?.id as string]: {
              text: t('Webgateway:tableHeaders.enabling'),
            },
          },
        }));
      });
      // Webgate feature switch state setting function
      setSwitchState('locked');
      const handleEnableSoultion = async () => {
        for (const data of notEnableSolutionData) {
          // Check for webgate feature
          const enabled = await isWebgateEnabled();
          // if API returns a response then set the featue enabled state
          if (enabled !== null) setIsFeatureEnabled(enabled);
          // if the feature is disaled then exit from the loop
          if (enabled === false || !enabled) {
            // empty the loading state
            setLoadingIds({});
            // Webgate feature switch state setting function
            setSwitchState('default');
            handleDismiss(); // Clear all notification bar before sending a new one
            sendNotification({
              type: 'warning',
              message: t('Common:notificationMessage.webgateFeatureDisabled'),
            });
            return;
          }
          isToggleFromDone.current = true;
          await handleEnable(
            typeof data.id === 'string' ? data.id : '',
            'Solution UI',
            typeof data.id === 'string' ? data.id : ''
          );
        }
      };
      handleEnableSoultion();
      break;
    }
    case t('Webgateway:filters.disableSolution'):{
      const enableSolutionData = updatedCheckedRowData.filter((value) => {
        const vpnStatus =
          vpnStatusUpdate[value?.id !== undefined ? value.id : ''];
        const newVpn = vpnStatus?.Links.find(
          (link) => link.linkName === 'Solution UI'
        );
        return vpnStatus && newVpn?.enabled === true;
      });
      if (!enableSolutionData.length) {
        return;
      }
      enableSolutionData.forEach((data) => {
        setLoadingIds((prev) => ({
          ...prev,
          'Solution UI': {
            ...(prev['Solution UI'] || {}),
            [data?.id as string]: {
              text: t('Webgateway:tableHeaders.disabling'),
            },
          },
        }));
      });
      // Webgate feature switch state setting function
      setSwitchState('locked');
      const handleDisableSoultion = async () => {
        for (const data of enableSolutionData) {
          // Check for webgate feature
          const enabled = await isWebgateEnabled();
          // if API returns a response then set the featue enabled state
          if (enabled !== null) setIsFeatureEnabled(enabled);
          // if the feature is disaled then exit from the loop
          if (enabled === false || !enabled) {
            // empty the loading state
            setLoadingIds({});
            // Webgate feature switch state setting function
            setSwitchState('default');
            handleDismiss(); // Clear all notification bar before sending a new one
            sendNotification({
              type: 'warning',
              message: t('Common:notificationMessage.webgateFeatureDisabled'),
            });
            return;
          }
          isToggleFromDone.current = true;
          await handleDisable(
            typeof data.id === 'string' ? data.id : '',
            'Solution UI',
            typeof data.id === 'string' ? data.id : ''
          );
        }
      };
      handleDisableSoultion();
      break;
    }
    default:
      break; // No matching filter found
    }
    // DeSelect the checked button
    setCheckedButtons('');

    // DeSelect the rows
    const updatedRowData = rowData.map((item) => {
      if (item._checked !== undefined) {
        return { ...item, _checked: false };
      }
      return item;
    });

    setRowData(updatedRowData);
    setInitialRowData(updatedRowData);
  };

  return (
    <MainContainer>
      <Wrapper enableContent={isFeatureEnabled}>
        <LayoutHeader>
          <HeaderWrapper>
            <SectionContainer
              gap='8px'
              padding={width < 1536 ? '24px 96px 32px' : '24px 120px 32px'}
            >
              <InnerHeader>
                <MainHeader>
                  <PageHeader
                    icon={`${loading || loadingVPNStatus ? '' : 'Home'}`}
                    title={t('Common:devices')}
                  />
                  {(loading || loadingVPNStatus) && (
                    <SpinnerContainer>
                      <Spinner size='medium' styling='primary' />
                    </SpinnerContainer>
                  )}
                  <TextContainer gap='16px' padding='3px 0 0px' height='auto'>
                    <PageHeaderText>
                      {t('Webgateway:introductionText')}
                    </PageHeaderText>
                  </TextContainer>
                </MainHeader>
              </InnerHeader>
            </SectionContainer>
          </HeaderWrapper>
        </LayoutHeader>
        <SectionContainer
          padding={width < 1536 ? '37px 96px 32px' : '35px 120px 64px'}
        >
          <ContentContainer
            gap='48px'
            padding='0px'
            align='center'
            justify='center'
            minWidth='1008px'
          >
            <TableFilterConatiner gap='48px' padding='0px'>
              <FilterSet gap='20px' padding='0px' minWidth='833px'>
                {/* check length of selected data if more than one then make every option availbe */}
                {hasIsChecked(rowData).length ? (
                  <ActionContainer>
                    <AreaTitle>
                      <BoldText>
                        {t('Webgateway:filters.filterTitle2')}
                      </BoldText>
                    </AreaTitle>
                    <ButtonListContainer>
                      <ButtonList>
                        {!(Object.keys(loadingIds).length && hasNestedKeys(loadingIds)) ? (
                          (() => {
                            const checkedRow = hasIsChecked(rowData);
                            let hasVisibleButton = false; // Flag to check if any button is shown

                            const renderedButtons = buttonLists.map(
                              (btn, index) => {
                                const singleRowSelected =
                                  checkedRow.length === 1;

                                if (singleRowSelected && checkedRow[0]?.id) {
                                  const getCheckedRow =
                                    vpnStatusUpdate[checkedRow[0].id];
                                  const newGetCheckedRow =
                                    getCheckedRow?.Links.find(
                                      (link) => link.linkName === btn.btnType
                                    );

                                  if (getCheckedRow) {
                                    // Logic to hide buttons for single selected row
                                    const isEnabled = newGetCheckedRow?.enabled;

                                    if (
                                      (btn.btnName === t('Webgateway:filters.enableEdge') && isEnabled) ||
                                      (btn.btnName === t('Webgateway:filters.editEdge') && !isEnabled) ||
                                      (btn.btnName === t('Webgateway:filters.disableEdge') && !isEnabled) ||
                                      (btn.btnName === t('Webgateway:filters.enableSolution') && isEnabled) ||
                                      (btn.btnName === t('Webgateway:filters.disableSolution') && !isEnabled) ||
                                      (btn.btnName === t('Webgateway:filters.editSolution') && !isEnabled)
                                    ) {
                                      return null; // Hide button if the condition is met
                                    }
                                  }
                                } else if (checkedRow.length > 1) {
                                  // Logic to hide buttons for multiple selected rows
                                  const allRowsHaveSameState = checkedRow.every(
                                    (row) => {
                                      const rowState = vpnStatusUpdate[row.id || ''];
                                      const newRowState = rowState?.Links.find(
                                        (link) => link.linkName === btn.btnType
                                      );

                                      if (!newRowState) return false;

                                      const isEnabled = newRowState?.enabled;

                                      return !(
                                        (btn.btnName === t('Webgateway:filters.enableEdge') && isEnabled) ||
                                        (btn.btnName === t('Webgateway:filters.disableEdge') && !isEnabled) ||
                                        (btn.btnName === t('Webgateway:filters.enableSolution') && isEnabled) ||
                                        (btn.btnName === t('Webgateway:filters.disableSolution') && !isEnabled) ||
                                        (btn.btnName === t('Webgateway:filters.editEdge')) ||
                                        (btn.btnName === t('Webgateway:filters.editSolution'))
                                      );
                                    }
                                  );

                                  if (!allRowsHaveSameState) {
                                    return null; // Hide button if not all rows have the same state
                                  }
                                }

                                hasVisibleButton = true; // A button is being rendered
                                return (
                                  <ButtonWithIcon
                                    position='left'
                                    icon='Success'
                                    design={
                                      checkedButtons.includes(btn.btnName)
                                        ? 'primary'
                                        : 'secondary'
                                    }
                                    size='normal'
                                    key={index * 13}
                                    onClick={() => {
                                      if (singleRowSelected && checkedRow[0]?.id) {
                                        const deviceData = devices.find(
                                          (device) => device.lid === checkedRow[0]?.id
                                        );
                                        setCheckedButtons(btn.btnName);
                                        btn.btnAction(
                                          (checkedRow[0]?.id as string) || '',
                                          btn.btnType || '',
                                          deviceData?.name || ''
                                        );
                                      } else handleButtonClick(btn.btnName);
                                    }}
                                  >
                                    {btn.btnName}
                                  </ButtonWithIcon>
                                );
                              }
                            );

                            // If no buttons are rendered, show a 'No actions are available' message
                            return hasVisibleButton ? (
                              renderedButtons
                            ) : (
                              <NoAction margin='0px' fontSize='12px'>
                                {t('Webgateway:filters.noActions')}
                              </NoAction>
                            );
                          })()
                        ) : (
                          <NoAction margin='0px' fontSize='12px'>
                            {t('Webgateway:filters.actionsInProcess')}
                          </NoAction>
                        )}
                      </ButtonList>

                      {hasIsChecked(rowData).length !== 1 && (
                        <ButtonWithIcon
                          icon='Success'
                          position='left'
                          size='normal'
                          onClick={doneModal} // Open done modal first then send final API
                          disabled={
                            buttonDisabled ||
                          (Object.keys(loadingIds).length && hasNestedKeys(loadingIds))
                              ? true
                              : false
                          }
                        >
                          {t('Webgateway:filters.doneButton')}
                        </ButtonWithIcon>
                      )}
                    </ButtonListContainer>
                  </ActionContainer>
                ) : (
                  <FilterSection gap='12px' padding='0px'>
                    <TextContainer
                      gap='8px'
                      padding='5px 0px'
                      direction='row'
                      align='center'
                    >
                      <ColumnText
                        fontWeight='600'
                        letter='..06px'
                        fontSize='12px'
                        lineHeight={-2}
                        height='14px'
                      >
                        {categories.length !== 0 || tags.length !== 0
                          ? t('Webgateway:filters.filterTitle1')
                          : t('Webgateway:filters.filterTitle')}
                      </ColumnText>
                    </TextContainer>
                    <FilterList
                      direction='row'
                      align='flex-start'
                      gap='8px'
                      padding='0px'
                    >
                      <InputWrapper>
                        <FilterInput
                          type='text'
                          placeholder={t('Webgateway:filters.searchByDeviceName')}
                          value={searchText}
                          onChange={onChangeInput}
                          lang={i18n.language}
                        />
                      </InputWrapper>
                      {categories.length !== 0 && (
                        <SelectWrapper>
                          <Select
                            icon='MetaCategories'
                            title={t('Webgateway:filters.category').toString()}
                            onSelect={onChangeCategory}
                            selected={selectedCategory}
                            list={categories}
                            bgColor='transparent'
                            disabled={categories.length === 0}
                          />
                        </SelectWrapper>
                      )}
                      {tags.length !== 0 && (
                        <SelectMultiWrapper>
                          <SelectMulti
                            icon='MetaTags'
                            title={t('Webgateway:filters.tags')}
                            onSelect={onChangeTag}
                            selected={selectedTag}
                            list={tags}
                            showFilter
                            bgColor='transparent'
                            disabled={tags.length === 0}
                          />
                        </SelectMultiWrapper>
                      )}
                    </FilterList>
                  </FilterSection>
                )}
                <FilterConditions
                  direction='row'
                  justify='space-between'
                  align='center'
                  gap='0px'
                  padding='0px'
                >
                  <TextContainer
                    height='40px'
                    direction='row'
                    align='center'
                    gap='2px'
                    padding='0 0 1px'
                    maxWidth='80% !important'
                  >
                    <ColumnText
                      family='Lato'
                      fontSize='12px'
                      flexWrap='wrap'
                      justifyContent='start'
                      gap='4px'
                    >
                      {t('Webgateway:filters.showingResults')} ({resultCount}):
                      {searchText !== '' && (
                        <FilterContainer>
                          <FilterListIcon>
                            <Icon icon='Search' color='dimmed' size={14} />
                          </FilterListIcon>
                          <FilterText>{searchText}</FilterText>
                          <ClearItem onClick={removeSearch}>
                            <Icon
                              icon='CloseCompact'
                              color='dimmed'
                              size={14}
                            />
                          </ClearItem>
                        </FilterContainer>
                      )}
                      {selectedCategory !== '' && (
                        <FilterContainer>
                          <FilterListIcon>
                            <Icon
                              icon='MetaCategories'
                              color='dimmed'
                              size={14}
                            />
                          </FilterListIcon>
                          <FilterText>{selectedCategory}</FilterText>
                          <ClearItem onClick={removeCategory}>
                            <Icon
                              icon='CloseCompact'
                              color='dimmed'
                              size={14}
                            />
                          </ClearItem>
                        </FilterContainer>
                      )}
                      {selectedTag.map((tag, key) => (
                        <FilterContainer key={key}>
                          {selectedCategory !== '' ||
                          (selectedTag.length > 1 && key !== 0) ? 
                            (
                              <SepratorContainer />
                            ) : 
                            (
                              <div />
                            )}
                          <FilterListIcon>
                            <Icon icon='MetaTags' color='dimmed' size={14} />
                          </FilterListIcon>
                          <FilterText>{tag}</FilterText>
                          <ClearItem onClick={() => removeTag(tag)}>
                            <Icon
                              icon='CloseCompact'
                              color='dimmed'
                              size={14}
                            />
                          </ClearItem>
                        </FilterContainer>
                      ))}
                      {(selectedTag.length !== 0 ||
                        searchText !== '' ||
                        selectedCategory !== '') && 
                        (
                          <ClearLinkAll onClick={onHandleClearAllLink}>
                            {t('Webgateway:filters.clearAll')}
                          </ClearLinkAll>
                        )}
                    </ColumnText>
                  </TextContainer>
                  <TextContainer
                    height='40px'
                    padding='4px 0px'
                    gap='0px'
                    justify='center'
                    align='center'
                  >
                    <SortByWrapper>
                      <SortBy
                        title={`${t('Webgateway:filters.sortedBy')}: ${
                          sortBy.title
                        }`}
                        selected={sortBy}
                        onSelect={sort}
                        list={sortByList}
                        bgColor='transparent'
                      />
                    </SortByWrapper>
                  </TextContainer>
                </FilterConditions>
              </FilterSet>
              <TableWrapper>
                <InnerTableWrapper>
                  <TypeTable
                    columnConfig={tableColumns}
                    rows={rowData}
                    hasTypeIcon
                    hasThumbnail
                    hasStatus
                    loadingText={t('Common:loadingData')}
                    isLoading={loading}
                    emptyTableText={t('Webgateway:noDeviceFound')}
                    selectable
                    selectCallback={handleRowSelect}
                    toggleAllCallback={handleSelectAllRows}
                    hasHeaderGroups
                  />
                </InnerTableWrapper>
              </TableWrapper>
              <Pagination
                pageSizeOptions={sizeOptions}
                totalPages={totalPages}
                defaultPage={currentPage}
                defaultPageSize={pageSize}
                onPageSizeChange={onSizePerPageChange}
                onPageChange={onPageChange}
                pageSizeText={t('Webgateway:pagination.itemsPerPage')}
                prevPageText={t('Webgateway:pagination.page')}
              />
            </TableFilterConatiner>
          </ContentContainer>
        </SectionContainer>
      </Wrapper>
    </MainContainer>
  );
};

export default WebGateway;
