import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Common_en from './locales/en/Common.json';
import Common_ja from './locales/ja/Common.json';

import WebGateway_en from './locales/en/WebGateway.json';
import WebGateway_ja from './locales/ja/WebGateway.json';

import ConnectionUsage_en from './locales/en/ConnectionUsage.json';
import ConnectionUsage_ja from './locales/ja/ConnectionUsage.json';

import CreateLinks_en from './locales/en/CreateLinks.json';
import CreateLinks_ja from './locales/ja/CreateLinks.json';

import EditLink_en from './locales/en/EditLink.json';
import EditLink_ja from './locales/ja/EditLink.json';

import DeviceLinks_en from './locales/en/DeviceLinks.json';
import DeviceLinks_ja from './locales/ja/DeviceLinks.json';

import Usage_en from './locales/en/Usage.json';
import Usage_ja from './locales/ja/Usage.json';

import Settings_en from './locales/en/Settings.json';
import Settings_ja from './locales/ja/Settings.json';


import LanguageDetector from 'i18next-browser-languagedetector';
const resources = {
  en: {
    Common: Common_en,
    Webgateway: WebGateway_en,
    ConnectionUsage: ConnectionUsage_en,
    CreateLinks: CreateLinks_en,
    EditLink: EditLink_en,
    Usage: Usage_en,
    DeviceLinks: DeviceLinks_en,
    Settings: Settings_en
  },
  ja: {
    Common: Common_ja,
    Webgateway: WebGateway_ja,
    ConnectionUsage: ConnectionUsage_ja,
    CreateLinks: CreateLinks_ja,
    EditLink: EditLink_ja,
    Usage: Usage_ja,
    DeviceLinks: DeviceLinks_ja,
    Settings: Settings_ja
  }
};

i18n.use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'ja',
    lng: localStorage.getItem('language') || navigator.language,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    defaultNS: 'Common',
    ns: ['Common'],
  });

export default i18n;