import React, { useState, useCallback, Fragment, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Icon, Checkbox } from 'scorer-ui-kit';
import { ITableColumnConfig, TypeCellAlignment } from 'scorer-ui-kit/dist/Tables';
import { useWindowSize } from '../../hooks/useWindowSize';
import { ISortBy } from '../molecules/SortBy';

const HeaderRow = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderCell = styled.div<{
  minWidth?: number;
  fixedWidth?: number;
  isSortActive?: boolean;
  sortable?: boolean;
  alignment?: TypeCellAlignment;
  hasCopyButton?: boolean;
  headerStyle: string;
  verticalAlign?: string;
  paddingTop?: string;
}>`
  height: inherit;
  align-items: center;
  vertical-align: top;
  line-height: 20px;
  font-weight: bold;
  color: var(--grey-11);
  position: relative;
  flex: 1;
  font-family: ${(p) => p.theme.fontFamily.ui};
  vertical-align: ${({ verticalAlign }) => verticalAlign};
  padding-top: ${({ paddingTop }) => paddingTop};

  ${({ fixedWidth }) =>
    fixedWidth &&
    css`
      width: ${fixedWidth}px;
      flex: 0 0 auto;
    `};

  ${({ minWidth }) => minWidth && css`
    min-width:${minWidth}px;
  `};

  ${({ theme, alignment, headerStyle }) =>
    alignment
      ? css`
          ${theme.typography.table[headerStyle][alignment]};
        `
      : css`
          ${theme.typography.table[headerStyle]['left']};
        `};

  ${({ theme: { styles }, headerStyle, isSortActive }) =>
    headerStyle === 'subHeader' &&
    css`
      padding-bottom: 10px;

      &::after {
        ${styles.tables.header.divider};
        content: '';
        display: block;
        height: 1px;
        // left: ${isSortActive ? '-15px' : '0'};
        left: 0;
        right: 0;
        // width: ${isSortActive ? 'calc(100% + 15px)' : '100%'};
        width: 100%;
        bottom: 0px;
        position: absolute;
      }
    `};
`;

const TableHeaderTitle = styled.div<{
  sortable?: boolean;
  isSortActive?: boolean;
  ascending?: boolean;
}>`
  display: inline-flex;
  justify-content: center;
  align-content: center;
  position: relative;
  padding: 8px 0px;
  user-select: none;
  z-index: 99;
  color: #717989;
  border-radius: 6px;
  text-wrap: nowrap;

  ${({ isSortActive }) =>
    isSortActive &&
    css`
      &:not(:hover) {
        div {
          display: inline-flex;
        }
        div > div {
          display: inline-flex;
        }
      }
      div {
          display: inline-flex;
        }
    `}

    /*
  ${({ theme: { styles }, sortable }) =>
    sortable &&
    css`
      ${styles.tables.header.sortable.default};

      &:hover {
        cursor: pointer;
        ${styles.tables.header.sortable.hover};

        div {
          ${styles.tables.header.sortable.hover};
          display: inline-flex;
        }
      }
    `}
    */
`;

const TitleItems = styled.div<{ alignment?: TypeCellAlignment }>`
  display: flex;
  flex-direction: column;
  width:100%;
  align-items: ${({ alignment }) =>
    alignment === 'center'
      ? 'center'
      : alignment === 'right'
        ? 'flex-end'
        : 'flex-start'};
`;

const GroupTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  margin-bottom: 3px;
`;

const Title = styled.div`
  ${({ theme: { typography } }) => css`
    ${typography.tables.groupName};
  `};
  padding-right: 8px;
  white-space: nowrap;
  color: #717989;
  z-index: 2;
  font-weight: 500;
`;

interface IUpDownIconProps {
  color: string;
  size: number;
}
const UpDownIconStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const IconWrapper = styled.div<{
  position?: string;
  top?: string;
  left?: string;
  zIndex?: string;
  marginLeft?: string;
}>`
  display: flex;
  align-items: center;
  margin-left: ${({ marginLeft }) => marginLeft};
  position: ${({ position }) => position};
  top: ${({ top }) => top};
  left: ${({ left }) => left};
  z-index: ${({ zIndex }) => zIndex};
`;

const UpDownIcon: React.FC<IUpDownIconProps> = ({ color, size }) => {
  return (
    <UpDownIconStyle>
      <Icon icon='Up' size={size} color={color} />
      <Icon icon='Down' size={size} color={color} />
    </UpDownIconStyle >
  );
};

const EmptyTitle = styled.div`
  height: 20px;
`;

const MiddleLine = styled.div<{ isLastOfGroup?: boolean }>`
  ${({ theme: { styles } }) => css`
    ${styles.tables.header.groupLine};
  `};

  height: 1px;
  flex: 1;
  ${({ isLastOfGroup }) =>
    isLastOfGroup &&
    css`
      margin-right: 15px;
    `};
`;

const renderGroupHeader = (
  columnConfig: ITableColumnConfig[],
  index: number
) => {
  if (index < 0) {
    return null;
  }

  let isLastOfGroup: boolean = true;

  if (index < columnConfig.length - 1) {
    isLastOfGroup =
      columnConfig[index].groupTitle !== columnConfig[index + 1].groupTitle;
  }

  if (!columnConfig[index].groupTitle) {
    return <EmptyTitle />;
  }

  if (
    index !== 0 &&
    columnConfig[index - 1].groupTitle === columnConfig[index].groupTitle
  ) {
    return (
      <Fragment>
        <EmptyTitle />
        <MiddleLine {...{ isLastOfGroup }} />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Title>{columnConfig[index].groupTitle}</Title>
      <MiddleLine {...{ isLastOfGroup }} />
    </Fragment>
  );
};

interface ITableHeaderProps {
  onToggleLastAccessed?: () => void;
  onToggleIngressEgress?: () => void;
  selectable?: boolean;
  hasStatus?: boolean;
  hasThumbnail?: boolean;
  hasTypeIcon?: boolean;
  allChecked?: boolean;
  isEmptyTable?: boolean;
  isLoading?: boolean;
  hasHeaderGroups: boolean;
  columnConfig?: ITableColumnConfig[];
  defaultAscending?: boolean;
  toggleAllCallback?: (checked: boolean) => void;
  sortCallback?: (columnId: string, ascending: boolean) => void;
  columnIdCallback?: (columnId: string) => void;
  sortBy?: ISortBy;
}

const AccordionTableHeader: React.FC<ITableHeaderProps> = ({
  selectable = false,
  hasStatus = false,
  hasThumbnail = false,
  hasTypeIcon = false,
  allChecked = false,
  isEmptyTable = false,
  isLoading = false,
  hasHeaderGroups = false,
  columnConfig = [],
  defaultAscending = true,
  toggleAllCallback = () => { },
  sortCallback = () => { },
  columnIdCallback = () => { },
  sortBy
}) => {
  const [width] = useWindowSize();
  const [iconShow, setIconShow] = useState<number>(0);
  const [hoverIcon, setHoverIcon] = useState<number>(0);

  // Define the column mapping (keys swapped with values)
  const columnMapping: { [key: string]: number } = {
    'name': 0,
    'lastAccessed': 3,
    'ingress': 4,
    'egress': 5,
  };

  useEffect(() => {
    if (sortBy) {
      let mappedValue = columnMapping[sortBy.sort];

      // If window width is <= 1280, set ingress and egress to 6
      if (width < 1280 && (sortBy.sort === 'ingress' || sortBy.sort === 'egress')) {
        mappedValue = 6;
      }

      if (mappedValue !== undefined) {
        setIconShow(mappedValue);
        setHoverIcon(mappedValue);
      }
    }
  }, [sortBy, width, columnMapping]);


  const toggleAllCallbackWrapper = useCallback(
    (checked: boolean) => {
      toggleAllCallback(checked);
    },
    [toggleAllCallback]
  );

  return (
    <HeaderRow>
      {selectable && (
        <HeaderCell
          headerStyle='header'
          fixedWidth={34}
          verticalAlign='top'
          paddingTop='19px'
        >
          <Checkbox
            checked={allChecked}
            disabled={isEmptyTable || isLoading}
            onChangeCallback={toggleAllCallbackWrapper}
          />
        </HeaderCell>
      )}
      {hasStatus && <HeaderCell headerStyle='header' fixedWidth={10} />}
      {hasThumbnail && <HeaderCell headerStyle='header' fixedWidth={70} />}
      {hasTypeIcon && <HeaderCell headerStyle='header' fixedWidth={35} />}

      {columnConfig.map((column, key, allColls) => {
        const {
          header,
          alignment,
          hasCopyButton,
          sortActive,
          sortable,
          minWidth,
          width,
          cellStyle
        }: ITableColumnConfig = column;
        return (
          <HeaderCell
            key={key}
            minWidth={minWidth}
            fixedWidth={width}
            isSortActive={sortActive}
            sortable={sortable}
            alignment={alignment}
            hasCopyButton={hasCopyButton}
            headerStyle={hasHeaderGroups ? 'subHeader' : 'header'}
          >
            <TitleItems {...{ alignment }}>
              <GroupTitle>{renderGroupHeader(allColls, key)}</GroupTitle>
              <TableHeaderTitle
                isSortActive={sortActive}
                sortable={sortable}
              >
                {header}
                {sortable && (
                  <IconWrapper marginLeft='9px'>
                    {(columnConfig[iconShow].header === header ||
                      columnConfig[hoverIcon].header === header
                    ) &&
                      (cellStyle !== 'firstColumn' ? (
                        <UpDownIcon size={7} color='dimmed' />
                      ) : defaultAscending ? (
                        <IconWrapper>
                          <Icon icon='Down' size={8} color='dimmed' />
                        </IconWrapper>
                      ) : (
                        <IconWrapper>
                          <Icon icon='Up' size={8} color='dimmed' />
                        </IconWrapper>
                      ))}
                  </IconWrapper>
                )}
              </TableHeaderTitle>
            </TitleItems>
          </HeaderCell>
        );
      })}
    </HeaderRow>
  );
};

export default AccordionTableHeader;
