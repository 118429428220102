import React, { useCallback, useEffect, useState } from 'react';
import Amplify, { Auth } from 'aws-amplify';
import { withAuthenticator } from 'aws-amplify-react';
import awsConfig from './lib/amplify';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import logoMarkSvg from './svg/logo-mark.svg';
import logoTextSvg from './svg/logo-text.svg';
import FeatureEnableDrawer from './components/FeatureEnableDrawer';

import { MainMenu, Layout, TopBar } from 'scorer-ui-kit';
import menu from './pages/menu.json';
import Login from './pages/Login';
import styled from 'styled-components';
import { useUsername } from './hooks/useUsername';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { useFeatureEnable } from './hooks/useFeatureEnable';

//pages
import WebGateway from './pages/WebGateway';
import Usage from './pages/Usage';
import ConnectionUsageData from './pages/ConnectionUsage';
import CreateLinks from './pages/CreateLinks';
import Settings from './pages/Settings';
import DeviceLinks from './pages/DeviceLinks';
import EditLink from './pages/EditLink';

const MainContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100vh;
  > div:first-child > div:nth-child(2) > div > button {
    // padding: 1px 4px 4px 6px;
  }
`;

export type IToggleState = 'default' | 'disabled' | 'loading' | 'locked' | 'failure' | undefined;

export interface ContextProps {
  isFeatureEnabled: boolean;
  setIsFeatureEnabled: (value: boolean) => void;
  switchState: IToggleState;
  setSwitchState: (value: IToggleState) => void;
}
export const FeatureEnabledContext = React.createContext<ContextProps>({
  isFeatureEnabled: false,
  setIsFeatureEnabled: () => { },
  switchState: 'default',
  setSwitchState: () => { }
});

export interface EditContextProps {
  isEditFromHome: boolean;
  setIsEditFromHome: (value: boolean) => void;
}

export const EditRoutingContext = React.createContext<EditContextProps>({
  isEditFromHome: false,
  setIsEditFromHome: () => { }
});

export const GatewayModalContext = React.createContext<GatewayModalContext>({
  modalClose: false,
  setModalClose: () => { },
});

Amplify.configure(awsConfig);

const App: React.FC<{}> = () => {
  const { username, signOut } = useUsername();
  const { t } = useTranslation(['Common']);
  const [translatedMenu, setTranslatedMenu] = useState(menu);
  const {
    state: { featureEnabled },
    actions: { fetchFeatureEnabled },
  } = useFeatureEnable();
  const [isFeatureEnabled, setIsFeatureEnabled] = useState<boolean>(true);
  const [modalClose, setModalClose] = useState<boolean>(false);
  const [switchState, setSwitchState] = useState<IToggleState>('default')

  // State to manage the routing of edit links page
  const [isEditFromHome, setIsEditFromHome] = useState<boolean>(false)

  const onLanguageChange = useCallback(() => {
    const language = i18n.language === 'ja' ? 'en' : 'ja';
    i18n.changeLanguage(language);
    localStorage.setItem('language', language);
  }, []);

  useEffect(() => {
    const translatedMenu = {
      items: menu.items.map(({ title, submenu, ...rest }) => ({
        title: t(title),
        submenu: submenu
          ? submenu.map(({ title: subTitle, ...subRest }) => ({
              title: t(subTitle),
              ...subRest,
            }))
          : undefined,
        ...rest,
      })),
    };
    setTranslatedMenu(translatedMenu);
  }, [t]);

  const getEnableFeature = useCallback(() => {
    fetchFeatureEnabled();
    setIsFeatureEnabled(featureEnabled);
  }, [fetchFeatureEnabled, featureEnabled]);

  useEffect(() => {
    getEnableFeature();
  }, [getEnableFeature]);

  return (
    <Router>
      <Layout>
        <FeatureEnabledContext.Provider
          value={{ isFeatureEnabled, setIsFeatureEnabled, switchState, setSwitchState }}
        >
          <EditRoutingContext.Provider
          value={{isEditFromHome, setIsEditFromHome}}
        >
          <MainMenu
            content={translatedMenu}
            logoMark={logoMarkSvg}
            logoText={logoTextSvg}
            canAlwaysPin={true}
            autoHideText={t('Common:sideBar.autoHide')}
            keepOpenText={t('Common:sideBar.keepOpen')}
            defaultMenuOpen={false}
          />
          <GatewayModalContext.Provider value={{ modalClose, setModalClose }}>
            <MainContainer>
              <TopBar
                userDrawerBespoke={<FeatureEnableDrawer username={username} />}
                hasSearch={false}
                loggedInUser={username}
                logoutLink='/'
                onLogout={signOut}
                hasLanguage
                currentUserText={t('Common:topBar.currentUser')}
                logoutText={t('Common:topBar.logout')}
                onLanguageToggle={onLanguageChange}
                hasUserDrawerFooter
                userDrawerFooter={{
                  icon: 'Information',
                  title: 'WGS 2025.01.30'
                }}
              />
              <Switch>
                <Route path='/' exact render={() => <WebGateway username={username} />} />
                <Route path='/links/:deviceID' exact component={DeviceLinks} />
                <Route path='/links/:deviceID/data-usage/:linkID' exact component={ConnectionUsageData} />
                <Route path='/links/:deviceID/create-link' exact component={CreateLinks} />
                <Route path='/links/:deviceID/edit-link/:linkID' exact component={EditLink} />
                <Route path='/settings' exact component={Settings} />
                <Route path='/usage' exact component={Usage} />
              </Switch>
            </MainContainer>
          </GatewayModalContext.Provider>
          </EditRoutingContext.Provider>
        </FeatureEnabledContext.Provider>
      </Layout>
    </Router>
  );
};

export default withAuthenticator(App, false, [
  <Login
    key='login'
    onLogin={({ username, password }) => Auth.signIn(username, password)}
  />
]);
