import React, { useCallback, useEffect, useContext } from 'react';
import { INotificationProps } from 'scorer-ui-kit';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { enableWebgateVPN, isWebgateEnabled, disableWebgateVPN } from '../lib/api_config';
import { FeatureEnabledContext, ContextProps, GatewayModalContext } from '../App';
import { useFeatureEnable } from '../hooks/useFeatureEnable';
import { ErrorType, handleException } from '../hooks/handleException';
import { useNotification } from './Notifications/useNotification';
import { handleDismiss } from './Notifications/Notification';
import ToggleSwitch from './molecules/ToggleSwitch';

const CustomContentContainer = styled.div`
  border-bottom: ${({ theme: { colors } }) => colors.divider} 1px solid;
`;

const NotificationLabel = styled.p`
  height: 16px;
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 14px;
  font-weight: 600;
  color: rgba(88, 88, 88, 0.9);
  margin-bottom: 10px;
`;

const FeatureInfoLabel = styled.label`
  font-size: 12px;
  line-height: 1.67;
  color: #8b9196;
  height: 100.2px;
  display: block;
`;

const SwitchContainer = styled.div`
  display: flex;
  margin-top: 12px;
  margin-bottom: 9px;
  height: 40px;
  > div:first-child{
    margin-top: 8px; 
    > label > div:first-child > span {
      color: rgba(88, 88, 88, 0.65);
      font-weight: 500;
    }
  }
`;

const FeatureEnableContainer = styled.div`
  margin: 17px 23px 17px 20px;
`;

const FeatureEnableDrawer: React.FC<{ username: string }> = ({ username }) => {

  const { isFeatureEnabled, setIsFeatureEnabled, switchState, setSwitchState }: ContextProps = useContext(FeatureEnabledContext);
  const { actions: { putFeatureEnabled } } = useFeatureEnable();
  const { t } = useTranslation(['Webgateway', 'Common']);
  const { sendNotification } = useNotification();
  const { setModalClose } = useContext(GatewayModalContext);

  const notiInfoSuccessEnabled = useCallback(() => {
    const content: INotificationProps = {
      type: 'success',
      message: t('Webgateway:notificationMessage.successMessage'),
    };
    return content;
  }, [t]);

  const enableWebgate = useCallback(async () => {
    setModalClose(true);
    try {
      const isWebgateEnabled = await enableWebgateVPN();
      if (isWebgateEnabled === null) {
        throw new Error(ErrorType.PARSE);
      }
      handleDismiss(); // Clear all notification bar before sending a new one
      if (isWebgateEnabled) {
        setIsFeatureEnabled(isWebgateEnabled);
        putFeatureEnabled(true, username);
        sendNotification(notiInfoSuccessEnabled());
      } else {
        sendNotification({
          type: 'error',
          message: t('Common:exceptionNotificationMessages.errorFeatureEnabling')
        });
      }
      setSwitchState('default');
    } catch (error) {
      setSwitchState('default');
      const exception = handleException(error);
      handleDismiss(); // Clear all notification bar before sending a new one
      sendNotification({
        type: 'error',
        message: t(`Common:exceptionNotificationMessages.${exception.message}`),
      });
    }
  }, [t, notiInfoSuccessEnabled, sendNotification, setIsFeatureEnabled, putFeatureEnabled, setModalClose, username, setSwitchState]);

  const notiInfoDisabled = useCallback(() => {
    const content: INotificationProps = {
      type: 'info',
      message: t('Webgateway:notificationMessage.disableMessage'),
      actionTextButton: t('Webgateway:notificationMessage.enableMessageNow'),
      onTextButtonClick: () => enableWebgate(),
      isPinned: false
    };
    return content;
  }, [t, enableWebgate]);

  const disableWebgate = useCallback(async () => {
    setModalClose(true);
    try {
      const isWebgateDisabled = await disableWebgateVPN();
      if (isWebgateDisabled === null) {
        throw new Error(ErrorType.PARSE);
      }
      handleDismiss(); // Clear all notification bar before sending a new one
      if (isWebgateDisabled) {
        setIsFeatureEnabled(!isWebgateDisabled);
        putFeatureEnabled(false, username);
        sendNotification(notiInfoDisabled());
        localStorage.setItem(
          `${username}_show_modal_popup_first_time`,
          'false'
        );
      } else {
        sendNotification({
          type: 'error',
          message: t('Common:exceptionNotificationMessages.errorFeatureDisabling')
        });
      }
      setSwitchState('default');
    } catch (error) {
      setSwitchState('default');
      const exception = handleException(error);
      handleDismiss(); // Clear all notification bar before sending a new one
      sendNotification({
        type: 'error',
        message: t(`Common:exceptionNotificationMessages.${exception.message}`),
      });
    }
  }, [t, setIsFeatureEnabled, putFeatureEnabled, sendNotification, notiInfoDisabled, setModalClose, username, setSwitchState]);

  const onChangeFeatureEnable = useCallback(async (event) => {
    setSwitchState('loading');
    if (event) {
      enableWebgate();
    } else {
      disableWebgate();
    }
  }, [enableWebgate, disableWebgate, setSwitchState]);

  const checkIsWebgateEnabled = useCallback(async () => {
    const enabled = await isWebgateEnabled();
    if (enabled) {
      setIsFeatureEnabled(enabled);
    } else {
      setIsFeatureEnabled(enabled);
    }
  }, [setIsFeatureEnabled]);

  useEffect(() => {
    checkIsWebgateEnabled();
  }, [checkIsWebgateEnabled]);

  return (
    <CustomContentContainer>
      <FeatureEnableContainer>
        <NotificationLabel>
          {t('Common:featureEnable')}
        </NotificationLabel>
        <SwitchContainer>
          <ToggleSwitch
            checked={isFeatureEnabled}
            labelText={isFeatureEnabled ? t('Common:enabled') : t('Common:disabled')}
            leftTheme='off'
            rightTheme='on'
            state={switchState}
            switchPosition='right'
            gap='58px'
            onChangeCallback={onChangeFeatureEnable}
          />
        </SwitchContainer>
        <FeatureInfoLabel>{t('Common:featureInfoLabel')}</FeatureInfoLabel>
      </FeatureEnableContainer>
    </CustomContentContainer>
  );
};

export default FeatureEnableDrawer;