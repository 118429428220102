import React, { useCallback, useEffect, useRef, useState, ReactElement } from 'react';
import styled from 'styled-components';

import { Content, PageHeader, ButtonWithIcon, Tabs, Tab, TabList, TabContent, TypeTable, MultilineContent, DatePicker, FilterDropdownContainer, useClickOutside, INotificationProps, useNotification } from 'scorer-ui-kit';
import { ITypeTableData, ITableColumnConfig } from 'scorer-ui-kit/dist/Tables';
import { useTranslation } from 'react-i18next';

import Billing from '../svg/billing-tax-calculation.svg';
import TotalCost from '../svg/billing-total-cost.svg';

import UsageTable from '../data/UsageData.json';

import env from '../lib/config';
import { getSystemTimezoneAbbreviation } from '../Utils/DateUtils';


const Header = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 15px;
`;

const HeaderContent = styled.div`
  display: flex;
  max-width: 610px;
`;

const ViewOnScorerButton = styled(ButtonWithIcon)`
  margin-left: auto;
  margin-top: 9px;
`;

const Divider = styled.div`
    height: 1px;
    border-radius: 3px;
    background-color: #efefef;
    margin-left: 0px;
`;

const PriceGuideDivider = styled.div`
    min-width: 320px;
    height: 1px;
    border-radius: 3px;
    opacity: 0.25;
    background-color: #afa9a9;
    margin-right: 100px;
`;

const UsageDataHeader = styled.div`
    display: flex;
    margin-top: 30px;
    margin-bottom: 21px;
`;

const UsageTitle = styled.p`
    font-family: ${({ theme }) => theme.fontFamily.ui};
    font-size: 20px;
    font-weight: 500;
    color: #5a6269;
`;

const ConsumptionContainer = styled.div`
    margin-top: 45px;
    margin-bottom: 50px;
    margin-left: -35px
`;

const Consumption = styled.div`
    display: flex;
    margin-bottom: 14px;
`;

const TotalContainer = styled.div`
    display: flex;
    margin-bottom: 14px;
    margin-top: 13px;
`;

const Title = styled.p`
    margin: 3px 48px 0 18px;
    font-size: 16px;
    font-weight: 500;
    color: #5a6269;
    font-family: ${({ theme }) => theme.fontFamily.ui};
`;

const CostTitle = styled.div`
    margin-left: auto;
    margin-top: 3px;
    font-family: ${({ theme }) => theme.fontFamily.ui};
    font-size: 16px;
    font-weight: 500;
    color: #5a6269;
`;

const PriceGuideInfo = styled.p`
    margin-top: 46px;
    line-height: 1.79;
    font-family: ${({ theme }) => theme.fontFamily.ui};
    font-size: 14px;
    color: #8b9196;
    white-space: pre-line;
    max-width: 932px;
`;

const PriceTiersContainer = styled.div`
    max-width: 385px;
    margin-top: 48px;  
    margin-bottom: 20px;
`;

const PriceTitle = styled.p`
    font-size: 16px;
    font-family: ${({ theme }) => theme.fontFamily.ui};
    color: #5a6269;
    font-size: 16px;
    font-weight: 500;
`;

const DateButton = styled(ButtonWithIcon)`
    margin-left: auto;
    margin-top: 19px;
`;

const BasicChargeContainer = styled.div`
    display: flex;
    margin-top: 36px;
    margin-bottom: 15px;
`;

const AdditionalDataContainer = styled.div`
    display: flex;
    margin-top: 17px;
    margin-bottom: 15px;
`;

const PlanTitle = styled.div`
    min-width: 138px;
    font-family: ${({ theme }) => theme.fontFamily.data};
    font-size: 14px;
    color: #788b91;
`;

const PlanContainer = styled.div`
    display: flex;
    min-width: 116px;
    font-family: ${({ theme }) => theme.fontFamily.data};
    font-size: 14px;
    margin-left: 15px;
    color: rgba(120, 138, 144, 0.72);
`;

const Unit = styled.div`
    min-width: 76px;
    font-family: ${({ theme }) => theme.fontFamily.data};
    font-size: 12px;
    margin-left: 5px;
    padding: 2px 0 0 0;
    font-style: italic;
    color: rgba(120, 138, 144, 0.72);
`;

const SvgIcon = styled.img`
  max-width: 18px;
`;

const DateDrawer = styled(FilterDropdownContainer)`
  position: absolute;
  top: 20px;
  z-index: 1;
  right: 1px;
`;

const DateDropdown = styled.div`
  position: relative;
  margin-left: auto;
`;

const DateContainer = styled.div`
  position: relative;
  display: flex;
`;

const TableRow = styled.div`
  display: flex;
`;

const TableColumn = styled.div`
  margin-left 59px;
`;

interface Interval {
  start: Date,
  end: Date
}

const Usage = () => {
  const [rowData, setRowData] = useState<ITypeTableData>([]);
  const [showDateDrawer, setShowDateDrawer] = useState(false);
  const [initialvalue, setInitialValue] = useState<Interval>({ start: new Date(), end: new Date() });
  const devices = UsageTable.devices;
  const { t } = useTranslation(['usage', 'Common']);
  const { sendNotification } = useNotification();
  const dateRef = useRef<HTMLDivElement>(null);
  useClickOutside(dateRef, () => setShowDateDrawer(false));

  const getConvertedDate = (selectedDate: Date) => {
    const date = selectedDate.toString();
    const splitDate = date.split(' ');
    const year = splitDate[3];
    const month = selectedDate.toLocaleString('default', { month: 'long' });
    const fullDate = month + ' ' + year;
    return fullDate;
  };

  const usageColumnConfig: ITableColumnConfig[] = [
    {
      header: t('Usage:usageData.deviceName'),
      sortable: false,
      cellStyle: 'firstColumn'
    },
    {
      header: t('Usage:usageData.baseUsage'),
      sortable: false,
      cellStyle: 'normalImportance'
    },
    {
      header: t('Usage:usageData.additionalUsage'),
      sortable: false,
      cellStyle: 'normalImportance'
    },
    {
      header: t('Usage:usageData.totalUsage'),
      sortable: false,
      cellStyle: 'normalImportance',
      showUnit: true
    },
    {
      header: t('Usage:usageData.cost'),
      sortable: false,
      alignment: 'right',
      cellStyle: 'highImportance'
    }
  ];

  const generateMultiLineColumn = (usage: string, cost: string): ReactElement[] => {
    return (
      [
        <TableRow key=''>
          {usage}
          <TableColumn>
            {cost}
          </TableColumn>
        </TableRow>
      ]
    );
  };

  const generateRowData = useCallback((): ITypeTableData => {
    const data: ITypeTableData = [];

    devices.forEach((device) => {
      const { deviceName, baseUsage, baseCost, additionalUsage, additionalCost, totalUsage, cost } = device;
      const row = {
        columns: [
          { text: deviceName },
          { customComponent: <MultilineContent contentArray={generateMultiLineColumn(baseUsage, baseCost)} /> },
          { customComponent: <MultilineContent contentArray={generateMultiLineColumn(additionalUsage, additionalCost)} /> },
          { text: totalUsage, unit: 'gb' },
          { text: cost }
        ]
      };
      data.push(row);
    });
    return data;
  }, [devices]);

  const notiInfoDisabled = useCallback(()=>{
    const content: INotificationProps = {
      type: 'info',
      message: t('Webgateway:notificationMessage.disableMessage'),
      actionTextButton: t('Webgateway:notificationMessage.enableMessageNow'),
      onTextButtonClick: () => {
        localStorage.setItem('vpn_webgate_enabled', 'true');
      },
      isPinned: true,
    };
    return content;
  }, [t]);

  useEffect(() => {
    if (localStorage.getItem('vpn_webgate_enabled') !== 'true') {
      sendNotification(notiInfoDisabled());
    }
  }, [sendNotification, notiInfoDisabled]);

  const handleButtonClick = () => {
    setShowDateDrawer(!showDateDrawer);
  };

  const onClickViewOnScorer = () => {
    window.open(env.SCORER_CLOUD_URL);
  };

  useEffect(() => {
    setRowData(generateRowData());
  }, [generateRowData,]);

  const updateDate = useCallback((date) => {
    setInitialValue(date);
    getConvertedDate(initialvalue.start);
  }, [initialvalue]);

  return (
    <Content>
      <Header>
        <HeaderContent>
          <PageHeader title={t('Common:usage')} icon='Usage' introductionText={t('Usage:introductionText')} />
        </HeaderContent>
        <ViewOnScorerButton size='small' design='primary' icon='Link' position='left' onClick={onClickViewOnScorer}>{t('Usage:viewOnScorer')}</ViewOnScorerButton>
      </Header>
      <Tabs>
        <TabList defaultTabId='usageData'>
          <Tab tabFor='usageData'>{t('Usage:usageData.usageData')}</Tab>
          <Tab tabFor='priceGuide'>{t('Usage:priceGuide.priceGuide')}</Tab>
        </TabList>
        <Divider />
        <TabContent tabId='usageData'>
          <UsageDataHeader>
            <UsageTitle>{getConvertedDate(initialvalue.start)}</UsageTitle>
            <DateDropdown ref={dateRef}>
              <DateButton size='small' design='secondary' icon='DateTime' position='left' onClick={handleButtonClick}>{t('Usage:usageData.changeDates')}</DateButton>
              {showDateDrawer && (
                <DateContainer>
                  <DateDrawer>
                    <DatePicker initialValue={initialvalue} dateMode='interval' timeMode='off' updateCallback={updateDate} timeZoneValueTitle={getSystemTimezoneAbbreviation()} />
                  </DateDrawer>
                </DateContainer>
              )}
            </DateDropdown>
          </UsageDataHeader>
          <TypeTable columnConfig={usageColumnConfig} rows={rowData} />
          <ConsumptionContainer>
            <Consumption>
              <SvgIcon src={Billing} alt='' />
              <Title>{t('Usage:usageData.consumptionTax')}</Title>
              <CostTitle>{UsageTable.consumptionTax}</CostTitle>
            </Consumption>
            <Divider />
            <TotalContainer>
              <SvgIcon src={TotalCost} alt='' />
              <Title>{t('Usage:usageData.totalCost')}</Title>
              <CostTitle>{UsageTable.totalCost}</CostTitle>
            </TotalContainer>
          </ConsumptionContainer>
        </TabContent>
        <TabContent tabId='priceGuide'>
          <PriceGuideInfo>
            {t('Usage:priceGuide.priceGuideInfo')}
          </PriceGuideInfo>
          <PriceTiersContainer>
            <PriceTitle>{t('Usage:priceGuide.priceTiers')}</PriceTitle>
            <BasicChargeContainer>
              <PlanTitle>{t('Usage:priceGuide.baseCharge')}</PlanTitle>
              <PlanContainer>{UsageTable.baseCharge}<Unit>{t('Usage:priceGuide.upTo')}</Unit></PlanContainer>
              <PlanContainer>{UsageTable.baseGbUsage}<Unit>{t('Usage:priceGuide.gbPerMonth')}</Unit></PlanContainer>
            </BasicChargeContainer>
            <PriceGuideDivider />
            <AdditionalDataContainer>
              <PlanTitle>{t('Usage:priceGuide.additionalData')}</PlanTitle>
              <PlanContainer>{UsageTable.additionalData}<Unit>{t('Usage:priceGuide.perAddition')}</Unit></PlanContainer>
              <PlanContainer>{UsageTable.additionalGbUsage}<Unit>gb</Unit></PlanContainer>
            </AdditionalDataContainer>
            <PriceGuideDivider />
          </PriceTiersContainer>
        </TabContent>
      </Tabs>
    </Content>
  );
};

export default Usage;