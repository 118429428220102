import { TFunction } from 'i18next';
import { DateInterval } from 'scorer-ui-kit';

// Date to Epoch
export const convertToEpoch = (dateString: Date | DateInterval): number => {
  const date = new Date(dateString as Date);
  date.setSeconds(0, 0); // Set seconds and milliseconds to 0
  return Math.floor(date.getTime() / 1000);
};

// Date to mm/dd
export const formatDate = (inputDate: string): string => {
  const dateObj = new Date(inputDate);
  const month = (dateObj.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based, so add 1
  const day = dateObj.getDate().toString().padStart(2, '0');
  return `${month}/${day}`;
};

export const formatTimestamp = (input: string | Date): string => {
  if (!input) {
    return '';
  }

  let date: Date;

  // Check if the input is a string and in the expected format
  if (typeof input === 'string') {
    // Parse the input format '16/Oct/2024:09:49:57 +0000'
    const dateParts = input.split(/[/ :]/);
    const day = parseInt(dateParts[0], 10);
    const monthStr = dateParts[1];
    const year = parseInt(dateParts[2], 10);
    const hours = parseInt(dateParts[3], 10);
    const minutes = parseInt(dateParts[4], 10);
    const month = new Date(Date.parse(monthStr + ' 1, 2021')).getMonth(); // Get month index (0-based)

    // Check if any of the parsed values are NaN
    if (isNaN(day) || isNaN(month) || isNaN(year) || isNaN(hours) || isNaN(minutes)) {
      return '';
    }

    date = new Date(Date.UTC(year, month, day, hours, minutes));
  } else {
    // If input is a Date object, use it directly
    date = input;
  }

  const year = date.getFullYear();
  const month = date.getMonth() + 1; // Months are zero-based in JavaScript
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  return `${year}/${String(month).padStart(2, '0')}/${String(day).padStart(2, '0')} - ${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
};


export const formatCalendarDateTime = (input: Date): string => {
  if (!input) {
    return '';
  }

  let date: Date = input;
  let timeZoneName = '';

  // Convert date to string and match timezone in parentheses
  const dateString = date.toString();  // Converts Date object to string
  const match = dateString.match(/\(([^)]+)\)/);
  if (match) {
    timeZoneName = ` (${match[1]})`;
  }

  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  return `${year}/${String(month).padStart(2, '0')}/${String(day).padStart(2, '0')} - ${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}${timeZoneName}`;
};



export const timeAgo = (input: string, t: TFunction): string => {
  if (!input) {
    return '';
  }

  // Parse the input format '16/Oct/2024:09:49:57 +0000'
  const dateParts = input.split(/[/ :]/);
  const day = parseInt(dateParts[0], 10);
  const monthStr = dateParts[1];
  const year = parseInt(dateParts[2], 10);
  const hours = parseInt(dateParts[3], 10);
  const minutes = parseInt(dateParts[4], 10);
  const month = new Date(Date.parse(monthStr + ' 1, 2021')).getMonth() + 1; // Get month index (1-based)

  // Check if any of the parsed values are NaN
  if (isNaN(day) || isNaN(month) || isNaN(year) || isNaN(hours) || isNaN(minutes)) {
    return '';
  }

  const date = new Date(Date.UTC(year, month - 1, day, hours, minutes));

  const now = new Date();
  const diffMs = now.getTime() - date.getTime();

  const diffMins = Math.floor(diffMs / (1000 * 60));
  const diffHours = Math.floor(diffMins / 60);
  const diffDays = Math.floor(diffHours / 24);
  const diffMonths = Math.floor(diffDays / 30);
  const diffYears = Math.floor(diffDays / 365);

  if (diffYears >= 1) {
    return `${diffYears} ${t(`DeviceLinks:tableText.timeAgoUnits.${diffYears !== 1 ? 'years' : 'year'}`)}`;
  } else if (diffMonths >= 1) {
    return `${diffMonths} ${t(`DeviceLinks:tableText.timeAgoUnits.${diffMonths !== 1 ? 'months' : 'month'}`)}`;
  } else if (diffDays >= 1) {
    return `${diffDays} ${t(`DeviceLinks:tableText.timeAgoUnits.${diffDays !== 1 ? 'days' : 'day'}`)}`;
  } else if (diffHours >= 1) {
    return `${diffHours} ${t(`DeviceLinks:tableText.timeAgoUnits.${diffHours !== 1 ? 'hours' : 'hour'}`)}`;
  } else {
    return `${diffMins} ${t(`DeviceLinks:tableText.timeAgoUnits.${diffMins !== 1 ? 'mins' : 'min'}`)}`;
  }
};


// Epoch to Expiry
export const formatEpochToDateTime = (epochTime: number) => {
  const date = new Date(epochTime * 1000);

  // Format the date as yyyy/MM/dd
  const formattedDate = date.toLocaleDateString('en-CA').replace(/-/g, '/');

  // Format the time as hh:mm
  const formattedTime = date.toTimeString().slice(0, 5);

  return `${formattedDate} - ${formattedTime}`;
}

// Epoch to Date function
export const convertEpochToDateString = (epoch: number): Date | undefined => {
  if (epoch === 0) {
    return undefined;
  }

  const date = new Date(epoch * 1000);
  return date;
};



// Helper function to get the system's timezone abbreviation
export const getSystemTimezoneAbbreviation = (): string => {
  const date = new Date();
  const formatter = new Intl.DateTimeFormat('en-US', { timeZoneName: 'short' });
  const timeZoneNamePart = formatter.formatToParts(date).find(part => part.type === 'timeZoneName');

  const offset = timeZoneNamePart?.value || 'GMT';

  // Mapping common time zone offsets to abbreviations
  const timezoneMapping: { [key: string]: string } = {
    'UTC': 'UTC',
    'GMT': 'GMT',
    'GMT+1': 'CET',   // Central European Time
    'GMT+2': 'EET',   // Eastern European Time
    'GMT+5:30': 'IST', // Indian Standard Time
    'GMT+9': 'JST',   // Japan Standard Time
    'GMT-5': 'EST',   // Eastern Standard Time (USA)
    'GMT-8': 'PST',   // Pacific Standard Time (USA)
    // Add more mappings as needed
  };

  // Use the mapping to find the abbreviation or fallback to the original offset
  return timezoneMapping[offset] || offset;
};