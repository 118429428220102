import { API, graphqlOperation } from 'aws-amplify';
import { GetWebgateStatusQuery, GetWebgateStatusQueryVariables, ToggleWebgateDeviceMutationVariables, ToggleWebgateResponse, CreateWebgateDeviceLinkMutationVariables, CreateWebgateDeviceLinkMutation, EditWebgateDeviceLinkMutationVariables, EditWebgateDeviceLinkMutation, CreateLinkBasicConfig, EditLinkAdvanceConfig, EditLinkBasicConfig, CreateLinkAdvanceConfig, GetLinksAllocatedStatus, WebgateNetworkAddressUpdateMutationVariables, WebgateNetworkAddressUpdateMutation, UpdateWebgateCustomDomainMutationVariables, UpdateWebgateCustomDomainMutationResponse } from '../API';
import { disableWebgateVPNMutation, enableWebgateVPNMutation, toggleWebgateDeviceMutation, createWebgateDeviceLinkMutation, editWebgateDeviceLinkMutation, webgateNetworkAddressUpdateMutation, updateWebgateCustomDomainMutation } from '../graphql/mutations';
import { deviceStatusQuery, devicesQuery, getWebgateStatusQuery, isWebgateEnabledQuery, getWebgateDeviceLinksQuery, getUsageStatsQuery, getTopUsageClientsQuery, getWeeklyUsageStatsQuery, getLinksAllocatedStatusQuery, getWebgateLinkInfoQuery } from '../graphql/queries';

export interface LastSnapshot {
  signedUrl: string;
  time: string;
}

interface Status {
  alive: boolean;
  diskUsageAlertLevel: string;
}

export interface DeviceStatus {
  lastSnapshot: LastSnapshot;
  status: Status;
  isImage?: boolean;
}

export interface Device {
  name: string;
  lid: string;
  type: string;
  tags: string[];
  group: string;
}

interface DevicesResponse {
  devices: Device[];
}

export const getDevices = async (): Promise<Device[]> => {
  const {
    data: { devices },
  } = (await API.graphql(graphqlOperation(devicesQuery))) as {
    data: DevicesResponse;
  };
  return devices;
};

export const getDeviceStatus = async (
  deviceID: string
): Promise<DeviceStatus> => {
  const {
    data: { deviceStatus },
  } = (await API.graphql(
    graphqlOperation(deviceStatusQuery, { deviceID })
  )) as { data: { deviceStatus: DeviceStatus } };
  return deviceStatus;
};

export const getWebgateStatus = async (
  deviceID: string,
  links: { linkID: string; linkName: string; isStandardLink: boolean }[]
): Promise<GetWebgateStatusQuery['getWebgateStatus']> => {
  const {
    data: { getWebgateStatus },
  } = (await API.graphql(
    graphqlOperation(getWebgateStatusQuery, {
      deviceID, links
    } as GetWebgateStatusQueryVariables)
  )) as { data: GetWebgateStatusQuery };
  return getWebgateStatus;
};

export const toggleWebgateDevice = async (
  deviceID: string,
  linkID: string,
  linkName: string,
  isStandardLink: boolean,
  setEnable: boolean,
  flush: boolean
) => {
  const { data } = (await API.graphql(
    graphqlOperation(toggleWebgateDeviceMutation, {
      deviceID,
      linkID,
      linkName,
      isStandardLink,
      setEnable,
      flush,
    } as ToggleWebgateDeviceMutationVariables)
  )) as { data: ToggleWebgateResponse };

  return data.toggleWebgateDevice; // Return the actual response object from the mutation
};

// Check webgate feature API call
interface IsWebgateEnabledResponse {
  isWebgateEnabled: boolean;
}
export const isWebgateEnabled = async () => {
  const { data: { isWebgateEnabled = false } = {} } = (await API.graphql(
    graphqlOperation(isWebgateEnabledQuery)
  )) as { data: IsWebgateEnabledResponse }
  return isWebgateEnabled;
};

// Enable webgate feature API call
interface EnableWebgateVPNResponse {
  enableWebgateVPN: boolean;
}
export const enableWebgateVPN = async (): Promise<boolean> => {
  const { data: { enableWebgateVPN = false } = {} } = (await API.graphql(
    graphqlOperation(enableWebgateVPNMutation)
  )) as { data: EnableWebgateVPNResponse }
  return enableWebgateVPN;
};

// Disable webgate feature API call
interface DisableWebgateVPNResponse {
  disableWebgateVPN: boolean;
}
export const disableWebgateVPN = async (): Promise<boolean> => {
  const { data: { disableWebgateVPN = false } = {} } = (await API.graphql(
    graphqlOperation(disableWebgateVPNMutation)
  )) as { data: DisableWebgateVPNResponse }
  return disableWebgateVPN;
};








// Create Links API's
export const createWebgateDeviceLink = async (
  deviceID: string,
  requestType: string,
  basicConfig: CreateLinkBasicConfig,
  advanceConfig?: CreateLinkAdvanceConfig
) => {
  const { data } = (await API.graphql(
    graphqlOperation(createWebgateDeviceLinkMutation, {
      deviceID,
      requestType,
      basicConfig,
      advanceConfig,
    } as CreateWebgateDeviceLinkMutationVariables)
  )) as { data: CreateWebgateDeviceLinkMutation };

  return data;
};


// Create/Update Webgate Custom Domain API
export const updateWebgateCustomDomain = async (
  deviceID: string,
  linkID: string,
  port: number,
  domainName: string,
  privateKey: string,
  certificate: string,
  remove?: boolean
) => {
  const { data } = (await API.graphql(
    graphqlOperation(updateWebgateCustomDomainMutation, {
      deviceID,
      linkID,
      port,
      domainName,
      privateKey,
      certificate,
      ...(remove !== undefined && { remove })
    } as UpdateWebgateCustomDomainMutationVariables)
  )) as { data: UpdateWebgateCustomDomainMutationResponse };

  return data;
};

// Edit Links API's
export const editWebgateDeviceLink = async (
  deviceID: string,
  linkID: string,
  requestType: string,
  basicConfig?: EditLinkBasicConfig,
  advanceConfig?: EditLinkAdvanceConfig
) => {
  const { data } = (await API.graphql(
    graphqlOperation(editWebgateDeviceLinkMutation, {
      deviceID,
      linkID,
      requestType,
      basicConfig,
      advanceConfig,
    } as EditWebgateDeviceLinkMutationVariables)
  )) as { data: EditWebgateDeviceLinkMutation };

  return data;
};






// Device Links API's

interface DeviceLinkUsage {
  ingress: number;
  egress: number;
}
interface Expiry {
  time: number;
  action: string
}
interface LinksLimit {
  uploadLimit: number;
  uploadLimitUnit: string
}
export interface StatusObject {
  id: string;
  message: string;
}
export interface DeviceLinksData {
  id?: string;
  name: string;
  linkID: string;
  url: string;
  status: StatusObject;
  port: number;
  protocol: string;
  noIndex: boolean;
  expiry: Expiry;
  lastAccessed: string;
  customDomains: string[];
  dataUsage: DeviceLinkUsage;
  uploadLimit: LinksLimit;
}

export interface Links {
  status_code?: number;
  status_msg?: string;
  standardLinks?: DeviceLinksData[];
  otherLinks?: DeviceLinksData[];
}

export const getWebgateDeviceLinks = async (
  deviceID: string
): Promise<Links> => {
  const {
    data: { getWebgateDeviceLinks },
  } = (await API.graphql(
    graphqlOperation(getWebgateDeviceLinksQuery, { deviceID })
  )) as { data: { getWebgateDeviceLinks: Links } };
  return getWebgateDeviceLinks;
};


// Webgate Link Info API

export interface WebgateLinkInfoPayload {
  customDomains?: string[];
  dataUsage: DeviceLinkUsage;
  expiry: Expiry;
  lastAccessed: string;
  linkID: string;
  name: string;
  noIndex: boolean;
  port: number;
  protocol: string;
  status: StatusObject;
  uploadLimit: LinksLimit;
  url: string;
}

export interface WebgateLinkInfo {
  status_code: number;
  status_msg: string;
  linkID: string;
  payload: WebgateLinkInfoPayload;
}

export const getWebgateLinkInfo = async (
  deviceID: string,
  linkID: string
): Promise<WebgateLinkInfo> => {
  const {
    data: { getWebgateLinkInfo },
  } = (await API.graphql(
    graphqlOperation(getWebgateLinkInfoQuery, { deviceID, linkID })
  )) as { data: { getWebgateLinkInfo: WebgateLinkInfo } };
  return getWebgateLinkInfo;
};




//Connection Usage API's

//1st API
interface UsageDetails {
  totalUsage: number;
  ingressUsage: number;
  egressUsage: number;
}

interface UsageData {
  currentMonthUsage: UsageDetails;
  lastMonthUsage: UsageDetails;
}

export const getDataUsage = async (
  linkID: string,
  deviceID: string
): Promise<UsageData> => {
  const {
    data: { getUsageStats },
  } = (await API.graphql(
    graphqlOperation(getUsageStatsQuery, { linkID, deviceID })
  )) as { data: { getUsageStats: UsageData } };
  return getUsageStats;
};



//2nd API
interface TotalIngressUsage {
  clientIP: string;
  ingressDataUsage: number;
}
interface TotalEgressDetails {
  clientIP: string;
  egressDataUsage: number;
}

interface TotalUsageData {
  ingress: TotalIngressUsage[];
  egress: TotalEgressDetails[];
}

export const getTopClient = async (
  linkID: string,
  deviceID: string,
  usageDate: number
): Promise<TotalUsageData> => {
  const {
    data: { getTopUsageClientsStats },
  } = (await API.graphql(
    graphqlOperation(getTopUsageClientsQuery, { usageDate, deviceID, linkID })
  )) as { data: { getTopUsageClientsStats: TotalUsageData } };
  return getTopUsageClientsStats;
};




//3rd API
interface DailyUsageStat {
  date: string;
  ingressUsage: number;
  egressUsage: number;
}

interface DailyUsageData {
  weeklyUsageData: DailyUsageStat[];
}


export const getWeeklyUsageStats = async (
  usageStartDate: number,
  usageEndDate: number,
  deviceID: string,
  linkID: string
): Promise<DailyUsageData> => {
  const {
    data: { getWeeklyUsageStats },
  } = (await API.graphql(
    graphqlOperation(getWeeklyUsageStatsQuery, { usageStartDate, usageEndDate, deviceID, linkID })
  )) as { data: { getWeeklyUsageStats: DailyUsageData } };
  return getWeeklyUsageStats;
};



// Settings page API
export const getLinksAllocatedStatus = async (): Promise<GetLinksAllocatedStatus> => {
  const {
    data: { getLinksAllocatedStatus },
  } = (await API.graphql(graphqlOperation(getLinksAllocatedStatusQuery))) as {
    data: { getLinksAllocatedStatus: GetLinksAllocatedStatus }
  };
  return getLinksAllocatedStatus;
};


export const webgateNetworkAddressUpdate = async (
  networkAddress: string
): Promise<boolean | null> => {
  const {
    data: { webgateNetworkAddressUpdate },
  } = (await API.graphql(
    graphqlOperation(webgateNetworkAddressUpdateMutation, {
      networkAddress,
    } as WebgateNetworkAddressUpdateMutationVariables)
  )) as {
    data: WebgateNetworkAddressUpdateMutation;
  };

  return webgateNetworkAddressUpdate?.networkAddressUpdate ?? null;
};

