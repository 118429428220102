
/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten
export const toggleWebgateDeviceMutation = /* GraphQL */ `
  mutation ToggleWebgateDevice(
    $deviceID: ID!
    $linkID: String!
    $linkName: String!
    $isStandardLink: Boolean!
    $setEnable: Boolean!
    $flush: Boolean!
  ) {
    toggleWebgateDevice(
      deviceID: $deviceID
      linkID: $linkID
      linkName: $linkName
      isStandardLink: $isStandardLink
      setEnable: $setEnable
      flush: $flush
    ) {
      linkID
      linkName
      enabled 
      flush
    }
  }
`;

export const enableWebgateVPNMutation = /* GraphQL */ `
  mutation EnableWebgateVPN {
    enableWebgateVPN
  }
`;

export const disableWebgateVPNMutation = /* GraphQL */ `
  mutation DisableWebgateVPN {
    disableWebgateVPN
  }
`;


// Create Links API's

export const createWebgateDeviceLinkMutation = /* GraphQL */ `
  mutation createWebgateDeviceLink(
    $deviceID: String!
    $requestType: String!
    $basicConfig: CreateLinkBasicConfig
    $advanceConfig: CreateLinkAdvanceConfig
  ) {
    createWebgateDeviceLink(
      deviceID: $deviceID
      requestType: $requestType
      basicConfig: $basicConfig
      advanceConfig: $advanceConfig
    ) {
      linkCreateStatus
      linkID
      linkExpiry
      linkUploadLimit
      linkExists
    }
  }
`;

// Create Webgate Custom Domain API

export const updateWebgateCustomDomainMutation = /* GraphQL */ `
  mutation updateWebgateCustomDomain(
    $deviceID: String!
    $linkID: String!
    $port: Int!
    $domainName: String!
    $privateKey: String!
    $certificate: String!
    $remove: Boolean
  ) {
    updateWebgateCustomDomain(
      deviceID: $deviceID
      linkID: $linkID
      port: $port
      domainName: $domainName
      privateKey: $privateKey
      certificate: $certificate
      remove: $remove
    ) {
      domainName
      domainUpdateStatus
    }
  }
`;


// Edit Link API's

export const editWebgateDeviceLinkMutation = /* GraphQL */ `
  mutation editWebgateDeviceLink(
    $deviceID: String!
    $linkID: String!
    $requestType: String!
    $basicConfig: EditLinkBasicConfig
    $advanceConfig: EditLinkAdvanceConfig
  ) {
    editWebgateDeviceLink(
      deviceID: $deviceID
      linkID: $linkID
      requestType: $requestType
      basicConfig: $basicConfig
      advanceConfig: $advanceConfig
    ) {
      linkID
      linkEdited
    }
  }
`;


// Settings Page API
export const webgateNetworkAddressUpdateMutation = /* GraphQL */ `
  mutation webgateNetworkAddressUpdate($networkAddress: String!) {
    webgateNetworkAddressUpdate(networkAddress: $networkAddress) {
      networkAddressUpdate
    }
  }
`;
