import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom'; // Assuming you are using React Router
import { getDevices, Device } from '../lib/api_config';

const useCheckDevice = (deviceID: string) => {
  const [deviceName, setDeviceName] = useState<string>('-');
  const [tagsCategories, setTagsCategories] = useState<{ icon: string; text: string }[]>([]);
  const history = useHistory();
  const [dataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    const fetchUsage = async () => {
      try {
        setDataLoading(true);
        const devices = await getDevices();
        const edgeDevices: Device[] = devices.filter(({ type }: Device) => type === 'raspi');

        // Find the device where the `lid` matches the `deviceID`
        const matchedDevice = edgeDevices.find((device: Device) => device.lid === deviceID);

        if (matchedDevice) {
          setDeviceName(matchedDevice.name);

          const tagsCategories = [];

          // Add MetaCategories if the group is not empty
          if (matchedDevice.group) {
            tagsCategories.push({
              icon: 'MetaCategories',
              text: matchedDevice.group,
            });
          }

          // Add MetaTags for each tag if tags array is not empty
          if (matchedDevice.tags.length > 0) {
            matchedDevice.tags.forEach((tag, index) => {
              tagsCategories.push({
                icon: 'MetaTags',
                text: tag || `Tag ${index + 1}`,
              });
            });
          }

          setTagsCategories(tagsCategories);
          setDataLoading(false);
        } else {
          setDataLoading(false);
          history.push('/');
        }
      } catch (error) {
        setDataLoading(false);
        history.push('/');
        console.error('Error in fetchUsage:', error);
      }
    };

    fetchUsage();
  }, [deviceID, history]);

  return { deviceName, tagsCategories, dataLoading };
};

export default useCheckDevice;
