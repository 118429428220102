import styled, { css } from 'styled-components';
import React, { useState, useCallback, ChangeEvent } from 'react';
import { ButtonWithLoading, TextField, PasswordField, Form, AlertBar } from 'scorer-ui-kit';
import GhostLogo from '../svg/ghost-logo.svg';
import { LoginScreen } from '../svg';
import env from '../lib/config';
import { Alert } from '../types';

const widthDesk = 480;

const RowCss = css`
  display: flex;
  flex-direction: row;
`;

const Box = styled.div<{ margin?: string; flex?: string }>`
  margin: 0px 70px 0px 70px;
  button {
    width: 100%;
  }
  ${({ margin }) =>
    margin &&
    css`
      margin: ${margin};
    `}
  ${({ flex }) =>
    flex &&
    css`
      flex: ${flex};
      justify-content: flex-end;
      display: flex;
      flex-direction: column;
    `}
`;

const LoginForm = styled(Form)`
  /* width:342px; */
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  flex: 1;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  animation: fadeIn ease-in 0.5s;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  color: hsl(208, 8%, 38%);
  position: relative;
  margin-top: 70px;
`;

const Description = styled.p`
  font-size: 14px;
  color: #8b9196;
  height: 50px;
  margin: 23px 70px 40px;
  line-height: 1.79;
`;

const CopyRight = styled.div`
  opacity: 0.65;
  font-size: 14px;
  font-style: italic;
  line-height: 1.79;
  text-align: center;
  color: hsl(207, 5%, 57%);
  margin-top: 49px;
`;

const Container = styled.div`
  transform: translate(-50%, -50%);
  left: 50%;
  position: absolute;
  top: 50%;
  @media screen and (max-width: 900px) {
    /* overflow: auto; */
    height: 100%;
    ${CopyRight} {
      display: none;
    }
    transform: translateX(-50%);
    left: 50%;
    top: 0;
  }
`;

const LinkContainer = styled.a`
  font-size: 14px;
  font-style: italic;
  color: #4699d4;
  text-decoration: none;
`;

const ForgotLink = styled(LinkContainer)`
  margin-top: 13px;
  text-align: center;
`;

const Logo = styled(LoginScreen)`
  width: 260px;
  height: 57px;
  object-fit: contain;
`;

const LogoBackground = styled.img`
  position: absolute;
  top: -120px;
  width: 100%;
  height: 890px;
  min-width: ${widthDesk}px;
  background: linear-gradient(-45deg, #7865d9, #c05353);
  object-fit: contain;
  mix-blend-mode: overlay;
  opacity: 0.87;
`;

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  position: relative;
  justify-content: center;
`;

const LoginBox = styled.div`
  ${RowCss}
  overflow: hidden;
  position: relative;
  min-width: ${widthDesk * 2}px;
  height: 560px;
  border-radius: 5px;
  box-shadow: 0 20px 30px 0 hsla(205, 24%, 26%, 0.15);
  border: solid 1px hsl(0, 0%, 91%);
  input {
    min-width: 296px;
  }
`;

const LabelContainer = styled.span`
  margin-left: 20px;
`;

const InputContainer = styled.div`
  margin: 0px 70px -100px;
  > label {
    gap: 0px; 
    margin-bottom: 20px;
    > span{
      display: flex;
      gap: 8px;
      -webkit-box-align: center;
      align-items: center;
      margin-bottom: 10px; 
    }
  }
`;

const TextFieldContainer = styled(TextField)`
  margin: 0px 0px 0px 0px;
  height: 43px;
`;

const PasswordFieldContainer = styled(PasswordField)`
  margin: 0px 0px 0px 0px;
  height: 43px;
`;

const AlertBarContainer = styled.div`
  margin: 10px 70px 39px 70px;
  height: 50px;
`;

const ButtonWithLoadingContainer = styled(ButtonWithLoading)`
  margin: -20px 0px 0px 0px;
  height: 40px;
`;

interface AuthProps {
  authState?: string;
  onStateChange?: (authState: string, data?: Date) => void;
}

interface Props {
  onLogin: (params: { username: string; password: string }) => void;
}
type OwnProps = AuthProps & Props;

const Login: React.FC<OwnProps> = ({ onLogin }) => {
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState<Alert | null>(null);
  const [form, setForm] = useState({ username: '', password: '' });

  const onFieldChange = useCallback(
    (key: 'username' | 'password') =>
      ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, [key]: value });
      },
    [form]
  );

  const onSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement | HTMLButtonElement>) => {
      e.preventDefault();
      setLoading(true);
      setAlert(null);

      if (form.password === '' || form.username === '') {
        setAlert({
          message: 'Username or Password cannot be empty',
          type: 'error',
        });
      } else {
        try {
          await onLogin(form);
          // let log = await onLogin(form);
        } catch (error) {
          setAlert({
            message: (error as Error).message,
            type: 'error',
          });
        }
      }
      setLoading(false);
    },
    [form, onLogin]
  );

  return (
    <Container>
      <LoginBox>
        <LogoContainer>
          <Logo />
          <LogoBackground src={GhostLogo} />
        </LogoContainer>
        <LoginForm>
          <Title>Sign In To Your Account</Title>
          <Description>
            This service requires a{' '}
            <LinkContainer
              href={env.SCORER_CLOUD_URL + '/login'}
              target='_blank'
            >
              SCORER Cloud
            </LinkContainer>{' '}
            account to login. If you do not have one, please make one first.
          </Description>
          <InputContainer>
            <TextFieldContainer
              fieldState='required'
              required
              label='Username'
              onChange={onFieldChange('username')}
              value={form.username}
              name='username'
            />

            <PasswordFieldContainer
              fieldState='required'
              required
              label='Password'
              onChange={onFieldChange('password')}
              value={form.password}
              name='password'
            />
          </InputContainer>

          <Box flex='1'>
            <ButtonWithLoadingContainer
              loading={loading}
              type='submit'
              onClick={onSubmit}
            >
              Login
            </ButtonWithLoadingContainer>
          </Box>

          <ForgotLink href={env.SCORER_CLOUD_URL + '/login'} target='_blank'>
            Forgotten Password
          </ForgotLink>

          <AlertBarContainer>
            {alert && <AlertBar type={alert.type} message={alert.message} />}
          </AlertBarContainer>
        </LoginForm>
      </LoginBox>
      <CopyRight>
        Copyright {new Date().getFullYear()} - Future Standard Co. Ltd. All
        Rights Reserved.
        <LabelContainer>Terms • Privacy</LabelContainer>
      </CopyRight>
    </Container>
  );
};

export default Login;
