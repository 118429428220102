import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { FeatureEnabledContext, ContextProps } from '../App';
import { Button, ButtonWithIcon, ButtonWithLoading, Content, Icon, PageHeader, SmallInput, Spinner } from 'scorer-ui-kit';
import { useTranslation } from 'react-i18next';
import { getLinksAllocatedStatus, isWebgateEnabled, webgateNetworkAddressUpdate } from '../lib/api_config';
import { useNotification } from '../components/Notifications/useNotification';
import { handleException } from '../hooks/handleException';
import { handleDismiss } from '../components/Notifications/Notification';

const MainContainer = styled(Content)`
  padding: 0px;
  max-width: calc(100vw - 80px);
  div {
    max-width: 100%;
  }
`;

const Wrapper = styled.div<{ enableContent?: boolean }>`
  opacity: ${({ enableContent }) => (enableContent ? '1' : '0.6')};
  pointer-events: ${({ enableContent }) => (enableContent ? 'auto' : 'none')};
  div.bWMatg{
    svg {
    g {
      stroke: #80b5ff;
    }
  }
`;

const SectionContainer = styled.div<{
  height?: string;
  width?: string;
  gap?: string;
  padding?: string;
  grow?: number;
  direction?: string;
  justify?: string;
  align?: string;
}>`
  width: ${({ width }) => (width ? width : 'auto')};
  height: ${({ height }) => (height ? height : 'auto')};
  align-self: stretch;
  flex-grow: ${({ grow }) => (grow ? grow : 0)};
  display: flex;
  flex-direction: ${({ direction }) => (direction ? direction : 'column')};
  justify-content: ${({ justify }) => (justify ? justify : 'flex-start')};
  align-items: ${({ align }) => (align ? align : 'stretch')};
  gap: ${({ gap }) => (gap ? gap : '16px')};
  padding: ${({ padding }) => (padding ? padding : '32px 96px 64px')};
`;

const Drawer = styled.div`
  height: 24px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  color: #898e96;
  position: relative;
`;

const IconWrapper = styled.div<{
  marginTop?: string;
  height?: string;
  width?: string;
  position?: 'absolute' | 'relative';
  left?: string;
  top?: string;
}>`
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '4px')};
  width: ${({ width }) => (width ? width : '16px')};
  height: ${({ height }) => (height ? height : '16px')};
  display: flex;
  justify-content: center;
  padding: 4px;
  align-items: center;
  flex-grow: 0;
  position: ${({ position }) => position === 'absolute' ? position : 'relative'};
  left: ${({ position, left }) => (position === 'absolute' ? left : 'auto')};
  top: ${({ position, top }) => (position === 'absolute' ? top : 'auto')};
`;

const Breadcrum = styled.div`
  width: auto;
  height: 14px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 2.5px;
`;

const BreadCrumLink = styled.div`
  width: auto;
  height: 14px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 2.5px;
  > a {
    letter-spacing: -0.2px;
    line-height: 14px;
  }
  > div {
    width: 16px;
    height: 14px;
    margin-top: 0px;
    > div {
      height: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        path {
          stroke-width: 1.2;
        }
      }
    }
  }
`;

const LinkContainer = styled.a<{ paddingBotton?: string }>`
  width: auto;
  font-size: 12px;
  font-weight: 500;
  color: #898e96;
  text-decoration: none !important;
`;

const HeaderSection = styled.div`
  width: 100%;
  padding: 24px 96px 32px;
  margin: 16px 0 4px 0;
`;

const InnerHeader = styled.div<{ width?: number }>`
  height: auto;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

const DeviceLeftContainer = styled.div`
  width:100%
  height: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 26px;
  padding: 0 32px 6px 0;
`;

const DeviceInfo = styled.div`
  height: auto;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0px;
  position: relative;
  > div:first-child > div:first-child > div:first-child {
    height: 55.5px; 
  }
`;

const SpinnerContainer = styled.div`
  position: absolute;
  left: -45px;
  bottom: 20%;
`;

const Optionals = styled.div`
  height: 66px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 16px;
  padding: 0 0 2px;
`;

const PageHeaderText = styled.div`
  width: 648px;
  height: auto;
  font-size: 14px;
  line-height: 1.43;
  color: #8b9196;
  flex-grow: 1;
  font-stretch: normal;
  letter-spacing: -0.05px;
  font-family: ${({ theme }) => theme.fontFamily.ui};
`;

const MainSection = styled.div`
  min-width: 940px;
  align-self: stretch;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  padding: 0;
`;

const DomainLink = styled(SmallInput) <{
  width?: string;
  paddingRight?: string;
  position?: string;
}>`
  position: ${({ position }) => position};
  label {
    margin-bottom: 8px;
    gap: 0px;
    span {
      font-weight: 400;
      line-height: 1;
      margin-bottom: 10px;
    }
    > div {
      height: 40px;
      margin-top: -1px;
      width: ${({ width }) => (width ? width : 'fit-content')};
    }
  }
`;

const UnlockWrapper = styled.div`
  > div > button > div {
    > div:nth-child(2) {
      max-width: 27px;
    }
  }
`;

const ContentSettings = styled.div`
  align-self: stretch;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 96px;
`;

const SafetyLock = styled.div`
  width: auto;
  height: auto;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 8px;
  padding: 0;
  position: relative;
  > div:nth-child(2) {
    position: absolute;
    right: 13px;
    bottom: 21px;
  }
`;

const SaveCancel = styled.div`
  min-width: 119px;
  height: 32px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  padding: 0;
  > button {
    height: 32px;
  }
`;

const LoadingButtonWrapper = styled.div<{
  loading?: boolean;
}>`
  > div > button > div {
    // width: ${({ loading }) => (loading ? 'auto' : '46px')};
    > div:first-child {
      padding: ${({ loading }) => (loading ? '0px 8px' : '0px')};
    }
  }
`;

const WarningContainer = styled.div`
  min-height: 40px;
  margin-top: 8px;
  min-width: 100%;
  margin-right: 56px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 14px;
  padding: 7px 11px;
  border-radius: 3px;
  background-color: #fa934e;
`;

const InnerContent = styled.div`
  display: flex;
  align-items: center;
  justify: content;
  gap: 14px;
  > div {
    height: 26px;
    width: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      height: 19.5px;
      width: 19.5px;
      > g {
        stroke: #fff;
      }
    }
  }
  > span {
    flex-grow: 1;
    font-size: 14px;
    letter-spacing: -0.2px;
    text-align: left;
    color: #fff;
  }
`;

const Settings: React.FC = () => {
  // Check validation for webgate enabled or not
  const { isFeatureEnabled, setIsFeatureEnabled, setSwitchState }: ContextProps = useContext(FeatureEnabledContext);

  // State for managing the state of input field
  const [isUnlock, setIsUnlock] = useState(false);

  // State for IP Value received from the API.
  const [IPFromAPI, setIPFromAPI] = useState<string>('');

  // State for storing the IP Value entered by the user.
  const [IPValue, setIPValue] = useState<string>('');

  // Function used from the useNotification component to send notifications
  const { sendNotification } = useNotification();

  // To use the text transalation files (English - Japanese)
  const { t } = useTranslation(['Settings', 'Common']);

  // State for managing the state of warning dialog box
  const [Warning, setWarning] = useState<boolean>(true);

  // State for status fetching loading
  const [fetchingStatus, setFetchingStatus] = useState(true);

  // State for setting api loading
  const [loading, setLoading] = useState(false);

  // Function for updating the state of input field
  const handleclick = () => {
    setIsUnlock(true);
  };

  // Breadcrum data
  const breadCrumsData = [
    {
      name: t('Common:breadCrums.settings'),
    },
    {
      name: t('Common:breadCrums.networkRange'),
    },
  ];

  // API call for fetching the current IP and setting the warning state
  const fetchStatus = useCallback(async () => {
    try {
      setFetchingStatus(true);
      const links = await getLinksAllocatedStatus();
      if (links && typeof links.linksAllocated === 'boolean') {
        setWarning(links.linksAllocated);
      }
      if (links?.networkAddress) {
        setIPFromAPI(links.networkAddress);
        setIPValue(links.networkAddress);
      }
    } catch (error) {
      console.error('Error in getLinksAllocatedStatus: ', error);
    } finally {
      setFetchingStatus(false);
    }
  }, []);

  useEffect(() => {
    fetchStatus();
  }, [fetchStatus]);

  const updateWarning = useCallback(() => {
    if (!isFeatureEnabled) setWarning(false);
  }, [isFeatureEnabled]);
    
  useEffect(() => {
    updateWarning();
  }, [updateWarning]);

  const setNetworkIPRange = useCallback(async () => {
    // Function to validate a subnet in CIDR format.
    const isValidSubnet = (subnet: string): boolean => {
      const subnetRegex = /^(10\.0\.0\.0\/8)$|^(10\.(?:[0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.0\.0\/16)$|^(10\.(?:[0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.(?:[0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.0\/24)$|^(172\.(?:1[6-9]|2[0-9]|3[0-1])\.0\.0\/16)$|^(172\.(?:1[6-9]|2[0-9]|3[0-1])\.(?:[0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.0\/24)$|^(192\.(?:[0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.0\.0\/16)$|^(192\.(?:[0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.(?:[0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.0\/24)$/;
      return subnetRegex.test(subnet);
    };

    // Validate if the change has been made.
    if (IPValue === IPFromAPI) {
      handleDismiss(); // Clear all notification bar before sending a new one
      sendNotification({
        type: 'info',
        message: t('Settings:notificationMessage.noChangeMade'),
      });
      return;
    }

    // Validate the subnet using the provided isValidSubnet function
    if (!IPValue || (IPValue && !isValidSubnet(IPValue))) {
      handleDismiss(); // Clear all notification bar before sending a new one
      sendNotification({
        type: 'info',
        message: t('Settings:notificationMessage.invalidIPAddressFormat'),
      });
      return;
    }

    try {
      setLoading(true);

      setSwitchState('locked');

      const enabled = await isWebgateEnabled();
      // if API returns a response then set the featue enabled state
      if (enabled !== null) setIsFeatureEnabled(enabled);
      // if the feature is disaled then exit from the loop
      if (enabled === false || !enabled) {
        handleDismiss(); // Clear all notification bar before sending a new one
        sendNotification({
          type: 'warning',
          message: t('Common:notificationMessage.webgateFeatureDisabled'),
        });
        return;
      }

      const result = await webgateNetworkAddressUpdate(IPValue);
      handleDismiss(); // Clear all notification bar before sending a new one
      if (result) {
        setIsUnlock(false);
        setIPFromAPI(IPValue);
        sendNotification({
          type: 'success',
          message: t('Settings:notificationMessage.updateSuccess'),
        });
      } else {
        sendNotification({
          type: 'error',
          message: t('Settings:notificationMessage.updateFailure'),
        });
      }
    } catch (error) {
      console.error('Failed to update network address:', error);
      const exception = handleException(error);
      handleDismiss(); // Clear all notification bar before sending a new one
      sendNotification({
        type: 'error',
        message: t(`Common:exceptionNotificationMessages.${exception.message}`),
      });
    } finally {
      setLoading(false);
      setSwitchState('default');
    }
  }, [
    IPValue,
    sendNotification,
    t,
    setIsFeatureEnabled,
    setSwitchState,
    IPFromAPI,
  ]);

  return (
    <MainContainer>
      <Wrapper enableContent={isFeatureEnabled}>
        <SectionContainer height='72px' gap='8px' padding='48px 96px 0'>
          <Drawer>
            <Breadcrum>
              {breadCrumsData.map((data, index) => {
                return (
                  <BreadCrumLink key={index}>
                    <LinkContainer>{data.name}</LinkContainer>
                    {index < breadCrumsData.length - 1 ? (
                      <IconWrapper marginTop='-4px' height='12px'>
                        <Icon icon='Right' color='dimmed' size={4} />
                      </IconWrapper>
                    ) : (
                      ''
                    )}
                  </BreadCrumLink>
                );
              })}
            </Breadcrum>
          </Drawer>
        </SectionContainer>
        <MainSection>
          <HeaderSection>
            <InnerHeader>
              <DeviceLeftContainer>
                <DeviceInfo>
                  <PageHeader
                    icon={`${fetchingStatus ? '' : 'ViewSettings'}`}
                    areaTitle={t('Settings:pageAreaTitle')}
                    title={t('Settings:pageTitle')}
                  />
                  {fetchingStatus && (
                    <SpinnerContainer>
                      <Spinner size='medium' styling='primary' />
                    </SpinnerContainer>
                  )}
                </DeviceInfo>
                <Optionals>
                  <PageHeaderText>
                    {t('Settings:introductionText')}
                  </PageHeaderText>
                </Optionals>
              </DeviceLeftContainer>
            </InnerHeader>
          </HeaderSection>
          <ContentSettings>
            <SafetyLock>
              <DomainLink
                width='210px'
                label={t('Settings:textFieldHeading')}
                id='subnet_range'
                name='subnet_range'
                placeholder='127.0.0.1/16'
                autoComplete='off'
                paddingRight='0px'
                onChange={e => setIPValue(e.target.value)}
                value={IPValue}
                fieldState={isUnlock || loading ? 'default' : 'disabled'}
                disabled={!isUnlock || loading}
              />
              {Warning && <Icon icon='Locked' size={16} />}
            </SafetyLock>
            {Warning ? (
              !fetchingStatus && (
                <WarningContainer>
                  <InnerContent>
                    <Icon icon='BigWarning' size={14} />
                    <span>{t('Settings:warningText')}</span>
                  </InnerContent>
                </WarningContainer>
              )
            ) : !isUnlock ? (
              <UnlockWrapper>
                <ButtonWithIcon
                  icon='Unlocked'
                  design='secondary'
                  size='normal'
                  position='left'
                  onClick={handleclick}
                  disabled={loading}
                >
                  {t('Settings:buttonText1')}
                </ButtonWithIcon>
              </UnlockWrapper>
            ) : (
              <SaveCancel>
                <Button
                  design='secondary'
                  size='normal'
                  onClick={() => {
                    setIsUnlock(false);
                    setIPValue(IPFromAPI);
                  }}
                  disabled={loading}
                >
                  {t('Settings:buttonText2')}
                </Button>
                <LoadingButtonWrapper loading={loading}>
                  <ButtonWithLoading
                    loading={loading}
                    size='normal'
                    onClick={setNetworkIPRange}
                    disabled={loading}
                  >
                    {t('Settings:buttonText3')}
                  </ButtonWithLoading>
                </LoadingButtonWrapper>
              </SaveCancel>
            )}
          </ContentSettings>
        </MainSection>
      </Wrapper>
    </MainContainer>
  );
};

export default Settings;
