import { INotificationProps } from "scorer-ui-kit";

// Convert B to KiB
export const convertBytesToKiB = (bytes: number): number => {
  const KiB = bytes / 1024;
  return parseFloat(KiB.toFixed(2));
};

// Convert B to MiB
export const convertBytesToMiB = (bytes: number): number => {
  const MiB = bytes / (1024 * 1024);
  return parseFloat(MiB.toFixed(2));
};

// Convert B to GiB
export const convertBytesToGiB = (bytes: number): number => {
  const GiB = bytes / (1024 * 1024 * 1024);
  return parseFloat(GiB.toFixed(2));
};

// Convert B to TiB
export const convertBytesToTiB = (bytes: number): number => {
  const GiB = bytes / (1024 * 1024 * 1024 * 1024);
  return parseFloat(GiB.toFixed(2));
};

// Convert B to PiB
export const convertBytesToPiB = (bytes: number): number => {
  const GiB = bytes / (1024 * 1024 * 1024 * 1024 * 1024);
  return parseFloat(GiB.toFixed(2));
};

// Convert based upon size and get value
export const convertValue = (sizeInBytes: number): number => {
  if (sizeInBytes < 1024) {
    return parseFloat(sizeInBytes.toFixed(2)); // B
  } else if (sizeInBytes < 1024 * 1024) {
    return parseFloat((sizeInBytes / 1024).toFixed(2)); // KiB
  } else if (sizeInBytes < 1024 * 1024 * 1024) {
    return parseFloat((sizeInBytes / (1024 * 1024)).toFixed(2)); // MiB
  } else if (sizeInBytes < 1024 * 1024 * 1024 * 1024) {
    return parseFloat((sizeInBytes / (1024 * 1024 * 1024)).toFixed(2)); // GiB
  } else if (sizeInBytes < 1024 * 1024 * 1024 * 1024 * 1024) {
    return parseFloat((sizeInBytes / (1024 * 1024 * 1024 * 1024 * 1024)).toFixed(2)); // TiB
  } else {
    return parseFloat((sizeInBytes / (1024 * 1024 * 1024 * 1024)).toFixed(2)); // PiB
  }
};

// Convert based upon size and get unit
export const getUnit = (sizeInBytes: number): string => {
  if (sizeInBytes < 1024) {
    return 'B';
  } else if (sizeInBytes < 1024 * 1024) {
    return 'KiB';
  } else if (sizeInBytes < 1024 * 1024 * 1024) {
    return 'MiB';
  } else if (sizeInBytes < 1024 * 1024 * 1024 * 1024) {
    return 'GiB';
  } else if (sizeInBytes < 1024 * 1024 * 1024 * 1024 * 1024) {
    return 'TiB';
  } else {
    return 'PiB';
  }
};


// Utility function to convert and validate fullwidth digits
export const convertAndValidateInput = (
  input: string,
  notificationMessage: string,
  onDismiss: () => void,
  sendNotification: (notification: INotificationProps) => void,
  t: (key: string) => string
): string | null => {
  const updatedValue = Number(input.replace(/[０-９]/g, (match) => {
    // Convert fullwidth digit to its corresponding regular digit
    return String.fromCharCode(match.charCodeAt(0) - 0xFF10 + 0x30);
  }));

  if (Number.isNaN(updatedValue) || updatedValue === null) {
    onDismiss(); // Clear all notification bars before sending a new one
    sendNotification({
      type: 'info', // Ensure this matches the 'AlertType'
      message: t(notificationMessage),
    });
    return null;
  }

  return updatedValue.toString();
};