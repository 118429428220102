import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { Icon as SelectIcon } from 'scorer-ui-kit';
import { useTranslation } from 'react-i18next';
import { Subtract } from '../../svg';

const LinkContainer = styled.a<{ paddingBotton?: string }>`
  height: 20px;
  font-size: 12px !important;
  font-weight: 500;
  line-height: 1.79;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 0px;
  &:hover {
    text-decoration: none !important;
  }
`;

const TitleContainer = styled.div`
  padding: 3px 8px 3px 4px;
  font-size: 12px;
  font-weight: 600;
  text-wrap: nowrap;
  text-align: center;
  font-family: Monorale;
  line-height: normal;
`;

const TableColumnLink = styled.div<{
  expanded?: boolean;
  zIndex?: string;
  border?: string;
  shadow?: string;
  background?: string;
  borderRadius?: string;
  width?: string;
  gap?: string;
  paddingLeft?: string;
}>`
  padding-left: ${({ paddingLeft }) => paddingLeft};
  gap: ${({ gap }) => (gap ? gap : '0px')};
  max-width: max-content !important;
  min-width: 100%;
  height: 24px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border: ${({ border }) => (border ? border : 'none')};
  box-shadow: ${({ shadow }) => (shadow ? shadow : 'none')};
  background-color: ${({ background }) => background ? background : ''};
  padding: 0px;
  border-radius: ${({ expanded, borderRadius }) => expanded ? borderRadius : '3px'};
  z-index: ${({ zIndex }) => (zIndex ? zIndex : 0)};
`;

const DropDownOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px; 
`;

const ButtonText = styled.span<{
  padding?: string;
}>`
  color: 'hsl(0, 0%, 100%)';
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  font-family: Monorale;
  height: 20px;
  padding: ${({ padding }) => (padding ? padding : '')};
  display: flex;
  align-items: center;
`;

const TableDropDown = styled.div<{ width?: string }>`
  position: absolute;
  width: ${({ width }) => (width ? width : 'auto')};
  height: 24px;
  display: flex;
  flex-direction: column;
  border-radius: 3px !important;
  left: -1px;
  cursor: pointer;
`;

const IconWrapper = styled.div<{
  marginTop?: string;
  width?: string;
  borderLeft?: string;
  padding?: string;
}>`
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '4px')};
  margin-bottom: 0px;
  width: ${({ width }) => (width ? width : '16px')};
  padding: ${({ padding }) => (padding ? padding : '0px')};
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  font-size: 16px !important;
  border-left: ${({ borderLeft }) => (borderLeft ? borderLeft : 'none')};
  
  > div{
    height: 100%;
    display: flex;
    align-items: center; 
  }
`;

interface IDropDownProps extends React.InputHTMLAttributes<HTMLButtonElement> {
  id: string;
  dropdownRef: React.RefObject<HTMLDivElement>;
  name: string;
  title: string;
  linkType: 'Edge UI' | 'Solution UI';
  link?: string;
  expanded: boolean;
  titleIcon?: string;
  hideArrow?: boolean;
  enabled: boolean;
  status: string | null;
  progressCheck?: boolean;
  toggleEnable?: (
    toggleId: string,
    linkType: 'Edge UI' | 'Solution UI',
    deviceName: string
  ) => void;
  toggleDisable?: (
    toggleId: string,
    linkType: 'Edge UI' | 'Solution UI',
    deviceName: string
  ) => void;
  toggleEdit?: (
    deviceId: string,
    linkType: 'Edge UI' | 'Solution UI',
  ) => void;
  toggleExpanded?: (
    toggleId: string,
    linkType: string,
    expanded: boolean
  ) => void;
}

const DropDown = forwardRef<HTMLDivElement, IDropDownProps>(
  (
    {
      id,
      name,
      title,
      linkType,
      expanded,
      link,
      titleIcon,
      enabled,
      status,
      toggleEnable,
      toggleDisable,
      toggleEdit,
      toggleExpanded,
      hideArrow,
      progressCheck = false
    },
    dropdownRef
  ) => {
    const { t } = useTranslation(['Webgateway']);

    const getArrow = (
      id: string,
      linkType: 'Edge UI' | 'Solution UI',
      expanded = false,
      hideArrow = false,
      toggleExpandedFun?: (id: string, linkType: 'Edge UI' | 'Solution UI', expanded: boolean) => void
    ) => {
      if (!hideArrow) {
        return (
          <IconWrapper
            marginTop='0px'
            borderLeft='1px solid rgb(217, 218, 217)'
            width='27px'
            onClick={() => {
              if (!progressCheck && toggleExpandedFun) toggleExpandedFun(id, linkType, expanded)
            }}
            ref={dropdownRef}
          >
            {expanded ? (
              <SelectIcon size={8} color='dimmed' icon='Up' />
            ) : (
              <SelectIcon size={8} color='dimmed' icon='Down' />
            )}
          </IconWrapper>
        );
      } else {
        return null;
      }
    };
    return (
      <TableDropDown>
        {enabled && link !== '' && (
          <TableColumnLink
            expanded={expanded}
            zIndex='1'
            border='1px solid #E8E8EC'
            shadow='0 2px 8px 0 rgba(0, 16, 64, 0.06)'
            background='#f5f9ff'
            borderRadius='3px 3px 0px 0px'
          >
            <LinkContainer href={link} target='_blank' rel='noopener noreferrer' onClick={() => toggleExpanded?.(id, linkType, true)}>
              {titleIcon && (
                <IconWrapper marginTop='0px' width='23px' padding='3px 0px 3px 3px'>
                  <SelectIcon size={12} color='dimmed' icon={titleIcon} />
                </IconWrapper>
              )}
              <TitleContainer>
                {link === '#' ? '-' : title}
              </TitleContainer>
            </LinkContainer>
            {getArrow(id, linkType, expanded, hideArrow, toggleExpanded)}
          </TableColumnLink>
        )}
        {!enabled && (
          <TableColumnLink
            onClick={() => {
              if (!progressCheck) toggleEnable && toggleEnable(id, linkType, name)
            }}
            zIndex='1'
            gap='10px'
            paddingLeft='2px !important'
            width='max-content'
          >
            <IconWrapper marginTop='0px' width='23px' padding='3px 0px 3px 3px'>
              <SelectIcon icon='Add' size={16} color='dimmed' />
            </IconWrapper>
            <ButtonText>{t('Webgateway:tableHeaders.enable')}</ButtonText>
          </TableColumnLink>
        )}
        {enabled && status === '-' && (
          <TableColumnLink
            onClick={() => toggleEnable && toggleEnable(id, linkType, name)}
            zIndex='1'
            gap='10px'
            paddingLeft='3px'
            width='max-content'
          >
            <IconWrapper marginTop='0px' width='23px' padding='3px 0px 3px 3px'>
              <SelectIcon icon='Add' size={16} color='dimmed' />
            </IconWrapper>
            <ButtonText >{t('Webgateway:tableHeaders.enable')}</ButtonText>
          </TableColumnLink>
        )}

        {expanded && enabled && (
          <DropDownOptions ref={dropdownRef}>
            <TableColumnLink
              onClick={() => toggleDisable && toggleDisable(id, linkType, name)}
              expanded={expanded}
              zIndex='2'
              border='1px solid #E8E8EC'
              background='#f5f9ff'
              borderRadius='0px 0px 3px 3px'
            >
              <IconWrapper marginTop='0px' width='23px'>
                <Subtract />
              </IconWrapper>
              <ButtonText padding='3px 8px 3px 4px'>{t('Webgateway:tableHeaders.disable')}</ButtonText>
            </TableColumnLink>
            <TableColumnLink
              onClick={() => toggleEdit && toggleEdit(id, linkType)}
              expanded={expanded}
              zIndex='2'
              border='1px solid #E8E8EC'
              background='#f5f9ff'
              borderRadius='0px 0px 3px 3px'
            >
              <IconWrapper marginTop='0px' width='23px'>
                <SelectIcon icon='Edit' size={12} color='dimmed' />
              </IconWrapper>
              <ButtonText padding='3px 8px 3px 4px'>{t('Webgateway:tableHeaders.edit')}</ButtonText>
            </TableColumnLink>
          </DropDownOptions>
        )}
      </TableDropDown>
    );
  })

export default DropDown;