import React from 'react';
import styled, { css } from 'styled-components';
import { Icon } from 'scorer-ui-kit';

interface ITooltipProps {
  icon?: string;
  infoIcon?: string;
  infoText: string;
  infoText2?: string;
  infoText3?: string;
  infoText4?: string;
  infoColor?: string;
  iconMargin?: string;
  tooltipPosition?: 'left' | 'center' | 'right';
  minWidth: string;
  tooltipTop?: string;
  textBackground?: string;
  textGap?: string;
}

const IconWrapper = styled.div`
  > div:first-child {
    display: flex;
  }
`;

const TooltipContainer = styled.div<{
  tooltipPosition: 'left' | 'center' | 'right';
  size?: string;
  minWidth?: string;
  tooltipTop?: string;
  color?: string;
  gap?: string;
}>`
  min-width: ${({ minWidth }) => (minWidth ? minWidth : 'auto')};
  visibility: hidden;
  padding: 12px 16px;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.23;
  font-family: Monorale;
  color: #ffffffec;
  box-shadow: 0 2px 3px 0 rgba(0, 16, 64, 0.06);
  border: solid 1px rgba(0, 6, 46, 0.2);
  background-color: ${({ color }) => (color ? color : '#b9bbc6')};
  border-radius: 4px;
  position: absolute;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${({ gap }) => (gap ? gap : '16px')};
  top: ${({ tooltipTop }) => (tooltipTop ? tooltipTop : 'calc(50% + 17px)')};
  > div{
    svg {
      path {
        stroke: #ffffffec;
      }
      g{
        path {
          stroke: #ffffffec;
        }
        circle {
          stroke: #ffffffec;
        }
      }
    }
  }

  ${({ tooltipPosition }) => {
    switch (tooltipPosition) {
      case 'left':
        return css`
        left: calc(100% + 10px);                 
        transform: translateX(-99%);
        `;
      case 'center':
        return css`
        left: 50%;
        transform: translateX(-50%);
        `;
      case 'right':
        return css`
        right: calc(100% + 10px);
        transform: translateX(99%);
        `;
      default:
        return '';
    }
  }}

  > div {
    height: ${({ size }) => (size && size)};
  }

&::after {
    content: '';
    background-color: ${({ color }) => (color ? color : '#b9bbc6')};
    position: absolute;
    height: 8px;
    width: 16px;
    top: -8px;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);

    ${({ tooltipPosition }) => {
    switch (tooltipPosition) {
      case 'left':
        return css`
            right: 10px;
          `;
      case 'center':
        return css``;
      case 'right':
        return css`
            left: 10px;
          `;
      default:
        return '';
    }
  }}
  }
`;


const TooltipWrapper = styled.div<{
  infoColor?: string;
  iconMargin?: string;
}>`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: ${({ iconMargin }) => (iconMargin ? iconMargin : '0px 0px 0px 8px')};

  > div:first-child {
    svg {
      g {
        path {
          stroke: ${({ infoColor }) => (infoColor ? infoColor : '#889096')};
        }
        circle {
          stroke: ${({ infoColor }) => (infoColor ? infoColor : '#889096')};
        }  
      }
    }
  }

  &:hover ${TooltipContainer} {
    visibility: visible;
    opacity: 1;
  }
`;

const StatusColor = styled.div<{
  width?: string;
  height?: string;
  color?: string;
}>`
  width: ${({ width }) => (width ? width : '4px')};
  height: ${({ height }) => (height ? height : '12px')};
  flex-grow: 0;
  border-radius: 3px;
  background-color: ${({ color }) => (color ? color : '#5bb98c')};
`;

const InfoTooltip: React.FC<ITooltipProps> = ({
  icon = 'Information',
  infoIcon = 'Information',
  infoText,
  infoText2,
  infoText3,
  infoText4,
  textBackground,
  infoColor,
  iconMargin,
  textGap,
  tooltipPosition = 'center',
  minWidth,
  tooltipTop,
}) => {
  return (
    <TooltipWrapper infoColor={infoColor} iconMargin={iconMargin}>
      <IconWrapper>
        {icon === 'square' ? (
          <StatusColor width='12px' color={infoColor} />
        ) : (
          <Icon size={12} icon='Information' weight='regular' />
        )}
      </IconWrapper>
      <TooltipContainer size='16px' tooltipPosition={tooltipPosition} minWidth={minWidth} tooltipTop={tooltipTop} color={textBackground} gap={textGap}>
        <Icon size={16} icon={infoIcon} weight='regular' />
        {infoText && (
          <>
            {infoText}
            {(infoText2 || infoText3 || infoText4) && <br />}
          </>
        )}
        {infoText2 && (
          <>
            {infoText2}
            {(infoText3 || infoText4) && <br />}
          </>
        )}
        {infoText3 && (
          <>
            {infoText3}
            {infoText4 && <br />}
          </>
        )}
        {infoText4 && infoText4}
      </TooltipContainer>
    </TooltipWrapper>
  );
};

export default InfoTooltip;
