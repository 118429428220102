import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'scorer-ui-kit';
import styled from 'styled-components';
import i18n from '../../i18n';

const CardModal = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  overflow: auto;
  padding-right: 10px;
`;

const CardTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
  font-family: ${({ theme }) => theme.fontFamily.ui};
  color: hsl(210, 6%, 47%);
  margin-bottom: 10px;
`;

const ModelContent = styled.div`
  margin: 4px 6px 0 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.79;
`;

const ConfirmationContent = styled.div<{confirmationMessage2?: string}>`
  margin: ${({ confirmationMessage2 }) => (confirmationMessage2 ? '4px 0px 0px' : '4px 6px 40px 0px')};
  font-size: 14px;
  font-weight: 500;
  line-height: 1.79;
  color: #7c7e7f;
  white-space: pre-wrap;
  display: flex;
  overflow-wrap: anywhere;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 6px;
`;

const Devices = styled.span<{ minWidth?: string; }>`
  min-width: ${({ minWidth }) => minWidth};
`;

interface ConfirmModalProps {
  title: string;
  content?: string;
  confirmationMessage: string;
  confirmationMessage2?: string;
  fromHomePageDone?: boolean;
  fromDeviceLinksDone?: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  confirmButtonText: string;
  cancelButtonText: string;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  title,
  content,
  confirmationMessage,
  confirmationMessage2,
  fromHomePageDone = false,
  fromDeviceLinksDone = false,
  onCancel,
  onConfirm,
  confirmButtonText,
  cancelButtonText,
}) => {
  // for switching the language between japanese and english.
    const { t } = useTranslation(['Webgateway', 'Common']);

  return (
    <CardModal>
      <CardTitle>{title}</CardTitle>
      {content && <ModelContent>{content}</ModelContent>}
      {fromHomePageDone 
      ? 
      <ConfirmationContent confirmationMessage2={confirmationMessage2}>
        <Devices minWidth={i18n.language === 'ja' ? '73px' : '58px'}>{t('Webgateway:confirmationPopup.devicesNames')}</Devices>
        <Devices>{confirmationMessage}</Devices>
      </ConfirmationContent> 
      :  
      <ConfirmationContent confirmationMessage2={confirmationMessage2}>{confirmationMessage}</ConfirmationContent>}
      {fromDeviceLinksDone 
      ?
      <ConfirmationContent confirmationMessage2={confirmationMessage2}>
        <Devices minWidth={i18n.language === 'ja' ? '60px' : '41px'}>{t('DeviceLinks:confirmationPopup.linkNames')}</Devices>
        <Devices>{confirmationMessage2}</Devices>
      </ConfirmationContent> 
      :
       confirmationMessage2 && <ConfirmationContent>{confirmationMessage2}</ConfirmationContent>}
      <ButtonContainer>
        <Button design='secondary' size='normal' onClick={onCancel}>
          {cancelButtonText}
        </Button>
        <Button design='primary' size='normal' onClick={onConfirm}>
          {confirmButtonText}
        </Button>
      </ButtonContainer>
    </CardModal>
  );
};

export default ConfirmModal;