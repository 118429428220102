import React, { useState, useCallback } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { BasicSearchInput, FilterButton } from 'scorer-ui-kit';

const fadeInAnimation = keyframes`
  0% {
    width: 50%;
    opacity: .5;
  }
  50% {
    opacity: 1;
  }
  100% {
    width: 100%;
  }
`;

const SearchInputWrapper = styled.div`
  flex: 1 1 200px;
  max-width: 320px;
  > div > div {
    border: solid 1px rgba(0, 9, 50, 0.12) !important;
    &:hover {
      border: solid 1px rgba(0, 9, 50, 0.12) !important;
    }
    height:32px !important;
    button {
      height: 30px;
      > div {
        margin: 0 !important;
      }
    } 
  }
`;

const CloseSearchInputWrapper = styled.div`
  ${({ theme }) =>
    theme &&
    css`
      animation: ${fadeInAnimation} ${theme.animation.speed.slow}
        ${theme.animation.easing.primary.inOut};
    `};
`;

const StyledFilterButton = styled(({ className, ...props }) => (
  <FilterButton className={className} {...props} />
))`
  padding: 0px;
  &:hover {
    color: #808080 !important;
    border: solid 1px rgba(0, 29, 95, 0.16) !important;
  }
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    min-width: 65px;
    margin: 0 !important;
    > div:first-child{
      margin-left: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      >div{
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    > div:last-child{
      padding-right: 10px !important;
      line-height: 14px;
    }
  }
`;

const StyledBasicSearchInput = styled(({ className, ...props }) => (
  <BasicSearchInput inputMode='numeric' className={className} {...props} />
))``;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${SearchInputWrapper}, ${StyledFilterButton} {
    box-shadow: 0 2px 1px 0 rgba(0, 102, 255, 0.04);
    animation: none;
    border: solid 1px rgba(0, 29, 95, 0.16);
    height: 32px;
    background-color: #FFFFFF !important;
    > div > div > div {
      svg {
        g {
          stroke: rgb(128, 131, 141) !important;
          stroke-width: 1.5;
        }
      }
    }
    
    &:hover {
      background-color: none !important;
    }
  }
`;

export interface IFilterInputs {
  iconSize?: number;
  icon?: string;
  paddingTop?: string;
  type?: string;
  placeholder?: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onCrossClick?: () => void;
  canHide?: boolean;
  showFieldText?: string;
}

const InputFilter: React.FC<IFilterInputs> = ({
  iconSize = 12,
  icon = 'Add',
  paddingTop = '0px',
  type = 'text',
  placeholder,
  value,
  onChange,
  onCrossClick,
  canHide = true,
  showFieldText = 'Filter',
}) => {
  const [isVisible, setIsVisible] = useState(!canHide);

  const handleVisibilityToggle = useCallback(() => {
    setIsVisible(!isVisible);
  }, [isVisible]);

  // Handle resetting the visibility on cross button click
  const handleClearInput = useCallback(() => {
    if (onCrossClick) onCrossClick();
    setIsVisible(false);
  }, [onCrossClick]);

  return (
    <Container>
      {isVisible ? (
        <SearchInputWrapper>
          <CloseSearchInputWrapper>
            <StyledBasicSearchInput
              id='search2'
              icon='Add'
              placeholder={placeholder}
              value={value}
              type={type}
              onChange={onChange}
              hasCrossButton={!!onCrossClick}
              onCrossClick={handleClearInput}
              style={{ paddingTop }}
              autoComplete='off'
            />
          </CloseSearchInputWrapper>
        </SearchInputWrapper>
      ) : (
        <StyledFilterButton
          icon={icon}
          iconSize={iconSize}
          onClick={handleVisibilityToggle}
        >
          {showFieldText}
        </StyledFilterButton>
      )}
    </Container>
  );
};

export default InputFilter;
