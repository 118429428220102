import React, { useContext, useState, useRef, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { useWindowSize } from '../hooks/useWindowSize';
import { useHistory, useParams } from 'react-router-dom';
import { FeatureEnabledContext, ContextProps } from '../App';
import { useTranslation } from 'react-i18next';
import { Button, ButtonWithIcon, Content, PageHeader, MultilineContent, TypeTable, DatePicker, FilterDropdownContainer, useClickOutside, DateInterval, Spinner } from 'scorer-ui-kit';
import { ITypeTableData, ITableColumnConfig, IRowData } from 'scorer-ui-kit/dist/Tables';
import 'react-circular-progressbar/dist/styles.css';
import { Doughnut, Line } from 'react-chartjs-2';
import { ChartData, ChartOptions } from 'chart.js';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import { getDataUsage, getTopClient, getWeeklyUsageStats } from '../lib/api_config';
import useCheckDevice from '../hooks/useCheckDevice';
import Breadcrums from '../components/molecules/Breadcrums';
import { convertToEpoch, formatDate, getSystemTimezoneAbbreviation } from '../Utils/DateUtils';
import { convertBytesToGiB, convertBytesToKiB, convertBytesToMiB, convertBytesToPiB, convertBytesToTiB, convertValue, getUnit } from '../Utils/ConversionUtils';
import { useLinkData } from '../hooks/useLinkData';
import { ErrorType, handleException } from '../hooks/handleException';
import { useNotification } from '../components/Notifications/useNotification';
import { handleDismiss } from '../components/Notifications/Notification';
import InfoTooltip from '../components/atoms/InfoTooltip';



ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ArcElement);

const ContentContainer = styled(Content)`
  padding: 0px;
  max-width: calc(100vw - 80px);
  > div {
    max-width: 100%
  }
`;

const Wrapper = styled.div<{ enableContent?: boolean }>`
  width: 100%;
  opacity: ${({ enableContent }) => (enableContent ? '1' : '0.6')};
  pointer-events: ${({ enableContent }) => (enableContent ? 'auto' : 'none')};
`;

const DrawerContainer = styled.div`
  padding: 48px 99px 0px;

  // Media queries for screen viewport above 1536px.
  @media (min-width: 1536px){
    padding: 48px 117px 0px 123px;
  }
`;

const Drawer = styled.div`
  min-width: 850px;
  height: 24px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  color: #898e96;
`;

const ColumnText = styled.p<{
  height?: string;
  width?: string;
  padding?: string;
  fontSize?: string;
  color?: string;
  fontWeight?: string;
  letter?: string;
  line?: number;
  margin?: string;
  family?: string;
}>`
  height: ${({ height }) => (height ? height : 'auto')};
  width: ${({ width }) => (width ? width : 'auto')};
  padding: ${({ padding }) => (padding ? padding : '0px')};
  font-family: ${({ family }) => (family ? family : 'monorale')};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '14px')};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ color }) => (color ? color : '#898e96')};
  margin: ${({ margin }) => (margin ? margin : '0px')};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : '500')};
  letter-spacing: ${({ letter }) => letter};
  line-height: ${({ line }) => line};
`;

const HeaderSection = styled.div`
  padding: 25px 96px 38px;

  // Media queries for screen viewport above 1536px.
  @media (min-width: 1536px){
    padding: 24px 120px 39px;
  }
`;

const InnerHeader = styled.div<{ width?: number }>`
  width: 100%;
  min-width: 400px;
  height: auto;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 16px 0px 5px 0;
`;

const DeviceLeftContainer = styled.div`
  height: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  padding: 0 32px 6px 0;
`;

const DeviceInfo = styled.div<{dataLoading?: boolean}>`
  height: auto;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;

  > div > div > div > div:nth-child(1) {
    ${({ dataLoading }) =>
    dataLoading &&
      `
      overflow-wrap: anywhere;
      gap: 1px;
      > div {
        line-height: 21px; 
      }
      > h1 {
        line-height: 30px; 
      }
      `
}
  }

  > div > div > div > div:nth-child(2) {
    ${({ dataLoading }) =>
    !dataLoading &&
      `
      overflow-wrap: anywhere;
      gap: 1px;
      > div {
        line-height: 21px; 
      }
      > h1 {
        line-height: 30px; 
      }
      `
}
  }
`;

const SpinnerContainer = styled.div`
  position: absolute;
  left: -45px;
  bottom: 20%;
`;

const DataNumber = styled.div<{ line?: string }>`
  width: auto;
  height: auto;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 4px;
  padding: 0;
  line-height: ${({ line }) => line};
`;

const LineDivider = styled.div`
  width: 100%;
  height: 4px;
  border-top: 1px solid #e6e8ec;
`;

const BoxContainer = styled.div<{
  minWidth?: string;
  width?: string;
  height?: string;
  grow?: number;
  direction?: string;
  alignItems?: string;
  justify?: string;
  gap?: string;
  padding?: string;
  margin?: string;
  borderLeft?: string;
  borderBottom?: string;
  background?: string;
  scrollWidth?: string;
  maxWidth?: string;
}>`
  height: ${({ height }) => (height ? height : 'auto')};
  width: ${({ width }) => (width ? width : 'auto')};
  min-width: ${({ minWidth }) => minWidth};
  max-width: ${({ maxWidth }) => maxWidth};
  flex-grow: ${({ grow }) => (grow ? grow : 0)};
  display: flex;
  align-self: stretch;
  flex-direction: ${({ direction }) => (direction ? direction : 'column')};
  align-items: ${({ alignItems }) => (alignItems ? alignItems : 'flex-start')};
  justify-content: ${({ justify }) => (justify ? justify : 'flex-start')};
  gap: ${({ gap }) => (gap ? gap : '0px')};
  padding: ${({ padding }) => (padding ? padding : '0px')};
  margin: ${({ margin }) => (margin ? margin : '0px')};
  border-left: ${({ borderLeft }) => borderLeft};
  border-bottom: ${({ borderBottom }) => borderBottom};
  background-color: ${({ background }) => background};
  scrollbar-width: ${({ scrollWidth }) => scrollWidth};
`;

const CardContainer = styled.div<{
  minWidth?: string;
  width?: string;
  height?: string;
  grow?: number;
  direction?: string;
  alignItems?: string;
  justify?: string;
  gap?: string;
  padding?: string;
  margin?: string;
  border?: string;
  background?: string;
  radius?: string;
  shadow?: string;
}>`
  height: ${({ height }) => (height ? height : 'auto')};
  min-width: ${({ minWidth }) => minWidth};
  width: ${({ width }) => (width ? width : 'auto')};
  flex-grow: ${({ grow }) => (grow ? grow : 0)};
  display: flex;
  align-self: stretch;
  flex-direction: ${({ direction }) => (direction ? direction : 'column')};
  align-items: ${({ alignItems }) => (alignItems ? alignItems : 'flex-start')};
  justify-content: ${({ justify }) => (justify ? justify : 'flex-start')};
  gap: ${({ gap }) => (gap ? gap : '0px')};
  padding: ${({ padding }) => (padding ? padding : '0px')};
  margin: ${({ margin }) => (margin ? margin : '0px')};
  border: ${({ border }) => (border ? border : 'solid 1px #e6e8ec')};
  border-radius: ${({ radius }) => (radius ? radius : '4px')};
  background-color: ${({ background }) => background ? background : '#fcfcfd'};
  box-shadow: ${({ shadow }) => shadow ? shadow : '0 1px 6px 0 rgba(0, 121, 255, 0.14)'};
`;

const ShowStats = styled.div<{
  maxWidth?: string;
  width?: string;
  border?: string;
  opacity?: string;
  position?: string;
  direction?: string;
  alignItems?: string;
  justify?: string;
  gap?: string;
  padding?: string;
  grow?: number;
}>`
  width: ${({ width }) => (width ? width : 'auto')};
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : 'auto')};
  height: auto;
  flex-grow: ${({ grow }) => (grow ? grow : 1)};
  display: flex;
  flex-direction: ${({ direction }) => (direction ? direction : 'column')};
  align-items: ${({ alignItems }) => (alignItems ? alignItems : 'flex-start')};
  justify-content: ${({ justify }) => (justify ? justify : 'flex-start')};
  gap: ${({ gap }) => (gap ? gap : '8px')};
  padding: ${({ padding }) => (padding ? padding : '0 0 0 12px')};
  border-left: ${({ border }) => border};
  position: ${({ position }) => position};
  opacity: ${({ opacity }) => (opacity ? opacity : 1)};
`;

const HalfBorder = styled.div`
  content: '';
  position: absolute;
  width: 4px;
  height: 20px;
  left: -4px;
  background-color: #92ceac;
  top: 50%;
`;

const BillCycleContainer = styled.div`
  height: auto;
  width: auto;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  padding: 0;
  opacity: 0.8;
  margin-left: -1px;
  margin-top: -1px;
  box-sizing: border-box;
`;

const ButtonGroup = styled.div`
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    height: 30px;
    font-size: 12px;
  }
`;

const ButtonIconContainer = styled.div``;

const DateDropdown = styled.div`
  position: relative;
`;

const UsageContainer = styled.div<{
  direction?: string;
  padding?: string;
  justify?: string;
  gap?: string;
  lineHeight?: string;
  alignItems?: string;
}>`
  height: auto;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: ${({ direction }) => (direction ? direction : 'row')};
  justify-content: ${({ justify }) => (justify ? justify : 'flex-start')};
  align-items: ${({ alignItems }) => (alignItems ? alignItems : 'center')};
  gap: ${({ direction, gap }) => (direction === 'column' ? '4px' : gap)};
  padding: ${({ padding }) => padding};
  line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : '20px')};
`;

const GraphContainer = styled.div`
  box-sizing: border-box;
  width: auto;
  min-width: 100%;
  top: -6px;
  position: relative;
  > canvas{
    max-height: 228px;
    width: 100% !important;
  }
`;

const TableContainer = styled.div`
  width: 100%;
  > div > div{
    > div:first-child{
      height: 26px;

      position: relative;
      &::after {
        content: '';
        display: block;
        height: 1px;
        width: 100%;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0; 
        background-color: var(--grey-6);
      }
      div > div > div {
        padding: 0px; 
        font-family: Lato;
        font-size: 12px;
        color: #5a6671;
      }
    }
    > div:not(:first-child){
      > div{
       height: 32px; 
      }
    }
    > div.sc-keuYuY, div.sc-jwWbkn {
      top: 0px;
    }
  }
`;

const IngressEgressTableContainer = styled.div`
  > div > div{
    > div:first-child{
      height: 40px;

      position: relative;
      &::after {
        content: '';
        display: block;
        height: 1px;
        width: 100%;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0; 
        background-color: var(--grey-6);
      }
      div > div > div {
        padding: 0px; 
        color: #717989; 
      }
    } 
    > div:not(:first-child){
     > div{
      height: 40px; 
      }
    }
  }
`;


const EgressCardContainer = styled(CardContainer)`
  div.egressTable {
    > div:first-child > div > div:first-child {
      > div:nth-child(3) {
        > div > div {
          margin-right: 13px !important;
        }
      }
    }
  }
`;

const HeadingButtonContainer = styled(BoxContainer)`
  min-width: 1008px;
  > div {
    > div:first-child {
      > button#primary {
        letter-spacing: 0.2px;
      }
    }
  }
`;

const ButtonContainer = styled(BoxContainer)`
  button {
    line-height: 30px;
  }
  > div:nth-child(2) > div > div:first-child > button {
    font-size: 12px;
    height: 30px;
  }
`;

const DateDrawer = styled(FilterDropdownContainer)`
  position: absolute;
  z-index: 100;
  top: 40px;
  right: 0px;
`;



// All Interfaces

// Type for the expected parameters
interface IParams {
  deviceID: string;
  linkID: string;
}


// Section 1: Monthly Data Usage

// Total data usage interfaces
interface IMonthUsage {
  totalUsage?: number;
  ingressUsage?: number;
  egressUsage?: number;
}
interface IMonthlyDataUsage {
  currentMonthUsage?: IMonthUsage;
  lastMonthUsage?: IMonthUsage;
}



// Section 2: Top Data Usage Clients 

// Top data usage clients interfaces
interface IEgressDataUsage {
  clientIP: string;
  egressDataUsage: number;
}
interface IIngressDataUsage {
  clientIP: string;
  ingressDataUsage: number;
}
interface ITopClientsDataUsage {
  egress: IEgressDataUsage[];
  ingress: IIngressDataUsage[];
}

// Section 3: Weekly Data Usage

interface IWeeklyDataUsage {
  date: string;
  ingressUsage?: number;
  egressUsage?: number;
}

interface IGraphTableData {
  name: string;
  day1: number;
  day2: number;
  day3: number;
  day4: number;
  day5: number;
  day6: number;
  day7: number;
}



const ConnectionUsageData: React.FC = () => {
  // variable from the params
  const { deviceID, linkID } = useParams<IParams>();

  // Accessed webgate link name
  const [linkName, setLinkName] = useState<string>('');

  // Variable defined to use the history hook provided by the router library
  const history = useHistory();

  // Function used from the useNotification component to send notifications
  const { sendNotification } = useNotification();

  // To use the text transalation files (English - Japanese) 
  const { t } = useTranslation(['ConnectionUsage', 'Common']);

  // Check validation for deviceID and linkID
  const { link, linkDataLoading } = useLinkData(deviceID, linkID);

  useEffect(() => {
    if (link?.connection === 'RTMPS') {
      sendNotification({
        type: 'warning',
        message: t('ConnectionUsage:notificationMessage.rtmpsNotExists'),
      });
      history.push('/');
    } else if (link){
      setLinkName(link.name);
    }
  }, [
    t, 
    link, 
    history,
    linkDataLoading, 
    sendNotification 
  ]);


  // Check validation for webgate enabled or not
  const { isFeatureEnabled }: ContextProps = useContext(FeatureEnabledContext);

  // To measure the viewport width
  const [width] = useWindowSize();


  // All States
  // Section 1: Monthly Data Usage

  // Current and previous month usage data
  const [monthlyDataUsage, setMonthlyDataUsage] = useState<IMonthlyDataUsage>({});




  // Section 2: Top Data Usage Clients 

  // Date input for top data usage clients API
  const [usageDate, setUsageDate] = useState<number>(convertToEpoch(new Date()));

  // Ingress table state
  const [ingressData, setIngressData] = useState<ITypeTableData>([]);

  // Egress table state
  const [egressData, setEgressData] = useState<ITypeTableData>([]);

  // Top Clients data usage state
  const [topClientsDataUsage, setTopClientsDataUsage] = useState<ITopClientsDataUsage>({
    'egress': [
      {
        'clientIP': '-',
        'egressDataUsage': NaN
      },
      {
        'clientIP': '-',
        'egressDataUsage': NaN
      },
      {
        'clientIP': '-',
        'egressDataUsage': NaN
      },
      {
        'clientIP': '-',
        'egressDataUsage': NaN
      },
      {
        'clientIP': '-',
        'egressDataUsage': NaN
      },
    ],
    'ingress': [
      {
        'clientIP': '-',
        'ingressDataUsage': NaN
      },
      {
        'clientIP': '-',
        'ingressDataUsage': NaN
      },
      {
        'clientIP': '-',
        'ingressDataUsage': NaN
      },
      {
        'clientIP': '-',
        'ingressDataUsage': NaN
      },
      {
        'clientIP': '-',
        'ingressDataUsage': NaN
      },
    ],
  });

  // Top data usage clients tables loading state
  const [topClientLoading, setTopClientLoading] = useState(false);

  // Calender States

  // State for opening and closing calender
  const [topClientsDateDrawer, setTopClientsDateDrawer] = useState(false);

  // State for last24hr button is clicked or not
  const [last24hr, setLast24hr] = useState(true);

  // State for initial values of calender
  const [topClientsDate, setTopClientsDate] = useState<Date | DateInterval>(new Date());

  // Reference and function for clicking outside the datepicker area
  const topClientsDatepickerRef = useRef<HTMLDivElement>(null);
  useClickOutside(topClientsDatepickerRef, () => setTopClientsDateDrawer(false));






  // Section 3: Weekly Data Usage

  // Start date input for weekly data usage API
  const [usageStartDate, setUsageStartDate] = useState<number>(convertToEpoch(new Date(new Date().setDate(new Date().getDate() - 6))));

  // End date input for weekly data usage API
  const [usageEndDate, setUsageEndDate] = useState<number>(convertToEpoch(new Date()));

  // Weekly graph table column state
  const [weeklyDataTable, setWeeklyDataTable] = useState<ITableColumnConfig[]>([
    {
      header: '',
      sortable: false,
      cellStyle: 'firstColumn',
      alignment: 'left',
      minWidth: 81.125,
    },
    {
      header: '',
      sortable: false,
      alignment: 'left',
      cellStyle: 'normalImportance',
      minWidth: 81.125,
    },
    {
      header: '',
      sortable: false,
      alignment: 'left',
      cellStyle: 'normalImportance',
      minWidth: 81.125,
    },
    {
      header: '',
      sortable: false,
      alignment: 'left',
      cellStyle: 'normalImportance',
      minWidth: 81.125,
    },
    {
      header: '',
      sortable: false,
      alignment: 'left',
      cellStyle: 'normalImportance',
      minWidth: 81.125,
    },
    {
      header: '',
      sortable: false,
      alignment: 'left',
      cellStyle: 'normalImportance',
      minWidth: 81.125,
    },
    {
      header: '',
      sortable: false,
      alignment: 'left',
      cellStyle: 'normalImportance',
      minWidth: 81.125,
    },
    {
      header: '',
      sortable: false,
      alignment: 'left',
      cellStyle: 'normalImportance',
      minWidth: 81.125,
    },
  ]);

  // Weekly graph table row state
  const [weeklyDataTableRow, setWeeklyDataTableRow] = useState<ITypeTableData>([]);

  // Weekly usage data state
  const [weeklyDataUsage, setWeeklyDataUsage] = useState<IWeeklyDataUsage[]>([]);

  // State for opening and closing calender
  const [weeklyDataDateDrawer, setWeeklyDataDateDrawer] = useState(false);

  // State for last7days button is clicked or not
  const [last7Days, setLast7Days] = useState(true);

  // State for initial values of calender
  const [weeklyDataDate, setWeeklyDataDate] = useState<DateInterval>({ start: new Date(new Date().setDate(new Date().getDate() - 6)), end: new Date() });

  // Reference and function for clicking outside the datepicker area
  const weeklyDatepickerRef = useRef<HTMLDivElement>(null);
  useClickOutside(weeklyDatepickerRef, () => setWeeklyDataDateDrawer(false));

  // Weekly graph table data
  const [graphTableData, setGraphTbData] = useState<IGraphTableData[]>([]);

  // Weekly graph table loading
  const [tableLoading, setTableLoading] = useState(false);





  // Page Data

  // Fetched device name from the API
  const { dataLoading } = useCheckDevice(deviceID);


  const breadCrums = [
    {
      text: t('Common:breadCrums.home'),
      href: '/',
    },
    {
      text: t('Common:breadCrums.deviceLinks'),
      href: `/links/${deviceID}`,
    },
    {
      text: t('Common:breadCrums.usageData'),
    },
  ];



  // Section 1: Current & Previous month data usage

  // Dougnut Data
  const doughnutData = {
    labels: ['Egress', 'Ingress'],
    datasets: [
      {
        data: [monthlyDataUsage.currentMonthUsage?.egressUsage || 50, monthlyDataUsage.currentMonthUsage?.ingressUsage || 50],
        backgroundColor: ['#76AAF4', '#92CEAC'],
        borderWidth: 0,
      },
    ],
  };
  const dougnutOptions = {
    cutout: '70%',
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false
      }
    }
  };





  // Section 2: Top Data Usage Clients section

  // Table column configuration for ingress and egress sections - top data usage clients
  const IngressEgressColumn: ITableColumnConfig[] = [
    {
      header: '#',
      sortable: false,
      cellStyle: 'normalImportance',
      minWidth: 27,
      alignment: 'left'
    },

    {
      header: t('ConnectionUsage:topClientsUsage.section1.tableText.header1'),
      sortable: false,
      alignment: 'left',
      cellStyle: 'normalImportance',
      minWidth: width >= 1536 ? 480 : 376,
    },
    {
      header: t('ConnectionUsage:topClientsUsage.section1.tableText.header2'),
      sortable: false,
      alignment: 'left',
      cellStyle: 'normalImportance',
      minWidth: 61,
    },
  ];


  // Ingress & Egress Tables

  // Generate first column 
  const generateSerialNumber = useCallback((id: number) => {
    return (
      <UsageContainer>
        <ColumnText fontWeight='bold' color='#7f838b' family='Lato'>
          {id}
        </ColumnText>
      </UsageContainer>
    );
  }, []);

  // Generate second column 
  const generateClient = useCallback((client: string) => {
    return (
      <>
        <UsageContainer direction='row' gap='4px' alignItems='flex-end'>
          <ColumnText color='#7f838b' family='Lato'>
            {client ? client : '-'}
          </ColumnText>
        </UsageContainer>
      </>
    );
  }, []);

  // Generate third column 
  const generateUsed = useCallback((used: number) => {
    const convertedValue = convertValue(used);
    const unit = getUnit(used);
    return (
      <>
        <UsageContainer
          direction='row'
          justify='flex-start'
          gap='4px'
          alignItems='end'
        >
          {used ? (
            <>
              <ColumnText color='#7f838b' family='Lato'>
                {convertedValue}
              </ColumnText>
              <ColumnText color='#b7bcc4' fontSize='10px' line={1.67}>
                {unit}
              </ColumnText>
            </>
          ) : (
            <ColumnText color='#7f838b' fontSize='12px' padding='0 30px 0 0'>
              -
            </ColumnText>
          )}
        </UsageContainer>
      </>
    );
  }, []);

  // Generate individual row data using the columns generate functions
  const generateIngresRowData = useCallback(
    (showData: IEgressDataUsage[] | IIngressDataUsage[]): ITypeTableData => {
      const emptyRow: ITypeTableData = [
        {
          columns: [],
        },
      ];

      const tableRow = showData.map((topUser: IEgressDataUsage | IIngressDataUsage, index: number) => {
        let clientIP: string;
        let dataUsage: number;

        if ('ingressDataUsage' in topUser) {
          // Narrowed down to IIngressDataUsage
          clientIP = topUser.clientIP;
          dataUsage = topUser.ingressDataUsage;
        } else {
          // Narrowed down to IEgressDataUsage
          clientIP = topUser.clientIP;
          dataUsage = topUser.egressDataUsage;
        }

        const row: IRowData = {
          id: Math.random() * 9567,
          header: {},
          columns: [
            {
              customComponent: (
                <MultilineContent contentArray={[generateSerialNumber(index + 1)]} />
              ),
            },
            {
              customComponent: (
                <MultilineContent contentArray={[generateClient(clientIP)]} />
              ),
            },
            {
              customComponent: (
                <MultilineContent contentArray={[generateUsed(dataUsage)]} />
              ),
            },
          ],
        };
        return row;
      });

      if (tableRow.length === 0) {
        return emptyRow;
      }
      return tableRow;
    },
    [generateSerialNumber, generateClient, generateUsed]
  );

  useEffect(() => {
    setIngressData(generateIngresRowData(topClientsDataUsage.ingress));
    setEgressData(generateIngresRowData(topClientsDataUsage.egress));
  }, [topClientsDataUsage, generateIngresRowData]);






  // Section 3: Data Traffic section

  // function for updating the initial value of calender
  const updateWeeklyDate = (date: Date | DateInterval) => {
    date = date as DateInterval;
    const start = new Date(date.start).getTime();
    const end = new Date(date.end).getTime();

    // Calculate the difference in days
    const differenceInDays = (end - start) / (1000 * 60 * 60 * 24);

    if (differenceInDays > 7) {
      handleDismiss(); // Clear all notification bar before sending a new one
      sendNotification({
        type: 'info',
        message: t('ConnectionUsage:notificationMessage.dateRangeExceeded'),
      });

      // Adjust the end date to be exactly 7 days from the start date
      const adjustedEnd = new Date(date.start);
      adjustedEnd.setDate(date.start.getDate() + 6); // Add 6 days (inclusive of the start day)
      date.end = adjustedEnd;

      // Update the states
      setUsageStartDate(convertToEpoch(date.start));
      setUsageEndDate(convertToEpoch(date.end));
      setWeeklyDataDate(date);
      // setWeeklyDataDateDrawer(false)
    } else if (differenceInDays < 7) {
      // If start and end are the same, adjust to 7 days
      const adjustedEnd = new Date(date.start);
      adjustedEnd.setDate(date.start.getDate() + 6);
      date.end = adjustedEnd;

      setUsageStartDate(convertToEpoch(date.start));
      setUsageEndDate(convertToEpoch(date.end));
      setWeeklyDataDate(date);
      // setWeeklyDataDateDrawer(false)
    }
  };

  const determineConversionFunction = (
    ingressData?: number,
    egressData?: number
  ) => {
    let maxValue, ingressDataConverted, egressDataConverted, unit;
    if (weeklyDataUsage && weeklyDataUsage.length > 0) {
      maxValue = Math.max(
        ...weeklyDataUsage.flatMap((entry) => [
          entry.ingressUsage || 0,
          entry.egressUsage || 0,
        ])
      );
    }
    if (maxValue && maxValue > 1024 * 1024 * 1024 * 1024 * 1024) {
      ingressDataConverted = convertBytesToPiB(ingressData || 0);
      egressDataConverted = convertBytesToPiB(egressData || 0);
      unit = 'PiB'; // Convert to PiB
    }
    else if (maxValue && maxValue > 1024 * 1024 * 1024 * 1024) {
      ingressDataConverted = convertBytesToTiB(ingressData || 0);
      egressDataConverted = convertBytesToTiB(egressData || 0);
      unit = 'TiB'; // Convert to TiB
    } else if (maxValue && maxValue > 1024 * 1024 * 1024) {
      ingressDataConverted = convertBytesToGiB(ingressData || 0);
      egressDataConverted = convertBytesToGiB(egressData || 0);
      unit = 'GiB';// Convert to GiB
    } else if (maxValue && maxValue > 1024 * 1024) {
      ingressDataConverted = convertBytesToMiB(ingressData || 0);
      egressDataConverted = convertBytesToMiB(egressData || 0);
      unit = 'MiB'; // Convert to MiB
    } else if (maxValue && maxValue > 1024) {
      ingressDataConverted = convertBytesToKiB(ingressData || 0);
      egressDataConverted = convertBytesToKiB(egressData || 0);
      unit = 'KiB'; // Convert to KiB
    } else {
      ingressDataConverted = ingressData || 0;
      egressDataConverted = egressData || 0;
      unit = 'B'; // Convert to Bytes
    }
    return { ingressDataConverted, egressDataConverted, unit };
  };

  // Updated Chart Data
  const dataToShow: ChartData<'line', number[], string> = {
    labels: weeklyDataUsage.map(data => formatDate(data.date) || '0'),
    datasets: [
      {
        label: t('ConnectionUsage:weeklyDataUsage.graphSection.legendText.text1'),
        data: weeklyDataUsage.map((data) => {
          const { ingressDataConverted } = determineConversionFunction(
            data.ingressUsage || 0,
            data.egressUsage || 0
          );
          return ingressDataConverted;
        }),
        borderColor: '#92ceac',
        backgroundColor: 'transparent',
        type: 'line',
        tension: 0.4,
      },
      {
        label: t('ConnectionUsage:weeklyDataUsage.graphSection.legendText.text2'),
        data: weeklyDataUsage.map((data) => {
          const { egressDataConverted } = determineConversionFunction(
            data.ingressUsage || 0,
            data.egressUsage || 0
          );
          return egressDataConverted;
        }),
        borderColor: '#9bc3fa',
        backgroundColor: 'transparent',
        type: 'line',
        tension: 0.4,
      },
    ],
  };

  // Updated Chart Options
  const options: ChartOptions<'line'> = {
    layout: {
      padding: {
        left: -5,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          tickColor: 'transparent',
          tickLength: 2,
        },
        ticks: {
          callback: function (value, index, ticks) {
            if (ticks.length - 1 === index) {
              const { unit } = determineConversionFunction();
              return `${value} ${unit}`;
            }
            return value;
          },
          precision: 0,
          color: '#a3a3a3',
          font: {
            size: 10,
            family: 'Lato',
            weight: '400',
          },
          padding: 6,
        },
      },
      x: {
        grid: {
          tickColor: 'transparent',
          tickLength: -10,
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 7,
          color: '#a3a3a3',
          font: {
            size: 10,
            family: 'Lato',
            weight: '400',
          },
          padding: 20,
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        labels: {
          font: {
            size: 12,
            family: 'Lato',
          },
          boxWidth: 16,
          boxHeight: 0,
        },
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (tooltipItem) {
            const label = tooltipItem.dataset.label || '';
            const value = tooltipItem.raw;
            const { unit } = determineConversionFunction();
            return `${label}: ${value} ${unit}`;
          },
        },
      },
    },
  };

  // Graph Table

  // Generate row heading
  const generateRowHeading = useCallback((name: string) => {
    return (
      <UsageContainer>
        <ColumnText
          height='14px'
          fontSize='12px'
          fontWeight='bold'
          color='#7f838b'
          family='Lato'
        >
          {name}
        </ColumnText>
      </UsageContainer>
    );
  }, []);

  // Generate Usage
  const generateUsage = useCallback((day: number) => {
    return (
      <>
        <UsageContainer direction='row' justify='flex-start' gap='4px' alignItems='flex-end'>
          <ColumnText color='#7f838b' fontSize='12px' height='14px' family='Lato'>
            {convertValue(day)}
          </ColumnText>
          <ColumnText color='#b7bcc4' fontSize='10px' height='14px' family='Lato'>
            {getUnit(day)}
          </ColumnText>
        </UsageContainer>
      </>
    );
  }, []);

  // Generate row data
  const generateRowData = useCallback((graphTbData): ITypeTableData => {
    const emptyRow: ITypeTableData = [
      {
        columns: [],
      },
    ];
    const tableRow = graphTbData.map((usage: IGraphTableData) => {
      const { name, day1, day2, day3, day4, day5, day6, day7 } = usage;

      const row: IRowData = {
        id: Math.random() * 9567,
        header: {},
        columns: [
          {
            customComponent: (
              <MultilineContent contentArray={[generateRowHeading(name)]} />
            ),
          },
          {
            customComponent: (
              <MultilineContent contentArray={[generateUsage(day1)]} />
            ),
          },
          {
            customComponent: (
              <MultilineContent contentArray={[generateUsage(day2)]} />
            ),
          },
          {
            customComponent: (
              <MultilineContent contentArray={[generateUsage(day3)]} />
            ),
          },
          {
            customComponent: (
              <MultilineContent contentArray={[generateUsage(day4)]} />
            ),
          },
          {
            customComponent: (
              <MultilineContent contentArray={[generateUsage(day5)]} />
            ),
          },
          {
            customComponent: (
              <MultilineContent contentArray={[generateUsage(day6)]} />
            ),
          },
          {
            customComponent: (
              <MultilineContent contentArray={[generateUsage(day7)]} />
            ),
          },
        ],
      };
      return row;
    });
    if (tableRow.length === 0) {
      return emptyRow;
    }
    return tableRow;
  }, [generateRowHeading, generateUsage]);

  useEffect(() => {
    setWeeklyDataTableRow(generateRowData(graphTableData));
  }, [graphTableData, generateRowData]);



  // API-1 : Monthly Data Usage
  // fetch Call
  const fetchMonthlyUsage = useCallback(async () => {

    try {
      const fetchUsageData = await getDataUsage(linkID, deviceID);
      if (fetchUsageData) setMonthlyDataUsage(fetchUsageData);
      else throw new Error(ErrorType.PARSE);
    } catch (error) {
      console.error('Error in fetchUsage:', error);
      const exception = handleException(error);
      handleDismiss(); // Clear all notification bar before sending a new one
      sendNotification({
        type: 'error',
        message: t(`Common:exceptionNotificationMessages.${exception.message}`),
      });
    }
  }, [linkID, deviceID, sendNotification, t]);

  useEffect(() => {
    fetchMonthlyUsage();
    // eslint-disable-next-line
  }, []);



  // API-2 : Top Clients Usage
  // fetch Call
  const fetchTopClientsUsage = useCallback(async () => {
    setTopClientLoading(true);

    try {
      const fetchTotalUsageData: ITopClientsDataUsage = await getTopClient(linkID, deviceID, usageDate);

      if (!fetchTotalUsageData) {
        throw new Error(ErrorType.PARSE);
      }
      let { egress = [], ingress = [] } = fetchTotalUsageData;

      const createDummyEntries = (count: number, dummyEntry: IEgressDataUsage | IIngressDataUsage) =>
        new Array(count).fill(dummyEntry);

      const processArray = (array: any, key: string, dummyEntry: IEgressDataUsage | IIngressDataUsage) => {
        array.sort((a: any, b: any) => b[key] - a[key]);
        if (array.length < 5) {
          return array.concat(createDummyEntries(5 - array.length, dummyEntry));
        }
        return array.slice(0, 5); // Trim to 5 entries
      };

      const dummyEgressEntry: IEgressDataUsage = { clientIP: '-', egressDataUsage: NaN };
      const dummyIngressEntry: IIngressDataUsage = { clientIP: '-', ingressDataUsage: NaN };

      egress = processArray(egress, 'egressDataUsage', dummyEgressEntry);
      ingress = processArray(ingress, 'ingressDataUsage', dummyIngressEntry);

      // Set the updated total usage
      setTopClientsDataUsage({ egress, ingress });

    } catch (error) {
      console.error('Error in GetTopClient:', error);
      const exception = handleException(error);
      handleDismiss(); // Clear all notification bar before sending a new one
      sendNotification({
        type: 'error',
        message: t(`Common:exceptionNotificationMessages.${exception.message}`),
      });
    } finally {
      setTopClientLoading(false);
    }
  }, [usageDate, linkID, deviceID, sendNotification, t]);

  useEffect(() => {
    fetchTopClientsUsage();
    // eslint-disable-next-line
  }, [usageDate]);



  // API-3 : Weekly Data Usage
  // fetch Call
  const fetchWeeklyUsage = useCallback(async () => {
    setWeeklyDataUsage([]);
    setWeeklyDataTable((prev) => prev.map(col => ({ ...col, header: '' })));
    setGraphTbData([]);
    setTableLoading(true);

    try {
      const fetchDailyUsageData = await getWeeklyUsageStats(
        usageStartDate,
        usageEndDate,
        deviceID,
        linkID
      );
      if (fetchDailyUsageData && fetchDailyUsageData.weeklyUsageData) {
        // Sort the weeklyUsageData array by date
        const sortedWeeklyUsageData = fetchDailyUsageData.weeklyUsageData.sort(
          (a: IWeeklyDataUsage, b: IWeeklyDataUsage) => new Date(a.date).getTime() - new Date(b.date).getTime()
        );

        setWeeklyDataUsage(sortedWeeklyUsageData);

        // Update only the header values in the tableColumns state
        setWeeklyDataTable((prevTableColumns) =>
          prevTableColumns.map((col, index) => ({
            ...col,
            header:
              index === 0
                ? ''
                : formatDate(sortedWeeklyUsageData[index - 1]?.date),
          }))
        );

        // Update Graph table rows
        const data = [
          {
            name: t('ConnectionUsage:weeklyDataUsage.graphSection.tableText.row2'),
            day1: sortedWeeklyUsageData[0]?.ingressUsage,
            day2: sortedWeeklyUsageData[1]?.ingressUsage,
            day3: sortedWeeklyUsageData[2]?.ingressUsage,
            day4: sortedWeeklyUsageData[3]?.ingressUsage,
            day5: sortedWeeklyUsageData[4]?.ingressUsage,
            day6: sortedWeeklyUsageData[5]?.ingressUsage,
            day7: sortedWeeklyUsageData[6]?.ingressUsage,
          },
          {
            name: t('ConnectionUsage:weeklyDataUsage.graphSection.tableText.row3'),
            day1: sortedWeeklyUsageData[0]?.egressUsage,
            day2: sortedWeeklyUsageData[1]?.egressUsage,
            day3: sortedWeeklyUsageData[2]?.egressUsage,
            day4: sortedWeeklyUsageData[3]?.egressUsage,
            day5: sortedWeeklyUsageData[4]?.egressUsage,
            day6: sortedWeeklyUsageData[5]?.egressUsage,
            day7: sortedWeeklyUsageData[6]?.egressUsage,
          },
        ];
        setGraphTbData(data);
        return;
      } else {
        throw new Error(ErrorType.PARSE);
      }

    } catch (error) {
      console.error('Error in GetWeeklyUsageStats:', error);
      const exception = handleException(error);
      handleDismiss(); // Clear all notification bar before sending a new one
      sendNotification({
        type: 'error',
        message: t(`Common:exceptionNotificationMessages.${exception.message}`),
      });
    } finally {
      setTableLoading(false);
    }
  }, [usageStartDate, usageEndDate, linkID, deviceID, t, sendNotification]);

  useEffect(() => {
    fetchWeeklyUsage();
    // eslint-disable-next-line
  }, [usageStartDate, usageEndDate]);

  return (
    <ContentContainer>
      <Wrapper enableContent={isFeatureEnabled}>
        <DrawerContainer>
          <Drawer>
            <Breadcrums
              backLink
              showBreadcrumbs
              breadcrumbs={breadCrums}
            />
          </Drawer>
        </DrawerContainer>
        <HeaderSection>
          <InnerHeader width={width}>
            <DeviceLeftContainer>
              <DeviceInfo dataLoading={dataLoading}>
                <PageHeader
                  icon={`${dataLoading ? '' : 'Link'}`}
                  iconColor='primary'
                  areaTitleBottom
                  areaTitle={linkName !== '' ? `${linkName === 'Edge UI' ? t('ConnectionUsage:monthlyUsage.edgeUI') : linkName === 'Solution UI' ? t('ConnectionUsage:monthlyUsage.solutionUI') : linkName } - ${linkID}` : '-'}
                  title={t('ConnectionUsage:pageTitle')}
                />
                {dataLoading && (
                  <SpinnerContainer>
                    <Spinner size='medium' styling='primary' />
                  </SpinnerContainer>
                )}
              </DeviceInfo>
            </DeviceLeftContainer>
          </InnerHeader>
        </HeaderSection>
        <LineDivider />
        <BoxContainer
          alignItems='stretch'
          gap='16px'
          padding={width >= 1536 ? '34px 120px 64px' : '36px 96px 64px'}
          grow={1}
        >
          <BoxContainer
            alignItems='stretch'
            gap='64px'
            padding='23px 0 0'
            grow={1}
          >
            <BoxContainer direction='row' gap='16px'>
              <BoxContainer gap='21px' width='311px' maxWidth='311px' grow={1}>
                <ColumnText height='21px' fontSize='18px' fontWeight='600' letter='-.2px'>
                  {t('ConnectionUsage:monthlyUsage.heading')}
                </ColumnText>
                <BoxContainer gap='24px' alignItems='stretch' grow={1}>
                  <BoxContainer gap='18px' grow={1}>
                    <ColumnText height='19px' fontSize='16px' color='#7f838b' letter='0.05px'>
                      {t('ConnectionUsage:monthlyUsage.section1.subHeading1')}
                    </ColumnText>
                    <CardContainer
                      grow={1}
                      height='210px'
                      padding='16px 0px 0px'
                      alignItems='stretch'
                      gap='32px'
                    >
                      <BoxContainer
                        padding='5px 24px 0px 23px'
                        gap='15px'
                        alignItems='stretch'
                      >
                        <BoxContainer
                          padding='8px 0 20px 0'
                          gap='24px'
                          alignItems='stretch'
                        >
                          <BoxContainer
                            direction='row'
                            alignItems='center'
                            gap='16px'
                            padding='0 0 23px'
                            borderBottom='solid 1px #e6e8ec'
                          >
                            <BoxContainer height='48px' width='48px' margin='6px 0px 0px 0px'>
                              <Doughnut data={doughnutData} options={dougnutOptions} />
                            </BoxContainer>
                            <BoxContainer gap='8px' width='183px'>
                              <ColumnText
                                color='#7f838b'
                                fontWeight='500'
                                line={1}
                                letter='-.4px'
                              >
                                {t('ConnectionUsage:monthlyUsage.section1.text1')}
                              </ColumnText>
                              <DataNumber>
                                <ColumnText
                                  fontSize='32px'
                                  fontWeight='600'
                                  color='#60646b'
                                  family='Lato'
                                  letter='.2px'
                                >
                                  {monthlyDataUsage.currentMonthUsage?.totalUsage ? convertValue(monthlyDataUsage.currentMonthUsage?.totalUsage) : '0'}
                                </ColumnText>
                                <ColumnText line={2} color='#b7bcc4'>
                                  {monthlyDataUsage.currentMonthUsage?.totalUsage ? getUnit(monthlyDataUsage.currentMonthUsage?.totalUsage) : 'B'}
                                </ColumnText>
                              </DataNumber>
                            </BoxContainer>
                          </BoxContainer>
                          <BoxContainer
                            direction='row'
                            gap='6px'
                            padding='2px 0 0'
                          >
                            <ShowStats
                              border='solid 4px #92ceac'
                              width='119.5px'
                            >
                              <BoxContainer
                                direction='row'
                                alignItems='center'
                              >
                                <ColumnText
                                  fontWeight='600'
                                  color='#7f838b'
                                >
                                  {t('ConnectionUsage:monthlyUsage.section1.text2')}
                                </ColumnText>
                                <InfoTooltip
                                  infoText={t('ConnectionUsage:monthlyUsage.section1.text2Tooltip')}
                                  minWidth='max-content'
                                />
                              </BoxContainer>
                              <DataNumber line='1.2'>
                                <ColumnText
                                  color='#60646b'
                                  fontSize='20px'
                                  family='Lato'
                                  fontWeight='600'
                                >
                                  {monthlyDataUsage.currentMonthUsage?.ingressUsage ? convertValue(monthlyDataUsage.currentMonthUsage?.ingressUsage) : '0'}
                                </ColumnText>
                                <ColumnText line={1.43} color='#b7bcc4'>
                                  {monthlyDataUsage.currentMonthUsage?.ingressUsage ? getUnit(monthlyDataUsage.currentMonthUsage?.ingressUsage) : 'B'}
                                </ColumnText>
                              </DataNumber>
                            </ShowStats>
                            <ShowStats
                              border='solid 4px #9bc3fa'
                              width='119.5px'
                            >
                              <BoxContainer
                                direction='row'
                                alignItems='center'
                              >
                                <ColumnText
                                  fontWeight='600'
                                  color='#7f838b'
                                >
                                  {t('ConnectionUsage:monthlyUsage.section1.text3')}
                                </ColumnText>
                                <InfoTooltip
                                  infoText={t('ConnectionUsage:monthlyUsage.section1.text3Tooltip')}
                                  minWidth='max-content'
                                />
                              </BoxContainer>
                              <DataNumber line='1.2'>
                                <ColumnText
                                  color='#60646b'
                                  fontSize='20px'
                                  family='Lato'
                                  fontWeight='600'
                                >
                                  {monthlyDataUsage.currentMonthUsage?.egressUsage ? convertValue(monthlyDataUsage.currentMonthUsage?.egressUsage) : '0'}
                                </ColumnText>
                                <ColumnText line={1.43} color='#b7bcc4'>
                                  {monthlyDataUsage.currentMonthUsage?.egressUsage ? getUnit(monthlyDataUsage.currentMonthUsage?.egressUsage) : 'B'}
                                </ColumnText>
                              </DataNumber>
                            </ShowStats>
                          </BoxContainer>
                        </BoxContainer>
                      </BoxContainer>
                    </CardContainer>
                  </BoxContainer>
                  <BoxContainer gap='16px' grow={1}>
                    <ColumnText height='19px' fontSize='16px' color='#7f838b' letter='0px'>
                      {t('ConnectionUsage:monthlyUsage.section2.subHeading2')}
                    </ColumnText>
                    <CardContainer
                      padding='24px 0'
                      gap='32px'
                      grow={1}
                      alignItems='stretch'
                    >
                      <BoxContainer
                        padding='0 24px'
                        gap='16px'
                        alignItems='stretch'
                        grow={1}
                      >
                        <BillCycleContainer>
                          <ShowStats
                            border='solid 4px #80b5ff'
                            opacity='0.8'
                            position='relative'
                            grow={0}
                            padding='0 0 0 12px'
                          >
                            <HalfBorder />
                            <ColumnText
                              fontSize='12px'
                              fontWeight='600'
                              color='#7f838b'
                              line={1.25}
                              letter='-.4px'
                            >
                              {t('ConnectionUsage:monthlyUsage.section2.text1')}
                            </ColumnText>
                            <DataNumber>
                              <ColumnText
                                color='#60646b'
                                fontWeight='600'
                                family='Lato'
                                line={1.2}
                                letter='0px'
                              >
                                {monthlyDataUsage.lastMonthUsage?.totalUsage ? convertValue(monthlyDataUsage.lastMonthUsage?.totalUsage) : '0'}
                              </ColumnText>
                              <ColumnText
                                line={1.4}
                                color='#b7bcc4'
                                fontSize='10px'
                              >
                                {monthlyDataUsage.lastMonthUsage?.totalUsage ? getUnit(monthlyDataUsage.lastMonthUsage?.totalUsage) : 'B'}
                              </ColumnText>
                            </DataNumber>
                          </ShowStats>
                          <ShowStats
                            grow={0}
                            border='solid 4px #92ceac'
                            opacity='0.8'
                            padding='0 0 0 12px'
                          >
                            <ColumnText
                              fontSize='12px'
                              fontWeight='600'
                              color='#7f838b'
                              line={1.25}
                            >
                              {t('ConnectionUsage:monthlyUsage.section2.text2')}
                            </ColumnText>
                            <DataNumber>
                              <ColumnText
                                color='#60646b'
                                fontWeight='600'
                                family='Lato'
                                line={1.2}
                                letter='0px'
                              >
                                {monthlyDataUsage.lastMonthUsage?.ingressUsage ? convertValue(monthlyDataUsage.lastMonthUsage?.ingressUsage) : '0'}
                              </ColumnText>
                              <ColumnText
                                line={1.4}
                                color='#b7bcc4'
                                fontSize='10px'
                              >
                                {monthlyDataUsage.lastMonthUsage?.ingressUsage ? getUnit(monthlyDataUsage.lastMonthUsage?.ingressUsage) : 'B'}
                              </ColumnText>
                            </DataNumber>
                          </ShowStats>
                          <ShowStats
                            grow={0}
                            border='solid 4px #80b5ff'
                            opacity='0.8'
                            padding='0 0 0 12px'
                          >
                            <ColumnText
                              fontSize='12px'
                              fontWeight='600'
                              color='#7f838b'
                              line={1.25}
                            >
                              {t('ConnectionUsage:monthlyUsage.section2.text3')}
                            </ColumnText>
                            <DataNumber>
                              <ColumnText
                                color='#60646b'
                                fontWeight='600'
                                family='Lato'
                                line={1.2}
                                letter='0px'
                              >
                                {monthlyDataUsage.lastMonthUsage?.egressUsage ? convertValue(monthlyDataUsage.lastMonthUsage?.egressUsage) : '0'}
                              </ColumnText>
                              <ColumnText
                                line={1.4}
                                color='#b7bcc4'
                                fontSize='10px'
                              >
                                {monthlyDataUsage.lastMonthUsage?.egressUsage ? getUnit(monthlyDataUsage.lastMonthUsage?.egressUsage) : 'B'}
                              </ColumnText>
                            </DataNumber>
                          </ShowStats>
                        </BillCycleContainer>
                      </BoxContainer>
                    </CardContainer>
                  </BoxContainer>
                </BoxContainer>
              </BoxContainer>
              <BoxContainer gap='13px' minWidth='681px' grow={1}>
                <BoxContainer
                  minWidth='681px'
                  direction='row'
                  alignItems='center'
                  justify='space-between'
                  padding='37px 0 0 0'
                  margin='0 0 0 -1px'
                >
                  <ColumnText
                    fontSize='16px'
                    fontWeight='500'
                    color='#7f838b'
                    letter='-.1px'
                    family='monorale'
                  >
                    {t('ConnectionUsage:weeklyDataUsage.heading')}
                  </ColumnText>

                  <ButtonContainer direction='row' gap='8px'>
                    <ButtonGroup>
                      <Button
                        style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                        id={last7Days ? 'primary' : 'secondary'}
                        size='normal'
                        design={last7Days ? 'primary' : 'secondary'}
                        onClick={() => {
                          setWeeklyDataDateDrawer(false);
                          setLast7Days(true);
                          if (usageStartDate !== convertToEpoch(new Date(new Date().setDate(new Date().getDate() - 6))) && usageEndDate !== convertToEpoch(new Date())) {
                            setWeeklyDataDate({ start: new Date(new Date().setDate(new Date().getDate() - 6)), end: new Date() });
                            setUsageStartDate(convertToEpoch(new Date(new Date().setDate(new Date().getDate() - 6))));
                            setUsageEndDate(convertToEpoch(new Date()));
                          }
                        }}
                      >
                        {t('ConnectionUsage:weeklyDataUsage.buttonText.text1')}
                      </Button>
                      <Button
                        style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                        id={last7Days ? 'secondary' : 'primary'}
                        size='normal'
                        design={last7Days ? 'secondary' : 'primary'}
                        onClick={() => {
                          last7Days && setWeeklyDataDateDrawer(!weeklyDataDateDrawer);
                          setLast7Days(false);
                        }}
                      >
                        {t('ConnectionUsage:weeklyDataUsage.buttonText.text2')}
                      </Button>
                    </ButtonGroup>
                    <ButtonIconContainer>
                      <DateDropdown ref={weeklyDatepickerRef}>
                        <ButtonWithIcon
                          icon='DateTime'
                          size='normal'
                          disabled={last7Days}
                          design='secondary'
                          position='left'
                          onClick={() => {
                            setWeeklyDataDateDrawer(!weeklyDataDateDrawer);
                          }}
                        >
                          {t('ConnectionUsage:weeklyDataUsage.buttonText.text3')}
                        </ButtonWithIcon>
                        {weeklyDataDateDrawer && (
                          <DateDrawer>
                            <DatePicker
                              availableRange={{
                                start: null,
                                end: new Date()
                              }}
                              initialValue={weeklyDataDate}
                              dateMode='interval'
                              timeMode='off'
                              updateCallback={updateWeeklyDate}
                              timeZoneValueTitle={getSystemTimezoneAbbreviation()}
                            />
                          </DateDrawer>
                        )}
                      </DateDropdown>
                    </ButtonIconContainer>
                  </ButtonContainer>
                </BoxContainer>

                <CardContainer
                  minWidth='681px'
                  height='356px'
                  padding='16px'
                  shadow='0 1px 6px 0 rgba(0, 121, 255, 0.14)'
                  border='solid 1px #e6e8ec'
                  background='#fcfcfd'
                  gap='6px'
                >
                  <GraphContainer>
                    <Line
                      data={dataToShow}
                      options={options}
                      height={228}

                    />
                  </GraphContainer>
                  <TableContainer>
                    <TypeTable
                      columnConfig={weeklyDataTable}
                      rows={weeklyDataTableRow}
                      isLoading={tableLoading}
                      loadingText={t('Common:loadingData')}
                      emptyTableText={t('ConnectionUsage:weeklyDataUsage.graphSection.tableText.noData')}
                    />
                  </TableContainer>
                </CardContainer>
              </BoxContainer>
            </BoxContainer>
            <BoxContainer alignItems='stretch' gap='16px'>
              <HeadingButtonContainer direction='row' justify='space-between'>
                <ColumnText
                  fontSize='18px'
                  fontWeight='600'
                  color='#60646b'
                  letter='-.1px'
                  family='monorale'
                >
                  {t('ConnectionUsage:topClientsUsage.heading')}
                </ColumnText>

                <ButtonContainer direction='row' gap='8px'>
                  <ButtonGroup>
                    <Button
                      style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                      id={last24hr ? 'primary' : 'secondary'}
                      size='normal'
                      design={last24hr ? 'primary' : 'secondary'}
                      onClick={() => {
                        setTopClientsDateDrawer(false);
                        setLast24hr(true);
                        if (usageDate !== convertToEpoch(new Date())) {
                          setUsageDate(convertToEpoch(new Date()));
                          setTopClientsDate(new Date());
                        }
                      }}
                    >
                      {t('ConnectionUsage:topClientsUsage.buttonText.text1')}
                    </Button>
                    <Button
                      style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                      id={last24hr ? 'secondary' : 'primary'}
                      size='normal'
                      design={last24hr ? 'secondary' : 'primary'}
                      onClick={() => {
                        last24hr && setTopClientsDateDrawer(!topClientsDateDrawer);
                        setLast24hr(false);
                      }}
                    >
                      {t('ConnectionUsage:topClientsUsage.buttonText.text2')}
                    </Button>
                  </ButtonGroup>
                  <ButtonIconContainer>
                    <DateDropdown ref={topClientsDatepickerRef}>
                      <ButtonWithIcon
                        icon='DateTime'
                        size='normal'
                        design='secondary'
                        disabled={last24hr}
                        position='left'
                        onClick={() => {
                          setTopClientsDateDrawer(!topClientsDateDrawer);
                        }}
                      >
                        {t('ConnectionUsage:topClientsUsage.buttonText.text3')}
                      </ButtonWithIcon>
                      {topClientsDateDrawer && (
                        <DateDrawer>
                          <DatePicker
                            availableRange={{
                              start: null,
                              end: new Date()
                            }}
                            initialValue={topClientsDate}
                            timeZoneValueTitle={getSystemTimezoneAbbreviation()}
                            dateMode='single'
                            timeMode='off'
                            updateCallback={(date: DateInterval | Date) => {
                              if (usageDate !== convertToEpoch(date)) {
                                setUsageDate(convertToEpoch(date));
                                setTopClientsDate(date);
                                // setTopClientsDateDrawer(false)
                              }
                            }}
                          />
                        </DateDrawer>
                      )}
                    </DateDropdown>
                  </ButtonIconContainer>
                </ButtonContainer>

              </HeadingButtonContainer>

              <BoxContainer
                direction='row'
                gap='16px'
                scrollWidth='none'
                minWidth='1008px'
              >
                <BoxContainer
                  minWidth='496px'
                  gap='16px'
                  grow={1}
                >
                  <ShowStats
                    direction='row'
                    alignItems='center'
                    padding='0 9px'
                    border='solid 3px #92ceac'
                  >
                    <ColumnText
                      fontSize='16px'
                      fontWeight='600'
                      color='#7f838b'
                    >
                      {t('ConnectionUsage:topClientsUsage.section1.heading')}
                    </ColumnText>
                  </ShowStats>
                  <CardContainer
                    alignItems='stretch'
                    gap='4px'
                    padding='15px'
                    grow={1}
                    minWidth='496px'
                  >
                    <IngressEgressTableContainer>
                      <TypeTable
                        columnConfig={IngressEgressColumn}
                        rows={ingressData}
                        isLoading={topClientLoading}
                        loadingText={t('Common:loadingData')}
                        emptyTableText={t('ConnectionUsage:topClientsUsage.section1.heading')}
                      />
                    </IngressEgressTableContainer>
                  </CardContainer>
                </BoxContainer>
                <BoxContainer
                  minWidth='496px'
                  gap='16px'
                  grow={1}
                >
                  <ShowStats
                    direction='row'
                    alignItems='center'
                    padding='0 9px'
                    border='solid 3px #9bc3fa'
                  >
                    <ColumnText
                      fontSize='16px'
                      fontWeight='600'
                      color='#7f838b'
                    >
                      {t('ConnectionUsage:topClientsUsage.section2.heading')}
                    </ColumnText>
                  </ShowStats>
                  <EgressCardContainer
                    alignItems='stretch'
                    gap='4px'
                    padding='15px'
                    grow={1}
                    minWidth='496px'
                  >
                    <IngressEgressTableContainer>
                      <TypeTable
                        columnConfig={IngressEgressColumn}
                        rows={egressData}
                        isLoading={topClientLoading}
                        emptyTableText='No Data Found'
                        loadingText={t('Common:loadingData')}
                      />
                    </IngressEgressTableContainer>
                  </EgressCardContainer>
                </BoxContainer>
              </BoxContainer>
            </BoxContainer>
          </BoxContainer>
        </BoxContainer>
      </Wrapper>
    </ContentContainer>
  );
};

export default ConnectionUsageData;
