import React, { useContext, useState, useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { FeatureEnabledContext, ContextProps, EditRoutingContext, EditContextProps } from '../App';
import { Button, ButtonWithIcon, Content, Icon, PageHeader, SelectField, SmallInput, TextAreaField, DatePicker, FilterDropdownContainer, useClickOutside, ButtonWithLoading, Spinner, DateInterval } from 'scorer-ui-kit';
import { useNotification } from '../components/Notifications/useNotification';
import { useTranslation } from 'react-i18next';
import Breadcrums from '../components/molecules/Breadcrums';
import ToggleSwitch from '../components/molecules/ToggleSwitch';
import { editWebgateDeviceLink, isWebgateEnabled, updateWebgateCustomDomain } from '../lib/api_config';
import { useHistory, useParams } from 'react-router-dom';
import useCheckDevice from '../hooks/useCheckDevice';
import { FetchTypeData, useLinkData } from '../hooks/useLinkData';
import { convertEpochToDateString, convertToEpoch, formatCalendarDateTime, getSystemTimezoneAbbreviation } from '../Utils/DateUtils';
import { EditLinkAdvanceConfig } from '../API';
import { validateDomainFields, validateInputs } from '../Utils/ValidateInputFields';
import { ErrorType, handleException } from '../hooks/handleException';
import { handleDismiss } from '../components/Notifications/Notification';
import { convertAndValidateInput } from '../Utils/ConversionUtils';

const MainContainer = styled(Content)`
  width: 100%;
  padding: 0px;
  max-width: calc(100vw - 80px);
  div {
    max-width: 100%;
  }
`;

const Wrapper = styled.div<{ enableContent?: boolean }>`
  width: 100%;
  opacity: ${({ enableContent }) => (enableContent ? '1' : '0.6')};
  pointer-events: ${({ enableContent }) => (enableContent ? 'auto' : 'none')};
`;

const SectionContainer = styled.div<{
  height?: string;
  width?: string;
  gap?: string;
  padding?: string;
  grow?: number;
  direction?: string;
  justify?: string;
  align?: string;
}>`
  width: ${({ width }) => (width ? width : 'auto')};
  height: ${({ height }) => (height ? height : 'auto')};
  align-self: stretch;
  flex-grow: ${({ grow }) => (grow ? grow : 0)};
  display: flex;
  flex-direction: ${({ direction }) => (direction ? direction : 'column')};
  justify-content: ${({ justify }) => (justify ? justify : 'flex-start')};
  align-items: ${({ align }) => (align ? align : 'stretch')};
  gap: ${({ gap }) => (gap ? gap : '16px')};
  padding: ${({ padding }) => (padding ? padding : '32px 96px 64px')};
`;

const Drawer = styled.div`
  height: 24px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  color: #898e96;
`;

const IconWrapper = styled.div<{
  marginTop?: string;
  height?: string;
  width?: string;
  position?: 'absolute' | 'relative';
  left?: string;
  top?: string;
  right?: string;
}>`
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '4px')};
  width: ${({ width }) => (width ? width : '16px')};
  height: ${({ height }) => (height ? height : '16px')};
  display: flex;
  justify-content: center;
  padding: 4px;
  align-items: center;
  flex-grow: 0;
  position: ${({ position }) =>
    position === 'absolute' ? position : 'relative'};
  left: ${({ position, left }) => (position === 'absolute' ? left : 'auto')};
  right: ${({ position, right }) => (position === 'absolute' ? right : 'auto')};
  top: ${({ position, top }) => (position === 'absolute' ? top : 'auto')};
`;

const ColumnText = styled.p<{
  padding?: string;
  fontSize?: string;
  color?: string;
  fontWeight?: string;
  letter?: string;
  line?: string;
  margin?: string;
  family?: string;
  height?: string;
}>`
  height: ${({ height }) => (height ? height : 'auto')};
  padding: ${({ padding }) => (padding ? padding : '0px')};
  font-family: ${({ family }) => (family ? family : 'Lato')};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '14px')};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ color }) => (color ? color : '#898e96')};
  margin: ${({ margin }) => (margin ? margin : '0px')};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : '500')};
  letter-spacing: ${({ letter }) => letter};
  line-height: ${({ line }) => line};
`;

const LinkContainer = styled.a<{
  fontSize?: string;
}>`
  width: auto;
  height: 12px;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '12px')};
  font-weight: 500;
  text-decoration: none !important;
  letter-spacing: -0.2px;
  line-height: 1;
`;

const DeviceInfo = styled.div<{ linkDataLoading?: boolean }>`
  width: auto;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  padding: 2px 0 4px;
  color: #8b9196;
  position: relative;

  > div > div > div > div:nth-child(1) {
    ${({ linkDataLoading }) =>
    linkDataLoading &&
    `
      overflow-wrap: anywhere;
      gap: 1px;
      > div {
        line-height: 21px; 
      }
      > h1 {
        line-height: 30px; 
      }
      `
}
  }

  > div > div > div > div:nth-child(2) {
    ${({ linkDataLoading }) =>
    !linkDataLoading &&
    `
      overflow-wrap: anywhere;
      gap: 1px;
      > div {
        line-height: 21px; 
      }
      > h1 {
        line-height: 30px; 
      }
      `
}
  }
`;

const SpinnerContainer = styled.div`
  position: absolute;
  left: -45px;
  bottom: 20%;
`;

const IconButton = styled(Button) <{
  border?: string;
  background?: string;
  radius?: string;
  height?: string;
  gap?: string;
  padding?: string;
  top?: string;
}>`
  display:flex;
  height: ${({ height }) => (height ? height : '28px')};
  align-items: center;
  justify-content:center';
  font-size : 14px;
  padding: ${({ padding }) => (padding ? padding : '0 8px')};
  gap: ${({ gap }) => (gap ? gap : '8px')};
  border-right: solid 1px #e6e8ec;
  border: ${({ border }) => (border ? border : '')};
  border-radius: ${({ radius }) => (radius ? radius : '3px')};
  background: ${({ background }) => (background ? background : '')};
  pointer-events: none;
  > p {
    overflow-wrap: anywhere;
    line-height: 18px;
    text-align: left;
  }
`;

const DeviceTagCategory = styled.div`
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
  padding: 0;
  font-family: Lato;
  flex-wrap: wrap;
`;

const PageHeaderText = styled.div`
  max-width: 648px !important;
  flex-grow: 1;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.43;
  letter-spacing: 0.04px;
  text-align: left;
  color: #717989;
  font-family: ${({ theme }) => theme.fontFamily.ui};
  height: 60.5px;
`;

const LineDivider = styled.div`
  width: 100%;
  height: 4px;
  padding: 8px;
  border-top: 1px solid #e6e8ec;
`;

const MainSection = styled.div`
  min-width: 940px;
  width: 100%;
  align-self: stretch;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  padding: 32px 96px 64px;
`;

const InnerMainContainer = styled.div`
  min-width: 100%;
`;

const ModalContentCreateLink = styled.div`
  min-width: 940px;
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 32px;
  padding: 0px;
`;

const FormGroupTitle = styled.div`
  width: 100%;
  height: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  padding: 0;
  height: 18.5px;
`;

const Line = styled.div`
  height: 1px;
  flex-grow: 1;
  background-color: #e8e8ec;
`;

const ConfigurationBlock = styled.div<{
  gap?: string;
  marginBottom?: string;
  padding?: string;
}>`
  min-width: 940px;
  margin-bottom: ${({ marginBottom }) => marginBottom};
  width: 100%;
  height: auto;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: ${({ gap }) => (gap ? gap : '8px')};
  padding: ${({ padding }) => (padding ? padding : '4px 0px 0px 0px')};
`;

const FieldRow = styled.div<{
  padding?: string;
  gap?: string;
  justifyContent?: string;
}>`
  min-width: 940px;
  height: auto;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : 'flex-start'};
  align-items: flex-start;
  gap: ${({ gap }) => (gap ? gap : '16px')};
  padding: ${({ padding }) => (padding ? padding : '8px 0')};
  label {
    margin-bottom: 0px !important;
    > span {
      height: 16.5px; 
    }
  }
`;

const LinkTypeField = styled(SelectField) <{
  width?: string;
  minWidth?: string;
}>`
  min-width: ${({ minWidth }) => (minWidth ? minWidth : '204px')};
  width: ${({ width }) => width};
  padding: 0 25px 1px 11px;
  line-height: 1.14;
  font-size: 14px !important;
`;

const DomainLink = styled(SmallInput) <{
  minWidth?: string;
  divWidth?: string;
  width?: string;
  paddingRight?: string;
  position?: string;
}>`
  position: ${({ position }) => position};
  width: ${({ divWidth }) => (divWidth ? divWidth : '100%')};
  label {
    width: 100%;
    margin-bottom: 0px !important;
    gap: 0px;
    span {
      margin-bottom: 8px;
      height: 16.5px;
    }
  }
  div {
    min-width: ${({ minWidth }) => (minWidth ? minWidth : 'fit-content')};
    max-width: 100%;
    height: 40px;
    display: flex;
    flex-grow: 1;
    align-items: center;
    div {
      right: 10px;
      padding-right: 0px !important;
      left: 12px;
      top: 0;
      font-size: 14px;
      line-height: 1.14;
      font-family: Lato;
    }
    input {
      width: ${({ width }) => (width ? width : '100%')};
      padding-right: ${({ paddingRight }) => paddingRight};
    }
  }
`;

const AdvanceOptions = styled.div<{
  minWidth?: string;
  padding?: string;
  marginRight?: string;
  marginTop?: string;
}>`
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : '0px')};
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '0px')};
  min-height: 80px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  padding: ${({ padding }) => (padding ? padding : '8px 0')};
`;

const AdvanceButtons = styled.div<{
  minWidth?: string;
}>`
  max-width: auto;
  min-height: 32px;
  max-height: auto;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  padding: 0;
`;

const ButtonWithIconWrapper = styled.div`
  button > div > div:first-child {
    padding: 0px 7px;
  }
`;

const ButtonsSection = styled.div<{
  loading?: string;
}>`
  max-width: 940px;
  min-height: 40px;
  max-height: auto;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 8px;

  > button {
    padding: 8px 16px;
    > a {
      height: auto;
      line-height: normal;
    }
  }

  > div > button > div > div:first-child {
    padding: 0px;
    padding: ${({ loading }) => (loading === 'true' ? '0px 16px' : '')};
  }
`;

const Div = styled.div``;

const CustomDomain = styled.div<{ marginTop?: string }>`
  min-width: 940px;
  height: auto;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 16px;
  padding: 0px;
  margin-top: ${({ marginTop }) => marginTop};
`;

const RemoveButtonTag = styled.div<{ position?: string }>`
  position: ${({ position }) => position};
  top: 0px;
  right: 0px;
  button {
    padding: 3px 8px;
    height: 20px;
  }
`;

const RemoveWarning = styled.div`
  height: 40px;
  font-size: 14px;
  font-weight: 600;
  color: #f76808;
  padding: 1px 33px;
  border-radius: 3px;
  border: solid 2px #f76808;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RemoveContent = styled.div<{ remove?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  opacity: ${({ remove }) => (remove ? '0.3' : '1')};
`;

const Title = styled.div<{ button?: boolean }>`
  min-width: 940px;
  height: 20px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  padding: 0;

  div {
    width: ${({ button }) => (button ? 'auto' : '100%')};
  }
`;

const Frame = styled.div`
  width : 100%
  height: auto;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 32px;
  padding: 0;
  label{
    margin-bottom: 0px !important;
    width: 100%;
    gap: 0px;
    placeholder: 8px !important;
    span{
      margin-bottom: 8px;
      height: 16.5px;
    }
  }
`;

const CustomTextAreaContainer = styled.div`
  position: relative;
  width: 100%;
`;

const CustomTextArea = styled(TextAreaField) <{ italics: boolean }>`
  width: 100%;
  resize: none;
  height: 165px;
  line-height: 1.57 !important;
  position: relative;
  ::placeholder {
    font-style: ${({ italics }) => (italics ? 'normal' : '')};
  }
`;

const LinkExpiry = styled.div`
  min-width: 940px;
  height: auto;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  padding: 0px;
`;

const SetLimit = styled.div`
  margin-top: 5px;
  min-width: 940px;
  height: auto;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 11px;
  padding: 0px;
`;

const HelperText = styled.div`
  display: flex;
  gap: 8px;
  margin-top: -4px;

  > div:first-child {
    display: flex;
    align-items: center;

    g {
      stroke: #76aaf4;
    }
  }
`;

const DatePickerSection = styled.div`
  height: 64px;
  width: 460px;
  position: relative;
`;

const DateDrawer = styled(FilterDropdownContainer)`
  position: absolute;
  z-index: 100;
  top: 72px;
  right: 0px;
`;

// All interfaces

// Type for the expected parameters
interface IParams {
  deviceID: string;
  linkID: string;
}

// Interface for the list of custom domains list
export interface IReadability {
  editable: boolean;
  value: string;
}
export interface ICustomDomainState {
  id: number;
  domain: string;
  privateKey: IReadability;
  certificate: IReadability;
  remove?: boolean;
}

// Interface for the modified list of custom domains list
interface IModifiedCustomDomainState {
  domain: string;
  privateKey: string;
  certificate: string;
  remove?: boolean;
}

// Interface for Modified Link Expiry
interface IModifiedLinkExpiry {
  time: number;
  action: string;
  remove: boolean;
}

// Interface for modified upload limit
interface IModifiedUploadLimit {
  uploadLimit: number;
  uploadLimitUnit: string;
  remove: boolean;
}

const EditLink: React.FC = () => {
  // variables from the params
  const { deviceID, linkID } = useParams<IParams>();

  // State for storing the link name
  const [linkName, setLinkName] = useState<string>('');

  // Variable defined to use the history hook provided by the router library
  const history = useHistory();

  // Check validation for deviceID and linkID
  const { link, linkDataLoading } = useLinkData(deviceID, linkID);

  // Check validation for webgate enabled or not
  const { isFeatureEnabled, setIsFeatureEnabled, setSwitchState }: ContextProps = useContext(FeatureEnabledContext);

  // Function for setting the state which is used to manage the routing of edit links page
  const { isEditFromHome }: EditContextProps = useContext(EditRoutingContext);

  useEffect(() => {
    if (!isFeatureEnabled && link) isEditFromHome ? history.push('/') : history.push(`/links/${deviceID}`);
  }, [isFeatureEnabled, deviceID, history, link, isEditFromHome]);

  // To use the text transalation files (English - Japanese)
  const { t } = useTranslation(['EditLink', 'Common']);

  // Function used from the useNotification component to send notifications
  const { sendNotification } = useNotification();

  // State for storing the daomin name
  const [domain, setDomain] = useState<string>('[link_id].webgate-biz.scorer.jp');

  // State for storing the matched link data from the API with the link name provided in the params
  const [matchedLink, setMatchedLink] = useState<FetchTypeData>();

  // All States

  // Section 1: Type Configuration

  // State for choosen link type value
  const [selectedLinkValue, setSelectedLinkValue] = useState<string>('');

  // State for using Webgate link name
  const [inputLinkName, setInputLinkName] = useState<string>('');

  // Section 2: Basic Configuration

  // State for choosen protocol type value
  const [selectedProtocol, setSelectedProtocol] = useState<string>('');

  // State for using the link port value
  const [inputPort, setInputPort] = useState<number | string>('');

  // Section 3: Advanced Configuration
  // State for using the index toggle behaviour
  const [indexToggle, setIndexToggle] = useState(true);

  // Section 3a: Custom Domain Configuration

  // State for checking the state of custom domain section
  const [isActiveDomain, setIsActiveDomain] = useState(false);

  // State for storing the list of custom domains with editable flags and ids
  const [customDomainList, setCustomDomainList] = useState<ICustomDomainState[]>([]);

  // State for storing the modified list of custom domains
  const [modifiedCustomDomainList, setModifiedCustomDomainList] = useState<IModifiedCustomDomainState[]>([]);

  // State for storing the max number of custom domain can be added at that time
  const [domainsFromAPI, setDomainsFromAPI] = useState<number>(0);

  // Section 3b: Link Expiry Configuration

  // State for checking the state of link expiry section
  const [isActiveLink, setIsActiveLink] = useState(false);

  //State for checking the date picker visibility state
  const [isDatePicker, setIsDatePicker] = useState(false);

  // State for storing the date in epoch format
  const [expiryDate, setExpiryDate] = useState<number>(0);

  // State for storing thee date choose from the calender
  const [selectedDate, setSelectedDate] = useState<Date | DateInterval | undefined>(undefined);

  // Variable to store initial expiry date
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + 7);
  currentDate.setHours(0, 0, 0, 0);

  // Reference and function for clicking outside the datepicker area
  const datepickerRef = useRef<HTMLDivElement>(null);
  useClickOutside(datepickerRef, () => setIsDatePicker(false));

  // State for storing the behaviour of the expiry
  const [selectedExpiry, setSelectedExpiry] = useState<string>('');

  // State for storing the Modified Link Expiry
  const [modifiedLinkExpiry, setModifiedLinkExpiry] =
    useState<IModifiedLinkExpiry>({
      time: 0,
      action: '',
      remove: false,
    });

  // Section 3c: Upload Limit Override Configuration

  // State for checking the state of upload limit section
  const [isActiveLimit, setIsActiveLimit] = useState(false);

  // State for storing the input of upload limit value
  const [inputUnit, setInputUnit] = useState<string>('100');

  // State for storing the unit of upload limit
  const [selectedUnit, setSelectedUnit] = useState<string>('MiB');

  // State for storing the modified upload limit
  const [modifiedUploadLimit, setModifiedUploadLimit] =
    useState<IModifiedUploadLimit>({
      uploadLimit: 0,
      uploadLimitUnit: '',
      remove: false,
    });

  // State for setting api loading
  const [loading, setLoading] = useState(false);

  // Page Data

  // Fetched device Name and the categories or tags from the API hook
  const { deviceName, tagsCategories } = useCheckDevice(deviceID);

  // Breadcrums data
  const breadCrumsData = [
    {
      href: '/',
      text: t('Common:breadCrums.home'),
    },
    {
      href: `/links/${deviceID}`,
      text: t('Common:breadCrums.deviceLinks'),
    },
    {
      text: t('Common:breadCrums.EditLink'),
    },
  ];

  // Create references for CustomDomain, LinkExpiry, SetLimit component
  const lastDomainRef = useRef<HTMLDivElement>(null);
  const linkExpiryRef = useRef<HTMLDivElement>(null);
  const limitRef = useRef<HTMLDivElement>(null);

  // Section 1: Type Configuration

  // Variable with options for the link type dropdown
  const linkOptions = [
    {
      value: 'Edge UI',
      label: t('EditLink:mainContent.typeConfig.typeOptions.option1'),
    },
    {
      value: 'Solution UI',
      label: t('EditLink:mainContent.typeConfig.typeOptions.option2'),
    },
    {
      value: 'Other Links',
      label: t('EditLink:mainContent.typeConfig.typeOptions.option3'),
    },
  ];

  // Function for updating the selected link type value
  const handleChangeLinkType = (value: string) => {
    if (value === 'Edge UI') {
      setSelectedLinkValue(value);
      setInputLinkName(t('CreateLinks:mainContent.typeConfig.typeOptions.option1'));
      setSelectedProtocol('HTTPS');
      setInputPort(20001);
    } else if (value === 'Solution UI') {
      setSelectedLinkValue(value);
      setInputLinkName(t('CreateLinks:mainContent.typeConfig.typeOptions.option2'));
      setSelectedProtocol('HTTPS');
      setInputPort(80);
    } else {
      setSelectedLinkValue(value);
      setInputLinkName('');
      setSelectedProtocol('');
      setInputPort('');
    }
  };

  // Function for updating the webgate link name
  const handleLinkNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputLinkName(e.target.value);
  };

  // Section 2: Basic Configuration

  // Variable with options for the protocol type dropdown
  const protocolOptions = [
    {
      value: 'HTTPS',
      label: t('EditLink:mainContent.basicConfig.typeOptions.option1'),
    },
    {
      value: 'RTMPS',
      label: t('EditLink:mainContent.basicConfig.typeOptions.option2'),
    },
  ];

  // Function for updating the selected protocol type value
  const handleChangeType = (value: string) => {
    if (value === 'RTMPS') {
      setInputPort(8443);
    } else {
      setInputPort('');
    }
    setSelectedProtocol(value);
  };

  // Funtion for setting the link port
  const handleChangePort = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    // Validate: Allow only numbers and prevent less than 0
    if (/^\d*$/.test(inputValue) && Number(inputValue) >= 0) {
      setInputPort(Number(inputValue));
    }
  };

  // Section 3: Advanced Configuration

  // Section 3a: Custom Domain Configuration

  // Function for updatng the isActiveDomain state and setting the initial values in customDomainList state
  const handleDomain = useCallback(() => {
    setCustomDomainList(prev => {
      const newDomain = {
        id: prev.length + 1,
        domain: '',
        privateKey: { editable: true, value: '' },
        certificate: { editable: true, value: '' },
      };

      const updatedList = [...prev, newDomain];

      setTimeout(() => {
        // Scroll to the last custom domain
        lastDomainRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 0);

      return updatedList;
    });

    setIsActiveDomain(true);
  }, []);

  // Function for updatng the individial keys in the customDomainList state
  const handleInputDomainChange = (
    id: number,
    field: keyof ICustomDomainState,
    value: string
  ) => {
    setCustomDomainList(prev =>
      prev.map(domain =>
        domain.id === id
          ? {
            ...domain,
            [field]:
              field === 'privateKey' || field === 'certificate'
                ? { ...domain[field], value: value.trim() } // Only update the value for privateKey or certificate
                : value.trim(), // For other fields, update directly
          }
          : domain
      )
    );
  };

  // Function for updating the state of private key & cetificate fields
  const handleEditable = (id: number, field: keyof ICustomDomainState) => {
    setCustomDomainList(prev =>
      prev.map(domain => {
        if (domain.id === id) {
          // Use type assertion to ensure that field is of type 'Readability'
          const currentField = domain[field] as IReadability;

          return {
            ...domain,
            [field]: {
              editable: !currentField?.editable,
              value: currentField?.editable ? '' : currentField?.value,
            },
          };
        }
        return domain; // Return the domain unchanged if the ID doesn't match
      })
    );
  };

  // Funcion for removing the custom domains one by one from the list
  const removeCustomDomain = useCallback(
    (id: number) => {
      setCustomDomainList(prev => {
        // Update the domain list based on the provided ID
        const updatedList = prev
          .map((domain) =>
            domain.id === id
              ? {
                ...domain,
                privateKey: {
                  value: '',
                  editable: false,
                },
                certificate: {
                  value: '',
                  editable: false,
                },
                remove: !domain.remove,
              }
              : domain
          )
          .filter(domain => domain.id <= domainsFromAPI || domain.id !== id)
          .map((prev, index) => ({
            ...prev,
            id: index + 1
          })); // Remove domain if id > domainsFromAPI

        // Check if the updated list is empty and update isActiveDomain
        if (updatedList.length === 0) {
          setIsActiveDomain(false);
        }

        return updatedList;
      });
    },
    [domainsFromAPI]
  );

  // Function for checking all the 3 fields of a custom domain are filled if the domain name is not matched with the API data
  const areAllUnmatchedDomainsFilled = () => {
    // Function for checking the filled domain name was their in the API data or not
    const isDomainInMatchedLink = (domainValue: number) => {
      if (matchedLink) {
        // Check if the domainValue exists in the links array
        return domainValue <= domainsFromAPI;
      }
      return false;
    };

    // Filter unmatched domains
    const unmatchedDomains = customDomainList.filter(
      domain => !isDomainInMatchedLink(domain.id)
    );

    // Check if all unmatched domains have non-empty domain, privateKey, and certificate values
    return unmatchedDomains.every(
      domain =>
        domain.domain.trim() !== '' &&
        domain.privateKey.value.trim() !== '' &&
        domain.certificate.value.trim() !== ''
    );
  };

  // Section 3b: Link Expiry Configuration

  // Function for closing the link expiry section
  const handleLink = () => {
    setIsActiveLink(true);
    setTimeout(() => {
      linkExpiryRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, 0);
  };

  // Variable with options for the expiry behaviour dropdown
  const expiryOptions = [
    {
      value: 'Disable',
      label: t('EditLink:mainContent.linkConfig.expiryOptions.option1'),
    },
    {
      value: 'Delete',
      label: t('EditLink:mainContent.linkConfig.expiryOptions.option2'),
    },
  ];

  // Function for updating the selected expiry behaviour value
  const handleChangeExpiry = (value: string) => {
    setSelectedExpiry(value);
  };

  // Section 3c: Upload Limit Override Configuration

  // Function for closing the limit override section
  const handleLimit = () => {
    setIsActiveLimit(true);
    setTimeout(() => {
      limitRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, 0);
  };

  // Funtion for changing the value of input limit in the state
  const handleInputUnit = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    if (inputValue.length <= 6) {
      setInputUnit(e.target.value);
    }
  };

  // Variable with options for the upload limit unit dropdown
  const unitOptions = [
    { value: 'MiB', label: t('EditLink:mainContent.limitConfig.unitOption1') },
    { value: 'GiB', label: t('EditLink:mainContent.limitConfig.unitOption2') },
  ];

  // Function for updating the upload limit unit value
  const handleChangeUnit = (value: string) => {
    setSelectedUnit(value);
  };

  // Fnution for redirecting the users from the edit link page to device links page
  const handleCancel = () => {
    isEditFromHome ? history.push('/') : history.push(`/links/${deviceID}`);
  };

  const SendBack = useCallback((name: string) => {
    handleDismiss(); // Clear all notification bar before sending a new one
    sendNotification({
      type: 'warning',
      message: t('EditLink:notificationMessage.isLinkDisabled').replace('{linkName}', name)
    });
    isEditFromHome ? history.push('/') : history.push(`/links/${deviceID}`);
  }, [t, deviceID, history, sendNotification, isEditFromHome]);

  // UseEffect for updating the field on the ui after the link variable is updated which we are taking from the useFetchLink hook
  useEffect(() => {
    if (link) {
      if (link.status.id.toLowerCase() === 'disabled') {
        SendBack(link.name);
        return;
      }
      setMatchedLink(link);
      let newLinkName;
      if (link.name === 'Edge UI' && link.port === 20001) {
        newLinkName = t('EditLink:mainContent.typeConfig.typeOptions.option1');
      } else if (link.name === 'Solution UI' && link.port === 80) {
        newLinkName = t('EditLink:mainContent.typeConfig.typeOptions.option2');
      } else {
        newLinkName = link.name;
      }
      setLinkName(newLinkName);
      setSelectedLinkValue(link.accordionGrp === 'Other Links' ? link.accordionGrp : link.name);

      setInputLinkName(newLinkName);

      setSelectedProtocol(link.connection);
      setDomain(link.links[0]);
      setInputPort(link.port);
      setIndexToggle(link.noIndex);

      if (link.links.length > 1) {
        setIsActiveDomain(true);
        setDomainsFromAPI(link.links.slice(1).length);
        const customDomainList = link.links
          .slice(1)
          .map((domain: string, index: number) => ({
            id: index + 1,
            domain,
            privateKey: { editable: false, value: '' },
            certificate: { editable: false, value: '' },
            remove: false,
          }));
        setCustomDomainList(customDomainList);
      }

      if (link.expiry?.time !== 0) {
        setIsActiveLink(true);
        setExpiryDate(link.expiry.time);
        setSelectedDate(convertEpochToDateString(link.expiry.time));
        setSelectedExpiry(link.accordionGrp !== 'Other Links' && ['Solution UI', 'Edge UI'].includes(link.name) && link.expiry.action === 'Flush' ? 'Delete' : link.expiry.action);
      }

      if (
        !(
          link.uploadLimit?.uploadLimit === 100 &&
          link.uploadLimit?.uploadLimitUnit === 'MiB'
        ) &&
        link.uploadLimit?.uploadLimit !== 0
      ) {
        setIsActiveLimit(true);
        setInputUnit(link.uploadLimit.uploadLimit.toString());
        setSelectedUnit(link.uploadLimit.uploadLimitUnit);
      }
    }
  },
  // eslint-disable-next-line
    [link, history, deviceID]);

  useEffect(() => {
    // Update linkName based on translations
    setLinkName((prev) => {
      return ['Edge UI', 'デバイス管理UI'].includes(prev) && inputPort === 20001
        ? t('EditLink:mainContent.typeConfig.typeOptions.option1')
        : ['Solution UI', 'ソリューションUI'].includes(prev) && inputPort === 80
          ? t('EditLink:mainContent.typeConfig.typeOptions.option2')
          : prev;
    });
    setInputLinkName((prev) => {
      return ['Edge UI', 'デバイス管理UI'].includes(prev) && inputPort === 20001
        ? t('EditLink:mainContent.typeConfig.typeOptions.option1')
        : ['Solution UI', 'ソリューションUI'].includes(prev) && inputPort === 80
          ? t('EditLink:mainContent.typeConfig.typeOptions.option2')
          : prev;
    });

  }, [inputPort, t]);


  // If matched link found then update the field of custom domain on the ui
  useEffect(() => {
    if (!matchedLink) return;

    // Map custom domain from matchedLink
    const customDomainListFromMatchedLink: ICustomDomainState[] =
      matchedLink.links.slice(1).map((link: string, index: number) => ({
        id: index + 1,
        domain: link,
        privateKey: { editable: false, value: '' },
        certificate: { editable: false, value: '' },
      }));

    const modifiedCustomDomainList: ICustomDomainState[] =
      customDomainListFromMatchedLink.map((localItem: ICustomDomainState) => {
        const matchedItem = customDomainList.find(
          apiItem => apiItem.domain === localItem.domain
        );
        // Case 1 & 2: Object unchanged or changed (use matchedItem for the latest values)
        // Case 3: Object removed from customDomainList but exists in matchedLink
        return matchedItem || localItem;
      });

    // Add any new items from the API that were not in matchedLink
    const newItems = customDomainList.filter(
      apiItem =>
        !customDomainListFromMatchedLink.some(
          (localItem: ICustomDomainState) => localItem.domain === apiItem.domain
        )
    );

    newItems.forEach(item => {
      modifiedCustomDomainList.push({ ...item });
    });

    // Add those entries in the modified custom domain list for which some changes have been done by the user
    const modifiedListWithoutId = modifiedCustomDomainList
      .filter(
        ({ privateKey, certificate, remove }) =>
          certificate.value || privateKey.value || remove === true
      )
      .map(({ id, privateKey, certificate, ...rest }) => ({
        ...rest,
        privateKey: privateKey.value,
        certificate: certificate.value,
      }));

    setModifiedCustomDomainList(modifiedListWithoutId);

    // Case for linkExpiry
    const modifiedLinkExpiry =
      expiryDate === 0 &&
        selectedExpiry === '' &&
        (matchedLink.expiry.time !== expiryDate ||
          matchedLink.expiry.action !== selectedExpiry)
        ? {
          // Case 1: expiryDate is 0 and selectedExpiry is empty and doesn't match matchedLink
          time: expiryDate,
          action: selectedExpiry,
          remove: true,
        }
        : {
          // Case 2: Regular case where expiryDate and selectedExpiry are valid
          time: expiryDate,
          action: ['Solution UI', 'Edge UI'].includes(selectedLinkValue) && selectedExpiry === 'Delete' ? 'Flush' : selectedExpiry,
          remove: false,
        };

    // Normalizing the Input Port using a common funciton
    const normalizedUnit = Number(inputUnit.replace(/[０-９]/g, (match) => {
      // Convert fullwidth digit to its corresponding regular digit
      return String.fromCharCode(match.charCodeAt(0) - 0xFF10 + 0x30);
    }));
    const updatedUnit = parseInt(normalizedUnit.toString());

    // Case for uploadLimit
    const modifiedUploadLimit =
      !updatedUnit &&
        selectedUnit === '' &&
        (matchedLink.uploadLimit.uploadLimit !== Number(updatedUnit) ||
          matchedLink.uploadLimit.uploadLimitUnit !== selectedUnit)
        ? {
          // Case 1: uploadLimit is 0 and selectedUnit is empty and doesn't match matchedLink
          uploadLimit: Number(updatedUnit),
          uploadLimitUnit: selectedUnit,
          remove: true,
        }
        : {
          // Case 2: Regular case where uploadLimit and selectedUnit are valid
          uploadLimit: Number(updatedUnit),
          uploadLimitUnit: selectedUnit,
          remove: false,
        };

    setModifiedLinkExpiry(modifiedLinkExpiry);
    setModifiedUploadLimit(modifiedUploadLimit);
  }, [
    inputUnit,
    expiryDate,
    matchedLink,
    selectedUnit,
    selectedExpiry,
    customDomainList,
    selectedLinkValue
  ]);

  // Compare the advance config from the API data and add those things in the object which are edited by the user

  const updatedAdvancedConfig = useCallback(() => {
    // Create an object to store the changed values
    let ConfigObj: EditLinkAdvanceConfig = {};

    // Compare and add only changed values
    if (matchedLink?.noIndex !== indexToggle) {
      ConfigObj.noIndex = indexToggle;
    }

    // Compare expiry
    if (
      matchedLink?.expiry.time !== modifiedLinkExpiry.time ||
      matchedLink.expiry.action !== modifiedLinkExpiry.action
    ) {
      ConfigObj.linkExpiry = modifiedLinkExpiry;
    }

    // Compare upload limit
    if (
      matchedLink?.uploadLimit.uploadLimit !==
      Number(modifiedUploadLimit.uploadLimit) ||
      matchedLink.uploadLimit.uploadLimitUnit !==
      modifiedUploadLimit.uploadLimitUnit
    ) {
      ConfigObj.linkUploadLimit = modifiedUploadLimit;
    }
    return ConfigObj;
  }, [matchedLink, indexToggle, modifiedLinkExpiry, modifiedUploadLimit]);

  // Edit Link API Call
  const handleEditDeviceLink = useCallback(async () => {
    const isDomainInMatchedLink = (domainValue: string) => {
      if (matchedLink) {
        // Check if the domainValue exists in the links array
        return matchedLink.links.slice(1).includes(domainValue);
      }
      return false;
    };

    const domainlist = customDomainList
      .filter(domain => !isDomainInMatchedLink(domain.domain))
      .map(({ id, privateKey, certificate, ...rest }) => ({
        ...rest,
        privateKey: privateKey.value,
        certificate: certificate.value,
      }));

    // Normalizing the Input Port using a common funciton
    const normalizedInputUnit = convertAndValidateInput(
      inputUnit,
      'CreateLinks:notificationMessage.enterValidUploadLimit',
      handleDismiss,
      sendNotification,
      t
    );
    const updatedInputUnit = parseInt(normalizedInputUnit || '');
    if (Number.isNaN(updatedInputUnit) || updatedInputUnit === null) return;
    setInputUnit(updatedInputUnit.toString());

    if (
      !validateInputs(
        selectedLinkValue,
        inputLinkName,
        selectedProtocol,
        inputPort,
        isActiveDomain,
        domainlist,
        isActiveLink,
        expiryDate,
        selectedExpiry,
        isActiveLimit,
        updatedInputUnit.toString(),
        selectedUnit,
        sendNotification,
        t
      )
    ) {
      return;
    }
    if (matchedLink) {
      const domains = customDomainList.map(item => item.domain);
      const isDuplicate = domains.some((domain, idx) => {
        return domains.indexOf(domain) !== idx;
      });
      // Check for duplicate Custom Domains
      if (isDuplicate) {
        handleDismiss(); // Clear all notification bar before sending a new one
        await new Promise(resolve => setTimeout(resolve, 300));
        sendNotification({
          type: 'info',
          message: t('EditLink:notificationMessage.duplicateDomains'),
        });
        return;
      }
      // Check for invalid custom domains in matched domains from the API data
      const matchedDomains = matchedLink.links.slice(1);
      if (
        validateDomainFields(
          matchedDomains,
          customDomainList,
          'privateKey',
          sendNotification,
          t
        ) ||
        validateDomainFields(
          matchedDomains,
          customDomainList,
          'certificate',
          sendNotification,
          t
        )
      ) {
        return true;
      }
    }
    // if (
    //   inputLinkName === 'デバイス管理UI' ? 'Edge UI' : inputLinkName === 'ソリューションUI' ? 'Solution UI' : inputLinkName === matchedLink?.name &&
    //   Object.keys(updatedAdvancedConfig()).length === 0 &&
    //   modifiedCustomDomainList.length === 0
    // ) {
    //   // handleDismiss(); // Clear all notification bar before sending a new one
    //   // await new Promise(resolve => setTimeout(resolve, 300));
    //   // sendNotification({
    //   //   type: 'info',
    //   //   message: t('EditLink:notificationMessage.noChangeMade'),
    //   // });
    //   return;
    // }
    try {
      setLoading(true);

      // Check for webgate feature
      const enabled = await isWebgateEnabled();
      // if API returns a response then set the featue enabled state
      if (enabled !== null) setIsFeatureEnabled(enabled);
      // if the feature is disaled then exit from the loop
      if (enabled === false || !enabled) {
        handleDismiss(); // Clear all notification bar before sending a new one
        await new Promise(resolve => setTimeout(resolve, 300));
        sendNotification({
          type: 'warning',
          message: t('Common:notificationMessage.webgateFeatureDisabled'),
        });
        isEditFromHome ? history.push('/') : history.push(`/links/${deviceID}`);
        return;
      }

      // Webgate feature switch state setting function
      setSwitchState('locked');

      // Call editWebgateDeviceLink only if any link details has been changed
      if (
        !(
          inputLinkName === matchedLink?.name &&
          Object.keys(updatedAdvancedConfig()).length === 0
        )
      ) {
        const linkData = await editWebgateDeviceLink(
          deviceID,
          linkID,
          'edit',
          {
            name: inputLinkName.trim() === 'デバイス管理UI' && inputPort === 20001 ? 'Edge UI' : inputLinkName.trim() === 'ソリューションUI' && inputPort === 80 ? 'Solution UI' : inputLinkName.trim(),
            port: Number(inputPort),
          },
          updatedAdvancedConfig()
        );
        if (!linkData) throw new Error(ErrorType.PARSE);

        handleDismiss(); // Clear all notification bar before sending a new one
        await new Promise(resolve => setTimeout(resolve, 300));
        if (linkData.editWebgateDeviceLink?.linkEdited) {
          // Notify the user of success
          sendNotification({
            type: 'success',
            message: t('EditLink:notificationMessage.success')
              .replace('{linkName}', inputLinkName.trim()),
          });
        } else {
          setSwitchState('default');
          sendNotification({
            type: 'info',
            message: t('EditLink:notificationMessage.notEdited')
              .replace('{linkName}', inputLinkName.trim())
          });
          return;
        }
      }

      // Call updateWebgateCustomDomain for the edited or newly added custom domains
      if (modifiedCustomDomainList.length > 0) {
        for (const domainData of modifiedCustomDomainList) {
          try {
            const domainUpdateData = await updateWebgateCustomDomain(
              deviceID,
              linkID,
              Number(inputPort),
              domainData.domain,
              domainData.privateKey,
              domainData.certificate,
              domainData.remove
            );

            handleDismiss(); // Clear all notification bar before sending a new one
            await new Promise(resolve => setTimeout(resolve, 300));
            if (
              !domainUpdateData?.updateWebgateCustomDomain?.domainUpdateStatus
            ) {
              sendNotification({
                type: 'error',
                message: t('EditLink:notificationMessage.domainUpdateFailed')
                  .replace('{domain}', domainData.domain)
                  .replace('{linkName}', inputLinkName.trim()),
              });
            } else {
              sendNotification({
                type: 'success',
                message: t('EditLink:notificationMessage.domainUpdateSuccess')
                  .replace('{domain}', domainData.domain)
                  .replace('{linkName}', inputLinkName.trim()),
              });
            }
          } catch (error) {
            const exception = handleException(error);
            console.error(
              `Error creating domain:${domainData.domain}, ${error}`
            );
            setSwitchState('default');

            handleDismiss(); // Clear all notification bar before sending a new one
            await new Promise(resolve => setTimeout(resolve, 300));
            sendNotification({
              type: 'error',
              message: t('EditLink:notificationMessage.errorUpdatingDomain')
                .replace('{domain}', domainData.domain)
                .replace('{linkName}', inputLinkName.trim())
                .replace('{errorMessage}', t(`Common:exceptionNotificationMessages.${exception.message}`)),
            });
            return;
          }
        }
      }

      // Wait for 2 seconds before navigating
      await new Promise(resolve => setTimeout(resolve, 3000));
      isEditFromHome ? history.push('/') : history.push(`/links/${deviceID}`);
      return;
    } catch (error) {
      const exception = handleException(error);
      console.error(`Error updating device:${deviceName}, ${error}`);
      setSwitchState('default');

      handleDismiss(); // Clear all notification bar before sending a new one
      await new Promise(resolve => setTimeout(resolve, 300));
      sendNotification({
        type: 'error',
        message: t('EditLink:notificationMessage.error')
          .replace('{linkName}', inputLinkName.trim())
          .replace('{errorMessage}', t(`Common:exceptionNotificationMessages.${exception.message}`)),
      });
    } finally {
      setLoading(false);
    }
  }, [
    t,
    linkID,
    history,
    deviceID,
    inputUnit,
    inputPort,
    expiryDate,
    deviceName,
    matchedLink,
    isActiveLink,
    selectedUnit,
    inputLinkName,
    isActiveLimit,
    isActiveDomain,
    isEditFromHome,
    selectedExpiry,
    setSwitchState,
    sendNotification,
    selectedProtocol,
    customDomainList,
    selectedLinkValue,
    setIsFeatureEnabled,
    updatedAdvancedConfig,
    modifiedCustomDomainList
  ]);

  // Function for generating the custom domain fields on the ui
  const generateCustomDomain = useCallback(
    (domain: ICustomDomainState) => {
      const isDomainInMatchedLink = (domainValue: number) => {
        if (matchedLink) {
          // Check if the domainValue exists in the custom domain links array.
          return domainValue <= domainsFromAPI;
        }
        return false;
      };

      const isDisabled = isDomainInMatchedLink(domain.id);

      return (
        <CustomDomain key={domain.id} marginTop='8px'>
          <Title button>
            <FormGroupTitle>
              <ColumnText fontSize='16px' color='#5a6269' family='monorale'>
                {t('EditLink:mainContent.customConfig.heading')}{' '}
                {customDomainList.length > 1 ? domain.id : ''}
              </ColumnText>
              <Line />
            </FormGroupTitle>
            {isDisabled && !domain.remove && (
              <RemoveButtonTag>
                <Button
                  design='secondary'
                  size='xsmall'
                  onClick={() => {
                    handleEditable(domain.id, 'privateKey');
                    handleEditable(domain.id, 'certificate');
                  }}
                  disabled={loading || domain.remove}
                >
                  {domain.privateKey.editable && domain.certificate.editable ? t('EditLink:mainContent.customConfig.cancelUpdateKey&Certificate') : t('EditLink:mainContent.customConfig.updateKey&Certificate')}
                </Button>
              </RemoveButtonTag>
            )}
            <RemoveButtonTag>
              <Button
                design='secondary'
                size='xsmall'
                onClick={() => removeCustomDomain(domain.id)}
                disabled={loading}
              >
                {domain.remove
                  ? t('EditLink:mainContent.customConfig.keepButton')
                  : t('EditLink:mainContent.customConfig.removeButton')}
              </Button>
            </RemoveButtonTag>
          </Title>
          {domain.remove && (
            <RemoveWarning>
              {t('EditLink:mainContent.customConfig.removeWarning')}
            </RemoveWarning>
          )}
          <RemoveContent remove={domain.remove}>
            <DomainLink
              name='domain'
              value={domain.domain}
              label={t('EditLink:mainContent.customConfig.domain')}
              fieldState={loading || isDisabled ? 'disabled' : 'default'}
              disabled={loading || isDisabled}
              autoComplete='off'
              minWidth='940px'
              placeholder='subdomain.yourdomain.com'
              onChange={(e) => handleInputDomainChange(domain.id, 'domain', e.target.value)}
            />
            <Frame>
              <CustomTextAreaContainer>
                <CustomTextArea
                  id={`${domain.id}privateKey`}
                  name={`${domain.id}privateKey`}
                  label={t('EditLink:mainContent.customConfig.privateKey')}
                  fieldState={loading || !domain.privateKey.editable ? 'disabled' : 'default'}
                  autoComplete='off'
                  disabled={loading || !domain.privateKey.editable}
                  italics={!domain.privateKey.editable}
                  placeholder={!domain.privateKey.editable ? t('EditLink:mainContent.customConfig.privateKeyHiddenPlaceHolder') : t('EditLink:mainContent.customConfig.privatePlaceHolder')}
                  value={domain.privateKey.value}
                  onChange={e => handleInputDomainChange(domain.id, 'privateKey', e.target.value)}
                />
              </CustomTextAreaContainer>

              <CustomTextAreaContainer>
                <CustomTextArea
                  id={`${domain.id}certificate`}
                  name={`${domain.id}certificate`}
                  label={t('EditLink:mainContent.customConfig.certificate')}
                  fieldState={loading || !domain.certificate.editable ? 'disabled' : 'default'}
                  autoComplete='off'
                  disabled={loading || !domain.certificate.editable}
                  italics={!domain.certificate.editable}
                  placeholder={!domain.certificate.editable ? t('EditLink:mainContent.customConfig.certificateHiddenPlaceHolder') : t('EditLink:mainContent.customConfig.certificatePlaceHolder')}
                  value={domain?.certificate.value}
                  onChange={e => handleInputDomainChange(domain.id, 'certificate', e.target.value)}
                />
              </CustomTextAreaContainer>
            </Frame>
          </RemoveContent>
        </CustomDomain>
      );
    },
    [
      t,
      loading,
      matchedLink,
      domainsFromAPI,
      customDomainList,
      removeCustomDomain
    ]
  );

  return (
    <MainContainer>
      <Wrapper enableContent={isFeatureEnabled}>
        <SectionContainer height='72px' gap='8px' padding='48px 99px 0'>
          <Drawer>
            <Breadcrums backLink showBreadcrumbs breadcrumbs={breadCrumsData} />
          </Drawer>
        </SectionContainer>
        <SectionContainer padding='16px 0 4px' gap='8px'>
          <SectionContainer gap='8px' padding='22px 96px 34px'>
            <SectionContainer padding='0'>
              <SectionContainer gap='24px' padding='0 32px 6px 0' grow={1}>
                <DeviceInfo linkDataLoading={linkDataLoading}>
                  <PageHeader
                    icon={`${linkDataLoading ? '' : 'Link'}`}
                    areaTitleBottom
                    areaTitle={
                      linkName !== '' ? `${linkName} - ${linkID}` : '-'
                    }
                    title={t('EditLink:pageTitle')}
                  />
                  {linkDataLoading && (
                    <SpinnerContainer>
                      <Spinner size='medium' styling='primary' />
                    </SpinnerContainer>
                  )}
                </DeviceInfo>
                <SectionContainer gap='16px' padding='2px 0'>
                  <SectionContainer direction='column' padding='0px' gap='19px'>
                    {tagsCategories.length !== 0 && (
                      <DeviceTagCategory>
                        {tagsCategories.map((data, index) => {
                          return (
                            <IconButton
                              key={index}
                              background='transparent'
                              design='secondary'
                              size='small'
                              border='solid 1px #e6e8ec'
                              height='max-content'
                              gap='2px'
                              padding='0px 0px 0px 1px'
                            >
                              <IconWrapper marginTop='0px'>
                                <Icon
                                  size={8}
                                  icon={data.icon}
                                  color='dimmed'
                                  weight='regular'
                                />
                              </IconWrapper>
                              <ColumnText
                                fontSize='12px'
                                padding='0px 6px 0px 0px'
                              >
                                {data.text}
                              </ColumnText>
                            </IconButton>
                          );
                        })}
                      </DeviceTagCategory>
                    )}
                    <PageHeaderText>
                      {t('EditLink:introductionText')}
                    </PageHeaderText>
                  </SectionContainer>
                </SectionContainer>
              </SectionContainer>
            </SectionContainer>
          </SectionContainer>
          <LineDivider />
        </SectionContainer>
        <MainSection>
          <InnerMainContainer>
            <ModalContentCreateLink>
              <ConfigurationBlock marginBottom='4px'>
                <FormGroupTitle>
                  <ColumnText fontSize='16px' color='#5a6269' family='monorale'>
                    {t('EditLink:mainContent.typeConfig.heading')}
                  </ColumnText>
                  <Line />
                </FormGroupTitle>
                <FieldRow>
                  <LinkTypeField
                    label={{
                      htmlFor: 'linkType',
                      text: t('EditLink:mainContent.typeConfig.linkType'),
                    }}
                    changeCallback={handleChangeLinkType}
                    placeholder={t('EditLink:mainContent.typeConfig.placeHolder1')}
                    defaultValue={selectedLinkValue}
                    value={selectedLinkValue}
                    minWidth='220px'
                    disabled
                    required
                  >
                    {' '}
                    {linkOptions.map(option => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </LinkTypeField>
                  <DomainLink
                    name='link_name'
                    id='link_name'
                    minWidth='420px'
                    divWidth='420px'
                    width='420px'
                    autoComplete='off'
                    paddingRight='0px'
                    maxLength={100}
                    label={t('EditLink:mainContent.typeConfig.linkName')}
                    placeholder={t('EditLink:mainContent.typeConfig.placeHolder2')}
                    value={inputLinkName}
                    onChange={handleLinkNameChange}
                    fieldState={
                      loading ||
                        ['Solution UI', 'Edge UI'].includes(selectedLinkValue)
                        ? 'disabled'
                        : 'default'
                    }
                    disabled={
                      loading ||
                      ['Solution UI', 'Edge UI'].includes(selectedLinkValue) || linkDataLoading
                    }
                  />
                </FieldRow>
              </ConfigurationBlock>

              <ConfigurationBlock>
                <FormGroupTitle>
                  <ColumnText fontSize='16px' color='#5a6269' family='monorale'>
                    {t('EditLink:mainContent.basicConfig.heading')}
                  </ColumnText>
                  <Line />
                </FormGroupTitle>
                <FieldRow>
                  <LinkTypeField
                    label={{
                      htmlFor: 'protocol',
                      text: t('EditLink:mainContent.basicConfig.protocol'),
                    }}
                    value={selectedProtocol}
                    defaultValue={selectedProtocol}
                    placeholder={t('EditLink:mainContent.basicConfig.typeOptions.placeHolder')}
                    changeCallback={handleChangeType}
                    minWidth='250px'
                    disabled
                    required
                  >
                    {' '}
                    {protocolOptions.map(option => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </LinkTypeField>
                  <DomainLink
                    name='domain'
                    id='domain'
                    divWidth='576px'
                    paddingRight='0px'
                    label={t('EditLink:mainContent.basicConfig.domain')}
                    value={domain}
                    fieldState='disabled'
                    disabled
                  />
                  <DomainLink
                    name='port_number'
                    id='port_number'
                    divWidth='auto'
                    paddingRight='0px'
                    label={t('EditLink:mainContent.basicConfig.portNumber')}
                    placeholder={t('EditLink:mainContent.basicConfig.placeHolder')}
                    autoComplete='off'
                    value={inputPort}
                    type='text'
                    onChange={handleChangePort}
                    fieldState='disabled'
                    disabled
                  />
                </FieldRow>
              </ConfigurationBlock>

              <ConfigurationBlock padding='8px 0px 0px 0px' marginBottom='8px'>
                <FormGroupTitle>
                  <ColumnText fontSize='16px' color='#5a6269' family='monorale'>
                    {t('EditLink:mainContent.advancedConfig.heading')}
                  </ColumnText>
                  <Line />
                </FormGroupTitle>
                <FieldRow
                  padding='0px'
                  gap='48px'
                  justifyContent='space-between'
                >
                  <AdvanceOptions minWidth='764px'>
                    <ColumnText
                      color='#889096'
                      fontSize='14px'
                      family='monorale'
                      height='16.5px'
                    >
                      {t('EditLink:mainContent.advancedConfig.addOptions')}
                    </ColumnText>
                    <ButtonWithIconWrapper>
                      <AdvanceButtons minWidth='764px'>
                        <ButtonWithIcon
                          icon='Add'
                          design='secondary'
                          shadow
                          size='normal'
                          position='left'
                          onClick={handleDomain}
                          disabled={
                            isActiveDomain ||
                            loading ||
                            linkDataLoading ||
                            selectedProtocol === 'RTMPS'
                          }
                        >
                          {t(
                            'EditLink:mainContent.advancedConfig.buttonsText.custom'
                          )}
                        </ButtonWithIcon>
                        <ButtonWithIcon
                          icon='Add'
                          design='secondary'
                          shadow
                          size='normal'
                          position='left'
                          onClick={handleLink}
                          disabled={isActiveLink || loading || linkDataLoading}
                        >
                          {t(
                            'EditLink:mainContent.advancedConfig.buttonsText.expiry'
                          )}
                        </ButtonWithIcon>
                        <ButtonWithIcon
                          icon='Add'
                          design='secondary'
                          shadow
                          size='normal'
                          position='left'
                          onClick={handleLimit}
                          disabled={
                            isActiveLimit ||
                            loading ||
                            linkDataLoading ||
                            selectedProtocol === 'RTMPS'
                          }
                        >
                          {t(
                            'EditLink:mainContent.advancedConfig.buttonsText.limit'
                          )}
                        </ButtonWithIcon>
                      </AdvanceButtons>
                    </ButtonWithIconWrapper>
                  </AdvanceOptions>
                  <AdvanceOptions padding='13px 0px 0px' marginRight='3px'>
                    <ColumnText
                      color='#889096'
                      fontSize='14px'
                      family='monorale'
                    >
                      {t('EditLink:mainContent.advancedConfig.search')}
                    </ColumnText>
                    <ToggleSwitch
                      checked={indexToggle}
                      labelText={t(
                        'EditLink:mainContent.advancedConfig.switchLabel'
                      )}
                      leftTheme='off'
                      rightTheme='on'
                      state={
                        loading ||
                          linkDataLoading ||
                          selectedProtocol === 'RTMPS'
                          ? 'disabled'
                          : 'default'
                      }
                      onChangeCallback={() => setIndexToggle(!indexToggle)}
                      info
                      infoColor='#12a594'
                      infoText={t(
                        'EditLink:mainContent.advancedConfig.tooltip'
                      )}
                      tooltipMinWidth='325px'
                    />
                  </AdvanceOptions>
                </FieldRow>
              </ConfigurationBlock>

              {isActiveDomain && (
                <CustomDomain>
                  <ModalContentCreateLink>
                    {customDomainList.map((domain, index) => (
                      <Div
                        key={domain.id}
                        ref={index === customDomainList.length - 1 ? lastDomainRef : null} // Only set ref for last element
                      >
                        {generateCustomDomain(domain)}
                      </Div>
                    ))}
                  </ModalContentCreateLink>
                  <AdvanceOptions>
                    <ButtonWithIcon
                      icon='Add'
                      design='secondary'
                      shadow
                      size='normal'
                      position='left'
                      disabled={
                        customDomainList.length >= domainsFromAPI + 5 ||
                        loading ||
                        !areAllUnmatchedDomainsFilled()
                      }
                      onClick={handleDomain}
                    >
                      {t('EditLink:mainContent.customConfig.buttonText')}
                    </ButtonWithIcon>
                  </AdvanceOptions>
                </CustomDomain>
              )}
              {isActiveLink && (
                <LinkExpiry ref={linkExpiryRef}>
                  <Title button>
                    <FormGroupTitle>
                      <ColumnText
                        fontSize='16px'
                        color='#5a6269'
                        family='monorale'
                      >
                        {t('EditLink:mainContent.linkConfig.heading')}
                      </ColumnText>
                      <Line />
                    </FormGroupTitle>
                    <RemoveButtonTag>
                      <Button
                        design='secondary'
                        size='xsmall'
                        onClick={() => {
                          setIsActiveLink(false);
                          setExpiryDate(0);
                          setSelectedDate(undefined);
                          setSelectedExpiry('');
                        }}
                        disabled={loading}
                      >
                        {t('EditLink:mainContent.linkConfig.removeButton')}
                      </Button>
                    </RemoveButtonTag>
                  </Title>
                  <FieldRow padding='0px'>
                    <DatePickerSection ref={datepickerRef}>
                      <DomainLink
                        id='time_date_of_expiry'
                        name='time_date_of_expiry'
                        label={t('EditLink:mainContent.linkConfig.subHeading1')}
                        placeholder={t('EditLink:mainContent.linkConfig.placeHolder1')}
                        fieldState={loading ? 'disabled' : 'default'}
                        disabled={loading}
                        value={selectedDate ? formatCalendarDateTime(selectedDate as Date) : ''}
                        onClick={() => setIsDatePicker(!isDatePicker)}
                        readOnly
                      />
                      <IconWrapper
                        position='absolute'
                        right='17px'
                        top='53%'
                        onClick={() => setIsDatePicker(!isDatePicker)}
                      >
                        <Icon size={16} icon='DateTime' color='dimmed' />
                      </IconWrapper>
                      {isDatePicker && (
                        <DateDrawer>
                          <DatePicker
                            dateMode='single'
                            timeMode='single'
                            availableRange={{ start: new Date(), end: null }}
                            timeZoneValueTitle={getSystemTimezoneAbbreviation()}
                            initialValue={selectedDate || currentDate}
                            updateCallback={(date: Date | DateInterval) => {
                              if (expiryDate !== convertToEpoch(date)) {
                                setExpiryDate(convertToEpoch(date));
                                setSelectedDate(date);
                              }
                            }}
                          />
                        </DateDrawer>
                      )}
                    </DatePickerSection>
                    <LinkTypeField
                      label={{
                        htmlFor: 'expiry',
                        text: t('EditLink:mainContent.linkConfig.subHeading2'),
                      }}
                      changeCallback={handleChangeExpiry}
                      placeholder={t('EditLink:mainContent.linkConfig.placeHolder2')}
                      disabled={loading}
                      value={selectedExpiry}
                      required
                    >
                      {' '}
                      {expiryOptions.map(option => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </LinkTypeField>
                  </FieldRow>
                </LinkExpiry>
              )}
              {isActiveLimit && (
                <SetLimit ref={limitRef}>
                  <Title button>
                    <FormGroupTitle>
                      <ColumnText
                        fontSize='16px'
                        color='#5a6269'
                        family='monorale'
                      >
                        {t('EditLink:mainContent.limitConfig.heading')}
                      </ColumnText>
                      <Line />
                    </FormGroupTitle>
                    <RemoveButtonTag>
                      <Button
                        design='secondary'
                        size='xsmall'
                        onClick={() => {
                          setIsActiveLimit(false);
                          setInputUnit('100');
                          setSelectedUnit('MiB');
                        }}
                        disabled={loading}
                      >
                        {t('EditLink:mainContent.limitConfig.removeButton')}
                      </Button>
                    </RemoveButtonTag>
                  </Title>
                  <FieldRow gap='16px'>
                    <DomainLink
                      id='size'
                      name='size'
                      minWidth='128px'
                      width='128px'
                      divWidth='auto'
                      label={t('EditLink:mainContent.limitConfig.subHeading1')}
                      placeholder={t('EditLink:mainContent.limitConfig.placeHolder1')}
                      type='text'
                      inputMode='numeric'
                      autoComplete='off'
                      value={inputUnit}
                      onChange={handleInputUnit}
                      fieldState={loading ? 'disabled' : 'default'}
                      disabled={loading}
                    />
                    <LinkTypeField
                      label={{
                        htmlFor: 'unit',
                        text: t('EditLink:mainContent.limitConfig.subHeading2'),
                      }}
                      minWidth='88px'
                      width='88px'
                      value={selectedUnit}
                      defaultValue={selectedUnit}
                      changeCallback={handleChangeUnit}
                      disabled={loading}
                    >
                      {' '}
                      {unitOptions.map(option => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </LinkTypeField>
                  </FieldRow>
                  <HelperText>
                    <Icon size={12} icon='Information' />
                    <ColumnText fontSize='12px' color='#76aaf4' height='14.5px'>
                      {t('EditLink:mainContent.limitConfig.helperText')}
                    </ColumnText>
                  </HelperText>
                </SetLimit>
              )}
              <ButtonsSection loading={loading ? 'true' : 'false'}>
                <Button size='large' design='secondary' onClick={handleCancel}>
                  <LinkContainer fontSize='16px'>
                    {t('EditLink:mainContent.actionButtons.button1')}
                  </LinkContainer>
                </Button>
                <ButtonWithLoading
                  design='primary'
                  loading={loading}
                  size='large'
                  onClick={handleEditDeviceLink}
                  disabled={loading ||
                    linkDataLoading ||
                  (
                    (inputLinkName === 'デバイス管理UI' ? 'Edge UI' :
                      inputLinkName === 'ソリューションUI' ? 'Solution UI' :
                        inputLinkName.trim()) === matchedLink?.name &&
                    Object.keys(updatedAdvancedConfig()).length === 0 &&
                    modifiedCustomDomainList.length === 0
                  )}
                >
                  {t('EditLink:mainContent.actionButtons.button2')}
                </ButtonWithLoading>
              </ButtonsSection>
            </ModalContentCreateLink>
          </InnerMainContainer>
        </MainSection>
      </Wrapper>
    </MainContainer>
  );
};

export default EditLink;
