import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import SelectList from '../atoms/SelectList';
import SelectButton from '../atoms/SelectButton';
import { SelectOption, OptionText } from '../atoms/SelectOption';
import { CheckMarkSVG } from '../../svg';
import { Icon } from 'scorer-ui-kit';
import { useTranslation } from 'react-i18next';

const Container = styled.div<{ width?: string }>`
  width: ${(props) => props.width};
  position: relative;
  height: 40px;
  outline: none;
  & > button {
    border: solid 1px hsl(0, 0%, 92%);
    border-radius: 4px;
  }
`;

const CheckMarkIcon = styled(CheckMarkSVG)`
  width: 14px;
  height: 14px;
  margin-right: 10px;
  flex-shrink: 0;
`;

const DirectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-top: 1px solid hsla(0, 0%, 53%, 0.15);
  button{
    height: 40px !important;
    span{
      font-size: 16px !important;
      font-family: Lato !important;
    }
  }
`;

const Button = styled.button<{ selected: boolean }>`
  font-family: ${({ theme }) => theme.fontFamily.data};
  border: none;
  color: ${(props) => (props.selected ? 'hsl(205,96%,63%)' : 'hsl(0,0%,53%)')};
  background-color: unset;
  flex: 1;
  height: 45px;
  font-size: 16px;
  font-weight: ${(props) => (props.selected ? 'bold' : 'regular')};
  outline: none;
  display: flex;
  flex-direction: row;
  padding: 0;
  align-items: center;
`;

const RadioBox = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 2px solid #62768c;
  margin-right: 10px;
  flex-shrink: 0;
`;

const ButtonText = styled.span`
  margin-left: 5px;
  margin-right: 15px;
`;

const IconWrapper = styled.div`
  margin: 9px 7px 2px 5px;
`;

export interface ISortBy {
  sort: string;
  ascending: boolean;
  title: string;
}

interface ISortByProps {
  title: string;
  list: ISortItem[];
  selected: ISortBy;
  onSelect: (item: ISortBy) => void;
  margin?: string;
  width?: string;
  bgColor?: string;
}

export interface ISortItem {
  sort: 'name' | 'tags' | 'category' | 'type' | 'status' | 'port' | 'lastAccessed' | 'ingress' | 'egress';
  title: string;
}

const SortBy: React.FC<ISortByProps> = ({
  title,
  list,
  width,
  selected: { sort, ascending },
  selected,
  onSelect,
  bgColor
}) => {
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation(['Webgateway', 'Common']);
  const toggle = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  const setAscending = useCallback(() => {
    const sortBy = { ...selected };
    sortBy.ascending = !sortBy.ascending;
    onSelect(sortBy);
  }, [selected, onSelect]);

  const select = useCallback(
    (item: ISortItem) => {
      const sortBy = { ...selected };
      sortBy.sort = item.sort;
      sortBy.title = item.title;
      onSelect(sortBy);
      setExpanded(false);
    },
    [selected, onSelect]
  );

  const onBlur = useCallback((e: React.FocusEvent<HTMLDivElement>) => {
    const currentTarget = e.currentTarget;
    setTimeout(() => {
      if (!currentTarget.contains(document.activeElement)) {
        setExpanded(false);
      }
    }, 0);
  }, []);

  return (
    <Container width={width} tabIndex={0} onBlur={onBlur}>
      <SelectButton
        expanded={expanded}
        bgColor={bgColor}
        onClick={toggle}
        title={title}
        icon='FilterSorting'
        hideArrow={false}
      />
      <SelectList expanded={expanded} boxWidth='268px' right='0'>
        {list.map((item) => (
          <SelectOption
            selected={sort === item.sort}
            onClick={() => {
              select(item);
            }}
            key={item.sort}
          >
            {sort === item.sort ? <CheckMarkIcon /> : <RadioBox />}
            <OptionText title={item.title}>{item.title}</OptionText>
          </SelectOption>
        ))}
        <DirectionContainer>
          <Button
            selected={ascending === true}
            type='button'
            onClick={setAscending}
          >
            <IconWrapper>
              <Icon size={17} color='dimmed' icon='FilterAscending' />
            </IconWrapper>
            <ButtonText>{t('Webgateway:sorting.ascending')}</ButtonText>
          </Button>
          <Button selected={!ascending} type='button' onClick={setAscending}>
            <IconWrapper>
              <Icon size={17} color='dimmed' icon='FilterDescending' />
            </IconWrapper>
            <ButtonText>{t('Webgateway:sorting.descending')}</ButtonText>
          </Button>
        </DirectionContainer>
      </SelectList>
    </Container>
  );
};

export default SortBy;
