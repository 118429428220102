import { useState, useEffect, useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom'; // If you're using react-router-dom for navigation
import { getWebgateLinkInfo, WebgateLinkInfo, WebgateLinkInfoPayload } from '../lib/api_config';
import { ContextProps, FeatureEnabledContext } from '../App';
import { useNotification } from '../components/Notifications/useNotification';
import { handleDismiss } from '../components/Notifications/Notification';

interface StatusObject {
  id: string;
  message: string;
}
interface ExpiryObject {
  time: number;
  action: string;
}
interface UploadObject {
  uploadLimit: number;
  uploadLimitUnit: string;
}
export interface FetchTypeData {
  name: string;
  linkID: string;
  links: string[];
  linkName: string;
  status: StatusObject;
  connection: string;
  port: number;
  noIndex: boolean;
  expiry: ExpiryObject;
  accordionGrp: string;
  ingressData: number;
  egressData: number;
  uploadLimit: UploadObject;
}

export const useLinkData = (deviceID: string, linkID: string) => {
  const [link, setLink] = useState<FetchTypeData | null>(null);
  const [linkDataLoading, setLinkDataLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const history = useHistory();

  // Check validation for webgate enabled or not
  const { setSwitchState }: ContextProps = useContext(FeatureEnabledContext);

  // Function used from the useNotification component to send notifications
  const { sendNotification } = useNotification();

  const fetchLinks = useCallback(async () => {
    setLinkDataLoading(true);
    // Webgate feature switch state setting function
    setSwitchState('locked');
    setError(null); // Reset any previous error

    try {
      const fetchWebgateLinkInfo: WebgateLinkInfo = await getWebgateLinkInfo( deviceID, linkID);

      // Check if the response is successful and has valid payload data
      if (
        fetchWebgateLinkInfo &&
        fetchWebgateLinkInfo.status_code === 200 &&
        fetchWebgateLinkInfo.payload
      ) {
        const payload = fetchWebgateLinkInfo.payload;

        // Ensure all relevant keys in payload are not null or undefined
        const isValidPayload = [
          payload.name,
          payload.linkID,
          payload.url,
          payload.protocol,
          payload.port,
          payload.noIndex,
          payload.expiry?.time,
          payload.dataUsage?.ingress,
          payload.dataUsage?.egress,
          payload.uploadLimit?.uploadLimit,
        ].every(value => value !== null && value !== undefined);

        if (isValidPayload) {
          // Map the API response payload to the FetchTypeData structure
          const mapPayloadToFetchTypeData = (
            payload: WebgateLinkInfoPayload
          ): FetchTypeData => ({
            name: payload.name,
            linkID: payload.linkID,
            links: [
              ...(payload.url ? [payload.url] : []),
              ...(payload.customDomains?.sort() || []),
            ],
            linkName: [20001, 80].includes(payload.port) ? 'standard' : 'other',
            status: payload.status,
            connection: payload.protocol,
            port: payload.port,
            noIndex: payload.noIndex,
            expiry: payload.expiry,
            accordionGrp: payload.port === 20001 ? 'Edge UI' : payload.port === 80 ? 'Solution UI' : 'Other Links',
            ingressData: payload.dataUsage.ingress,
            egressData: payload.dataUsage.egress,
            uploadLimit: payload.uploadLimit,
          });

          const mappedData = mapPayloadToFetchTypeData(payload);

          // Set the link if it matches the linkID, otherwise redirect
          if (mappedData.linkID === linkID) {
            setLink(mappedData);
          } else {
            history.push('/');
          }
        } else {
          console.warn(
            'Invalid payload: Some required fields are missing or null.'
          );
          setError('Invalid link information received.');
        }
      } else if (fetchWebgateLinkInfo.status_code === 404) {
        handleDismiss(); // Clear all notification bar before sending a new one
        sendNotification({
          type: 'warning',
          message: fetchWebgateLinkInfo.status_msg,
        });
        history.push('/');
      } else {
        history.push('/');
        setError('Failed to fetch link information. Error code: ' + fetchWebgateLinkInfo.status_code);
      }
    } catch (err) {
      history.push('/');
      console.error('Error in fetchLinks:', err);
      setError('Failed to fetch links');
    } finally {
      setLinkDataLoading(false);
      // Webgate feature switch state setting function
      setSwitchState('default');
    }
  }, [deviceID, linkID, history, sendNotification, setSwitchState]);

  useEffect(() => {
    fetchLinks();
  }, [fetchLinks]);

  return { link, linkDataLoading, error };
};
