import React, { useState, useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { IToggleState } from '../../App';
import { Button, ButtonWithIcon, Content, Icon, SelectField, SmallInput, TextAreaField, DatePicker, FilterDropdownContainer, useClickOutside, ButtonWithLoading, DateInterval, PageHeader } from 'scorer-ui-kit';
import { useTranslation } from 'react-i18next';
import ToggleSwitch from '../../components/molecules/ToggleSwitch';
import { editWebgateDeviceLink, getWebgateStatus, isWebgateEnabled, toggleWebgateDevice, updateWebgateCustomDomain } from '../../lib/api_config';
import { FetchTypeData } from '../../hooks/useLinkData';
import { convertToEpoch, formatCalendarDateTime, getSystemTimezoneAbbreviation } from '../../Utils/DateUtils';
import { EditLinkAdvanceConfig } from '../../API';
import { validateDomainFields, validateInputs } from '../../Utils/ValidateInputFields';
import { ErrorType, handleException } from '../../hooks/handleException';
import { handleDismiss, INotificationProps } from '../../components/Notifications/Notification';
import { ILoadingIds, ITypeData } from '../../pages/DeviceLinks';
import { convertAndValidateInput } from '../../Utils/ConversionUtils';

const MainContainer = styled(Content)`
  width: 100%;
  padding: 0px;
  max-width: calc(100vw - 80px);
  max-height: 80vh;
  div {
    max-width: 100%;
  }
`;

const Wrapper = styled.div<{ enableContent?: boolean }>`
  width: 100%;
  opacity: ${({ enableContent }) => (enableContent ? '1' : '0.6')};
  pointer-events: ${({ enableContent }) => (enableContent ? 'auto' : 'none')};
`;

const SectionContainer = styled.div<{
  height?: string;
  width?: string;
  gap?: string;
  padding?: string;
  grow?: number;
  direction?: string;
  justify?: string;
  align?: string;
}>`
  width: ${({ width }) => (width ? width : 'auto')};
  height: ${({ height }) => (height ? height : 'auto')};
  align-self: stretch;
  flex-grow: ${({ grow }) => (grow ? grow : 0)};
  display: flex;
  flex-direction: ${({ direction }) => (direction ? direction : 'column')};
  justify-content: ${({ justify }) => (justify ? justify : 'flex-start')};
  align-items: ${({ align }) => (align ? align : 'stretch')};
  gap: ${({ gap }) => (gap ? gap : '16px')};
  padding: ${({ padding }) => (padding ? padding : '32px 96px 16px')};
`;

const IconWrapper = styled.div<{
  marginTop?: string;
  height?: string;
  width?: string;
  position?: 'absolute' | 'relative';
  left?: string;
  top?: string;
  right?: string;
}>`
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '4px')};
  width: ${({ width }) => (width ? width : '16px')};
  height: ${({ height }) => (height ? height : '16px')};
  display: flex;
  justify-content: center;
  padding: 4px;
  align-items: center;
  flex-grow: 0;
  position: ${({ position }) => position === 'absolute' ? position : 'relative'};
  left: ${({ position, left }) => (position === 'absolute' ? left : 'auto')};
  right: ${({ position, right }) => (position === 'absolute' ? right : 'auto')};
  top: ${({ position, top }) => (position === 'absolute' ? top : 'auto')};
`;

const ColumnText = styled.p<{
  padding?: string;
  fontSize?: string;
  color?: string;
  fontWeight?: string;
  letter?: string;
  line?: string;
  margin?: string;
  family?: string;
  height?: string;
}>`
  height: ${({ height }) => (height ? height : 'auto')};
  padding: ${({ padding }) => (padding ? padding : '0px')};
  font-family: ${({ family }) => (family ? family : 'Lato')};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '14px')};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ color }) => (color ? color : '#898e96')};
  margin: ${({ margin }) => (margin ? margin : '0px')};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : '500')};
  letter-spacing: ${({ letter }) => letter};
  line-height: ${({ line }) => line};
`;

const LinkContainer = styled.a<{
  fontSize?: string;
}>`
  width: auto;
  height: 12px;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '12px')};
  font-weight: 500;
  text-decoration: none !important;
  letter-spacing: -0.2px;
  line-height: 1;
`;

const DeviceInfo = styled.div`
  width: auto;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  padding: 2px 0 4px;
  color: #8b9196;
  position: relative;

  > div > div > div > div:nth-child(2) {
    overflow-wrap: anywhere;
    gap: 1px;
  }
`;

const PageHeaderContainer = styled(PageHeader)`
  width: 155px;
  height: 26px !important;
  flex-grow: 0;
  font-family: Raleway-v4020;
  font-size: 26px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #60646b;
`;

const LineDivider = styled.div`
  width: 100%;
  height: 4px;
  padding: 8px;
  border-top: 1px solid #e6e8ec;
`;

const MainSection = styled.div`
  min-width: 940px;
  width: 100%;
  align-self: stretch;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  padding: 31px 96px 64px;
`;

const InnerMainContainer = styled.div`
  min-width: 100%;
`;

const ModalContentCreateLink = styled.div`
  min-width: 940px;
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 32px;
  padding: 0px;
`;

const FormGroupTitle = styled.div`
  width: 100%;
  height: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  padding: 0;
  height: 18.5px;
`;

const Line = styled.div`
  height: 1px;
  flex-grow: 1;
  background-color: #e8e8ec;
`;

const ConfigurationBlock = styled.div<{
  gap?: string;
  marginBottom?: string;
  padding?: string;
}>`
  min-width: 940px;
  margin-bottom: ${({ marginBottom }) => marginBottom};
  width: 100%;
  height: auto;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: ${({ gap }) => (gap ? gap : '8px')};
  padding: ${({ padding }) => (padding ? padding : '4px 0px 0px 0px')};
`;

const FieldRow = styled.div<{
  padding?: string;
  gap?: string;
  justifyContent?: string;
}>`
  min-width: 940px;
  height: auto;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: ${({ justifyContent }) => justifyContent ? justifyContent : 'flex-start'};
  align-items: flex-start;
  gap: ${({ gap }) => (gap ? gap : '16px')};
  padding: ${({ padding }) => (padding ? padding : '8px 0')};
  label {
    margin-bottom: 0px !important;
    > span {
      height: 16.5px;
    }
  }
`;

const LinkTypeField = styled(SelectField) <{
  width?: string;
  minWidth?: string;
}>`
  min-width: ${({ minWidth }) => (minWidth ? minWidth : '204px')};
  width: ${({ width }) => width};
  padding: 0 25px 1px 11px;
  line-height: 1.14;
  font-size: 14px !important;
`;

const DomainLink = styled(SmallInput) <{
  minWidth?: string;
  divWidth?: string;
  width?: string;
  paddingRight?: string;
  position?: string;
}>`
  position: ${({ position }) => position};
  width: ${({ divWidth }) => (divWidth ? divWidth : '100%')};
  label {
    width: 100%;
    margin-bottom: 0px !important;
    gap: 0px;
    span {
      margin-bottom: 8px;
      height: 16.5px;
    }
  }
  div {
    min-width: ${({ minWidth }) => (minWidth ? minWidth : 'fit-content')};
    max-width: 100%;
    height: 40px;
    display: flex;
    flex-grow: 1;
    align-items: center;
    div {
      right: 10px;
      padding-right: 0px !important;
      left: 12px;
      top: 0;
      font-size: 14px;
      line-height: 1.14;
      font-family: Lato;
    }
    input {
      width: ${({ width }) => (width ? width : '100%')};
      padding-right: ${({ paddingRight }) => paddingRight};
    }
  }
`;

const AdvanceOptions = styled.div<{
  minWidth?: string;
  padding?: string;
  marginRight?: string;
  marginTop?: string;
}>`
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : '0px')};
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '0px')};
  min-height: 80px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  padding: ${({ padding }) => (padding ? padding : '8px 0')};
`;

const AdvanceButtons = styled.div<{
  minWidth?: string;
}>`
  max-width: auto;
  min-height: 32px;
  max-height: auto;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  padding: 0;
`;

const ButtonWithIconWrapper = styled.div`
  button > div > div:first-child {
    padding: 0px 7px;
  }
`;

const ButtonsSection = styled.div<{
  loading?: string;
}>`
  max-width: 940px;
  min-height: 40px;
  max-height: auto;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 8px;

  > button {
    padding: 8px 16px;
    > a {
      height: auto;
      line-height: normal;
    }
  }

  > div > button > div > div:first-child {
    padding: 0px;
    padding: ${({ loading }) => (loading === 'true' ? '0px 16px' : '')};
  }
`;

const Div = styled.div``;

const CustomDomain = styled.div<{ marginTop?: string }>`
  min-width: 940px;
  height: auto;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 16px;
  padding: 0px;
  margin-top: ${({ marginTop }) => marginTop};
`;

const RemoveButtonTag = styled.div<{ position?: string }>`
  position: ${({ position }) => position};
  top: 0px;
  right: 0px;
  button {
    padding: 3px 8px;
    height: 20px;
  }
`;

const RemoveWarning = styled.div`
  height: 40px;
  font-size: 14px;
  font-weight: 600;
  color: #f76808;
  padding: 1px 33px;
  border-radius: 3px;
  border: solid 2px #f76808;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RemoveContent = styled.div<{ remove?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  opacity: ${({ remove }) => (remove ? '0.3' : '1')};
`;

const Title = styled.div<{ button?: boolean }>`
  min-width: 940px;
  height: 20px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  padding: 0;

  div {
    width: ${({ button }) => (button ? 'auto' : '100%')};
  }
`;

const Frame = styled.div`
  width : 100%
  height: auto;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 32px;
  padding: 0;
  label{
    margin-bottom: 0px !important;
    width: 100%;
    gap: 0px;
    placeholder: 8px !important;
    span{
      margin-bottom: 8px;
      height: 16.5px;
    }
  }
`;

const CustomTextAreaContainer = styled.div`
  position: relative;
  width: 100%;
`;

const CustomTextArea = styled(TextAreaField) <{ italics: boolean }>`
  width: 100%;
  resize: none;
  height: 165px;
  line-height: 1.57 !important;
  position: relative;
  ::placeholder {
    font-style: ${({ italics }) => (italics ? 'normal' : '')};
  }
`;

const LinkExpiry = styled.div`
  min-width: 940px;
  height: auto;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  padding: 0px;
`;

const SetLimit = styled.div`
  margin-top: 5px;
  min-width: 940px;
  height: auto;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 11px;
  padding: 0px;
`;

const HelperText = styled.div`
  display: flex;
  gap: 8px;
  margin-top: -4px;

  > div:first-child {
    display: flex;
    align-items: center;

    g {
      stroke: #76aaf4;
    }
  }
`;

const DatePickerSection = styled.div`
  height: 64px;
  width: 460px;
  position: relative;
`;

const DateDrawer = styled(FilterDropdownContainer)`
  position: absolute;
  z-index: 100;
  top: 72px;
  right: 0px;
`;

// All interfaces

// Interface for the list of custom domains list
export interface IReadability {
  editable: boolean;
  value: string;
}
export interface ICustomDomainState {
  id: number;
  domain: string;
  privateKey: IReadability;
  certificate: IReadability;
  remove?: boolean;
}

// Interface for the modified list of custom domains list
interface IModifiedCustomDomainState {
  domain: string;
  privateKey: string;
  certificate: string;
  remove?: boolean;
}

// Interface for Modified Link Expiry
interface IModifiedLinkExpiry {
  time: number;
  action: string;
  remove: boolean;
}

// Interface for modified upload limit
interface IModifiedUploadLimit {
  uploadLimit: number;
  uploadLimitUnit: string;
  remove: boolean;
}
interface EnableConfirmModal {
  deviceID: string;
  setModalOpen: (newStatus: boolean) => void;
  handleCancel: () => void;
  devicelinkData: ITypeData;
  setLoadingIds: ((value: ILoadingIds | ((prev: ILoadingIds) => ILoadingIds)) => void);
  setApiRowData: ((value: ITypeData[] | ((prev: ITypeData[]) => ITypeData[])) => void);
  sendNotification: (newNotification: INotificationProps) => void;
  isFeatureEnabled: boolean;
  setIsFeatureEnabled: (value: boolean) => void;
  setSwitchState: (value: IToggleState) => void;
}

const ConfirmEnableModal: React.FC<EnableConfirmModal> = ({
  deviceID,
  handleCancel,
  devicelinkData,
  setLoadingIds,
  setApiRowData,
  sendNotification,
  setModalOpen,
  isFeatureEnabled,
  setIsFeatureEnabled,
  setSwitchState
}) => {
  // variables from the devicelinkData
  const linkID = devicelinkData.linkID || '';

  // To use the text transalation files (English - Japanese)
  const { t } = useTranslation(['EditLink', 'Common']);

  // State for storing the daomin name
  const [domain, setDomain] = useState<string>('[link_id].webgate-biz.scorer.jp');

  // State for storing the matched link data from the API with the link name provided in the params
  const [matchedLink, setMatchedLink] = useState<FetchTypeData>();

  // Create references for CustomDomain, LinkExpiry, SetLimit component
  const lastDomainRef = useRef<HTMLDivElement>(null);
  const linkExpiryRef = useRef<HTMLDivElement>(null);
  const limitRef = useRef<HTMLDivElement>(null);

  // All States

  // Section 1: Type Configuration

  // State for choosen link type value
  const [selectedLinkValue, setSelectedLinkValue] = useState<string>('');

  // State for using Webgate link name
  const [inputLinkName, setInputLinkName] = useState<string>('');

  // Section 2: Basic Configuration

  // State for choosen protocol type value
  const [selectedProtocol, setSelectedProtocol] = useState<string>('');

  // State for using the link port value
  const [inputPort, setInputPort] = useState<number | string>('');

  // Section 3: Advanced Configuration
  // State for using the index toggle behaviour
  const [indexToggle, setIndexToggle] = useState(true);

  // Section 3a: Custom Domain Configuration

  // State for checking the state of custom domain section
  const [isActiveDomain, setIsActiveDomain] = useState(false);

  // State for storing the list of custom domains with editable flags and ids
  const [customDomainList, setCustomDomainList] = useState<ICustomDomainState[]>([]);

  // State for storing the modified list of custom domains
  const [modifiedCustomDomainList, setModifiedCustomDomainList] = useState<IModifiedCustomDomainState[]>([]);

  // State for storing the max number of custom domain can be added at that time
  const [domainsFromAPI, setDomainsFromAPI] = useState<number>(0);

  // Section 3b: Link Expiry Configuration

  // State for checking the state of link expiry section
  const [isActiveLink, setIsActiveLink] = useState(false);

  //State for checking the date picker visibility state
  const [isDatePicker, setIsDatePicker] = useState(false);

  // State for storing the date in epoch format
  const [expiryDate, setExpiryDate] = useState<number>(0);

  // State for storing thee date choose from the calender
  const [selectedDate, setSelectedDate] = useState<Date | DateInterval | undefined>(undefined);

  // Variable to store initial expiry date
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + 7);
  currentDate.setHours(0, 0, 0, 0);

  // Reference and function for clicking outside the datepicker area
  const datepickerRef = useRef<HTMLDivElement>(null);
  useClickOutside(datepickerRef, () => setIsDatePicker(false));

  // State for storing the behaviour of the expiry
  const [selectedExpiry, setSelectedExpiry] = useState<string>('');

  // State for storing the Modified Link Expiry
  const [modifiedLinkExpiry, setModifiedLinkExpiry] =
    useState<IModifiedLinkExpiry>({
      time: 0,
      action: '',
      remove: false,
    });

  // Section 3c: Upload Limit Override Configuration

  // State for checking the state of upload limit section
  const [isActiveLimit, setIsActiveLimit] = useState(false);

  // State for storing the input of upload limit value
  const [inputUnit, setInputUnit] = useState<string>('100');

  // State for storing the unit of upload limit
  const [selectedUnit, setSelectedUnit] = useState<string>('MiB');

  // State for storing the modified upload limit
  const [modifiedUploadLimit, setModifiedUploadLimit] =
    useState<IModifiedUploadLimit>({
      uploadLimit: 0,
      uploadLimitUnit: '',
      remove: false,
    });

  // State for setting api loading
  const [loading, setLoading] = useState(false);

  // Page Data

  // Section 1: Type Configuration

  // Variable with options for the link type dropdown
  const linkOptions = [
    {
      value: 'Edge UI',
      label: t('EditLink:mainContent.typeConfig.typeOptions.option1'),
    },
    {
      value: 'Solution UI',
      label: t('EditLink:mainContent.typeConfig.typeOptions.option2'),
    },
    {
      value: 'Other Links',
      label: t('EditLink:mainContent.typeConfig.typeOptions.option3'),
    },
  ];

  // Function for updating the webgate link name
  const handleLinkNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputLinkName(e.target.value);
  };

  // Section 2: Basic Configuration

  // Variable with options for the protocol type dropdown
  const protocolOptions = [
    {
      value: 'HTTPS',
      label: t('EditLink:mainContent.basicConfig.typeOptions.option1'),
    },
    {
      value: 'RTMPS',
      label: t('EditLink:mainContent.basicConfig.typeOptions.option2'),
    },
  ];

  // Section 3: Advanced Configuration

  // Section 3a: Custom Domain Configuration

  // Function for updatng the isActiveDomain state and setting the initial values in customDomainList state
  const handleDomain = useCallback(() => {
    setCustomDomainList(prev => {
      const newDomain = {
        id: prev.length + 1,
        domain: '',
        privateKey: { editable: true, value: '' },
        certificate: { editable: true, value: '' },
      };

      const updatedList = [...prev, newDomain];

      setTimeout(() => {
        // Scroll to the last custom domain
        lastDomainRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 0);

      return updatedList;
    });

    setIsActiveDomain(true);
  }, []);

  // Function for updatng the individial keys in the customDomainList state
  const handleInputDomainChange = (
    id: number,
    field: keyof ICustomDomainState,
    value: string
  ) => {
    setCustomDomainList((prev) =>
      prev.map((domain) =>
        domain.id === id
          ? {
            ...domain,
            [field]:
              field === 'privateKey' || field === 'certificate'
                ? { ...domain[field], value: value.trim() } // Only update the value for privateKey or certificate
                : value.trim(), // For other fields, update directly
          }
          : domain
      )
    );
  };

  // Function for updating the state of private key & cetificate fields
  const handleEditable = (id: number, field: keyof ICustomDomainState) => {
    setCustomDomainList((prev) =>
      prev.map((domain) => {
        if (domain.id === id) {
          // Use type assertion to ensure that field is of type 'Readability'
          const currentField = domain[field] as IReadability;

          return {
            ...domain,
            [field]: {
              editable: !currentField?.editable,
              value: currentField?.editable ? '' : currentField?.value,
            },
          };
        }
        return domain; // Return the domain unchanged if the ID doesn't match
      })
    );
  };

  // Funcion for removing the custom domains one by one from the list
  const removeCustomDomain = useCallback(
    (id: number) => {
      setCustomDomainList((prev) => {
        // Update the domain list based on the provided ID
        const updatedList = prev
          .map((domain) =>
            domain.id === id
              ? {
                ...domain,
                privateKey: {
                  value: '',
                  editable: false,
                },
                certificate: {
                  value: '',
                  editable: false,
                },
                remove: !domain.remove,
              }
              : domain
          )
          .filter((domain) => domain.id <= domainsFromAPI || domain.id !== id)
          .map((prev, index) => ({
            ...prev,
            id: index + 1,
          })); // Remove domain if id > domainsFromAPI

        // Check if the updated list is empty and update isActiveDomain
        if (updatedList.length === 0) {
          setIsActiveDomain(false);
        }

        return updatedList;
      });
    },
    [domainsFromAPI]
  );

  // Function for checking all the 3 fields of a custom domain are filled if the domain name is not matched with the API data
  const areAllUnmatchedDomainsFilled = () => {
    // Function for checking the filled domain name was their in the API data or not
    const isDomainInMatchedLink = (domainValue: number) => {
      if (matchedLink) {
        // Check if the domainValue exists in the links array
        return domainValue <= domainsFromAPI;
      }
      return false;
    };

    // Filter unmatched domains
    const unmatchedDomains = customDomainList.filter(
      (domain) => !isDomainInMatchedLink(domain.id)
    );

    // Check if all unmatched domains have non-empty domain, privateKey, and certificate values
    return unmatchedDomains.every(
      (domain) =>
        domain.domain.trim() !== '' &&
        domain.privateKey.value.trim() !== '' &&
        domain.certificate.value.trim() !== ''
    );
  };

  // Section 3b: Link Expiry Configuration

  // Function for closing the link expiry section
  const handleLink = () => {
    setIsActiveLink(true);
    setTimeout(() => {
      linkExpiryRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, 0);
  };

  // Variable with options for the expiry behaviour dropdown
  const expiryOptions = [
    {
      value: 'Disable',
      label: t('EditLink:mainContent.linkConfig.expiryOptions.option1'),
    },
    {
      value: 'Delete',
      label: t('EditLink:mainContent.linkConfig.expiryOptions.option2'),
    },
  ];

  // Function for updating the selected expiry behaviour value
  const handleChangeExpiry = (value: string) => {
    setSelectedExpiry(value);
  };

  // Section 3c: Upload Limit Override Configuration

  // Function for closing the limit override section
  const handleLimit = () => {
    setIsActiveLimit(true);
    setTimeout(() => {
      limitRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, 0);
  };

  // Funtion for changing the value of input limit in the state
  const handleInputUnit = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    if (inputValue.length <= 6) {
      setInputUnit(e.target.value);
    }
  };

  // Variable with options for the upload limit unit dropdown
  const unitOptions = [
    { value: 'MiB', label: t('EditLink:mainContent.limitConfig.unitOption1') },
    { value: 'GiB', label: t('EditLink:mainContent.limitConfig.unitOption2') },
  ];

  // Function for updating the upload limit unit value
  const handleChangeUnit = (value: string) => {
    setSelectedUnit(value);
  };

  // Function for toggle enable the device link
  const handleEnable = useCallback(
    async (linkID: string, linkName: string, linkType: string) => {
      try {
        setLoadingIds((prev: ILoadingIds) => {
          const key = linkID !== '' ? linkID : linkName;
          if (!prev[key]) {
            return {
              ...prev,
              [key]: {
                displayText: t('DeviceLinks:tableText.dropdown.action1'),
                text: 'Enabling',
              },
            };
          }
          return prev;
        });
        const getToggleStatus = await toggleWebgateDevice(
          deviceID,
          linkID,
          linkType.toLowerCase() === 'standard' && linkName === 'デバイス管理UI'
            ? 'Edge UI'
            : linkType.toLowerCase() === 'standard' && linkName === 'ソリューションUI'
              ? 'Solution UI'
              : linkName,
          linkType.toLowerCase() === 'standard' ? true : false,
          true,
          false
        );

        // Check if enabling the link was successful
        if (!getToggleStatus.enabled || !getToggleStatus) {
          throw new Error();
        }

        const getLinkToggleStatus = await getWebgateStatus(deviceID, [
          {
            isStandardLink: linkType.toLowerCase() === 'standard' ? true : false,
            linkID: linkID,
            linkName: linkType.toLowerCase() === 'standard' && linkName === 'デバイス管理UI'
              ? 'Edge UI'
              : linkType.toLowerCase() === 'standard' && linkName === 'ソリューションUI'
                ? 'Solution UI'
                : linkName,
          },
        ]);

        if (!getLinkToggleStatus) throw new Error();

        const selectedDeviceLink = getLinkToggleStatus?.Links.find((link) => {
          if (linkType === 'standard') {
            return linkName === 'デバイス管理UI'
              ? 'Edge UI'
              : linkName === 'ソリューションUI'
                ? 'Solution UI'
                : linkName;
          } else return link?.linkID === linkID;
        });
        const linkUrl = selectedDeviceLink?.url;
        if (linkUrl) {
          setApiRowData((prev: ITypeData[]) => {
            const selectedLink = prev.find((link) => {
              if (linkType === 'standard' && ['Edge UI', 'デバイス管理UI'].includes(linkName))
                return link.port === 20001;
              else if (linkType === 'standard' && ['Solution UI', 'ソリューションUI'].includes(linkName))
                return link.port === 80;
              else return link?.linkID === linkID;
            });

            if (
              selectedLink && !selectedLink.links?.find((existingLink) => existingLink === linkUrl)
            ) {
              setDomain(linkUrl);
              selectedLink.links.push(linkUrl);
              selectedLink.linkID =
                getLinkToggleStatus?.Links.find((link) => {
                  if (linkType === 'standard')
                    return link.linkName === linkName;
                  else return link?.linkID === linkID;
                })?.linkID || '';
            }
            if (selectedLink) {
              selectedLink.status.id = t('DeviceLinks:deviceStatusSection.text4');
              selectedLink.status.message = '';

              if (linkType === 'standard') {
                selectedLink.port =
                  getLinkToggleStatus?.Links.find((link) => {
                    if (linkType === 'standard')
                      return link.linkName === linkName;
                    else return link?.linkID === linkID;
                  })?.linkName === 'Edge UI'
                    ? 20001
                    : linkName === 'デバイス管理UI'
                      ? 20001
                      : 80;
                selectedLink.connection = 'HTTPS';
              }
            }
            return [...prev];
          });
          handleDismiss(); // Clear all notification bar before sending a new one
          sendNotification({
            type: 'success',
            message: t('DeviceLinks:notificationMessage.enableSuccess').replace(
              '{linkName}',
              ['Edge UI', 'デバイス管理UI'].includes(linkName)
                ? t('DeviceLinks:tableText.edgeUI')
                : ['Solution UI', 'ソリューションUI'].includes(linkName)
                  ? t('DeviceLinks:tableText.solutionUI')
                  : linkName
            ),
          });
          return selectedDeviceLink.linkID;
        }
      } catch {
        handleDismiss(); // Clear all notification bar before sending a new one
        sendNotification({
          type: 'error',
          message: t('DeviceLinks:notificationMessage.enableError').replace(
            '{linkName}',
            ['Edge UI', 'デバイス管理UI'].includes(linkName)
              ? t('DeviceLinks:tableText.edgeUI')
              : ['Solution UI', 'ソリューションUI'].includes(linkName)
                ? t('DeviceLinks:tableText.solutionUI')
                : linkName
          ),
        });
      } finally {
        setLoadingIds((prev: ILoadingIds) => {
          const updated = { ...prev };
          delete updated[
            linkID !== ''
              ? linkID
              : ['Edge UI', 'デバイス管理UI'].includes(linkName)
                ? t('DeviceLinks:tableText.edgeUI')
                : ['Solution UI', 'ソリューションUI'].includes(linkName)
                  ? t('DeviceLinks:tableText.solutionUI')
                  : linkName
          ];
          return updated;
        });

        // Use the callback function to ensure the latest `loadingIds` state is considered
        setLoadingIds((currentLoadingIds: ILoadingIds) => {
          if (Object.keys(currentLoadingIds).length === 0) {
            // Webgate feature switch state setting function
            setSwitchState('default');
          }
          return currentLoadingIds;
        });
      }
    },
    [
      t,
      deviceID,
      setLoadingIds,
      setApiRowData,
      setSwitchState,
      sendNotification
    ]
  );

  // UseEffect for updating the field on the ui after the link variable is updated which we are taking from the useFetchLink hook
  useEffect(() => {
    if (devicelinkData) {
      setMatchedLink(devicelinkData);

      let newLinkName;

      if (devicelinkData.name === t('DeviceLinks:tableText.edgeUI') && devicelinkData.port === 20001) {
        newLinkName = t('EditLink:mainContent.typeConfig.typeOptions.option1');
      } else if (devicelinkData.name === t('DeviceLinks:tableText.solutionUI') && devicelinkData.port === 80) {
        newLinkName = t('EditLink:mainContent.typeConfig.typeOptions.option2');
      } else {
        newLinkName = devicelinkData.name;
      }
      setSelectedLinkValue(
        devicelinkData.accordionGrp ===
          t('DeviceLinks:tableText.emptyOtherText.accordionText')
          ? 'Other Links'
          : devicelinkData.accordionGrp === t('DeviceLinks:tableText.emptyStandardText.accordionText') && devicelinkData.name === t('DeviceLinks:tableText.edgeUI')
            ? 'Edge UI'
            : 'Solution UI'
      );

      setInputLinkName(newLinkName);
      setSelectedProtocol(devicelinkData.connection);
      setDomain(devicelinkData.links[0]);
      setInputPort(devicelinkData.port);
      setIndexToggle(devicelinkData.noIndex);

      if (devicelinkData.links.length > 1) {
        setIsActiveDomain(true);
        setDomainsFromAPI(devicelinkData.links.slice(1).length);
        const customDomainList = devicelinkData.links
          .slice(1)
          .map((domain: string, index: number) => ({
            id: index + 1,
            domain,
            privateKey: { editable: false, value: '' },
            certificate: { editable: false, value: '' },
            remove: false,
          }));
        setCustomDomainList(customDomainList);
      }

      if (
        !(
          devicelinkData.uploadLimit?.uploadLimit === 100 &&
          devicelinkData.uploadLimit?.uploadLimitUnit === 'MiB'
        ) &&
        devicelinkData.uploadLimit?.uploadLimit !== 0
      ) {
        setIsActiveLimit(true);
        setInputUnit(devicelinkData.uploadLimit.uploadLimit.toString());
        setSelectedUnit(devicelinkData.uploadLimit.uploadLimitUnit);
      }
    }
  }, [devicelinkData, deviceID, t]);

  // If matched link found then update the field of custom domain on the ui
  useEffect(() => {
    if (!matchedLink) return;

    // Map custom domain from matchedLink
    const customDomainListFromMatchedLink: ICustomDomainState[] =
      matchedLink.links.slice(1).map((link: string, index: number) => ({
        id: index + 1,
        domain: link,
        privateKey: { editable: false, value: '' },
        certificate: { editable: false, value: '' },
      }));

    const modifiedCustomDomainList: ICustomDomainState[] =
      customDomainListFromMatchedLink.map((localItem: ICustomDomainState) => {
        const matchedItem = customDomainList.find(
          (apiItem) => apiItem.domain === localItem.domain
        );
        return matchedItem || localItem;
      });

    // Add any new items from the API that were not in matchedLink
    const newItems = customDomainList.filter(
      (apiItem) =>
        !customDomainListFromMatchedLink.some(
          (localItem: ICustomDomainState) => localItem.domain === apiItem.domain
        )
    );

    newItems.forEach((item) => {
      modifiedCustomDomainList.push({ ...item });
    });

    // Add those entries in the modified custom domain list for which some changes have been done by the user
    const modifiedListWithoutId = modifiedCustomDomainList
      .filter(
        ({ privateKey, certificate, remove }) => certificate.value || privateKey.value || remove === true)
      .map(({ id, privateKey, certificate, ...rest }) => ({
        ...rest,
        privateKey: privateKey.value,
        certificate: certificate.value,
      }));

    setModifiedCustomDomainList(modifiedListWithoutId);

    // Case for linkExpiry
    const modifiedLinkExpiry =
      expiryDate === 0 &&
        selectedExpiry === '' &&
        (matchedLink.expiry.time !== expiryDate ||
          matchedLink.expiry.action !== selectedExpiry)
        ? {
          // Case 1: expiryDate is 0 and selectedExpiry is empty and doesn't match matchedLink
          time: expiryDate,
          action: selectedExpiry,
          remove: true,
        }
        : {
          // Case 2: Regular case where expiryDate and selectedExpiry are valid
          time: expiryDate,
          action: ['Solution UI', 'Edge UI'].includes(selectedLinkValue) && selectedExpiry === 'Delete' ? 'Flush' : selectedExpiry,
          remove: false,
        };


    const normalizedUnit = Number(inputUnit.replace(/[０-９]/g, (match) => {
      // Convert fullwidth digit to its corresponding regular digit
      return String.fromCharCode(match.charCodeAt(0) - 0xFF10 + 0x30);
    }));

    const updatedUnit = parseInt(normalizedUnit.toString())

    // Case for uploadLimit
    const modifiedUploadLimit =
      !updatedUnit &&
        selectedUnit === '' &&
        (matchedLink.uploadLimit.uploadLimit !== Number(updatedUnit) ||
          matchedLink.uploadLimit.uploadLimitUnit !== selectedUnit)
        ? {
          // Case 1: uploadLimit is 0 and selectedUnit is empty and doesn't match matchedLink
          uploadLimit: Number(updatedUnit),
          uploadLimitUnit: selectedUnit,
          remove: true,
        }
        : {
          // Case 2: Regular case where uploadLimit and selectedUnit are valid
          uploadLimit: Number(updatedUnit),
          uploadLimitUnit: selectedUnit,
          remove: false,
        };

    setModifiedLinkExpiry(modifiedLinkExpiry);
    setModifiedUploadLimit(modifiedUploadLimit);
  }, [
    inputUnit,
    expiryDate,
    matchedLink,
    selectedUnit,
    selectedExpiry,
    customDomainList,
    selectedLinkValue,
  ]);

  // Compare the advance config from the API data and add those things in the object which are edited by the user

  const updatedAdvancedConfig = useCallback(() => {
    // Create an object to store the changed values
    let ConfigObj: EditLinkAdvanceConfig = {};

    // Compare and add only changed values
    if (matchedLink?.noIndex !== indexToggle) {
      ConfigObj.noIndex = indexToggle;
    }

    // Compare expiry
    if (
      matchedLink?.expiry.time !== modifiedLinkExpiry.time ||
      matchedLink.expiry.action !== modifiedLinkExpiry.action
    ) {
      ConfigObj.linkExpiry = modifiedLinkExpiry;
    }

    // Compare upload limit
    if (
      matchedLink?.uploadLimit.uploadLimit !== Number(modifiedUploadLimit.uploadLimit) ||
      matchedLink.uploadLimit.uploadLimitUnit !== modifiedUploadLimit.uploadLimitUnit
    ) {
      ConfigObj.linkUploadLimit = modifiedUploadLimit;
    }
    return ConfigObj;
  }, [matchedLink, indexToggle, modifiedLinkExpiry, modifiedUploadLimit]);

  // Edit Link API Call
  const handleEditDeviceLink = useCallback(async () => {
    const isDomainInMatchedLink = (domainValue: string) => {
      if (matchedLink) {
        // Check if the domainValue exists in the links array
        return matchedLink.links.slice(1).includes(domainValue);
      }
      return false;
    };

    const domainlist = customDomainList
      .filter((domain) => !isDomainInMatchedLink(domain.domain))
      .map(({ id, privateKey, certificate, ...rest }) => ({
        ...rest,
        privateKey: privateKey.value,
        certificate: certificate.value,
      }));

    const normalizedInputUnit = convertAndValidateInput(
      inputUnit,
      'CreateLinks:notificationMessage.enterValidUploadLimit',
      handleDismiss,
      sendNotification,
      t
    );

    const updatedInputUnit = parseInt(normalizedInputUnit || '');

    if (Number.isNaN(updatedInputUnit) || updatedInputUnit === null) return;
    setInputUnit(updatedInputUnit.toString());


    if (
      !validateInputs(
        selectedLinkValue,
        inputLinkName,
        selectedProtocol,
        inputPort,
        isActiveDomain,
        domainlist,
        isActiveLink,
        expiryDate,
        selectedExpiry,
        isActiveLimit,
        updatedInputUnit.toString(),
        selectedUnit,
        sendNotification,
        t
      )
    ) {
      return;
    }
    if (matchedLink) {
      const domains = customDomainList.map((item) => item.domain);
      const isDuplicate = domains.some((domain, idx) => {
        return domains.indexOf(domain) !== idx;
      });
      // Check for duplicate Custom Domains
      if (isDuplicate) {
        handleDismiss(); // Clear all notification bar before sending a new one
        await new Promise((resolve) => setTimeout(resolve, 300));
        sendNotification({
          type: 'info',
          message: t('EditLink:notificationMessage.duplicateDomains'),
        });
        return;
      }
      // Check for invalid custom domains in matched domains from the API data
      const matchedDomains = matchedLink.links.slice(1);
      if (
        validateDomainFields(
          matchedDomains,
          customDomainList,
          'privateKey',
          sendNotification,
          t
        ) ||
        validateDomainFields(
          matchedDomains,
          customDomainList,
          'certificate',
          sendNotification,
          t
        )
      ) {
        return true;
      }
    }
    try {
      setLoading(true);
      // Check for webgate feature
      const enabled = await isWebgateEnabled();
      // if API returns a response then set the featue enabled state
      if (enabled !== null) setIsFeatureEnabled(enabled);
      // if the feature is disaled then exit from the loop
      if (enabled === false || !enabled) {
        handleDismiss(); // Clear all notification bar before sending a new one
        await new Promise((resolve) => setTimeout(resolve, 300));
        sendNotification({
          type: 'warning',
          message: t('Common:notificationMessage.webgateFeatureDisabled'),
        });
        setIsFeatureEnabled(false)
        setModalOpen(false);
        return;
      }

      // Webgate feature switch state setting function
      setSwitchState('locked');

      // Call editWebgateDeviceLink only if any link details has been changed
      const enableResponse = await handleEnable(
        devicelinkData.linkID,
        devicelinkData.name,
        devicelinkData.linkName
      );
      const linkId = (linkID ? linkID : enableResponse) || '';

      if (!(inputLinkName === matchedLink?.name && Object.keys(updatedAdvancedConfig()).length === 0)) {
        setDomain((prev) => {
          return prev;
        });

        const linkData = await editWebgateDeviceLink(
          deviceID,
          linkId,
          'edit',
          {
            name:
              inputLinkName.trim() === 'デバイス管理UI'
                ? 'Edge UI'
                : inputLinkName.trim() === 'ソリューションUI'
                  ? 'Solution UI'
                  : inputLinkName.trim(),
            port: Number(inputPort),
          },
          updatedAdvancedConfig()
        );
        if (!linkData) throw new Error(ErrorType.PARSE);

        handleDismiss(); // Clear all notification bar before sending a new one
        await new Promise((resolve) => setTimeout(resolve, 300));
        if (linkData.editWebgateDeviceLink?.linkEdited) {
          // Notify the user of success
          setApiRowData((prev: ITypeData[]) => {
            return prev.map((item) => {
              const isDeviceLink =
                item.linkName === 'standard'
                  ? item.name === devicelinkData.name
                  : item.linkID === devicelinkData.linkID;

              if (isDeviceLink) {
                return {
                  ...item,
                  name: ['Standard Links', '標準リンク'].includes(item.accordionGrp) && ['Edge UI', 'デバイス管理UI'].includes(item.name) ?
                    t('DeviceLinks:tableText.edgeUI') :
                    ['Standard Links', '標準リンク'].includes(item.accordionGrp) && ['Solution UI', 'ソリューションUI'].includes(item.name) ?
                      t('DeviceLinks:tableText.solutionUI') :
                      inputLinkName.trim(),
                  expiry: {
                    time: expiryDate,
                    action: selectedExpiry
                  },
                  uploadLimit: {
                    uploadLimit: parseInt(updatedInputUnit.toString(), 10),
                    uploadLimitUnit: selectedUnit,
                  },
                  noIndex: indexToggle,
                };
              }
              return item;
            });
          });
          sendNotification({
            type: 'success',
            message: t('EditLink:notificationMessage.success').replace('{linkName}', inputLinkName.trim()),
          });
        } else {
          sendNotification({
            type: 'info',
            message: t('EditLink:notificationMessage.notEdited').replace('{linkName}', inputLinkName.trim()),
          });
          return;
        }
      }

      // Call updateWebgateCustomDomain for the edited or newly added custom domains
      if (modifiedCustomDomainList.length > 0) {
        for (const domainData of modifiedCustomDomainList) {
          try {
            const domainUpdateData = await updateWebgateCustomDomain(
              deviceID,
              linkId,
              Number(inputPort),
              domainData.domain,
              domainData.privateKey,
              domainData.certificate,
              domainData.remove
            );

            handleDismiss(); // Clear all notification bar before sending a new one
            await new Promise((resolve) => setTimeout(resolve, 300));
            if (
              !domainUpdateData?.updateWebgateCustomDomain?.domainUpdateStatus
            ) {
              sendNotification({
                type: 'error',
                message: t('EditLink:notificationMessage.domainUpdateFailed')
                  .replace('{domain}', domainData.domain)
                  .replace('{linkName}', inputLinkName.trim()),
              });
            } else {
              setApiRowData((prev: ITypeData[]) => {
                return prev.map((item) => {
                  const isDeviceLink =
                    item.linkName === 'standard'
                      ? item.name === devicelinkData.name
                      : item.linkID === devicelinkData.linkID;

                  if (isDeviceLink) {
                    if (domainData.remove) {
                      return {
                        ...item,
                        links: item.links.filter(
                          (link) => link !== domainData.domain
                        ),
                      };
                    } else if (domainData.remove === false) {
                      return {
                        ...item,
                        links: item.links,
                      };
                    } else {
                      return {
                        ...item,
                        links: [...item.links, domainData.domain],
                      };
                    }
                  }
                  return item;
                });
              });
              sendNotification({
                type: 'success',
                message: t('EditLink:notificationMessage.domainUpdateSuccess')
                  .replace('{domain}', domainData.domain)
                  .replace('{linkName}', inputLinkName.trim()),
              });
            }
          } catch (error) {
            const exception = handleException(error);
            console.error(
              `Error creating domain:${domainData.domain}, ${error}`
            );
            setSwitchState('default');

            handleDismiss(); // Clear all notification bar before sending a new one
            await new Promise((resolve) => setTimeout(resolve, 300));
            sendNotification({
              type: 'error',
              message: t('EditLink:notificationMessage.errorUpdatingDomain')
                .replace('{domain}', domainData.domain)
                .replace('{linkName}', inputLinkName.trim())
                .replace('{errorMessage}', t(`Common:exceptionNotificationMessages.${exception.message}`)),
            });
            return;
          }
        }
      }
      return;
    } catch (error) {
      const exception = handleException(error);
      setSwitchState('default');

      handleDismiss(); // Clear all notification bar before sending a new one
      await new Promise((resolve) => setTimeout(resolve, 300));
      setModalOpen(false);
      sendNotification({
        type: 'error',
        message: t('EditLink:notificationMessage.error')
          .replace('{linkName}', inputLinkName.trim())
          .replace('{errorMessage}', t(`Common:exceptionNotificationMessages.${exception.message}`)),
      });
    } finally {
      setModalOpen(false);
      setLoading(false);
    }
  }, [
    t,
    linkID,
    deviceID,
    inputPort,
    inputUnit,
    expiryDate,
    indexToggle,
    matchedLink,
    setModalOpen,
    isActiveLink,
    handleEnable,
    selectedUnit,
    isActiveLimit,
    setApiRowData,
    inputLinkName,
    setSwitchState,
    devicelinkData,
    isActiveDomain,
    selectedExpiry,
    selectedProtocol,
    sendNotification,
    customDomainList,
    selectedLinkValue,
    setIsFeatureEnabled,
    updatedAdvancedConfig,
    modifiedCustomDomainList
  ]);

  // Function for generating the custom domain fields on the ui
  const generateCustomDomain = useCallback(
    (domain: ICustomDomainState) => {
      const isDomainInMatchedLink = (domainValue: number) => {
        if (matchedLink) {
          // Check if the domainValue exists in the custom domain links array.
          return domainValue <= domainsFromAPI;
        }
        return false;
      };

      const isDisabled = isDomainInMatchedLink(domain.id);

      return (
        <CustomDomain key={domain.id} marginTop="8px">
          <Title button>
            <FormGroupTitle>
              <ColumnText fontSize="16px" color="#5a6269" family="monorale">
                {t('EditLink:mainContent.customConfig.heading')}{' '}
                {customDomainList.length > 1 ? domain.id : ''}
              </ColumnText>
              <Line />
            </FormGroupTitle>
            {isDisabled && !domain.remove && (
              <RemoveButtonTag>
                <Button
                  design="secondary"
                  size="xsmall"
                  onClick={() => {
                    handleEditable(domain.id, 'privateKey');
                    handleEditable(domain.id, 'certificate');
                  }}
                  disabled={loading || domain.remove}
                >
                  {domain.privateKey.editable && domain.certificate.editable
                    ? t('EditLink:mainContent.customConfig.cancelUpdateKey&Certificate')
                    : t('EditLink:mainContent.customConfig.updateKey&Certificate')}
                </Button>
              </RemoveButtonTag>
            )}
            <RemoveButtonTag>
              <Button
                design="secondary"
                size="xsmall"
                onClick={() => removeCustomDomain(domain.id)}
                disabled={loading}
              >
                {domain.remove
                  ? t('EditLink:mainContent.customConfig.keepButton')
                  : t('EditLink:mainContent.customConfig.removeButton')}
              </Button>
            </RemoveButtonTag>
          </Title>
          {domain.remove && (
            <RemoveWarning>
              {t('EditLink:mainContent.customConfig.removeWarning')}
            </RemoveWarning>
          )}
          <RemoveContent remove={domain.remove}>
            <DomainLink
              name="domain"
              value={domain.domain}
              label={t('EditLink:mainContent.customConfig.domain')}
              fieldState={loading || isDisabled ? 'disabled' : 'default'}
              disabled={loading || isDisabled}
              autoComplete='off'
              minWidth="940px"
              placeholder="subdomain.yourdomain.com"
              onChange={(e) =>
                handleInputDomainChange(domain.id, 'domain', e.target.value)
              }
            />
            <Frame>
              <CustomTextAreaContainer>
                <CustomTextArea
                  id={`${domain.id}privateKey`}
                  name={`${domain.id}privateKey`}
                  label={t('EditLink:mainContent.customConfig.privateKey')}
                  italics={!domain.privateKey.editable}
                  fieldState={loading || !domain.privateKey.editable ? 'disabled' : 'default'}
                  disabled={loading || !domain.privateKey.editable}
                  placeholder={
                    !domain.privateKey.editable
                      ? t('EditLink:mainContent.customConfig.privateKeyHiddenPlaceHolder')
                      : t('EditLink:mainContent.customConfig.privatePlaceHolder')
                  }
                  autoComplete='off'
                  value={domain.privateKey.value}
                  onChange={(e) => handleInputDomainChange(domain.id, 'privateKey', e.target.value)}
                />
              </CustomTextAreaContainer>

              <CustomTextAreaContainer>
                <CustomTextArea
                  id={`${domain.id}certificate`}
                  name={`${domain.id}certificate`}
                  label={t('EditLink:mainContent.customConfig.certificate')}
                  italics={!domain.certificate.editable}
                  fieldState={loading || !domain.certificate.editable ? 'disabled' : 'default'}
                  disabled={loading || !domain.certificate.editable}
                  placeholder={
                    !domain.certificate.editable
                      ? t('EditLink:mainContent.customConfig.certificateHiddenPlaceHolder')
                      : t('EditLink:mainContent.customConfig.certificatePlaceHolder')
                  }
                  autoComplete='off'
                  value={domain?.certificate.value}
                  onChange={(e) => handleInputDomainChange(domain.id, 'certificate', e.target.value)}
                />
              </CustomTextAreaContainer>
            </Frame>
          </RemoveContent>
        </CustomDomain>
      );
    },
    [
      t,
      loading,
      matchedLink,
      domainsFromAPI,
      customDomainList,
      removeCustomDomain,
    ]
  );

  return (
    <MainContainer>
      <Wrapper enableContent={isFeatureEnabled}>
        <SectionContainer padding='0 0 4px' gap='8px'>
          <SectionContainer gap='8px' padding='24px 96px 35px'>
            <SectionContainer padding='0'>
              <SectionContainer gap='24px' padding='0 32px 6px 0' grow={1}>
                <DeviceInfo>
                  <PageHeaderContainer
                    icon='Link'
                    areaTitleBottom
                    areaTitle={`${devicelinkData.name} - ${linkID || '[link_id].webgate-biz.scorer.jp'}`}
                    title={t('DeviceLinks:confirmationPopup.enableModalHeading')}
                  />
                </DeviceInfo>
              </SectionContainer>
            </SectionContainer>
          </SectionContainer>
          <LineDivider></LineDivider>
        </SectionContainer>
        <MainSection>
          <InnerMainContainer>
            <ModalContentCreateLink>
              <ConfigurationBlock marginBottom="4px">
                <FormGroupTitle>
                  <ColumnText fontSize="16px" color="#5a6269" family="monorale">
                    {t('EditLink:mainContent.typeConfig.heading')}
                  </ColumnText>
                  <Line />
                </FormGroupTitle>
                <FieldRow>
                  <LinkTypeField
                    label={{ htmlFor: 'linkType', text: t('EditLink:mainContent.typeConfig.linkType'), }}
                    placeholder={t('EditLink:mainContent.typeConfig.placeHolder1')}
                    defaultValue={selectedLinkValue}
                    value={selectedLinkValue}
                    minWidth="220px"
                    disabled
                    required
                  >
                    {' '}
                    {linkOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </LinkTypeField>
                  <DomainLink
                    name='link_name'
                    id='link_name'
                    minWidth="420px"
                    divWidth="420px"
                    width="420px"
                    paddingRight="0px"
                    maxLength={100}
                    autoComplete='off'
                    label={t('EditLink:mainContent.typeConfig.linkName')}
                    placeholder={t('EditLink:mainContent.typeConfig.placeHolder2')}
                    value={inputLinkName}
                    onChange={handleLinkNameChange}
                    fieldState={loading || ['Solution UI', 'Edge UI'].includes(selectedLinkValue) ? 'disabled' : 'default'}
                    disabled={loading || ['Solution UI', 'Edge UI'].includes(selectedLinkValue)}
                  />
                </FieldRow>
              </ConfigurationBlock>

              <ConfigurationBlock>
                <FormGroupTitle>
                  <ColumnText fontSize="16px" color="#5a6269" family="monorale">
                    {t('EditLink:mainContent.basicConfig.heading')}
                  </ColumnText>
                  <Line />
                </FormGroupTitle>
                <FieldRow>
                  <LinkTypeField
                    label={{ htmlFor: 'protocol', text: t('EditLink:mainContent.basicConfig.protocol'), }}
                    value={selectedProtocol}
                    defaultValue={selectedProtocol}
                    placeholder={t('EditLink:mainContent.basicConfig.typeOptions.placeHolder')}
                    minWidth="250px"
                    disabled
                    required
                  >
                    {' '}
                    {protocolOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </LinkTypeField>
                  <DomainLink
                    name='domain'
                    id='domain'
                    divWidth="576px"
                    paddingRight="0px"
                    label={t('EditLink:mainContent.basicConfig.domain')}
                    value={domain}
                    fieldState="disabled"
                    disabled
                  />
                  <DomainLink
                    name='port_number'
                    id='port_number'
                    divWidth="auto"
                    paddingRight="0px"
                    label={t('EditLink:mainContent.basicConfig.portNumber')}
                    placeholder={t('EditLink:mainContent.basicConfig.placeHolder')}
                    autoComplete='off'
                    value={inputPort}
                    type="text"
                    fieldState="disabled"
                    disabled
                  />
                </FieldRow>
              </ConfigurationBlock>

              <ConfigurationBlock padding="8px 0px 0px 0px" marginBottom="8px">
                <FormGroupTitle>
                  <ColumnText fontSize="16px" color="#5a6269" family="monorale">
                    {t('EditLink:mainContent.advancedConfig.heading')}
                  </ColumnText>
                  <Line />
                </FormGroupTitle>
                <FieldRow
                  padding="0px"
                  gap="48px"
                  justifyContent="space-between"
                >
                  <AdvanceOptions minWidth="764px">
                    <ColumnText
                      color="#889096"
                      fontSize="14px"
                      family="monorale"
                      height="16.5px"
                    >
                      {t('EditLink:mainContent.advancedConfig.addOptions')}
                    </ColumnText>
                    <ButtonWithIconWrapper>
                      <AdvanceButtons minWidth="764px">
                        <ButtonWithIcon
                          icon="Add"
                          design="secondary"
                          shadow
                          size="normal"
                          position="left"
                          onClick={handleDomain}
                          disabled={isActiveDomain || loading || selectedProtocol === 'RTMPS'}
                        >
                          {t(
                            'EditLink:mainContent.advancedConfig.buttonsText.custom'
                          )}
                        </ButtonWithIcon>
                        <ButtonWithIcon
                          icon="Add"
                          design="secondary"
                          shadow
                          size="normal"
                          position="left"
                          onClick={handleLink}
                          disabled={isActiveLink || loading}
                        >
                          {t(
                            'EditLink:mainContent.advancedConfig.buttonsText.expiry'
                          )}
                        </ButtonWithIcon>
                        <ButtonWithIcon
                          icon="Add"
                          design="secondary"
                          shadow
                          size="normal"
                          position="left"
                          onClick={handleLimit}
                          disabled={
                            isActiveLimit ||
                            loading ||
                            selectedProtocol === 'RTMPS'
                          }
                        >
                          {t(
                            'EditLink:mainContent.advancedConfig.buttonsText.limit'
                          )}
                        </ButtonWithIcon>
                      </AdvanceButtons>
                    </ButtonWithIconWrapper>
                  </AdvanceOptions>
                  <AdvanceOptions padding="13px 0px 0px" marginRight="3px">
                    <ColumnText
                      color="#889096"
                      fontSize="14px"
                      family="monorale"
                    >
                      {t('EditLink:mainContent.advancedConfig.search')}
                    </ColumnText>
                    <ToggleSwitch
                      checked={indexToggle}
                      labelText={t(
                        'EditLink:mainContent.advancedConfig.switchLabel'
                      )}
                      leftTheme="off"
                      rightTheme="on"
                      state={
                        loading || selectedProtocol === 'RTMPS'
                          ? 'disabled'
                          : 'default'
                      }
                      onChangeCallback={() => setIndexToggle(!indexToggle)}
                      info
                      infoColor="#12a594"
                      infoText={t(
                        'EditLink:mainContent.advancedConfig.tooltip'
                      )}
                      tooltipMinWidth="325px"
                    />
                  </AdvanceOptions>
                </FieldRow>
              </ConfigurationBlock>

              {isActiveDomain && (
                <CustomDomain>
                  <ModalContentCreateLink>
                    {customDomainList.map((domain, index) => (
                      <Div
                        key={domain.id}
                        ref={index === customDomainList.length - 1 ? lastDomainRef : null}
                      >
                        {generateCustomDomain(domain)}
                      </Div>
                    ))}
                  </ModalContentCreateLink>
                  <AdvanceOptions>
                    <ButtonWithIcon
                      icon="Add"
                      design="secondary"
                      shadow
                      size="normal"
                      position="left"
                      disabled={
                        customDomainList.length >= domainsFromAPI + 5 ||
                        loading ||
                        !areAllUnmatchedDomainsFilled()
                      }
                      onClick={handleDomain}
                    >
                      {t('EditLink:mainContent.customConfig.buttonText')}
                    </ButtonWithIcon>
                  </AdvanceOptions>
                </CustomDomain>
              )}
              {isActiveLink && (
                <LinkExpiry ref={linkExpiryRef}>
                  <Title button>
                    <FormGroupTitle>
                      <ColumnText
                        fontSize="16px"
                        color="#5a6269"
                        family="monorale"
                      >
                        {t('EditLink:mainContent.linkConfig.heading')}
                      </ColumnText>
                      <Line />
                    </FormGroupTitle>
                    <RemoveButtonTag>
                      <Button
                        design="secondary"
                        size="xsmall"
                        onClick={() => {
                          setIsActiveLink(false);
                          setExpiryDate(0);
                          setSelectedDate(undefined);
                          setSelectedExpiry('');
                        }}
                        disabled={loading}
                      >
                        {t('EditLink:mainContent.linkConfig.removeButton')}
                      </Button>
                    </RemoveButtonTag>
                  </Title>
                  <FieldRow padding="0px">
                    <DatePickerSection ref={datepickerRef}>
                      <DomainLink
                        id='time_date_of_expiry'
                        name='time_date_of_expiry'
                        label={t('EditLink:mainContent.linkConfig.subHeading1')}
                        placeholder={t('EditLink:mainContent.linkConfig.placeHolder1')}
                        fieldState={loading ? 'disabled' : 'default'}
                        disabled={loading}
                        value={selectedDate ? formatCalendarDateTime(selectedDate as Date) : ''}
                        onClick={() => setIsDatePicker(!isDatePicker)}
                        readOnly
                      />
                      <IconWrapper
                        position="absolute"
                        right="17px"
                        top="53%"
                        onClick={() => setIsDatePicker(!isDatePicker)}
                      >
                        <Icon size={16} icon="DateTime" color="dimmed" />
                      </IconWrapper>
                      {isDatePicker && (
                        <DateDrawer>
                          <DatePicker
                            dateMode="single"
                            timeMode="single"
                            availableRange={{ start: new Date(), end: null }}
                            timeZoneValueTitle={getSystemTimezoneAbbreviation()}
                            initialValue={selectedDate || currentDate}
                            updateCallback={(date: Date | DateInterval) => {
                              if (expiryDate !== convertToEpoch(date)) {
                                setExpiryDate(convertToEpoch(date));
                                setSelectedDate(date);
                              }
                            }}
                          />
                        </DateDrawer>
                      )}
                    </DatePickerSection>
                    <LinkTypeField
                      label={{
                        htmlFor: 'expiry',
                        text: t('EditLink:mainContent.linkConfig.subHeading2'),
                      }}
                      changeCallback={handleChangeExpiry}
                      placeholder={t('EditLink:mainContent.linkConfig.placeHolder2')}
                      disabled={loading}
                      value={selectedExpiry}
                      required
                    >
                      {' '}
                      {expiryOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </LinkTypeField>
                  </FieldRow>
                </LinkExpiry>
              )}
              {isActiveLimit && (
                <SetLimit ref={limitRef}>
                  <Title button>
                    <FormGroupTitle>
                      <ColumnText
                        fontSize="16px"
                        color="#5a6269"
                        family="monorale"
                      >
                        {t('EditLink:mainContent.limitConfig.heading')}
                      </ColumnText>
                      <Line />
                    </FormGroupTitle>
                    <RemoveButtonTag>
                      <Button
                        design="secondary"
                        size="xsmall"
                        onClick={() => {
                          setIsActiveLimit(false);
                          setInputUnit('100');
                          setSelectedUnit('MiB');
                        }}
                        disabled={loading}
                      >
                        {t('EditLink:mainContent.limitConfig.removeButton')}
                      </Button>
                    </RemoveButtonTag>
                  </Title>
                  <FieldRow gap="16px">
                    <DomainLink
                      id='size'
                      name='size'
                      minWidth="128px"
                      width="128px"
                      divWidth="auto"
                      label={t('EditLink:mainContent.limitConfig.subHeading1')}
                      placeholder={t('EditLink:mainContent.limitConfig.placeHolder1')}
                      type="text"
                      inputMode='numeric'
                      autoComplete='off'
                      value={inputUnit}
                      onChange={handleInputUnit}
                      fieldState={loading ? 'disabled' : 'default'}
                      disabled={loading}
                    />
                    <LinkTypeField
                      label={{
                        htmlFor: 'unit',
                        text: t('EditLink:mainContent.limitConfig.subHeading2'),
                      }}
                      minWidth="88px"
                      width="88px"
                      value={selectedUnit}
                      defaultValue={selectedUnit}
                      changeCallback={handleChangeUnit}
                      disabled={loading}
                    >
                      {' '}
                      {unitOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </LinkTypeField>
                  </FieldRow>
                  <HelperText>
                    <Icon size={12} icon="Information" />
                    <ColumnText fontSize="12px" color="#76aaf4" height="14.5px">
                      {t('EditLink:mainContent.limitConfig.helperText')}
                    </ColumnText>
                  </HelperText>
                </SetLimit>
              )}
              <ButtonsSection loading={loading ? 'true' : 'false'}>
                <Button
                  size="large"
                  design="secondary"
                  onClick={handleCancel}
                  disabled={loading}
                >
                  <LinkContainer fontSize="16px">
                    {t('EditLink:mainContent.actionButtons.button1')}
                  </LinkContainer>
                </Button>
                <ButtonWithLoading
                  design="primary"
                  loading={loading}
                  size="large"
                  onClick={handleEditDeviceLink}
                  disabled={loading}
                >
                  {t('DeviceLinks:confirmationPopup.enableLink')}
                </ButtonWithLoading>
              </ButtonsSection>
            </ModalContentCreateLink>
          </InnerMainContainer>
        </MainSection>
      </Wrapper>
    </MainContainer>
  );
};

export default ConfirmEnableModal;
