import React, { ChangeEvent, useState, useCallback } from 'react';
import styled from 'styled-components';
import SelectButton from '../atoms/SelectButton';
import SelectList from '../atoms/SelectList';
import { SelectOption, OptionText } from '../atoms/SelectOption';
import SearchInput from '../atoms/SearchInput';
import { CheckMarkSVG } from '../../svg';
import { useTranslation } from 'react-i18next';

const FilterInput = styled(SearchInput)`
  & input {
    border-radius: 0;
    border: none;
    height: 55px;
    width: 106px;
    flex-shrink: 0;
    box-shadow: inset 0 0px 6px 0 hsla(0, 0%, 0%, 0.06);
    background-color: hsl(208, 100%, 97%);
    ::placeholder {
      opacity: 0.5;
      color: hsl(0, 0%, 53%);
      position: absolute;
      top: 15px;
    }
  }
`;

const CheckMarkIcon = styled(CheckMarkSVG)`
  width: 14px;
  height: 14px;
  margin-right: 10px;
  flex-shrink: 0;
`;


const Container = styled.div<{ width?: string, disabled: boolean }>`
  width:${props => props.width};
  position: relative;
  height: 100%;
  outline: none;
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  & > button {
    border: solid 1px hsl(0, 0%, 92%);
  }
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: hsl(213, 4%, 55%);
  padding:6px 0 9px 8px;
  border: solid 1px hsla(200, 7%, 24%,0.15);
  border-left:none;
  border-right:none;
`;

const IconBox = styled.div`
  width:18px;
  height:18px;
  margin-right:5px;
  display:flex;
  align-items:center;
`;

const CheckBox = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 5px;
  border: solid 2px hsl(208, 25%, 78%);
`;

interface ISelectProps {
  title: string;
  list: string[];
  selected: string[];
  onSelect: (items: string[]) => void;
  showFilter?: boolean;
  width?: string;
  clearable?: boolean;
  icon?: string;
  bgColor?: string;
  disabled: boolean;
}

const SelectMulti: React.FC<ISelectProps> = ({ title, showFilter, list, selected, icon, width, onSelect, bgColor, disabled }) => {
  // for switching the language between japanese and english.
  const { t } = useTranslation(['Webgateway']);

  const [expanded, setExpanded] = useState(false);
  const [search, setSearch] = useState('');
  const listItems = list.filter(item => (!search || item.toLowerCase().includes(search.toLowerCase())));

  const onSearch = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value || '');
  }, []);

  const toggle = useCallback(() => {
    setSearch('');
    setExpanded(!expanded);
  }, [expanded]);

  const select = useCallback((item: string, selectedList: string[]) => {
    if (selectedList.includes(item)) {
      onSelect(selectedList.filter(select => item !== select));
    } else {
      const selected = selectedList.concat([item]);
      onSelect(selected);
    }
  }, [onSelect]);

  const onBlur = useCallback((e: React.FocusEvent<HTMLDivElement>) => {
    const currentTarget = e.currentTarget;
    setTimeout(() => {
      if (!currentTarget.contains(document.activeElement)) {
        setSearch('');
        setExpanded(false);
      }
    }, 0);
  }, []);

  return (
    <Container width={width} tabIndex={0} onBlur={onBlur} disabled={disabled}>
      <SelectButton expanded={expanded} onClick={toggle} title={title} icon={icon} hideArrow={false} bgColor={bgColor} disabled={disabled} />
      <SelectList expanded={expanded} boxWidth='268px'>
        {showFilter ? <FilterInput type='text' placeholder={t('Webgateway:filters.filterTags')} value={search} onChange={onSearch} /> : ''}
        <Title>{t('Webgateway:filters.showing').replace('{No.1}', listItems.length.toString()).replace('{No.2}', list.length.toString())}</Title>
        {
          listItems.map((item) =>
            <SelectOption selected={selected.includes(item)} onClick={() => { select(item, selected); }} key={item}>
              <IconBox>{selected.includes(item) ? <CheckMarkIcon /> : <CheckBox />}</IconBox><OptionText title={item}>{item}</OptionText>
            </SelectOption>)
        }
      </SelectList>
    </Container>
  );
};

export default SelectMulti;
