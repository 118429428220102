import React, { useCallback, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { Checkbox, Icon, TableRowThumbnail, useCopyToClipboard } from 'scorer-ui-kit';
import { ITableColumnConfig, IRowData, IDeviceStatus } from 'scorer-ui-kit/dist/Tables';

type TypeDeviceStatus = IDeviceStatus;

const StatusContainer = styled.div<{ status: TypeDeviceStatus }>`
  height: 42px;
  width: 4px;
  border-radius: 3px;
  display: block;
  ${({ status, theme: { colors } }) => css`
    background: ${colors.status[status]};
    content: '${status}';
  `}
`;

const RowContainer = styled.div<{ isEmpty: boolean }>`
  display: flex;
  align-items: stretch;
  color: white;
  height:min-content;
  padding-top: 15px;
  ${({ isEmpty }) =>
    isEmpty &&
    css`
      visibility: hidden;
    `}
`;

const CopyToClipboard = styled.button`
  cursor: pointer;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 14px;
  width: 20px;
  height: 20px;
  background: transparent;
  border: none;
  outline: none;

  ${({ theme }) =>
    theme &&
    css`
      transition: ${theme.animation.speed.normal}
        ${theme.animation.easing.easeOut};
    `}

  svg {
    display: block;
  }

  &:hover {
    opacity: 1 !important;
  }
`;

const IconWrapper = styled.div<{
  position?: string;
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
  zIndex?: string;
}>`
  position: ${({ position }) => position};
  top: ${({ top }) => top};
  right: ${({ right }) => right};
  bottom: ${({ bottom }) => bottom};
  left: ${({ left }) => left};
  z-index: ${({ zIndex }) => zIndex};
`;

const CellContainer = styled.div<{
  cellStyle: string;
  alignment: string;
  hideDivider?: boolean;
  hasCopyButton?: boolean;
  fixedWidth?: number;
  minWidth?: number;
}>`
  flex: 1;
  padding: 15px 0px;
  display: flex; 
  align-items: center; 
  position: relative;
  line-height: 30px;
  font-family: ${(p) => p.theme.fontFamily.data};
  &:hover ${CopyToClipboard} {
    opacity: 0.5;
  }

  &,
  a {
    ${({ theme, cellStyle, alignment }) =>
    cellStyle === 'firstColumn'
      ? css`${theme.typography.table.columnData[cellStyle]};`
      : css`${theme.typography.table.columnData[cellStyle][alignment]};`}
  }

  a:hover {
    text-decoration: underline;
  }


  ${({ theme: { styles }, hideDivider }) =>
    !hideDivider &&
    css`
      &::after {
        ${styles.tables.rows.divider};
        content: '';
        display: block;
        height: 1px;
        left: 0;
        right: 0;
        width: 100%;
        bottom: 0px;
        position: absolute;
      }
    `}

  ${({ fixedWidth }) =>
    fixedWidth &&
    css`
      width: ${fixedWidth}px;
      flex: 0 0 auto;
    `};
  
  ${({ minWidth }) => minWidth && css`
    min-width:${minWidth}px;
  `};
`;

const UnitText = styled.span`
  ${({ theme }) => css`
    ${theme.typography.table.columnData['unit']};
  `}
`;

const StatusBlip = styled.div<{ status?: string }>`
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 8px;
  ${({ status = 'neutral', theme: { colors } }) => css`
    background: ${colors.status[status]};
  `}
`;

interface ITypeTableCellProps {
  isLastRow?: boolean;
  hideDivider?: boolean;
  cellStyle?: string;
  alignment?: string;
  href?: string;
  showUnit?: boolean;
  showStatus?: boolean;
  unit?: string;
  status?: string;
  hasCopyButton?: boolean;
  fixedWidth?: number;
  minWidth?: number;
}

const TypeTableCell: React.FC<ITypeTableCellProps> = ({
  showUnit = false,
  showStatus = false,
  status,
  unit = '',
  cellStyle = 'normalImportance',
  alignment = 'left',
  hideDivider,
  isLastRow,
  hasCopyButton,
  href,
  fixedWidth,
  minWidth,
  children,
}) => {
  hideDivider = isLastRow ? true : hideDivider;
  const { copyToClipboard } = useCopyToClipboard();

  function extractText(content: ReactNode): string {
    let extractedText = '';

    // content = content.props.contentArray;
    // const traverse = (node: ReactNode) => {
    //   if (typeof node === 'string' || typeof node === 'number') {
    //     extractedText += node;
    //   } else if (Array.isArray(node)) {
    //     node.forEach(traverse);
    //   } else if (React.isValidElement(node)) {
    //     const { children } = node.props;
    //     traverse(children);
    //   }
    // };

    const traverse = (node: ReactNode) => {
      if (typeof node === 'string' || typeof node === 'number') {
        extractedText += node;
      } else if (Array.isArray(node)) {
        node.forEach(traverse);
      } else if (React.isValidElement(node)) {
        const { children } = node.props as { children?: ReactNode };
        traverse(children);
      }
    };

    traverse(content);
    return extractedText;
  }



  return (
    <CellContainer
      {...{ cellStyle, alignment, hideDivider, hasCopyButton, fixedWidth, minWidth }}
    >
      {showStatus ? <StatusBlip {...{ status }} /> : null}
      {href ? <a href={href}>{children}</a> : children}
      {showUnit ? <UnitText>{unit}</UnitText> : null}
      {hasCopyButton ? (
        <CopyToClipboard
          onClick={() => copyToClipboard(extractText(children))}
        >
          <IconWrapper
            position='absolute'
            bottom='-25px'
            right='5px'
          >
            <Icon icon='Copy' size={16} />
          </IconWrapper>
        </CopyToClipboard>
      ) : null}
    </CellContainer>
  );
};

interface IProps {
  isLastRow?: boolean;
  selectable?: boolean;
  hasStatus?: boolean;
  hasThumbnail?: boolean;
  hasTypeIcon?: boolean;
  columnConfig: ITableColumnConfig[];
  rowData: IRowData;
  selectCallback?: (checked: boolean, id?: string | number) => void;
  closeText?: string;
}

const AccordionTableRow: React.FC<IProps> = ({
  selectable = false,
  selectCallback,
  hasStatus,
  hasThumbnail,
  hasTypeIcon,
  rowData,
  isLastRow,
  columnConfig,
}) => {
  const wrappedSelectCallback = useCallback(
    (checked: boolean) => {
      if (selectCallback) {
        selectCallback(checked, rowData.id);
      }
    },
    [rowData.id, selectCallback]
  );

  const isEmpty = rowData.columns.length === 0;

  return (
    <RowContainer isEmpty={isEmpty}>
      {selectable && (
        <TypeTableCell hideDivider = {isLastRow ? true : false} fixedWidth={34}>
          <Checkbox
            checked={rowData._checked}
            onChangeCallback={wrappedSelectCallback}
          />
        </TypeTableCell>
      )}
      {hasStatus && (
        <TypeTableCell hideDivider fixedWidth={10}>
          <StatusContainer status={rowData.header?.status} />;
        </TypeTableCell>
      )}
      {hasThumbnail && (
        <TypeTableCell hideDivider fixedWidth={70}>
          <TableRowThumbnail
            image={rowData.header?.image}
            mediaUrl={rowData.header?.mediaUrl}
            mediaType={rowData.header?.mediaType}
          />
        </TypeTableCell>
      )}
      {hasTypeIcon && (
        <TypeTableCell hideDivider fixedWidth={35}>
          <Icon
            icon={rowData.header?.icon || ''}
            color='dimmed'
            weight='regular'
            size={16}
          />
        </TypeTableCell>
      )}
      {rowData.columns.map((cell, key) => {
        const {
          cellStyle,
          alignment,
          showUnit,
          showStatus,
          hasCopyButton,
          minWidth,
          width
        } = columnConfig[key];
        const { unit, status, text, customComponent } = cell;
        return (
          <TypeTableCell
            key={key}
            href={cell.href}
            {...{
              cellStyle,
              alignment,
              showUnit,
              showStatus,
              hasCopyButton,
              unit,
              status,
              isLastRow,
              fixedWidth: width,
              minWidth
            }}
          >
            {customComponent ? customComponent : text}
          </TypeTableCell>
        );
      })}
    </RowContainer>
  );
};

export default AccordionTableRow;
