import React, { useContext, useState, useCallback, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { FeatureEnabledContext, ContextProps } from '../App';
import { Button, ButtonWithIcon, Content, Icon, PageHeader, SelectField, SmallInput, TextAreaField, DatePicker, FilterDropdownContainer, useClickOutside, ButtonWithLoading, DateInterval, Spinner } from 'scorer-ui-kit';
import { useNotification } from '../components/Notifications/useNotification';
import { useTranslation } from 'react-i18next';
import Breadcrums from '../components/molecules/Breadcrums';
import ToggleSwitch from '../components/molecules/ToggleSwitch';
import { updateWebgateCustomDomain, createWebgateDeviceLink, isWebgateEnabled } from '../lib/api_config';
import { useHistory, useParams } from 'react-router-dom';
import useCheckDevice from '../hooks/useCheckDevice';
import { convertToEpoch, formatCalendarDateTime, getSystemTimezoneAbbreviation } from '../Utils/DateUtils';
import { validateInputs } from '../Utils/ValidateInputFields';
import { ErrorType, handleException } from '../hooks/handleException';
import { handleDismiss } from '../components/Notifications/Notification';
import { convertAndValidateInput } from '../Utils/ConversionUtils';

const MainContainer = styled(Content)`
  width: 100%;
  padding: 0px;
  max-width: calc(100vw - 80px);
  div {
    max-width: 100%;
  }
`;

const Wrapper = styled.div<{ enableContent?: boolean }>`
  width: 100%;
  opacity: ${({ enableContent }) => (enableContent ? '1' : '0.6')};
  pointer-events: ${({ enableContent }) => (enableContent ? 'auto' : 'none')};
`;

const SectionContainer = styled.div<{
  height?: string;
  width?: string;
  gap?: string;
  padding?: string;
  grow?: number;
  direction?: string;
  justify?: string;
  align?: string;
}>`
  width: ${({ width }) => (width ? width : 'auto')};
  height: ${({ height }) => (height ? height : 'auto')};
  align-self: stretch;
  flex-grow: ${({ grow }) => (grow ? grow : 0)};
  display: flex;
  flex-direction: ${({ direction }) => (direction ? direction : 'column')};
  justify-content: ${({ justify }) => (justify ? justify : 'flex-start')};
  align-items: ${({ align }) => (align ? align : 'stretch')};
  gap: ${({ gap }) => (gap ? gap : '16px')};
  padding: ${({ padding }) => (padding ? padding : '32px 96px 64px')};
`;

const Drawer = styled.div`
  height: 24px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  color: #898e96;
`;

const IconWrapper = styled.div<{
  marginTop?: string;
  height?: string;
  width?: string;
  position?: 'absolute' | 'relative';
  left?: string;
  top?: string;
  right?: string;
}>`
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '4px')};
  width: ${({ width }) => (width ? width : '16px')};
  height: ${({ height }) => (height ? height : '16px')};
  display: flex;
  justify-content: center;
  padding: 4px;
  align-items: center;
  flex-grow: 0;
  position: ${({ position }) =>
    position === 'absolute' ? position : 'relative'};
  left: ${({ position, left }) => (position === 'absolute' ? left : 'auto')};
  right: ${({ position, right }) => (position === 'absolute' ? right : 'auto')};
  top: ${({ position, top }) => (position === 'absolute' ? top : 'auto')};
`;

const ColumnText = styled.p<{
  padding?: string;
  fontSize?: string;
  color?: string;
  fontWeight?: string;
  letter?: string;
  line?: string;
  margin?: string;
  family?: string;
  height?: string;
}>`
  height: ${({ height }) => (height ? height : 'auto')};
  padding: ${({ padding }) => (padding ? padding : '0px')};
  font-family: ${({ family }) => (family ? family : 'Lato')};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '14px')};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ color }) => (color ? color : '#898e96')};
  margin: ${({ margin }) => (margin ? margin : '0px')};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : '500')};
  letter-spacing: ${({ letter }) => letter};
  line-height: ${({ line }) => line};
`;

const LinkContainer = styled.a<{
  fontSize?: string;
}>`
  width: auto;
  height: 12px;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '12px')};
  font-weight: 500;
  text-decoration: none !important;
  letter-spacing: -0.2px;
  line-height: 1;
`;

const DeviceInfo = styled.div<{dataLoading?: boolean}>`
  width: auto;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  padding: 0px 0 4px;
  color: #8b9196;
  position: relative;

  > div > div > div > div:nth-child(1) {
    ${({ dataLoading }) =>
    dataLoading &&
      `
      overflow-wrap: anywhere;
      gap: 1px;
      > div {
        line-height: 21px; 
      }
      > h1 {
        line-height: 30px; 
      }
      `
}
  }

  > div > div > div > div:nth-child(2) {
    ${({ dataLoading }) =>
    !dataLoading &&
      `
      overflow-wrap: anywhere;
      gap: 1px;
      > div {
        line-height: 21px; 
      }
      > h1 {
        line-height: 30px; 
      }
      `
}
  }
`;

const SpinnerContainer = styled.div`
  position: absolute;
  left: -45px;
  bottom: 20%;
`;

const IconButton = styled(Button) <{
  border?: string;
  background?: string;
  radius?: string;
  height?: string;
  gap?: string;
  padding?: string;
  top?: string;
}>`
  display:flex;
  height: ${({ height }) => (height ? height : '28px')};
  align-items: center;
  justify-content:center';
  font-size : 14px;
  padding: ${({ padding }) => (padding ? padding : '0 8px')};
  gap: ${({ gap }) => (gap ? gap : '8px')};
  border-right: solid 1px #e6e8ec;
  border: ${({ border }) => (border ? border : '')};
  border-radius: ${({ radius }) => (radius ? radius : '3px')};
  background: ${({ background }) => (background ? background : '')};
  pointer-events: none;
  > p {
    overflow-wrap: anywhere;
    line-height: 18px;
    text-align: left;
  }
`;

const DeviceTagCategory = styled.div`
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
  padding: 0;
  font-family: Lato;
  flex-wrap: wrap;
`;

const PageHeaderText = styled.div`
  max-width: 648px !important;
  flex-grow: 1;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.43;
  letter-spacing: 0.04px;
  text-align: left;
  color: #717989;
  font-family: ${({ theme }) => theme.fontFamily.ui};
  height: 60.5px;
`;

const LineDivider = styled.div`
  width: 100%;
  height: 4px;
  padding: 8px;
  border-top: 1px solid #e6e8ec;
`;

const MainSection = styled.div`
  min-width: 940px;
  width: 100%;
  align-self: stretch;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  padding: 32px 96px 64px 96px;
`;

const InnerMainContainer = styled.div`
  min-width: 100%;
  padding: 0px 71px 0px 0px;
`;

const ModalContentCreateLink = styled.div`
  min-width: 940px;
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 36px;
  padding: 0px;
`;

const FormGroupTitle = styled.div`
  width: 100%;
  height: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  padding: 0;
  height: 18.5px;
`;

const Line = styled.div`
  height: 1px;
  flex-grow: 1;
  background-color: #e8e8ec;
`;

const ConfigurationBlock = styled.div<{
  gap?: string;
  marginBottom?: string;
}>`
  min-width: 940px;
  margin-bottom: ${({ marginBottom }) => marginBottom};
  width: 100%;
  height: auto;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: ${({ gap }) => (gap ? gap : '8px')};
  padding: 4px 0px 0px 0px;
`;

const FieldRow = styled.div<{
  padding?: string;
  gap?: string;
  justifyContent?: string;
}>`
  min-width: 940px;
  height: auto;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: ${({ justifyContent }) => justifyContent ? justifyContent : 'flex-start'};
  align-items: flex-start;
  gap: ${({ gap }) => (gap ? gap : '16px')};
  padding: ${({ padding }) => (padding ? padding : '8px 0')};
  label {
    > span {
      height: 16.5px; 
    }
  }
`;

const LinkTypeField = styled(SelectField) <{
  width?: string;
  minWidth?: string;
}>`
  min-width: ${({ minWidth }) => (minWidth ? minWidth : '204px')};
  width: ${({ width }) => width};
  padding: 0 25px 1px 11px;
  line-height: 1.14;
  font-size: 14px !important;
`;

const DomainLink = styled(SmallInput) <{
  minWidth?: string;
  divWidth?: string;
  width?: string;
  paddingRight?: string;
  position?: string;
}>`
  position: ${({ position }) => position};
  width: ${({ divWidth }) => (divWidth ? divWidth : '100%')};
  label {
    span {
      height: 16.5px;
    }
  }
  div {
    min-width: ${({ minWidth }) => (minWidth ? minWidth : 'fit-content')};
    max-width: 100%;
    height: 40px;
    display: flex;
    flex-grow: 1;
    align-items: center;
    div {
      right: 10px;
      padding-right: 0px !important;
      left: 12px;
      top: 0;
      font-size: 14px;
      line-height: 1.14;
      font-family: Lato;
    }
    input {
      width: ${({ width }) => (width ? width : '100%')};
      padding-right: ${({ paddingRight }) => paddingRight};
    }
  }
`;

const AdvanceOptions = styled.div<{
  minWidth?: string;
  padding?: string;
  marginRight?: string;
  marginTop?: string;
}>`
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : '0px')};
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '0px')};
  max-width: 940px;
  min-height: 80px;
  max-height: auto;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  padding: ${({ padding }) => (padding ? padding : '8px 0')};
`;

const AdvanceButtons = styled.div`
  max-width: auto;
  min-height: 32px;
  max-height: auto;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  padding: 0;
`;

const ButtonWithIconWrapper = styled.div`
  button > div > div:first-child {
    padding: 0px 7px;
  }
`;

const ButtonsSection = styled.div<{
  loading?: string;
}>`
  max-width: 940px;
  min-height: 40px;
  max-height: auto;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 8px;

  > button {
    padding: 8px 16px;
    > a {
      height: auto;
      line-height: normal;
    }
  }

  > div > button > div > div:first-child {
    padding: 0px;
    padding: ${({ loading }) => (loading === 'true' ? '0px 16px' : '')};
  }
`;

const Div = styled.div``;

const CustomDomain = styled.div`
  min-width: 940px;
  height: auto;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 16px;
  padding: 0px;
  margin-bottom: 4px;
`;

const RemoveButtonTag = styled.div`
  margin-top: -1px;
  button {
    padding: 3px 8px;
    height: 20px;
  }
`;

const Title = styled.div<{ button?: boolean }>`
  min-width: 940px;
  height: 20px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  padding: 0;

  div {
    width: ${({ button }) => (button ? 'auto' : '100%')};
  }
`;

const Frame = styled.div`
  width : 100%
  height: auto;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 32px;
  padding: 0;
  label{
    margin-bottom: 0px !important;
    width: 100%;
    gap: 0px;
    span{
      margin-bottom: 8px;
      height: 16.5px;
    }
  }
`;

const CustomTextArea = styled(TextAreaField)`
  placeholder: 8px !important;
  width: 100%;
  resize: none;
  height: 165px;
  padding: 12px;
  line-height: 20px;
`;

const LinkExpiry = styled.div`
  min-width: 940px;
  height: auto;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  padding: 0px;
`;

const SetLimit = styled.div`
  margin-top: 5px;
  min-width: 940px;
  height: auto;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 11px;
  padding: 0px;
`;

const HelperText = styled.div`
  display: flex;
  gap: 8px;
  margin-top: -4px;

  > div:first-child {
    display: flex;
    align-items: center;

    g {
      stroke: #76aaf4;
    }
  }
`;

const DatePickerSection = styled.div`
  height: 64px;
  width: 460px;
  position: relative;
  display: flex;
  align-items: center;
`;

const DateDrawer = styled(FilterDropdownContainer)`
  position: absolute;
  z-index: 100;
  top: 72px;
  right: 0px;
`;

// All interfaces

// Type for the expected parameters
interface IParams {
  deviceID: string;
}

// Section 3a: Custom Domain Configuration

// Interface for the list of custom domains list
interface ICustomDomainState {
  id: number;
  domain: string;
  privateKey: string;
  certificate: string;
}

const CreateLinks: React.FC = () => {
  // variable from the params
  const { deviceID } = useParams<IParams>();

  // Check validation for webgate enabled or not
  const {
    isFeatureEnabled,
    setIsFeatureEnabled,
    setSwitchState,
  }: ContextProps = useContext(FeatureEnabledContext);

  // Variable defined to use the history hook provided by the router library
  const history = useHistory();

  // To use the text transalation files (English - Japanese)
  const { t } = useTranslation(['CreateLinks', 'Common']);

  // Function used from the useNotification component to send notifications
  const { sendNotification } = useNotification();

  // All States

  // Section 1: Type Configuration

  // State for choosen link type value
  const [selectedLinkValue, setSelectedLinkValue] = useState<string>('');

  // State for using Webgate link name
  const [inputLinkName, setInputLinkName] = useState<string>('');

  // Section 2: Basic Configuration Configuration

  // State for choosen protocol type value
  const [selectedProtocol, setSelectedProtocol] = useState<string>('');

  // State for using linkID
  const [linkID, setLinkID] = useState<string>('');

  // State for using the link port value
  const [inputPort, setInputPort] = useState<string>('');

  // Section 3: Advanced Configuration
  // State for using the index toggle behaviour
  const [indexToggle, setIndexToggle] = useState(true);

  // Section 3a: Custom Domain Configuration

  // State for checking the state of custom domain section
  const [isActiveDomain, setIsActiveDomain] = useState(false);

  // State for storing the list of custom domains
  const [customDomainList, setCustomDomainList] = useState<ICustomDomainState[]>([]);

  // Section 3b: Link Expiry Configuration

  // State for checking the state of link expiry section
  const [isActiveLink, setIsActiveLink] = useState(false);

  //State for checking the date picker visibility state
  const [isDatePicker, setIsDatePicker] = useState(false);

  // State for storing the date in epoch format
  const [expiryDate, setExpiryDate] = useState<number>(0);

  // State for storing thee date choose from the calender
  const [selectedDate, setSelectedDate] = useState<Date | DateInterval | undefined>(undefined);

  // Variable to store initial expiry date
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + 7);
  currentDate.setHours(0, 0, 0, 0);

  // Reference and function for clicking outside the datepicker area
  const datepickerRef = useRef<HTMLDivElement>(null);
  useClickOutside(datepickerRef, () => setIsDatePicker(false));

  // State for storing the behaviour of the expiry
  const [selectedExpiry, setSelectedExpiry] = useState<string>('');

  // Section 3c: Upload Limit Override Configuration

  // State for checking the state of upload limit section
  const [isActiveLimit, setIsActiveLimit] = useState(false);

  // State for storing the input of upload limit value
  const [inputUnit, setInputUnit] = useState<string>('100');

  // State for storing the unit of upload limit
  const [selectedUnit, setSelectedUnit] = useState<string>('MiB');

  // State for setting api loading
  const [loading, setLoading] = useState(false);

  // Page Data

  // Fetched device Name and the categories or tags from the API hook
  const { deviceName, tagsCategories, dataLoading } = useCheckDevice(deviceID);

  // Breadcrums data
  const breadCrumsData = [
    {
      href: '/',
      text: t('Common:breadCrums.home'),
    },
    {
      href: `/links/${deviceID}`,
      text: t('Common:breadCrums.deviceLinks'),
    },
    {
      text: t('Common:breadCrums.createLinks'),
    },
  ];

  // Create references for CustomDomain, LinkExpiry, SetLimit component
  const lastDomainRef = useRef<HTMLDivElement>(null);
  const linkExpiryRef = useRef<HTMLDivElement>(null);
  const limitRef = useRef<HTMLDivElement>(null);

  // Section 1: Type Configuration

  // Variable with options for the link type dropdown
  const linkOptions = [
    {
      value: 'Edge UI',
      label: t('CreateLinks:mainContent.typeConfig.typeOptions.option1'),
    },
    {
      value: 'Solution UI',
      label: t('CreateLinks:mainContent.typeConfig.typeOptions.option2'),
    },
    {
      value: 'Other Links',
      label: t('CreateLinks:mainContent.typeConfig.typeOptions.option3'),
    },
  ];

  // Function for updating the selected link type value
  const handleChangeLinkType = (value: string) => {
    if (value === 'Edge UI') {
      setSelectedLinkValue(value);
      setInputLinkName(t('CreateLinks:mainContent.typeConfig.typeOptions.option1'));
      setSelectedProtocol('HTTPS');
      setInputPort('20001');
    } else if (value === 'Solution UI') {
      setSelectedLinkValue(value);
      setInputLinkName(t('CreateLinks:mainContent.typeConfig.typeOptions.option2'));
      setSelectedProtocol('HTTPS');
      setInputPort('80');
    } else {
      setSelectedLinkValue(value);
      setInputLinkName('');
      setSelectedProtocol('');
      setInputPort('');
    }
  };

  // Function for updating the webgate link name
  const handleLinkNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputLinkName(e.target.value);
  };

  useEffect(() => {
    // Update linkName based on translations
    setInputLinkName((prev) => {
      return ['Edge UI', 'デバイス管理UI'].includes(prev) && inputPort === '20001'
        ? t('EditLink:mainContent.typeConfig.typeOptions.option1')
        : ['Solution UI', 'ソリューションUI'].includes(prev) && inputPort === '80'
          ? t('EditLink:mainContent.typeConfig.typeOptions.option2')
          : prev;
    });
  
  }, [inputPort, t]);

  // Section 2: Basic Configuration

  // Variable with options for the protocol type dropdown
  const protocolOptions = [
    {
      value: 'HTTPS',
      label: t('CreateLinks:mainContent.basicConfig.typeOptions.option1'),
    },
    {
      value: 'RTMPS',
      label: t('CreateLinks:mainContent.basicConfig.typeOptions.option2'),
    },
  ];

  // Function for updating the selected protocol type value
  const handleChangeType = (value: string) => {
    if (value === 'RTMPS') {
      setInputPort('8443');

      //set default value of noIndex
      setIndexToggle(true);

      // close custom domain section and set default values
      setIsActiveDomain(false);
      setCustomDomainList([]);

      // close upload limit section and set default values
      setIsActiveLimit(false);
      setInputUnit('100');
      setSelectedUnit('MiB');
    } else {
      setInputPort('');
    }
    setSelectedProtocol(value);
  };

  // Funtion for setting the link port
  const handleChangePort = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value; 
    if(inputValue.length <= 5){
      setInputPort(e.target.value);
    }
  };

  // Section 3: Advanced Configuration

  // Section 3a: Custom Domain Configuration

  // Function for updatng the isActiveDomain state and setting the initial values in customDomainList state
  const handleDomain = useCallback(() => {
    setCustomDomainList((prev) => {
      const updatedList = [
        ...prev,
        { 
          id: prev.length + 1, 
          domain: '', 
          privateKey: '', 
          certificate: '' 
        },
      ];
      
      setTimeout(() => {
        // Scroll to the last custom domain
        lastDomainRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 0);
      
      return updatedList;
    });
  
    setIsActiveDomain(true);
  }, []);

  // Function for updatng the individial keys in the customDomainList state
  const handleInputDomainChange = (
    id: number,
    field: keyof ICustomDomainState,
    value: string
  ) => {
    setCustomDomainList((prev) =>
      prev.map((domain) =>
        domain.id === id ? { ...domain, [field]: value.trim() } : domain
      )
    );
  };

  // Funcion for removing the custom domains one by one from the list
  const removeCustomDomain = (id: number) => {
    setCustomDomainList((prev) => {
      // Filter out the domain with the specified ID
      const updatedList = prev.filter((domain) => domain.id !== id);
      const returnList = updatedList.map((domain, index) => ({
        ...domain,
        id: index + 1,
      }));
      if (returnList.length === 0) {
        setIsActiveDomain(false);
      }
      // Update IDs of remaining domains
      return returnList;
    });
  };

  // Function for checking if the 3 entries in the last custom domain are filled
  const isLastDomainFilled = () => {
    if (customDomainList.length === 0) return false; // No domains
    const lastDomain = customDomainList[customDomainList.length - 1];
    return (
      lastDomain.domain.trim() !== '' &&
      lastDomain.privateKey.trim() !== '' &&
      lastDomain.certificate.trim() !== ''
    );
  };

  // Section 3b: Link Expiry Configuration

  // Function for closing the link expiry section
  const handleLink = () => {
    setIsActiveLink(true);
    setTimeout(() => {
      linkExpiryRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, 0);
  };

  // Variable with options for the expiry behaviour dropdown
  const expiryOptions = [
    {
      value: 'Disable',
      label: t('CreateLinks:mainContent.linkConfig.expiryOptions.option1'),
    },
    {
      value: 'Delete',
      label: t('CreateLinks:mainContent.linkConfig.expiryOptions.option2'),
    },
  ];

  // Function for updating the selected expiry behaviour value
  const handleChangeExpiry = (value: string) => {
    setSelectedExpiry(value);
  };

  // Section 3c: Upload Limit Override Configuration

  // Function for closing the limit override section
  const handleLimit = () => {
    setIsActiveLimit(true);
    setTimeout(() => {
      limitRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, 0);
  };

  // Funtion for changing the value of input limit in the state
  const handleInputUnit = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    if(inputValue.length <= 6){
      setInputUnit(e.target.value);
    }
  };

  // Variable with options for the upload limit unit dropdown
  const unitOptions = [
    {
      value: 'MiB',
      label: t('CreateLinks:mainContent.limitConfig.unitOption1'),
    },
    {
      value: 'GiB',
      label: t('CreateLinks:mainContent.limitConfig.unitOption2'),
    },
  ];

  // Function for updating the upload limit unit value
  const handleChangeUnit = (value: string) => {
    setSelectedUnit(value);
  };

  // Fnution for redirecting the users from the create link page to device links page
  const handleCancel = () => {
    history.push(`/links/${deviceID}`);
  };

  // Create Link API Call
  const handleCreateDeviceLink = useCallback(async () => {

    // Normalizing the Input Port using a common funciton
    const updatedInputPort = convertAndValidateInput(
      inputPort,
      'CreateLinks:notificationMessage.portCheck',
      handleDismiss,
      sendNotification,
      t
    );
    if (Number.isNaN(updatedInputPort) || updatedInputPort === null) return;
    setInputPort(updatedInputPort);

    // Normalizing the Input Port using a common funciton
    const normalizedInputUnit = convertAndValidateInput(
      inputUnit,
      'CreateLinks:notificationMessage.enterValidUploadLimit',
      handleDismiss,
      sendNotification,
      t
    );
    const updatedInputUnit = parseInt(normalizedInputUnit || '');
    if (Number.isNaN(updatedInputUnit) || updatedInputUnit === null) return;
    setInputUnit(updatedInputUnit.toString());

    if (
      !validateInputs(
        selectedLinkValue,
        inputLinkName,
        selectedProtocol,
        updatedInputPort,
        isActiveDomain,
        customDomainList,
        isActiveLink,
        expiryDate,
        selectedExpiry,
        isActiveLimit,
        updatedInputUnit.toString(),
        selectedUnit,
        sendNotification,
        t
      )
    ) {
      return;
    }

    try {
      setLoading(true);

      // Webgate feature switch state setting function
      setSwitchState('locked');

      // Check for webgate feature
      const enabled = await isWebgateEnabled();
      // if API returns a response then set the featue enabled state
      if (enabled !== null) setIsFeatureEnabled(enabled);
      // if the feature is disaled then exit from the loop
      if (enabled === false || !enabled) {
        handleDismiss(); // Clear all notification bar before sending a new one
        sendNotification({
          type: 'warning',
          message: t('Common:notificationMessage.webgateFeatureDisabled'),
        });
        return;
      }

      const linkData = await createWebgateDeviceLink(
        deviceID,
        'create',
        {
          name: inputLinkName.trim() === 'デバイス管理UI' && updatedInputPort === '20001' ? 'Edge UI' : inputLinkName.trim() === 'ソリューションUI' && updatedInputPort === '80'? 'Solution UI' : inputLinkName.trim(),
          port: Number(updatedInputPort),
          protocol: selectedProtocol,
        },
        {
          noIndex: indexToggle,
          linkExpiry: {
            time: expiryDate,
            action: ['Solution UI', 'Edge UI'].includes(selectedLinkValue) && selectedExpiry === 'Delete' ? 'Flush' : selectedExpiry,
          },
          linkUploadLimit: {
            uploadLimit: Number(updatedInputUnit),
            uploadLimitUnit: selectedUnit,
          },
        }
      );

      if (!linkData) throw new Error(ErrorType.PARSE);
      if (linkData.createWebgateDeviceLink?.linkCreateStatus) {
        handleDismiss(); // Clear all notification bar before sending a new one
        // Notify the user of success
        sendNotification({
          type: 'success',
          message: t('CreateLinks:notificationMessage.success')
            .replace('{linkName}', inputLinkName.trim()),
        });

        if (linkData.createWebgateDeviceLink.linkID) {
          setLinkID(linkData.createWebgateDeviceLink.linkID.concat('.webgate-biz.scorer.jp'));

          for (const domainData of customDomainList) {
            try {
              const domainUpdateData = await updateWebgateCustomDomain(
                deviceID,
                linkData.createWebgateDeviceLink.linkID,
                Number(inputPort),
                domainData.domain,
                domainData.privateKey,
                domainData.certificate
              );

              if (
                !domainUpdateData?.updateWebgateCustomDomain?.domainUpdateStatus
              ) {
                handleDismiss(); // Clear all notification bar before sending a new one
                await new Promise((resolve) => setTimeout(resolve, 300));
                sendNotification({
                  type: 'error',
                  message: t('CreateLinks:notificationMessage.domainUpdateFailed')
                    .replace('{domain}', domainData.domain)
                    .replace('{linkName}', inputLinkName.trim()),
                });
              } else {
                handleDismiss(); // Clear all notification bar before sending a new one
                await new Promise((resolve) => setTimeout(resolve, 300));
                sendNotification({
                  type: 'success',
                  message: t('CreateLinks:notificationMessage.domainUpdateSuccess')
                    .replace('{domain}', domainData.domain)
                    .replace('{linkName}', inputLinkName.trim()),
                });
              }
            } catch (error) {
              const exception = handleException(error);
              console.error(
                `Error creating domain:${domainData.domain}, ${error}`
              );
              setSwitchState('default');

              handleDismiss(); // Clear all notification bar before sending a new one
              await new Promise((resolve) => setTimeout(resolve, 300));
              sendNotification({
                type: 'error',
                message: t('CreateLinks:notificationMessage.errorUpdatingDomain')
                  .replace('{domain}', domainData.domain)
                  .replace('{linkName}', inputLinkName.trim())
                  .replace('{errorMessage}', t(`Common:exceptionNotificationMessages.${exception.message}`)),
              });
              return;
            }
          }
        }
        // After processing all domains successfully, navigate to links page
        await new Promise((resolve) => setTimeout(resolve, 3000));
        history.push(`/links/${deviceID}`);
        return;
      } else if (linkData.createWebgateDeviceLink?.linkExists) {
        handleDismiss(); // Clear all notification bar before sending a new one
        setSwitchState('default');
        sendNotification({
          type: 'info',
          message: t('CreateLinks:notificationMessage.alreadyExist').replace(
            '{deviceName}',
            deviceName
          ),
        });
      }
    } catch (error) {
      const exception = handleException(error);
      console.error('Error creating device link:', exception.message);
      // Webgate feature switch state setting function
      setSwitchState('default');

      handleDismiss(); // Clear all notification bar before sending a new one
      sendNotification({
        type: 'error',
        message: t('CreateLinks:notificationMessage.error')
          .replace('{linkName}', inputLinkName.trim())
          .replace('{errorMessage}', t(`Common:exceptionNotificationMessages.${exception.message}`)),
      });
    } finally {
      setLoading(false);
    }
  }, [
    t,
    deviceID,
    inputLinkName,
    inputPort,
    selectedProtocol,
    customDomainList,
    isActiveLink,
    expiryDate,
    selectedExpiry,
    inputUnit,
    selectedUnit,
    sendNotification,
    deviceName,
    history,
    indexToggle,
    isActiveDomain,
    isActiveLimit,
    selectedLinkValue,
    setIsFeatureEnabled,
    setSwitchState,
  ]);

  // Function for generating the custom domain fields on the ui
  const generateCustomDomain = useCallback(
    (domain: ICustomDomainState) => {
      return (
        <CustomDomain key={domain.id}>
          <Title button>
            <FormGroupTitle>
              <ColumnText fontSize='16px' color='#5a6269' family='monorale'>
                {t('CreateLinks:mainContent.customConfig.heading')}{' '}
                {customDomainList.length > 1 ? domain.id : ''}
              </ColumnText>
              <Line />
            </FormGroupTitle>
            <RemoveButtonTag>
              <Button
                design='secondary'
                size='xsmall'
                onClick={() => removeCustomDomain(domain.id)}
                disabled={loading}
              >
                {t('CreateLinks:mainContent.customConfig.removeButton')}
              </Button>
            </RemoveButtonTag>
          </Title>
          <DomainLink
            name='domain'
            value={domain.domain}
            label={t('CreateLinks:mainContent.customConfig.domain')}
            fieldState={loading ? 'disabled' : 'default'}
            disabled={loading}
            placeholder='subdomain.yourdomain.com'
            autoComplete='off'
            minWidth='940px'
            onChange={(e) => handleInputDomainChange(domain.id, 'domain', e.target.value)}
          />
          <Frame>
            <CustomTextArea
              id={`${domain.id}privateKey`}
              name={`${domain.id}privateKey`}
              label={t('CreateLinks:mainContent.customConfig.privateKey')}
              fieldState={loading ? 'disabled' : 'default'}
              disabled={loading}
              autoComplete='off'
              placeholder={t('CreateLinks:mainContent.customConfig.privatePlaceHolder')}
              value={domain.privateKey}
              onChange={(e) => handleInputDomainChange(domain.id, 'privateKey', e.target.value)}
            />
            <CustomTextArea
              id={`${domain.id}certificate`}
              name={`${domain.id}certificate`}
              label={t('CreateLinks:mainContent.customConfig.certificate')}
              fieldState={loading ? 'disabled' : 'default'}
              disabled={loading}
              autoComplete='off'
              placeholder={t('CreateLinks:mainContent.customConfig.certificatePlaceHolder')}
              value={domain?.certificate}
              onChange={(e) => handleInputDomainChange(domain.id, 'certificate', e.target.value)}
            />
          </Frame>
        </CustomDomain>
      );
    },
    [customDomainList, loading, t]
  );

  return (
    <MainContainer>
      <Wrapper enableContent={isFeatureEnabled}>
        <SectionContainer height='72px' gap='8px' padding='48px 99px 0'>
          <Drawer>
            <Breadcrums backLink showBreadcrumbs breadcrumbs={breadCrumsData} />
          </Drawer>
        </SectionContainer>
        <SectionContainer padding='16px 0 4px' gap='8px'>
          <SectionContainer gap='8px' padding='24px 96px 34px'>
            <SectionContainer padding='0'>
              <SectionContainer gap='24px' padding='0 32px 6px 0' grow={1}>
                <DeviceInfo dataLoading={dataLoading}>
                  <PageHeader
                    icon={`${dataLoading ? '' : 'Link'}`}
                    areaTitleBottom
                    areaTitle={deviceName}
                    title={t('CreateLinks:pageTitle')}
                  />
                  {dataLoading && (
                    <SpinnerContainer>
                      <Spinner size='medium' styling='primary' />
                    </SpinnerContainer>
                  )}
                </DeviceInfo>
                <SectionContainer gap='16px' padding='2px 0'>
                  <SectionContainer direction='column' padding='0px' gap='19px'>
                    {tagsCategories.length !== 0 && (
                      <DeviceTagCategory>
                        {tagsCategories.map((data, index) => {
                          return (
                            <IconButton
                              key={index}
                              background='transparent'
                              design='secondary'
                              size='small'
                              border='solid 1px #e6e8ec'
                              height='max-content'
                              gap='2px'
                              padding='0px 0px 0px 1px'
                            >
                              <IconWrapper marginTop='0px'>
                                <Icon
                                  size={8}
                                  icon={data.icon}
                                  color='dimmed'
                                  weight='regular'
                                />
                              </IconWrapper>
                              <ColumnText
                                fontSize='12px'
                                padding='0px 6px 0px 0px'
                              >
                                {data.text}
                              </ColumnText>
                            </IconButton>
                          );
                        })}
                      </DeviceTagCategory>
                    )}
                    <PageHeaderText>
                      {t('CreateLinks:introductionText')}
                    </PageHeaderText>
                  </SectionContainer>
                </SectionContainer>
              </SectionContainer>
            </SectionContainer>
          </SectionContainer>
          <LineDivider />
        </SectionContainer>
        <MainSection>
          <InnerMainContainer>
            <ModalContentCreateLink>
              <ConfigurationBlock>
                <FormGroupTitle>
                  <ColumnText fontSize='16px' color='#5a6269' family='monorale'>
                    {t('CreateLinks:mainContent.typeConfig.heading')}
                  </ColumnText>
                  <Line />
                </FormGroupTitle>
                <FieldRow>
                  <LinkTypeField
                    label={{
                      htmlFor: 'linkType',
                      text: t('CreateLinks:mainContent.typeConfig.linkType'),
                    }}
                    changeCallback={handleChangeLinkType}
                    placeholder={t('CreateLinks:mainContent.typeConfig.placeHolder1')}
                    disabled={loading}
                    value={selectedLinkValue}
                    minWidth='220px'
                    required
                  >
                    {' '}
                    {linkOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </LinkTypeField>
                  <DomainLink
                    name='link_name'
                    id='link_name'
                    minWidth='420px'
                    divWidth='420px'
                    width='420px'
                    paddingRight='0px'
                    autoComplete='off'
                    maxLength={100}
                    label={t('CreateLinks:mainContent.typeConfig.linkName')}
                    placeholder={t('CreateLinks:mainContent.typeConfig.placeHolder2')}
                    value={inputLinkName}
                    onChange={handleLinkNameChange}
                    fieldState={loading || ['Solution UI', 'Edge UI'].includes(selectedLinkValue) || !selectedLinkValue ? 'disabled' : 'default'}
                    disabled={loading || ['Solution UI', 'Edge UI'].includes(selectedLinkValue) || !selectedLinkValue}
                  />
                </FieldRow>
              </ConfigurationBlock>

              <ConfigurationBlock>
                <FormGroupTitle>
                  <ColumnText fontSize='16px' color='#5a6269' family='monorale'>
                    {t('CreateLinks:mainContent.basicConfig.heading')}
                  </ColumnText>
                  <Line />
                </FormGroupTitle>
                <FieldRow>
                  <LinkTypeField
                    label={{
                      htmlFor: 'protocol',
                      text: t('CreateLinks:mainContent.basicConfig.protocol'),
                    }}
                    value={selectedProtocol}
                    placeholder={t('CreateLinks:mainContent.basicConfig.typeOptions.placeHolder')}
                    changeCallback={handleChangeType}
                    disabled={loading || ['Solution UI', 'Edge UI'].includes(selectedLinkValue) || !selectedLinkValue}
                    minWidth='250px'
                    required
                  >
                    {' '}
                    {protocolOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </LinkTypeField>
                  <DomainLink
                    name='domain'
                    id='domain'
                    divWidth='576px'
                    paddingRight='0px'
                    label={t('CreateLinks:mainContent.basicConfig.domain')}
                    placeholder='[link_id].webgate-biz.scorer.jp'
                    value={linkID}
                    fieldState='disabled'
                    disabled
                  />
                  <DomainLink
                    name='port_number'
                    id='port_number'
                    divWidth='auto'
                    paddingRight='0px'
                    label={t('CreateLinks:mainContent.basicConfig.portNumber')}
                    placeholder={t('CreateLinks:mainContent.basicConfig.placeHolder')}
                    autoComplete='off'
                    value={inputPort}
                    type='text'
                    inputMode='numeric'
                    onChange={handleChangePort}
                    fieldState={
                      !selectedLinkValue
                        ? 'disabled'
                        : selectedProtocol === 'RTMPS' ||
                          loading ||
                          ['Solution UI', 'Edge UI'].includes(selectedLinkValue)
                          ? 'disabled'
                          : 'default'
                    }
                    disabled={
                      selectedProtocol === 'RTMPS' ||
                      loading ||
                      ['Solution UI', 'Edge UI'].includes(selectedLinkValue) ||
                      !selectedLinkValue
                    }
                  />
                </FieldRow>
              </ConfigurationBlock>

              <ConfigurationBlock marginBottom='4px'>
                <FormGroupTitle>
                  <ColumnText fontSize='16px' color='#5a6269' family='monorale'>
                    {t('CreateLinks:mainContent.advancedConfig.heading')}
                  </ColumnText>
                  <Line />
                </FormGroupTitle>
                <FieldRow
                  padding='0px'
                  gap='48px'
                  justifyContent='space-between'
                >
                  <AdvanceOptions minWidth='764px'>
                    <ColumnText
                      color='#889096'
                      fontSize='14px'
                      family='monorale'
                      height='16.5px'
                    >
                      {t('CreateLinks:mainContent.advancedConfig.addOptions')}
                    </ColumnText>
                    <ButtonWithIconWrapper>
                      <AdvanceButtons>
                        <ButtonWithIcon
                          icon='Add'
                          design='secondary'
                          shadow
                          size='normal'
                          position='left'
                          onClick={handleDomain}
                          disabled={isActiveDomain || loading || selectedProtocol === 'RTMPS'}
                        >
                          {t('CreateLinks:mainContent.advancedConfig.buttonsText.custom')}
                        </ButtonWithIcon>

                        <ButtonWithIcon
                          icon='Add'
                          design='secondary'
                          shadow
                          size='normal'
                          position='left'
                          onClick={handleLink}
                          disabled={isActiveLink || loading}
                        >
                          {t('CreateLinks:mainContent.advancedConfig.buttonsText.expiry')}
                        </ButtonWithIcon>
                        <ButtonWithIcon
                          icon='Add'
                          design='secondary'
                          shadow
                          size='normal'
                          position='left'
                          onClick={handleLimit}
                          disabled={
                            isActiveLimit ||
                            loading ||
                            selectedProtocol === 'RTMPS'
                          }
                        >
                          {t('CreateLinks:mainContent.advancedConfig.buttonsText.limit')}
                        </ButtonWithIcon>
                      </AdvanceButtons>
                    </ButtonWithIconWrapper>
                  </AdvanceOptions>
                  <AdvanceOptions padding='13px 0px 0px' marginRight='2px'>
                    <ColumnText
                      color='#889096'
                      fontSize='14px'
                      family='monorale'
                    >
                      {t('CreateLinks:mainContent.advancedConfig.search')}
                    </ColumnText>
                    <ToggleSwitch
                      checked={indexToggle}
                      labelText={t(
                        'CreateLinks:mainContent.advancedConfig.switchLabel'
                      )}
                      leftTheme='off'
                      rightTheme='on'
                      state={
                        loading || selectedProtocol === 'RTMPS'
                          ? 'disabled'
                          : 'default'
                      }
                      onChangeCallback={() => setIndexToggle(!indexToggle)}
                      info
                      infoColor='#12a594'
                      infoText={t('CreateLinks:mainContent.advancedConfig.tooltip')}
                      tooltipMinWidth='325px'
                    />
                  </AdvanceOptions>
                </FieldRow>
              </ConfigurationBlock>

              {isActiveDomain && (
                <CustomDomain>
                  <ModalContentCreateLink>
                    {customDomainList.map((domain, index) => (
                      <Div
                        key={domain.id}
                        ref={index === customDomainList.length - 1 ? lastDomainRef : null}
                      >
                        {generateCustomDomain(domain)}
                      </Div>
                    ))}
                  </ModalContentCreateLink>
                  <AdvanceOptions marginTop='-4px'>
                    <ButtonWithIcon
                      icon='Add'
                      design='secondary'
                      size='normal'
                      position='left'
                      shadow
                      disabled={customDomainList.length === 5 || !isLastDomainFilled() || loading}
                      onClick={handleDomain}
                    >
                      {t('CreateLinks:mainContent.customConfig.buttonText')}
                    </ButtonWithIcon>
                  </AdvanceOptions>
                </CustomDomain>
              )}
              {isActiveLink && (
                <LinkExpiry ref={linkExpiryRef}>
                  <Title button>
                    <FormGroupTitle>
                      <ColumnText
                        fontSize='16px'
                        color='#5a6269'
                        family='monorale'
                      >
                        {t('CreateLinks:mainContent.linkConfig.heading')}
                      </ColumnText>
                      <Line />
                    </FormGroupTitle>
                    <RemoveButtonTag>
                      <Button
                        design='secondary'
                        size='xsmall'
                        onClick={() => {
                          setIsActiveLink(false);
                          setExpiryDate(0);
                          setSelectedDate(undefined);
                          setSelectedExpiry('');
                        }}
                        disabled={loading}
                      >
                        {t('CreateLinks:mainContent.linkConfig.removeButton')}
                      </Button>
                    </RemoveButtonTag>
                  </Title>
                  <FieldRow padding='0px'>
                    <DatePickerSection ref={datepickerRef}>
                      <DomainLink
                        id='time_date_of_expiry'
                        name='time_date_of_expiry'
                        label={t('CreateLinks:mainContent.linkConfig.subHeading1')}
                        placeholder={t('CreateLinks:mainContent.linkConfig.placeHolder1')}
                        fieldState={loading ? 'disabled' : 'default'}
                        disabled={loading}
                        value={selectedDate ? formatCalendarDateTime(selectedDate as Date) : ''}
                        onClick={() => setIsDatePicker(!isDatePicker)}
                        readOnly
                      />
                      <IconWrapper
                        position='absolute'
                        right='17px'
                        top='53%'
                        onClick={() => setIsDatePicker(!isDatePicker)}
                      >
                        <Icon size={16} icon='DateTime' color='dimmed' />
                      </IconWrapper>
                      {isDatePicker && (
                        <DateDrawer>
                          <DatePicker
                            dateMode='single'
                            timeMode='single'
                            availableRange={{start: new Date(), end: null}} 
                            timeZoneValueTitle={getSystemTimezoneAbbreviation()}
                            initialValue={selectedDate || currentDate}
                            updateCallback={(date: Date | DateInterval) => {
                              if (expiryDate !== convertToEpoch(date)) {
                                setExpiryDate(convertToEpoch(date));
                                setSelectedDate(date);
                              }
                            }}
                          />
                        </DateDrawer>
                      )}
                    </DatePickerSection>
                    <LinkTypeField
                      label={{
                        htmlFor: 'expiry',
                        text: t('CreateLinks:mainContent.linkConfig.subHeading2')
                      }}
                      changeCallback={handleChangeExpiry}
                      placeholder={t('CreateLinks:mainContent.linkConfig.placeHolder2')}
                      disabled={loading}
                      value={selectedExpiry}
                      required
                    >
                      {' '}
                      {expiryOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </LinkTypeField>
                  </FieldRow>
                </LinkExpiry>
              )}
              {isActiveLimit && (
                <SetLimit ref={limitRef}>
                  <Title button>
                    <FormGroupTitle>
                      <ColumnText
                        fontSize='16px'
                        color='#5a6269'
                        family='monorale'
                      >
                        {t('CreateLinks:mainContent.limitConfig.heading')}
                      </ColumnText>
                      <Line />
                    </FormGroupTitle>
                    <RemoveButtonTag>
                      <Button
                        design='secondary'
                        size='xsmall'
                        onClick={() => {
                          setIsActiveLimit(false);
                          setInputUnit('100');
                          setSelectedUnit('MiB');
                        }}
                        disabled={loading}
                      >
                        {t('CreateLinks:mainContent.limitConfig.removeButton')}
                      </Button>
                    </RemoveButtonTag>
                  </Title>
                  <FieldRow gap='16px'>
                    <DomainLink
                      id='size'
                      name='size'
                      minWidth='128px'
                      divWidth='128px'
                      label={t('CreateLinks:mainContent.limitConfig.subHeading1')}
                      placeholder={t('CreateLinks:mainContent.limitConfig.placeHolder1')}
                      type='text'
                      inputMode='numeric'
                      autoComplete='off'
                      value={inputUnit}
                      onChange={handleInputUnit}
                      fieldState={loading ? 'disabled' : 'default'}
                      disabled={loading}
                    />
                    <LinkTypeField
                      label={{
                        htmlFor: 'unit',
                        text: t('CreateLinks:mainContent.limitConfig.subHeading2'),
                      }}
                      minWidth='88px'
                      width='88px'
                      value={selectedUnit}
                      defaultValue={selectedUnit}
                      changeCallback={handleChangeUnit}
                      disabled={loading}
                    >
                      {' '}
                      {unitOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </LinkTypeField>
                  </FieldRow>
                  <HelperText>
                    <Icon size={12} icon='Information' />
                    <ColumnText fontSize='12px' color='#76aaf4' height='14.5px'>
                      {' '}
                      {t('CreateLinks:mainContent.limitConfig.helperText')}
                    </ColumnText>
                  </HelperText>
                </SetLimit>
              )}
              <ButtonsSection loading={loading ? 'true' : 'false'}>
                <Button size='large' design='secondary' onClick={handleCancel}>
                  <LinkContainer fontSize='16px'>
                    {t('CreateLinks:mainContent.actionButtons.button1')}
                  </LinkContainer>
                </Button>
                <ButtonWithLoading
                  design='primary'
                  loading={loading}
                  size='large'
                  onClick={handleCreateDeviceLink}
                  disabled={loading || !selectedLinkValue}
                >
                  {t('CreateLinks:mainContent.actionButtons.button2')}
                </ButtonWithLoading>
              </ButtonsSection>
            </ModalContentCreateLink>
          </InnerMainContainer>
        </MainSection>
      </Wrapper>
    </MainContainer>
  );
};

export default CreateLinks;
