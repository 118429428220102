import validator from 'validator';
import { ICustomDomainState } from '../pages/EditLink';
import { handleDismiss } from '../components/Notifications/Notification';

const keyRegex = /-----BEGIN PRIVATE KEY-----[\s\S]+-----END PRIVATE KEY-----/;
const certificateRegex = /-----BEGIN CERTIFICATE-----[\s\S]+-----END CERTIFICATE-----/;



export const validatePortField = (
  selectedLinkValue: string,
  inputPort: string | number,
  selectedProtocol: string,
  sendNotification: (message: { type: 'info'; message: string }) => void,
  t: (key: string) => string
) => {
  handleDismiss() // Clear all notification bar before sending a new one
  const portNumber = Number(inputPort);
  if (selectedLinkValue === 'Other Links' && portNumber === 20001) {
    sendNotification({ type: 'info', message: t('CreateLinks:notificationMessage.portReservedForEdgeUI') });
    return false;
  }

  if ((portNumber < 1024 || portNumber > 49151) && selectedLinkValue === 'Other Links') {
    sendNotification({ type: 'info', message: t('CreateLinks:notificationMessage.portCheck') });
    return false;
  }

  if (selectedProtocol === 'HTTPS' && portNumber === 8443) {
    sendNotification({ type: 'info', message: t('CreateLinks:notificationMessage.invalidPortForHTTPS') });
    return false;
  }
  return true;
};

const validateCustomDomains = (
  customDomainList: Array<{ domain: string; privateKey: string; certificate: string }>,
  sendNotification: (message: { type: 'info'; message: string }) => void,
  t: (key: string) => string
) => {
  handleDismiss() // Clear all notification bar before sending a new one
  const invalidDomains = customDomainList.some(item => !validator.isFQDN(item.domain));
  if (invalidDomains) {
    sendNotification({ type: 'info', message: t('CreateLinks:notificationMessage.invalidCustomDomain') });
    return false;
  }

  const valueArr = customDomainList.map(item => item.domain);
  const isDuplicate = valueArr.some((item, idx) => valueArr.indexOf(item) !== idx);
  if (isDuplicate) {
    sendNotification({ type: 'info', message: t('CreateLinks:notificationMessage.duplicateDomains') });
    return false;
  }

  const invalidPrivateKey = customDomainList.some(item => !keyRegex.test(item.privateKey));
  if (invalidPrivateKey) {
    sendNotification({ type: 'info', message: t('CreateLinks:notificationMessage.invalidPrivateKey') });
    return false;
  }

  const invalidCertificate = customDomainList.some(item => !certificateRegex.test(item.certificate));
  if (invalidCertificate) {
    sendNotification({ type: 'info', message: t('CreateLinks:notificationMessage.invalidCertificate') });
    return false;
  }

  return true;
};


export const validDate = (
  selectedDate: number,
  sendNotification: (message: { type: 'info'; message: string }) => void,
  t: (key: string) => string
) => {
  handleDismiss() // Clear all notification bar before sending a new one
  if (selectedDate === 0) {
    sendNotification({ type: 'info', message: t('CreateLinks:notificationMessage.selectValidDate') });
    return false;
  }
  if (selectedDate && selectedDate * 1000 <= Date.now()) {
    sendNotification({ type: 'info', message: t('CreateLinks:notificationMessage.dateCannotBeEarlier') });
    return false;
  }
  return true;
};

const validateUploadLimit = (
  selectedUnit: string,
  inputUnit: string,
  sendNotification: (message: { type: 'info'; message: string }) => void,
  t: (key: string) => string
) => {
  handleDismiss() // Clear all notification bar before sending a new one
  if (selectedUnit === 'MiB' && Number(inputUnit) > 100000) {
    sendNotification({
      type: 'info',
      message: t('CreateLinks:notificationMessage.maxLimitExceeded')
        .replace('{value}', '100000')
        .replace('{unit}', selectedUnit),
    });
    return false;
  }

  if (selectedUnit === 'GiB' && Number(inputUnit) > 100) {
    sendNotification({
      type: 'info',
      message: t('CreateLinks:notificationMessage.maxLimitExceeded')
        .replace('{value}', '100')
        .replace('{unit}', selectedUnit),
    });
    return false;
  }

  return true;
};


export const validateInputs = (
  selectedLinkValue: string,
  inputLinkName: string,
  selectedProtocol: string,
  inputPort: string | number,
  isActiveDomain: boolean,
  customDomainList: Array<{ domain: string; privateKey: string; certificate: string }>,
  isActiveLink: boolean,
  expiryDate: number,
  selectedExpiry: string,
  isActiveLimit: boolean,
  inputUnit: string,
  selectedUnit: string,
  sendNotification: (message: { type: 'info'; message: string }) => void,
  t: (key: string) => string
): boolean => {
  handleDismiss() // Clear all notification bar before sending a new one
  if (!selectedLinkValue) {
    sendNotification({ type: 'info', message: t('CreateLinks:notificationMessage.selectLinkType') });
    return false;
  }
  if (!inputLinkName.trim()) {
    sendNotification({ type: 'info', message: t('CreateLinks:notificationMessage.enterLinkName') });
    return false;
  }

  if (!selectedProtocol) {
    sendNotification({ type: 'info', message: t('CreateLinks:notificationMessage.selectProtocol') });
    return false;
  }

  if (inputPort === '') {
    sendNotification({ type: 'info', message: t('CreateLinks:notificationMessage.enterPortNumber') });
    return false;
  }

  if (!validatePortField(
    selectedLinkValue,
    inputPort,
    selectedProtocol,
    sendNotification,
    t
  )) {
    return false;
  }

  if (!validateCustomDomains(customDomainList, sendNotification, t)) {
    return false;
  }


  if (isActiveDomain) {
    if (customDomainList.some(item => !item.domain)) {
      sendNotification({ type: 'info', message: t('CreateLinks:notificationMessage.invalidCustomDomain') });
      return false;
    }
    if (customDomainList.some(item => !item.privateKey)) {
      sendNotification({ type: 'info', message: t('CreateLinks:notificationMessage.enterValidPrivateKey') });
      return false;
    }
    if (customDomainList.some(item => !item.certificate)) {
      sendNotification({ type: 'info', message: t('CreateLinks:notificationMessage.enterValidCertificate') });
      return false;
    }
  }

  if (isActiveLink) {
    if (!validDate(
      expiryDate,
      sendNotification,
      t)) {
      return false;
    }
    if (!selectedExpiry) {
      sendNotification({ type: 'info', message: t('CreateLinks:notificationMessage.selectExpiryType') });
      return false;
    }
  }


  if (isActiveLimit) {
    if (!inputUnit || /^0+$/.test(inputUnit) || Number(inputUnit) < 0) {
      sendNotification({ type: 'info', message: t('CreateLinks:notificationMessage.enterValidUploadLimit') });
      return false;
    }
    if (!(validateUploadLimit(selectedUnit,
      inputUnit,
      sendNotification,
      t))) {
      return false;
    }
  }
  return true;
}
// Helper function to validate domain objects
const isInvalidDomainField = (
  domainObj: ICustomDomainState,
  field: 'privateKey' | 'certificate',
  regex: RegExp
): boolean => {
  return domainObj[field]?.editable &&
    (!domainObj[field].value || !regex.test(domainObj[field].value));
};

// Generalized function to validate domain fields
export const validateDomainFields = (
  matchedDomains: string[],
  customDomainList: ICustomDomainState[],
  field: 'privateKey' | 'certificate',
  sendNotification: (message: { type: 'info'; message: string }) => void,
  t: (key: string) => string
): boolean => {
  const regex = field === 'privateKey' ? keyRegex : certificateRegex;
  const notificationMessageKey =
    field === 'privateKey'
      ? 'EditLink:notificationMessage.invalidPrivateKey'
      : 'EditLink:notificationMessage.invalidCertificate';
  // Check if any matched domain has an invalid field
  const isInvalid = matchedDomains.some((item) => {
    const domainObj = customDomainList.find((domain) => domain.domain === item);
    return domainObj ? isInvalidDomainField(domainObj, field, regex) : false;
  });
  handleDismiss() // Clear all notification bar before sending a new one
  if (isInvalid) {
    sendNotification({
      type: 'info',
      message: t(notificationMessageKey),
    });
  }

  return isInvalid;
};
