import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { Icon as SelectIcon } from 'scorer-ui-kit';
import { Link } from 'react-router-dom';
import { Subtract } from '../../svg';
import { useTranslation } from 'react-i18next';

const LinkContainer = styled(Link) <{ paddingBottom?: string }>`
  font-size: 12px !important;
  font-weight: 600 !important;
  line-height: 1.79;
  flex-grow: 0;
  padding-bottom: 0px;
  display: flex;
  align-items: center;
  &:hover {
    text-decoration: none !important;
  }
`;

const TitleWrapper = styled.div`
  min-width: 80px;
  height: 20px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-center;
  align-items: center;
  gap: 8px;
  padding: 3px 8px 3px 4px;
  text-wrap: nowrap;
  font-family: Monorale;
  line-height: normal;
`;

const TableColumnLink = styled.div<{
  expanded?: boolean;
  zIndex?: string;
  border?: string;
  background?: string;
  borderRadius?: string;
}>`
  height: 24px;
  flex-grow: 0;
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: ${({ border }) => (border ? border : 'none')};
  background-color: ${({ background }) => background ? background : 'inherit'};
  padding: 0px;
  border-radius: ${({ expanded, borderRadius }) => expanded ? borderRadius : '3px'};
  z-index: ${({ zIndex }) => (zIndex ? zIndex : 0)};
  box-shadow: 0 2px 4px 2px rgba(0, 0, 51, 0.06);
  font-family: Monorale;
`;

const EnableContainer = styled.div<{ paddingBottom?: string }>`
  font-size: 12px !important;
  font-weight: 600 !important;
  line-height: 1.79;
  flex-grow: 0;
  cursor: pointer;
  padding-bottom: 0px;
  display: flex;
  align-items: center;
  &:hover {
    text-decoration: none !important;
  }
`;

const EnableTitleWrapper = styled.div`
  height: 20px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-center;
  align-items: center;
  gap: 8px;
  padding: 3px 8px 3px 4px;
  text-wrap: nowrap;
  font-family: Monorale;
  line-height: normal;
`;

const TableColumnLinks = styled.div`
  z-index: 2;
`;


const ButtonText = styled.span`
  width: 100%;
  font-size: 12px;
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamily.data};
  text-align: start;
  padding: 3px 8px 3px 4px;
  display: flex;
  align-items: end;
  line-height: 18px;
`;

const TableDropDown = styled.div`
  position: absolute;
  height: 24px;
  display: flex;
  flex-direction: column;
  right: -1px;
  top: -12px;
  max-width: none !important;
  border-radius: 3px;
  background-color: #f5f9ff;
  box-shadow: 0 2px 4px 2px rgba(0, 0, 51, 0.06);
`;

const IconWrapper = styled.div<{
  marginTop?: string;
  width?: string;
  borderLeft?: string;
}>`
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '4px')};
  margin-bottom: 0px;
  width: ${({ width }) => (width ? width : '16px')};
  min-width: ${({ width }) => (width ? width : '')};
  height: 24px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  font-size: 16px !important;
  cursor: pointer;
  border-left: ${({ borderLeft }) => (borderLeft ? borderLeft : 'none')};
  > div:first-child {
    height: 100%;
    display: flex;
    align-items: center; 
  }
`;

interface IDropdownItem {
  displayName: string;
  name: string;
  icon: string;
  action: (linkID: string, name: string, linkType: string) => void;
}

interface IDropDownProps extends React.InputHTMLAttributes<HTMLButtonElement> {
  id: string;
  dropdownRef?: React.RefObject<HTMLDivElement>;
  name: string;
  linkId?: string;
  title: string;
  linkType: 'Edge UI' | 'Solution UI' | 'standard' | 'other';
  link: string;
  expanded: boolean;
  titleIcon?: string;
  hideArrow?: boolean;
  progressCheck?: number;
  status: string;
  connection: string;
  dropdownItems: IDropdownItem[];
  toggleExpanded: (
    toggleId: string,
    linkType: string,
    expanded: boolean
  ) => void;
}

const GenericDropdown = forwardRef<HTMLDivElement, IDropDownProps>(
  (
    {
      id,
      name,
      linkId,
      title,
      linkType,
      expanded,
      link,
      titleIcon,
      dropdownItems,
      toggleExpanded,
      hideArrow,
      status,
      connection,
      progressCheck = 0
    },
    dropdownRef
  ) => {
    // To use the text transalation files (English - Japanese)
    const { t } = useTranslation(['DeviceLinks', 'Common']);

    const Item = dropdownItems[0];

    const getArrow = (
      expanded = false,
      hideArrow = false,
      toggleExpandedFunction: (id: string, linkType: string, expanded: boolean) => void,
      id: string,
      linkType: 'Edge UI' | 'Solution UI' | 'standard' | 'other'
    ) => {
      if (!hideArrow) {
        return (
          <IconWrapper
            marginTop='0px'
            borderLeft='1px solid rgb(217, 218, 217)'
            width='27px'
            onClick={() => {
              if (!progressCheck) toggleExpandedFunction(id, linkType, expanded)
            }}
          >
            {expanded ? (
              <SelectIcon size={8} color='dimmed' icon='Up' />
            ) : (
              <SelectIcon size={8} color='dimmed' icon='Down' />
            )}
          </IconWrapper>
        );
      } else {
        return null;
      }
    };

    return (
      <TableDropDown ref={dropdownRef}>
        <TableColumnLink
          expanded={expanded}
          zIndex='1'
          background='#f5f9ff'
          border='solid 1px #E8E8EC'
          borderRadius='3px 3px 0px 0px'
        >
          {(status === t('DeviceLinks:deviceStatusSection.text5').toLowerCase() || connection === 'RTMPS') && (
            <EnableContainer
              rel="noopener noreferrer"
              onClick={() => {
                if (!progressCheck) {
                  toggleExpanded(id, linkType, true);
                  Item?.action(linkId ? linkId : '', name, linkType);
                }
              }}
            >
              {Item?.icon && (
                <IconWrapper marginTop="0px" width="23px">
                  {Item?.icon === "Subtract" ? <Subtract /> : <SelectIcon size={12} color="dimmed" icon={Item?.icon} />}
                </IconWrapper>
              )}
              <EnableTitleWrapper>
                {link === '#' ? '-' : Item?.displayName}
              </EnableTitleWrapper>
            </EnableContainer>
          )}
          {!(status === t('DeviceLinks:deviceStatusSection.text5').toLowerCase() || connection === 'RTMPS') && (
            <LinkContainer to={link} rel='noopener noreferrer' onClick={() => toggleExpanded(id, linkType, true)}>
            {titleIcon && (
              <IconWrapper marginTop='0px' width='23px'>
                <SelectIcon size={12} color='dimmed' icon={titleIcon} />
              </IconWrapper>
            )}
            <TitleWrapper>
              {link === '#' ? '-' : title}
            </TitleWrapper>
          </LinkContainer>
          )}
          {getArrow(expanded, hideArrow, toggleExpanded, id, linkType)}
        </TableColumnLink >
        {expanded && (
          <TableColumnLinks
            onClick={() => toggleExpanded(id, linkType, expanded)}
          >
            {dropdownItems
              .filter((dropdownItem) => {
                if (connection === 'RTMPS' && status !== t('DeviceLinks:deviceStatusSection.text5').toLowerCase())
                  return dropdownItem.name !== 'Disable';
                if (connection === 'RTMPS' && status === t('DeviceLinks:deviceStatusSection.text5').toLowerCase())
                  return (
                    dropdownItem.name !== 'Enable' &&
                    dropdownItem.name !== 'View Usage'
                  );
                if (status === t('DeviceLinks:deviceStatusSection.text5').toLowerCase())
                  return dropdownItem.name !== 'Enable';
                return dropdownItem;
              }).map((item, index) => (
              <TableColumnLink
                key={index}
                onClick={() => { item.action(linkId ? linkId : '', name, linkType) }}
                expanded={expanded}
                zIndex='2 !important'
                border='1px solid #E8E8EC'
                background='#f5f9ff'
                borderRadius={index === dropdownItems.length - 1 ? '0px 0px 3px 3px' : '0px'}
              >
                <IconWrapper marginTop='0px' width='23px'>
                {item.icon === "Subtract" ? <Subtract /> : <SelectIcon icon={item.icon} size={12} color='dimmed' />}
                </IconWrapper>
                <ButtonText>{item.displayName}</ButtonText>
              </TableColumnLink>
            ))}
          </TableColumnLinks>
        )}
      </TableDropDown>
    );
  })
export default GenericDropdown;
