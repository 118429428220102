
export enum ErrorType {
  NETWORK = 'NetworkError',
  GRAPHQL = 'GraphQLError',
  API = 'APIError',
  PARSE = 'ParseError',
  TIMEOUT = 'TimeoutError',
  CORS = 'CROSError',
  ABORT = 'AbortError',
  INVALID_URL = 'InvalidUrlError',
  RATE_LIMIT = 'RateLimitError',
  UNAUTHORIZED = 'UnauthorizedError',
  SERVER_ERROR = 'ServerError',
  FORBIDDEN = 'ForbiddenError',
  NOT_FOUND = 'NotFoundError',
  MEMORY = 'MemoryError',
  UNKNOWN = 'UnknownError',
  TIMEOUT_CONNECTION = 'TimeoutConnectionError',
  DNS = 'DNSError',
  CONNECTION_REFUSED = 'ConnectionRefusedError',
  RESPONSE_FORMAT = 'ResponseFormatError',
  INVALID_TOKEN = 'InvalidTokenError',
  DUPLICATE_REQUEST = 'DuplicateRequestError',
}

interface BaseError {
  type: ErrorType;
  message: string;
  code?: string;
  statusCode?: number;
}

const isBaseError = (error: unknown): error is BaseError => {
  return (
    typeof error === 'object' &&
    error !== null &&
    'message' in error &&
    typeof (error as { message: unknown }).message === 'string'
  );
};

const handleNetworkError = (error: BaseError,) => {
  return { type: ErrorType.NETWORK, message: error.message };
};

const handleGraphQLError = (error: BaseError,) => {
  return {
    type: ErrorType.GRAPHQL,
    message: error.message,
    code: error.code,
    statusCode: error.statusCode,
  };
};

const handleAPIError = (error: BaseError) => {
  return {
    type: ErrorType.API,
    message: error.message,
    code: error.code,
    statusCode: error.statusCode,
  };
};

const handleParseError = (error: BaseError) => {
  return {
    type: ErrorType.PARSE,
    message: 'parsingError',
    code: error.code,
  };
};



const handleTimeoutError = () => {
  return { type: ErrorType.TIMEOUT, message: 'timeoutError' };
};

const handleCROSError = () => {
  return { type: ErrorType.CORS, message: 'corsError' };
};

const handleAbortError = () => {
  return { type: ErrorType.ABORT, message: 'abortError' };
};

const handleInvalidUrlError = () => {
  return { type: ErrorType.INVALID_URL, message: 'invalidUrlError' };
};

const handleRateLimitError = () => {
  return { type: ErrorType.RATE_LIMIT, message: 'rateLimitError' };
};

const handleUnauthorizedError = () => {
  return { type: ErrorType.UNAUTHORIZED, message: 'rateLimitError' };
};

const handleServerError = () => {
  return { type: ErrorType.SERVER_ERROR, message: 'serverError' };
};

const handleForbiddenError = () => {
  return { type: ErrorType.FORBIDDEN, message: 'forbiddenError' };
};

const handleNotFoundError = () => {
  return { type: ErrorType.NOT_FOUND, message: 'notFoundError' };
};

const handleMemoryError = () => {
  return { type: ErrorType.MEMORY, message: 'memoryError' };
};

const handleTimeoutConnectionError = () => {
  return { type: ErrorType.TIMEOUT_CONNECTION, message: 'timeoutConnectionError' };
};

const handleDNSError = () => {
  return { type: ErrorType.DNS, message: 'dnsError' };
};

const handleConnectionRefusedError = () => {
  return { type: ErrorType.CONNECTION_REFUSED, message: 'connectionRefusedError' };
};

const handleResponseFormatError = () => {
  return { type: ErrorType.RESPONSE_FORMAT, message: 'responseFormatError' };
};

const handleInvalidTokenError = () => {
  return { type: ErrorType.INVALID_TOKEN, message: 'invalidTokenError' };
};

const handleDuplicateRequestError = () => {
  return { type: ErrorType.DUPLICATE_REQUEST, message: 'duplicateRequestError' };
};

const handleUnknownError = (error: BaseError) => {
  return { type: ErrorType.UNKNOWN, message: error.message };
};

// Centralized error handler
export const handleException = (error: unknown) => {
  if (isBaseError(error)) {
    switch (error.message) {
    case ErrorType.NETWORK:
      return handleNetworkError(error);
    case ErrorType.GRAPHQL:
      return handleGraphQLError(error);
    case ErrorType.API:
      return handleAPIError(error);
    case ErrorType.PARSE:
      return handleParseError(error);
    case ErrorType.TIMEOUT:
      return handleTimeoutError();
    case ErrorType.CORS:
      return handleCROSError();
    case ErrorType.ABORT:
      return handleAbortError();
    case ErrorType.INVALID_URL:
      return handleInvalidUrlError();
    case ErrorType.RATE_LIMIT:
      return handleRateLimitError();
    case ErrorType.UNAUTHORIZED:
      return handleUnauthorizedError();
    case ErrorType.SERVER_ERROR:
      return handleServerError();
    case ErrorType.FORBIDDEN:
      return handleForbiddenError();
    case ErrorType.NOT_FOUND:
      return handleNotFoundError();
    case ErrorType.MEMORY:
      return handleMemoryError();
    case ErrorType.TIMEOUT_CONNECTION:
      return handleTimeoutConnectionError();
    case ErrorType.DNS:
      return handleDNSError();
    case ErrorType.CONNECTION_REFUSED:
      return handleConnectionRefusedError();
    case ErrorType.RESPONSE_FORMAT:
      return handleResponseFormatError();
    case ErrorType.INVALID_TOKEN:
      return handleInvalidTokenError();
    case ErrorType.DUPLICATE_REQUEST:
      return handleDuplicateRequestError();
    default:
      return handleUnknownError(error);
    }
  }

  return { type: ErrorType.UNKNOWN, message: 'unknownError' };
};
